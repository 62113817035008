import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import AvailabilityTimesForm from './AvailabilityTimesForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonContainer: {
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    addButton: {
      width: '100%',
      marginTop: theme.spacing(3)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  availabilityTimes?: AvailabilityTimes | null;
  onSave: (availabilityTimes?: AvailabilityTimes | null) => void;
  title: string;
  showLimit: boolean;
}

const ServiceTimesDialog: React.FC<Props> = ({
  open,
  onClose,
  availabilityTimes,
  onSave,
  title,
  showLimit
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {title}
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Alert variant="filled" severity="warning">
            <AlertTitle>Achtung!</AlertTitle>
            Sobald Sie hier Zeiten eingeben ist diese Bestellart / Produktgruppe
            nur an diesem Tag und nicht für anderen Tage verfügbar.
            <br />
            Sind keine Zeiten angegeben, gelten die Öffnungszeiten
          </Alert>
          <AvailabilityTimesForm
            initialAvailabilityTimes={availabilityTimes}
            onSave={onSave}
            showLimit={showLimit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ServiceTimesDialog;
