import { useQuery } from '@apollo/client';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TABLE_TYPES } from '../constants';
import { GetTablesQueryDocument } from '../services/graphql/typed-operations';
import { gastronomyState } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';
import GastronomyUnreachableOverrideStatus from './GastronomyUnreachableOverrideStatus';
import OrderTypeDisabledUntilSwitch from './OrderTypeDisabledUntilSwitch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2)
    }
  })
);

const SideMenuSwitches: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const tableTypes =
    (gastronomy && gastronomy.options.tableTypes) || TABLE_TYPES;

  const { data, loading } = useQuery(GetTablesQueryDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'not set',
        status: 'ALL'
      }
    },
    skip: !gastronomy
  });

  if (!gastronomy || !gastronomy.gastronomyFee) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <GastronomyUnreachableOverrideStatus gastronomy={gastronomy} />
              }
              label="Geöffnet"
            />
            {data &&
              tableTypes.map((t, i) => (
                <OrderTypeDisabledUntilSwitch
                  key={i}
                  tableType={t}
                  tables={data.getTables}
                />
              ))}
          </FormGroup>
        </FormControl>
      )}
    </div>
  );
};

export default SideMenuSwitches;
