import { IconButton, Tooltip } from '@material-ui/core';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import React, { useEffect, useState } from 'react';
import AppQrCode from './AppQrCode';

interface Props {
  title: string;
  type: 'g' | 't' | 'url';
  value: string;
}

const DownloadAppQrCode: React.FC<Props> = ({ title, value, type }) => {
  const [show, setShow] = useState<boolean>(false);
  const id = `${type}_${value}`;

  useEffect(() => {
    const download = () => {
      const canvas = document.getElementById(id) as HTMLCanvasElement;

      if (canvas) {
        const link = document.createElement('a');
        link.download = `qrcode_${type}_${title}.png`;
        link.href = canvas.toDataURL();
        link.click();
      }
    };

    if (show) {
      download();
    }
  }, [show, id, title, type]);

  return (
    <>
      <div hidden>
        {show && <AppQrCode value={value} type={type} size={2048} id={id} />}
      </div>
      <Tooltip title="QR-Code herunterladen">
        <IconButton onClick={() => setShow(true)}>
          <SaveAltRounded />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DownloadAppQrCode;
