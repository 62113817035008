import {
  createStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import {
  calculateReceiptTotal,
  getReceiptDataFromReceipt
} from '../helpers/ReceiptHelper';
import { isAdminSelector } from '../store/auth';

interface Props {
  receipt: FullReceiptFragment;
  showModifier?: boolean;
  showComments?: boolean;
  showCoupons?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canceled: {
      color: theme.palette.error.main,
      textDecoration: 'line-through'
    }
  })
);

const ReceiptItems: React.FC<Props> = ({
  receipt,
  showModifier,
  showComments,
  showCoupons = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isAdmin = useRecoilValue(isAdminSelector);
  const receiptData = getReceiptDataFromReceipt(receipt);

  const itemsSumInclTip = useMemo(() => {
    if (receiptData) {
      return receiptData.totalSum + receiptData.tipSum;
    }

    return 0;
  }, [receiptData]);

  const itemsSumInclTipAndCredit = useMemo(() => {
    if (receiptData) {
      return receiptData.totalSum + receiptData.tipSum - receiptData.creditSum;
    }

    return 0;
  }, [receiptData]);

  return (
    <List dense>
      {receiptData.items.map((item, i) => {
        return (
          <ListItem key={item.id} divider={i === receiptData.items.length - 1}>
            <ListItemText
              primary={
                <>
                  {item.productGroupTitle && (
                    <Typography
                      component="div"
                      variant="caption"
                      color="textSecondary"
                    >
                      {item.productGroupTitle}
                    </Typography>
                  )}
                  <span
                    className={item.is_canceled ? classes.canceled : undefined}
                  >
                    {item.amount} x {item.title}
                  </span>
                </>
              }
              secondary={
                <>
                  {showModifier &&
                    item.modifiers.map((m, i) => (
                      <span key={i}>
                        + {m.title}
                        <br />
                      </span>
                    ))}
                  {showComments && item.comment
                    ? `Kommentar: ${item.comment}`
                    : ''}
                </>
              }
            />
            <ListItemSecondaryAction>
              <span className={item.is_canceled ? classes.canceled : undefined}>
                {item.price}
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}

      <ListItem>
        <ListItemText primary="Zwischensumme" />
        <ListItemSecondaryAction>
          {formatNumber(receiptData.totalSum / 100, '€')}
        </ListItemSecondaryAction>
      </ListItem>

      {receiptData.tipSum > 0 && (
        <ListItem divider>
          <ListItemText primary="Trinkgeld" />
          <ListItemSecondaryAction>
            {formatNumber(receiptData.tipSum / 100, '€')}
          </ListItemSecondaryAction>
        </ListItem>
      )}

      {receiptData.tipSum > 0 &&
        ((showCoupons && receiptData.credits.length > 0) ||
          receipt.redeemed_bonus_amount) && (
          <ListItem>
            <ListItemText primary="Zwischensumme" />
            <ListItemSecondaryAction>
              {formatNumber(itemsSumInclTip / 100, '€')}
            </ListItemSecondaryAction>
          </ListItem>
        )}

      {showCoupons &&
        receiptData.credits.map((credit, i) => (
          <ListItem divider={i === receiptData.credits.length - 1} key={i}>
            <ListItemText primary={isAdmin ? credit.title : 'Onlinezahlung'} />
            <ListItemSecondaryAction>{credit.amount}</ListItemSecondaryAction>
          </ListItem>
        ))}

      {showCoupons &&
        receiptData.credits.length > 0 &&
        receipt.redeemed_bonus_amount && (
          <ListItem>
            <ListItemText primary="Zwischensumme" />
            <ListItemSecondaryAction>
              {formatNumber(itemsSumInclTipAndCredit / 100, '€')}
            </ListItemSecondaryAction>
          </ListItem>
        )}

      {receipt.redeemed_bonus_amount && (
        <ListItem divider>
          <ListItemText
            primary={`${receipt.redeemed_bonus_amount} Bonuspunkte`}
          />
          <ListItemSecondaryAction>
            {formatNumber((receipt.redeemed_bonus_amount / 100) * -1, '€')}
          </ListItemSecondaryAction>
        </ListItem>
      )}

      <ListItem>
        <ListItemText primary="Summe" />
        <ListItemSecondaryAction>
          <b>
            {formatNumber(
              calculateReceiptTotal({
                receiptSum: receiptData.totalSum,
                creditSum: receiptData.creditSum,
                discountSum: receiptData.discountSum,
                tipSum: receiptData.tipSum,
                bonusAmount: receipt.redeemed_bonus_amount || 0,
                substractCreditFromSum: showCoupons
              }) / 100,
              '€'
            )}
          </b>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Offener Betrag ${
            receipt.payment_type === 'CASH' ? '(BARZAHLUNG)' : ''
          }`}
          style={{
            color:
              receipt.payment_type === 'CASH'
                ? theme.palette.error.main
                : theme.palette.success.main
          }}
        />
        <ListItemSecondaryAction
          style={{
            color:
              receipt.payment_type === 'CASH'
                ? theme.palette.error.main
                : theme.palette.success.main,
            fontWeight: 'bold'
          }}
        >
          {receipt.payment_type === 'CASH'
            ? formatNumber(
                calculateReceiptTotal({
                  receiptSum: receiptData.totalSum,
                  creditSum: receiptData.creditSum,
                  discountSum: receiptData.discountSum,
                  tipSum: receiptData.tipSum,
                  bonusAmount: receipt.redeemed_bonus_amount || 0,
                  substractCreditFromSum: true
                }) / 100,
                '€'
              )
            : '0 €'}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

export default ReceiptItems;
