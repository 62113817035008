import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import validate from 'uuid-validate';
import CircularIndeterminate from './CircularIndeterminate';
import ReceiptCard from './ReceiptCard';
import { getUuidFromShortUuid } from '../helpers/UUIDShortener';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { flatten } from 'lodash';
import { add, isBefore, subHours } from 'date-fns';
import { GraphQLError } from 'graphql';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AlarmAddRounded, DoneRounded } from '@material-ui/icons';
import { lazyRetry } from '../helpers/lazyRetry';
import { useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { DEFAULT_PICKUP_TIMES } from '../constants';
import { useRecoilValue } from 'recoil';
import { userAtom, isAdminSelector } from '../store/auth';
import {
  gastronomyState,
  hasPosSelector,
  openReceiptsState,
  updateState
} from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import {
  AdminUpdateReceiptMutationDocument,
  GetGastronomyQueryDocument,
  GetReceiptQueryDocument
} from '../services/graphql/typed-operations';
import CancelOrderDialog from './CancelOrderDialog';

const PrintReceiptButton = React.lazy(() =>
  lazyRetry(() => import('../components/PrintReceiptButton'))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    orderButton: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    alert: {
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    button: {
      margin: theme.spacing(2, 0),
      width: '100%'
    }
  })
);

interface Props {
  uuid?: string;
  showCoupons?: boolean;
}

const ReceiptView: React.FC<Props> = ({ uuid, showCoupons = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [receipt, setReceipt] = useState<FullReceiptFragment>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [pickupTimes, setPickupTimes] = useState<number[]>(
    DEFAULT_PICKUP_TIMES
  );
  const user = useRecoilValue(userAtom);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setOpenReceipts = useSetRecoilState(openReceiptsState);
  const apolloClient = useApolloClient();

  const fetchReceipt = async () => {
    if (uuid) {
      setIsLoading(true);
      let isUuid = false;
      let isShortUuid = false;

      try {
        isUuid = validate(uuid, 4);
      } catch (error) {}

      try {
        isShortUuid = validate(getUuidFromShortUuid(uuid), 4);
      } catch (error) {}

      if ((user && isUuid) || isShortUuid) {
        try {
          const { data } = await apolloClient.query({
            query: GetReceiptQueryDocument,
            variables: {
              uuid
            }
          });

          if (data.getReceipt) {
            const result = data.getReceipt;
            console.debug('Receipt', data.getReceipt);
            setReceipt(result);
          } else {
            throw new Error('API Fehler!');
          }
        } catch (error) {
          setErrorSnackbarMessage(error, setSnackbarMessages);
        }
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReceipt();
  }, [uuid, gastronomy, isAdmin, user]);

  useEffect(() => {
    const fetchGastronomy = async () => {
      if (receipt) {
        try {
          const { data } = await apolloClient.query({
            query: GetGastronomyQueryDocument,
            variables: { params: { uuid: receipt.user_uuid } }
          });

          if (!data) {
            throw new Error('API Fehler!');
          }

          const result = data.getGastronomy;

          if (
            result &&
            result.options.pickupTimes &&
            Array.isArray(result.options.pickupTimes) &&
            result.options.pickupTimes.length
          ) {
            setPickupTimes(flatten(result.options.pickupTimes));
          }
        } catch (error) {
          setErrorSnackbarMessage(error, setSnackbarMessages);
        }
      }
    };

    if (receipt) {
      fetchGastronomy();
    }
  }, [receipt]);

  const extendOrder = async () => {
    if (isAdmin && receipt && receipt.uuid && receipt.expires_at) {
      try {
        const input: ReceiptUpdateInput = {
          uuid: receipt.uuid,
          expires_at: add(new Date(receipt.expires_at), {
            minutes: 10
          }).toISOString()
        };

        const { data } = await apolloClient.mutate({
          mutation: AdminUpdateReceiptMutationDocument,
          variables: {
            input
          }
        });

        if (!data) {
          return;
        }

        const newReceipt = data.adminUpdateReceipt;

        setReceipt(newReceipt);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Bestellung ${
                newReceipt.expires_at
                  ? 'bis ' + new Date(newReceipt.expires_at).toLocaleString()
                  : ''
              } verlängert`
            }
          ],
          setSnackbarMessages
        );
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      setOpenDialog(false);
    }
  };

  const updateOrder = async (status: ReceiptStatus, pickupTime?: number) => {
    if (receipt && receipt.uuid) {
      try {
        const input: ReceiptUpdateInput = {
          uuid: receipt.uuid,
          status
        };

        if (status === 'ACCEPTED' && pickupTime && pickupTime > 0) {
          input.estimated_pickup_time = add(new Date(), {
            minutes: pickupTime
          }).toISOString();
        }

        const { data } = await apolloClient.mutate({
          mutation: AdminUpdateReceiptMutationDocument,
          variables: { input }
        });

        if (!data) {
          throw new Error('API Fehler!');
        }

        console.debug('Receipt', data.adminUpdateReceipt);

        const result = data.adminUpdateReceipt;

        updateState<ReceiptFragment>(result, setOpenReceipts);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: 'Bestellung aktualisiert!'
            }
          ],
          setSnackbarMessages
        );

        setReceipt(result);
      } catch (error) {
        if (error.errors) {
          for (const e of error.errors as GraphQLError[]) {
            if (
              e.extensions &&
              e.extensions.code &&
              e.extensions.code === 'ACTION_FORBIDDEN'
            ) {
              fetchReceipt();
              break;
            }
          }
        }
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const OrderButtons: React.ReactNode = (
    <>
      {receipt && (
        <Grid container>
          <Grid item xs={12}>
            <PrintReceiptButton receipt={receipt} />
          </Grid>
          {isAdmin &&
            (receipt.status === 'PENDING_RECEIVED' ||
              receipt.status === 'RECEIVED') && (
              <Grid item xs={12}>
                <Button
                  onClick={extendOrder}
                  className={classes.button}
                  variant="contained"
                  color="default"
                >
                  Verlängern
                  <AlarmAddRounded className={classes.rightIcon} />
                </Button>
              </Grid>
            )}

          {(receipt.status === 'PENDING_RECEIVED' ||
            receipt.status === 'RECEIVED') &&
            receipt.requested_pickup_time &&
            isBefore(new Date(), new Date(receipt.requested_pickup_time)) && (
              <Grid item xs={12}>
                <Alert
                  variant="filled"
                  severity="info"
                  className={classes.alert}
                >
                  <AlertTitle>Vorbestellung</AlertTitle>
                  Der Kunde hat diese Bestellung für{' '}
                  {new Date(
                    receipt.requested_pickup_time
                  ).toLocaleString()}{' '}
                  vorbestellt!
                </Alert>
              </Grid>
            )}

          {receipt.status === 'RECEIVED' && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h5'}>Bestellung annehmen:</Typography>
              </Grid>
              {receipt.table_type !== 'DINEIN' &&
              !receipt.requested_pickup_time ? (
                <Grid item container spacing={3}>
                  {pickupTimes.map((pt, i) => (
                    <Grid item xs={12} sm={6} key={i}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => updateOrder('ACCEPTED', pt)}
                        className={classes.orderButton}
                        disabled={hasPos}
                      >
                        {pt} Min
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateOrder('ACCEPTED')}
                    className={classes.orderButton}
                    disabled={hasPos}
                  >
                    Annehmen
                  </Button>
                </Grid>
              )}
            </>
          )}
          {receipt.status === 'ACCEPTED' && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateOrder(
                    receipt.table_type === 'DINEIN' ? 'COMPLETED' : 'READY'
                  )
                }
                className={classes.orderButton}
                disabled={
                  receipt.requested_pickup_time
                    ? isBefore(
                        new Date(),
                        subHours(new Date(receipt.requested_pickup_time), 1)
                      )
                    : false
                }
              >
                Bestellung{' '}
                {receipt.table_type === 'DINEIN' ? 'serviert' : 'bereit'}
                <DoneRounded className={classes.rightIcon} />
              </Button>
            </Grid>
          )}
          {receipt.status === 'READY' && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateOrder('COMPLETED')}
                className={classes.orderButton}
              >
                Bestellung abschließen
                <DoneRounded className={classes.rightIcon} />
              </Button>
            </Grid>
          )}
          {receipt.isCancelable && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{
                  margin: theme.spacing(2, 0),
                  textAlign: 'center',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                onClick={() => setOpenDialog(true)}
              >
                Bestellung stornieren
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );

  return (
    <>
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {receipt && (
            <Grid container spacing={3}>
              {receipt.is_training && (
                <Grid item xs={12}>
                  <Alert
                    variant="filled"
                    severity="info"
                    className={classes.margin}
                  >
                    <AlertTitle>Testbestellung</AlertTitle>
                    Diese Bestellung dient nur zur Veranschaulichung des
                    Bestellprozesses und scheint nicht in Ihren Umsätzen auf!
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} sm={8}>
                <ReceiptCard receipt={receipt} showCoupons={showCoupons} />
              </Grid>

              <Grid item xs={12} sm={4}>
                {OrderButtons}
              </Grid>

              <CancelOrderDialog
                receipt={receipt}
                setReceipt={setReceipt}
                isOpen={openDialog}
                onClose={() => setOpenDialog(false)}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ReceiptView;
