import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import { gastronomyState } from '../store/gastronomy';

interface Props {
  report: GastronomyReportFragment;
}

const ReportTable: React.FC<Props> = ({ report }) => {
  const gastronomy = useRecoilValue(gastronomyState);

  return (
    <>
      <List dense={true}>
        <ListItem>
          <ListItemText
            inset
            primary="Bestellungen Speisen"
            secondary={formatNumber(report.sumOrderItems.food / 100, '€')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary="Bestellungen Getränke"
            secondary={formatNumber(report.sumOrderItems.drinks / 100, '€')}
          />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary="Bestellungen Sonstige"
            secondary={formatNumber(report.sumOrderItems.other / 100, '€')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="Bestellungen gesamt"
            secondary={formatNumber(report.sumOrderItems.total / 100, '€')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="Gutscheine"
            secondary={formatNumber(report.sumCoupons.internal / 100, '€')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="getsby Gutscheine"
            secondary={formatNumber(report.sumCoupons.external / 100, '€')}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="Fremdgutscheine (z.B. Sodexo)"
            secondary={formatNumber(
              Math.abs(report.sumOrderItems.external_coupons / 100),
              '€'
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="Trinkgeld"
            secondary={formatNumber(report.sumTips / 100, '€')}
          />
        </ListItem>
        {gastronomy?.options.hasBonusProgram && (
          <ListItem>
            <ListItemText
              inset
              primary="Eingelöste Bonuspunkte"
              secondary={formatNumber(report.sumBonusPoints / 100, '€')}
            />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default ReportTable;
