import React, { useEffect, useState } from 'react';
import { userAtom } from '../store/auth';
import { useApolloClient } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import {
  getCognitoGroups,
  getEmailFromCognitoUser,
  loadInitialGastronomy
} from '../helpers/AuthHelper';
import Layout from '../containers/Layout';
import CircularIndeterminate from './CircularIndeterminate';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';

const UserInitialization: React.FC = () => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const [user, setUser] = useRecoilState(userAtom);
  const [error, setError] = useState<Error>();
  const location = useLocation();
  const apolloClient = useApolloClient();

  useEffect(() => {
    const refreshTimer = setTimeout(() => {
      window.location.reload();
    }, 30 * 60 * 1000);

    return () => {
      clearTimeout(refreshTimer);
    };
  }, [location.pathname]);

  useEffect(() => {
    const resolveCognitoUser = async () => {
      try {
        const cred = await Auth.currentCredentials();
        if (cred.authenticated) {
          const session = await Auth.currentSession();
          const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
          const groups = getCognitoGroups(session);
          const email = await getEmailFromCognitoUser(user);
          const dashboardUser = { uuid: user.getUsername(), email, groups };
          setUser(dashboardUser);
          const gastro = await loadInitialGastronomy(
            apolloClient,
            dashboardUser
          );
          if (gastro) {
            setGastronomy(gastro);
          } else {
            setGastronomy(undefined);
          }
        } else {
          setUser(undefined);
          setGastronomy(undefined);
        }
      } catch (err) {
        Sentry.captureException(err);
        setError(err);
      }
    };

    resolveCognitoUser();
  }, []);

  if (error) {
    return <div>Fehler: {error.message}. Bitte die Seite neu laden.</div>;
  }

  return user !== null && gastronomy !== null ? (
    <Layout />
  ) : (
    <CircularIndeterminate />
  );
};

export default UserInitialization;
