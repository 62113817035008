import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import React from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import DownloadQrCodeZip from '../../components/DownloadQrCodeZip';
import HeaderContainer from '../../components/HeaderContainer';
import TableTable from '../../components/TableTable';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import OrderTypeDisabledUntilSwitch from '../../components/OrderTypeDisabledUntilSwitch';
import useOrderTypes from '../../hooks/useOrderTypes';
import { getOrderTypeLabel } from '../../helpers/OrderHelper';
import { useRecoilValue } from 'recoil';
import { gastronomyState, tablesState } from '../../store/gastronomy';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { hasOrderTypeFeature } from '../../helpers/GastronomyFeeModelHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    toolbarButton: {
      width: '100%'
    }
  })
);

interface Props {
  tableType: TableType;
}

const GastronomyOrderType: React.FC<Props> = ({ tableType }) => {
  const classes = useStyles();
  const tables = useRecoilValue(tablesState);
  const gastronomy = useRecoilValue(gastronomyState);
  const { isLoading, downloadPdf } = useOrderTypes([tableType]);

  if (
    gastronomy?.gastronomyFee &&
    !hasOrderTypeFeature(gastronomy.gastronomyFee, tableType)
  ) {
    return (
      <>
        <HeaderContainer title={getOrderTypeLabel(tableType)} />
        <Alert variant="filled" severity="info">
          <AlertTitle>Hinweis</AlertTitle>
          {getOrderTypeLabel(tableType)} ist in Ihrem gewählten Tarif nicht
          inkludiert! <br />
          Sie können Ihren Tarif jederzeit in Ihrem{' '}
          <Link to="/profile#payment-fee" style={{ color: 'white' }}>
            Profil
          </Link>{' '}
          ändern.
        </Alert>
      </>
    );
  }

  return (
    <>
      <HeaderContainer title={getOrderTypeLabel(tableType)} />
      <Paper className={classes.toolbarContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sm={6}>
            {tables && (
              <DownloadQrCodeZip
                tables={tables}
                classes={classes.toolbarButton}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button
              variant="contained"
              color="default"
              onClick={downloadPdf}
              className={classes.toolbarButton}
            >
              QR Code Sticker herunterladen
              <SaveAltRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <OrderTypeDisabledUntilSwitch
              tableType={tableType}
              tables={tables}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {isLoading ? (
              <CircularIndeterminate />
            ) : (
              <TableTable
                tables={tables}
                addButtonLabel={`${getOrderTypeLabel(tableType)} hinzufügen`}
                allowedTableTypes={[tableType]}
                tableNumberPlaceholder={'Abholstation 1'}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyOrderType;
