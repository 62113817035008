import { IAllProps } from '@tinymce/tinymce-react';

export const ALLERGENS: Allergen[] = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'L',
  'M',
  'N',
  'O',
  'P',
  'R'
];

export const TABLE_TYPES: TableType[] = [
  'DINEIN',
  // 'RESERVATION',
  'TAKEAWAY',
  'SELFSERVICE',
  'DRIVEIN',
  'DELIVERY'
];

export const CARD_PAYMENT_TYPES: PaymentMethodType[] = [
  'CARD',
  'APPLE_PAY',
  'GOOGLE_PAY'
];

export const PAYMENT_TYPES: PaymentMethodType[] = [
  ...CARD_PAYMENT_TYPES,
  'EPS'
];

export const STORAGE_PRIVATE_KEY = 'getsbyPrivateKey';
export const STORAGE_GASTRONOMY_KEY = 'getsbyGastronomy';
export const IMG_BASE_PATH = `${process.env.PUBLIC_URL}/img`;
export const FILE_BASE_PATH = `${process.env.PUBLIC_URL}/files`;

export const STORAGE_KEY_APP_DATA = 'gAppData';

export const DRAWER_WIDTH = 240;

export const MONTHLY_OBONO_FEE_IN_CENTS = 1200;

export const DEFAULT_PICKUP_TIMES = [5, 10, 15, 20, 30, 45];

export const PRIVACY_POLICY_URL =
  'https://getsby-public.s3.eu-central-1.amazonaws.com/documents/privacy_policy.pdf';
export const GTC_URL =
  'https://getsby-public.s3.eu-central-1.amazonaws.com/documents/gtc.pdf';
export const GDPR_URL =
  'https://getsby-public.s3.eu-central-1.amazonaws.com/documents/gdpr.pdf';
export const GASTRO_TUTORIAL_URL =
  'https://getsby-public.s3.eu-central-1.amazonaws.com/documents/getsby_tutorial.pdf';

export const A_TRUST_CERTIFICATE_URL =
  'http://www.a-trust.at/RKSVSeriennummer/';

export const TINY_MCE_CONFIG: IAllProps['init'] = {
  schema: 'html5',
  height: 500,
  menubar: false,
  convert_urls: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help'
  ],
  toolbar: `
        undo redo | formatselect | bold italic backcolor | 
        alignleft aligncenter | alignright alignjustify | 
        bullist numlist outdent indent | removeformat | help | code`,
  content_css: ['https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css'],
  valid_elements:
    '@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
    'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
    'onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|' +
    'name|href|target|title|class|onfocus|onblur],strong/b,em/i,strike,u,' +
    '#p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|' +
    'src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup,' +
    '-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|' +
    'height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|' +
    'height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot,' +
    '#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor' +
    '|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,-div,' +
    '-span,-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face' +
    '|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite],' +
    'object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width' +
    '|height|src|*],script[src|type],map[name],area[shape|coords|href|alt|target],bdo,' +
    'button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|' +
    'valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method],' +
    'input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value],' +
    'kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value],' +
    'q[cite],samp,select[disabled|multiple|name|size],small,' +
    'textarea[cols|rows|disabled|name|readonly],tt,var,big,' +
    'getsby[data-type|src|height|width|color-hex|color-rgba|color-theme]',
  extended_valid_elements: 'script[src|async|defer|type|charset]'
};
