import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneRounded from '@material-ui/icons/DoneRounded';
import SubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { formatNumber } from '../helpers/FormatHelper';
import { ALLERGENS } from '../constants';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  AddProductModifierItemMutationDocument,
  GetProductsQueryDocument,
  UpdateProductModifierItemMutationDocument
} from '../services/graphql/typed-operations';
import { gastronomyState, hasPosOrWincaratSelector } from '../store/gastronomy';
import Joi from 'joi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginRight: theme.spacing(2),
      width: '100%'
    },
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    radioButton: {
      margin: theme.spacing(1)
    }
  })
);

const schemaValues = Joi.object({
  title: Joi.string(),
  print_title: Joi.string().allow(''),
  description: Joi.string().allow(''),
  price_modifier: Joi.number().min(0),
  order: Joi.number().min(1),
  status: Joi.boolean(),
  product: Joi.object().optional().allow(null)
});

interface Props {
  modifierUuid: string;
  modifierItem?: ModifierItemFragment;
  onClose: () => void;
}

const ModifierItemForm: React.FC<Props> = ({
  modifierUuid,
  modifierItem,
  onClose
}) => {
  const classes = useStyles();
  const getDefaultValues = () => ({
    title: modifierItem?.title || '',
    print_title: modifierItem?.print_title || '',
    description: modifierItem?.description || '',
    price_modifier: modifierItem?.price_modifier || 0,
    order: modifierItem?.order || 1,
    product: modifierItem?.product || null,
    status:
      modifierItem && typeof modifierItem.status === 'boolean'
        ? modifierItem.status
        : true
  });

  const [values, setValues] = useState(getDefaultValues());
  const [errors, setErrors] = useState<string[]>([]);
  const [disablePrice, setDisablePrice] = useState<boolean>(false);
  const [allergenList, setAllergenList] = useState<Allergen[]>(
    modifierItem?.allergenList || []
  );
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const hasPosOrWincarat = useRecoilValue(hasPosOrWincaratSelector);
  const apolloClient = useApolloClient();

  const { data } = useQuery(GetProductsQueryDocument, {
    variables: { params: { user_uuid: gastronomy?.uuid || 'NOT SET' } },
    skip: !gastronomy
  });

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const handleAllergenListChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (event.target.checked) {
      setAllergenList((prev) => [...prev, value as Allergen]);
    } else {
      setAllergenList((prev) => prev.filter((a) => a !== value));
    }
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation error', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setValues(getDefaultValues());
  };

  const saveProductModifierItem = async () => {
    try {
      const result = schemaValues.validate(values);
      if (!processJoiResult(result)) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );
        return;
      }

      if (modifierItem) {
        const input: ProductModifierItemUpdateInput = {
          uuid: modifierItem.uuid,
          title: values.title,
          print_title: values.print_title,
          description: values.description,
          price_modifier: +values.price_modifier,
          order: +values.order,
          product_uuid: values.product ? values.product.uuid : undefined,
          allergenList
        };
        const { data } = await apolloClient.mutate({
          mutation: UpdateProductModifierItemMutationDocument,
          variables: { input },
          refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
        });

        if (!data) {
          return;
        }

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Option ${values.title} geändert`
            }
          ],
          setSnackbarMessages
        );
      } else {
        const input: ProductModifierItemAddInput = {
          modifier_uuid: modifierUuid,
          title: values.title,
          print_title: values.print_title,
          description: values.description,
          price_modifier: +values.price_modifier,
          order: +values.order,
          product_uuid: values.product ? values.product.uuid : undefined,
          allergenList
        };
        const { data } = await apolloClient.mutate({
          mutation: AddProductModifierItemMutationDocument,
          variables: { input },
          refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
        });

        if (!data) {
          return;
        }

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Option ${values.title} hinzugefügt`
            }
          ],
          setSnackbarMessages
        );

        resetForm();
      }
      onClose();
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <ListItem divider>
      <ListItemIcon>
        <SubdirectoryArrowRight />
      </ListItemIcon>
      <ListItemText>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <TextField
              error={errors.includes('title')}
              id="modifier-item-title"
              label="Titel"
              placeholder="Barbeque Sauce"
              required={true}
              className={classes.textField}
              value={values.title}
              onChange={handleChange('title')}
              variant="outlined"
              disabled={hasPosOrWincarat}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              id="modifier-item-print-title"
              label="Drucktitel"
              placeholder="BBQ"
              className={classes.textField}
              value={values.print_title}
              onChange={handleChange('print_title')}
              variant="outlined"
              helperText="Optionaler Titel für interne Bezeichnungen"
              disabled={hasPosOrWincarat}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              error={errors.includes('price_modifier')}
              id="modifier-item-price"
              label="Preis in Cent"
              helperText="Aufpreis zum Basisprodukt"
              required={true}
              value={values.price_modifier}
              type="number"
              onChange={handleChange('price_modifier')}
              className={classes.textField}
              variant="outlined"
              disabled={hasPosOrWincarat || disablePrice}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              error={errors.includes('order')}
              id="modifier-item-order"
              label="Reihenfolge"
              required={true}
              value={values.order}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={handleChange('order')}
              className={classes.textField}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              id="modifier-item-description"
              label="Beschreibung"
              placeholder="Vom Bio-Weiderind"
              value={values.description}
              type="text"
              onChange={handleChange('description')}
              className={classes.textField}
              variant="outlined"
              multiline={true}
              rows={2}
            />
          </Grid>
          <Grid item sm={12}>
            <FormControl component="fieldset" className={classes.radioButton}>
              <FormLabel component="legend">Allergene</FormLabel>
              <FormGroup row>
                {ALLERGENS.map((a, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={allergenList.includes(a)}
                        onChange={handleAllergenListChange}
                        value={a}
                        color="primary"
                      />
                    }
                    label={a}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="modifieritem-product"
              options={data ? data.getProducts : []}
              getOptionLabel={(product) =>
                `${product.title} (${formatNumber(
                  product.gross_price / 100,
                  '€'
                )})`
              }
              getOptionSelected={(option, value) => option.uuid === value.uuid}
              className={classes.textField}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Produkt verlinken"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={values.product}
              onChange={(_event, newValue) => {
                if (newValue) {
                  setValues({
                    ...values,
                    title: newValue.title,
                    price_modifier: newValue.gross_price,
                    product: newValue
                  });

                  setDisablePrice(true);
                } else {
                  setValues({
                    ...values,
                    product: null
                  });

                  setDisablePrice(false);
                }
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Verwerfen
              <CloseIcon className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              onClick={saveProductModifierItem}
              className={classes.input}
              variant="contained"
              color="primary"
            >
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

export default ModifierItemForm;
