import {
  Button,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { isPreOrderAllowed } from '../helpers/MiscHelper';
import ServiceTimesDialog from './ServiceTimesDialog';
import SimpleDialog from './SimpleDialog';
import TableLineItem from './TableLineItem';
import TableForm from './TableForm';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import {
  gastronomyState,
  hasPosSelector,
  removeFromState,
  tablesState,
  updateState
} from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import {
  AdminUpdateTableMutationDocument,
  DeleteTableMutationDocument
} from '../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {},
    button: {
      margin: theme.spacing(1)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    container: {
      justifyContent: 'center',
      margin: theme.spacing(2)
    },
    addButtonContainer: {
      justifyContent: 'center',
      marginTop: theme.spacing(3)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  tables: TableFragment[];
  room?: RoomFragment;
  addButtonLabel: string;
  tableNumberLabel?: string;
  tableNumberPlaceholder?: string;
  allowedTableTypes: TableType[];
}

const TableTable: React.FC<Props> = ({
  tables,
  room,
  addButtonLabel,
  tableNumberLabel,
  tableNumberPlaceholder,
  allowedTableTypes
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedTable, setSelectedTable] = useState<TableFragment | null>();
  const [showTableAddForm, setShowTableAddForm] = useState<boolean>(false);
  const hasPos = useRecoilValue(hasPosSelector);
  const showPreOrderSwitch = isPreOrderAllowed(allowedTableTypes);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setTables = useSetRecoilState(tablesState);
  const apolloClient = useApolloClient();
  const gastronomy = useRecoilValue(gastronomyState);

  const deleteTable = async (table: TableFragment) => {
    setOpenDeleteDialog(false);

    try {
      await apolloClient.mutate({
        mutation: DeleteTableMutationDocument,
        variables: { uuid: table.uuid }
      });

      removeFromState(table, setTables);

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Tisch ${table.table_number} erfolgreich gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const selectTable = (table: TableFragment) => {
    setSelectedTable(table);
    setIsDialogOpen(true);
  };

  const selectDeleteTable = (table: TableFragment) => {
    setSelectedTable(table);
    setOpenDeleteDialog(true);
  };

  const onDialogClose = () => {
    setSelectedTable(null);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    setSelectedTable((prev) => {
      return tables.find((t) => (prev ? prev.uuid === t.uuid : null));
    });
  }, [tables]);

  const saveServiceTimes = async (
    availabilityTimes?: AvailabilityTimes | null
  ) => {
    try {
      if (selectedTable) {
        const { data } = await apolloClient.mutate({
          mutation: AdminUpdateTableMutationDocument,
          variables: {
            input: {
              uuid: selectedTable.uuid,
              service_times: availabilityTimes
                ? ({
                    ...availabilityTimes,
                    cc: gastronomy?.country || 'AT'
                  } as AvailabilityTimesInput)
                : null
            }
          }
        });

        if (!data) {
          return;
        }

        const newTable = data.adminUpdateTable;

        console.debug('Table Update', newTable);

        updateState(newTable, setTables);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Servicezeiten aktualisiert`
            }
          ],
          setSnackbarMessages
        );
      }
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      {selectedTable && (
        <ServiceTimesDialog
          open={isDialogOpen}
          onClose={onDialogClose}
          availabilityTimes={selectedTable.service_times}
          onSave={saveServiceTimes}
          title={`Service Zeiten für Tisch ${selectedTable.table_number}`}
          showLimit={true}
        />
      )}
      {selectedTable && (
        <SimpleDialog
          open={openDeleteDialog}
          title={`Tisch ${selectedTable.table_number} löschen`}
          text={'Möchten Sie diesen Tisch wirklich löschen?'}
          onPositiveClose={() => deleteTable(selectedTable)}
          onNegativeClose={() => {
            setSelectedTable(null);
            setOpenDeleteDialog(false);
          }}
          buttonPositiveLabel={'Löschen'}
          buttonNegativeLabel={'Zurück'}
        />
      )}
      {tables.length > 10 && (
        <div className={classes.container}>
          {showTableAddForm && (
            <TableForm
              onClose={() => setShowTableAddForm(false)}
              room={room}
              allowedTableTypes={allowedTableTypes}
              tableNumberLabel={tableNumberLabel}
              tableNumberPlaceholder={tableNumberPlaceholder}
              showPreOrderSwitch={showPreOrderSwitch}
            />
          )}
          {!showTableAddForm && (
            <Button
              disabled={hasPos}
              variant="outlined"
              className={classes.addButton}
              onClick={() => setShowTableAddForm(true)}
            >
              {addButtonLabel}
              <AddIcon className={classes.rightIcon} />
            </Button>
          )}
        </div>
      )}
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bestellart</TableCell>
            <TableCell>{tableNumberLabel}</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tables.map((table) => {
            return (
              <TableLineItem
                key={table.uuid}
                table={table}
                selectDeleteTable={selectDeleteTable}
                selectTable={selectTable}
                allowedTableTypes={allowedTableTypes}
                showPreOrderSwitch={showPreOrderSwitch}
                tableNumberLabel={tableNumberLabel}
                tableNumberPlaceholder={tableNumberPlaceholder}
              />
            );
          })}
        </TableBody>
      </Table>
      <div className={classes.container}>
        {showTableAddForm && (
          <TableForm
            onClose={() => setShowTableAddForm(false)}
            room={room}
            allowedTableTypes={allowedTableTypes}
            tableNumberLabel={tableNumberLabel}
            tableNumberPlaceholder={tableNumberPlaceholder}
            showPreOrderSwitch={showPreOrderSwitch}
          />
        )}
        {!showTableAddForm && (
          <Button
            disabled={hasPos}
            variant="outlined"
            className={classes.addButton}
            onClick={() => setShowTableAddForm(true)}
          >
            {addButtonLabel}
            <AddIcon className={classes.rightIcon} />
          </Button>
        )}
      </div>
    </>
  );
};

export default TableTable;
