import React, { useEffect } from 'react';
import Config from '../config';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { chunk, extend, flattenDeep } from 'lodash';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';

interface Props {
  area: string;
  onChange: (event: any) => void;
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== 'undefined';
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector('body.first-hit-completed');
    if (!isAlreadyLoaded && isBrowser) {
      if (window.google && !cleaningUp) {
        console.error('google api is already presented');
        return;
      }
      this.isCleaningUp().then(this.injectScript);
    }
    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

let listenerSetgeometry: google.maps.MapsEventListener | null = null;
let listenerAddfeature: google.maps.MapsEventListener | null = null;
let listenerRemovefeature: google.maps.MapsEventListener | null = null;

const MapPolygonEdit: React.FC<Props> = ({ area, onChange }) => {
  const gastronomy = useRecoilValue(gastronomyState);
  const [mapObject, setMapObject] = React.useState<google.maps.Map | null>(
    null
  );
  useEffect(() => {
    try {
      if (typeof area === 'string' && mapObject) {
        if (area) {
          let data = JSON.parse(area);
          if (data.type !== 'FeatureCollection') {
            data = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: JSON.parse(area),
                  properties: { prop0: 'value0' }
                }
              ]
            };
          }

          if (listenerSetgeometry) {
            listenerSetgeometry.remove();
          }
          if (listenerAddfeature) {
            listenerAddfeature.remove();
          }
          if (listenerRemovefeature) {
            listenerRemovefeature.remove();
          }

          mapObject.data.forEach((feature) => mapObject.data.remove(feature));
          mapObject.data.addGeoJson(data);
          mapObject.data.toGeoJson(function (data: object) {
            mapObject.fitBounds(get_bounds(data));
          });
        }

        listenerSetgeometry = mapObject.data.addListener(
          'setgeometry',
          save_data
        );
        listenerAddfeature = mapObject.data.addListener(
          'addfeature',
          save_data
        );
        listenerRemovefeature = mapObject.data.addListener(
          'removefeature',
          save_data
        );
      }
    } catch (error) {
      console.log('polygon err', error);
    }
  }, [area, mapObject !== null]);

  function get_bounds(data: any) {
    const coordinates: any[] = [];
    for (const feat of data.features) {
      extend(coordinates, feat.geometry.coordinates);
    }
    const chunks = chunk(flattenDeep(coordinates), 2);
    const bounds = new google.maps.LatLngBounds();
    for (const [lng, lat] of chunks) {
      const latlng = new google.maps.LatLng(lat, lng);
      bounds.extend(latlng);
    }
    return bounds;
  }
  function save_data() {
    if (mapObject) {
      mapObject.data.toGeoJson(function (data) {
        onChange({ target: { value: JSON.stringify(data) } });
      });
    }
  }
  const mapOnLoad = React.useCallback(function callback(
    map: google.maps.Map<Element>
  ) {
    setMapObject(map);
    map.data.setStyle({ editable: true });
    map.data.setControls(['Polygon']);

    // right click removes the clicked vertex
    map.data.addListener('rightclick', function (ev) {
      const newPolyPoints: google.maps.LatLng[] = [];
      ev.feature
        .getGeometry()
        .forEachLatLng(function (latlng: google.maps.LatLng) {
          if (
            latlng.lat() != ev.latLng.lat() ||
            latlng.lng() != ev.latLng.lng()
          ) {
            newPolyPoints.push(latlng);
          }
        });
      let newPoly;
      if (newPolyPoints.length <= 2) {
        newPoly = new google.maps.Data.Polygon([]);
      } else {
        newPoly = new google.maps.Data.Polygon([newPolyPoints]);
      }
      ev.feature.setGeometry(newPoly);
    });

    listenerSetgeometry = map.data.addListener('setgeometry', save_data);
    listenerAddfeature = map.data.addListener('addfeature', save_data);
    listenerRemovefeature = map.data.addListener('removefeature', save_data);
    if (typeof area === 'string' && area) {
      let data = JSON.parse(area);
      if (data.type !== 'FeatureCollection') {
        data = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: JSON.parse(area),
              properties: { prop0: 'value0' }
            }
          ]
        };
      }
      if (map) {
        map.data.addGeoJson(data);
        map.data.toGeoJson(function (data) {
          map.fitBounds(get_bounds(data));
        });
      }
    }
  },
  []);
  const containerStyle = {
    width: '100%',
    height: '80vh'
  };
  if (gastronomy && gastronomy.lat && gastronomy.lng) {
    return (
      <LoadScriptOnlyIfNeeded googleMapsApiKey={Config.gmaps_key}>
        <GoogleMap
          center={{
            lat: gastronomy.lat,
            lng: gastronomy.lng
          }}
          mapContainerStyle={containerStyle}
          zoom={14}
          onLoad={mapOnLoad}
        ></GoogleMap>
      </LoadScriptOnlyIfNeeded>
    );
  }
  return null;
};

export default MapPolygonEdit;
