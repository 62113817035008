import { atom, selector, SetterOrUpdater } from 'recoil';

export const gastronomyState = atom<FullGastronomyFragment | undefined | null>({
  key: 'gastronomy',
  default: null
});

export const tablesState = atom<TableFragment[]>({
  key: 'tables',
  default: []
});

export const roomsState = atom<RoomFragment[]>({
  key: 'rooms',
  default: []
});

export const printersState = atom<PrinterFragment[]>({
  key: 'printers',
  default: []
});

export const openReceiptsState = atom<ReceiptFragment[]>({
  key: 'openReceipts',
  default: []
});

export const messageLogsState = atom<MessageLogFragment[]>({
  key: 'messageLogs',
  default: []
});

export const hasPosSelector = selector<boolean>({
  key: 'hasPosSelector',
  get: ({ get }) => {
    const gastronomy = get(gastronomyState);

    if (gastronomy && gastronomy.posConfig && gastronomy.posConfig.pos_type) {
      switch (gastronomy.posConfig.pos_type) {
        case 'GKS':
        case 'MATRIX':
        case 'OCTOBOX':
        case 'MELZER':
          return true;
        default:
          return false;
      }
    }

    return false;
  }
});

export const hasPosOrWincaratSelector = selector<boolean>({
  key: 'hasPosOrWincaratSelector',
  get: ({ get }) => {
    const gastronomy = get(gastronomyState);
    const hasPos = get(hasPosSelector);

    return hasPos || gastronomy?.posConfig?.pos_type === 'WINCARAT';
  }
});

export const addToState = <T>(
  item: T,
  setItems: SetterOrUpdater<T[]>
): void => {
  if (!item) {
    return;
  }

  setItems((current) => [...current, item]);
};

export const updateState = <T extends Record<string, unknown>>(
  item: T,
  setItems: SetterOrUpdater<T[]>
): void => {
  if (!item || !item.uuid) {
    return;
  }

  setItems((current) =>
    current.map((i) => {
      if (i.uuid === item.uuid) {
        return { ...item };
      }

      return i;
    })
  );
};

export const removeFromState = <T extends Record<string, unknown>>(
  item: T,
  setItems: SetterOrUpdater<T[]>
): void => {
  if (!item || !item.uuid) {
    return;
  }

  setItems((current) => current.filter((i) => i.uuid !== item.uuid));
};
