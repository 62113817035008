import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: theme.typography.body2.fontSize,
      verticalAlign: 'middle'
    }
  })
);

interface Props {
  text: string;
}

const InfoTooltip: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <Tooltip title={text}>
      <InfoRounded className={classes.icon} />
    </Tooltip>
  );
};

export default InfoTooltip;
