import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetProductModifierQueryDocument } from '../services/graphql/typed-operations';
import { gastronomyState, hasPosSelector } from '../store/gastronomy';
import ModifierForm from './ModifierForm';
import ModifierPanel from './ModifierPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonContainer: {
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    addButton: {
      width: '100%',
      height: 50
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  onClose: () => void;
  product: FullProductFragment;
}

const ModifierDialog: React.FC<Props> = ({ onClose, product, ...other }) => {
  const classes = useStyles();
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [disableModifier, setDisableModifier] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const hasPos = useRecoilValue(hasPosSelector);
  const gastronomy = useRecoilValue(gastronomyState);

  const [getProductModifierQuery] = useLazyQuery(
    GetProductModifierQueryDocument,
    {
      onCompleted: (data) => {
        if (data) {
          const result = data.getProductModifier;

          console.debug('ProductModifier', result);

          for (const modifier of result) {
            if (modifier.modifierItems) {
              for (const modifierItem of modifier.modifierItems) {
                if (
                  modifierItem.product &&
                  modifierItem.product.uuid === product.uuid
                ) {
                  setDisableModifier(true);
                  break;
                }
              }
            }
          }
        }
      }
    }
  );

  useEffect(() => {
    if (gastronomy) {
      getProductModifierQuery({
        variables: { params: { user_uuid: gastronomy.uuid } }
      });
    }
  }, [gastronomy, product]);

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          Optionsgruppen - {product.title}
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {disableModifier && (
            <Chip
              icon={<WarningIcon />}
              label="Dieses Produkt wird als Option verwendet und darf somit selbst keine Optionen haben!"
              color="primary"
            />
          )}
          {showAddForm && (
            <ModifierForm
              product_uuid={product.uuid}
              onClose={() => setShowAddForm(false)}
            />
          )}
          {!showAddForm && (
            <div className={classes.addButtonContainer}>
              <Button
                disabled={hasPos || disableModifier}
                variant="outlined"
                className={classes.addButton}
                onClick={() => setShowAddForm(true)}
              >
                Optionsgruppe hinzufügen
                <AddIcon className={classes.rightIcon} />
              </Button>
            </div>
          )}
          <div>
            {product.modifier &&
              product.modifier.map((modifier) => (
                <ModifierPanel
                  modifier={modifier}
                  handleChange={handleChange}
                  expanded={expanded}
                  key={modifier.uuid}
                />
              ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModifierDialog;
