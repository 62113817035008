import {
  Badge,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  colors,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { ReceiptStatusCount } from 'models';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { Redirect } from 'react-router-dom';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import ReceiptTable from '../../components/ReceiptTable';
import TotalRevenueCard from '../../components/TotalRevenueCard';
import { captureSilentError } from '../../helpers/ErrorHelper';
import { unionBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { isAdminSelector } from '../../store/auth';
import { useQuery } from '@apollo/client';
import { GetReceiptsWithGastronomyQueryDocument } from '../../services/graphql/typed-operations';
import { endOfDay, startOfDay } from 'date-fns/esm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1),
      width: '100%'
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    badge: {
      margin: theme.spacing(2, 0)
    },
    chartHeader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })
);

interface OrdersByGastronomy {
  uuid: string;
  name: string;
  count: number;
}

const AdminMonitor: React.FC = () => {
  const classes = useStyles();
  const [receipts, setReceipts] = useState<ReceiptWithGastronomyFragment[]>([]);
  const [receiptCount, setReceiptCount] = useState<number>(0);
  const [
    receiptStatusCount,
    setReceiptStatusCount
  ] = useState<ReceiptStatusCount>({
    PENDING_RECEIVED: 0,
    RECEIVED: 0,
    PENDING_ACCEPTED: 0,
    ACCEPTED: 0,
    PENDING_READY: 0,
    READY: 0,
    PENDING_COMPLETED: 0,
    COMPLETED: 0,
    PENDING_ERROR: 0,
    ERROR: 0,
    TRAINING: 0
  });
  const [ordersByGastronomy, setOrdersByGastronomy] = useState<
    OrdersByGastronomy[]
  >([]);
  const [receiptTotal, setReceiptTotal] = useState<number>(0);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [showAll, setShowAll] = useState<boolean>(false);

  const { data: openReceiptsData, loading: loadingOpenReceiptsData } = useQuery(
    GetReceiptsWithGastronomyQueryDocument,
    {
      variables: { params: { status: ['PENDING_RECEIVED', 'RECEIVED'] } },
      pollInterval: 30000,
      onError: (error) => {
        captureSilentError(error);
      }
    }
  );
  const {
    data: todaysReceiptsData,
    loading: loadingTodaysReceiptsData
  } = useQuery(GetReceiptsWithGastronomyQueryDocument, {
    variables: {
      params: {
        status: showAll
          ? undefined
          : [
              'PENDING_RECEIVED',
              'RECEIVED',
              'PENDING_ACCEPTED',
              'ACCEPTED',
              'PENDING_READY',
              'READY',
              'PENDING_COMPLETED',
              'COMPLETED',
              'PENDING_ERROR',
              'ERROR'
            ],
        datetime_from: startOfDay(new Date()).toISOString(),
        datetime_to: endOfDay(new Date()).toISOString()
      }
    },
    pollInterval: 30000,
    onError: (error) => {
      captureSilentError(error);
    }
  });

  useEffect(() => {
    const todaysReceipts = todaysReceiptsData
      ? todaysReceiptsData.getReceipts
      : [];
    const openReceipts = openReceiptsData ? openReceiptsData.getReceipts : [];

    const mergedReceipts = unionBy(todaysReceipts, openReceipts, (r) => r.uuid);

    setReceipts(mergedReceipts);

    const total = todaysReceipts.reduce((prev, current) => {
      if (current.status && current.status !== 'ERROR' && current.total_price) {
        return prev + current.total_price;
      } else {
        return prev;
      }
    }, 0);

    setReceiptTotal(total);

    const gastronomies: OrdersByGastronomy[] = [];

    for (const receipt of todaysReceipts) {
      if (receipt.gastronomy && receipt.gastronomy.uuid) {
        const index = gastronomies.findIndex((r) =>
          receipt.gastronomy ? r.uuid === receipt.gastronomy.uuid : false
        );

        if (index >= 0) {
          gastronomies[index].count++;
        } else {
          gastronomies.push({
            uuid: receipt.gastronomy.uuid,
            name: receipt.gastronomy.name || '',
            count: 1
          });
        }
      }
    }

    setOrdersByGastronomy(gastronomies);
    getReceiptCount(todaysReceipts);
  }, [todaysReceiptsData, openReceiptsData]);

  const getReceiptCount = (newReceipts: ReceiptWithGastronomyFragment[]) => {
    let count = 0;

    if (newReceipts && newReceipts.length) {
      const statusCount = newReceipts.reduce(
        (obj: ReceiptStatusCount, v) => {
          if (v.status) {
            obj[v.status] = (obj[v.status] || 0) + 1;
          }
          return obj;
        },
        {
          PENDING_RECEIVED: 0,
          RECEIVED: 0,
          PENDING_ACCEPTED: 0,
          ACCEPTED: 0,
          PENDING_READY: 0,
          READY: 0,
          PENDING_COMPLETED: 0,
          COMPLETED: 0,
          PENDING_ERROR: 0,
          ERROR: 0,
          TRAINING: 0
        }
      );

      console.debug('StatusCount', statusCount);

      newReceipts.forEach((receipt) => {
        if (receipt.status && receipt.status !== 'ERROR') {
          count++;
        }
      });

      if (statusCount) {
        setReceiptStatusCount(statusCount);
      }

      setReceiptCount(count);
    }
  };

  if (!isAdmin) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <HeaderContainer title={'Monitor'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TotalRevenueCard total={receiptTotal} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.chartHeader}>
              <Badge
                className={classes.margin}
                color="primary"
                badgeContent={receiptCount}
                max={1000}
              >
                <Typography variant={'h5'} className={classes.chartHeader}>
                  Bestellungen nach Status
                </Typography>
              </Badge>
            </div>
            <Chart
              chartType="PieChart"
              loader={<CircularIndeterminate />}
              data={[
                ['Status', 'Anzahl'],
                ['Wird übermittelt...', receiptStatusCount.PENDING_RECEIVED],
                ['Warten auf Bestätigung', receiptStatusCount.RECEIVED],
                ['Wird bestätigt...', receiptStatusCount.PENDING_ACCEPTED],
                ['Bestätigt', receiptStatusCount.ACCEPTED],
                ['Wird Bereit...', receiptStatusCount.PENDING_READY],
                ['Bereit', receiptStatusCount.READY],
                ['Wird abgeschlossen...', receiptStatusCount.PENDING_COMPLETED],
                ['Abgeschlossen', receiptStatusCount.COMPLETED],
                ['Wird storniert...', receiptStatusCount.PENDING_ERROR],
                ['Storniert', receiptStatusCount.ERROR]
              ]}
              width="100%"
              height="400px"
              options={{
                animation: {
                  duration: 1000,
                  easing: 'out',
                  startup: true
                },
                colors: [
                  colors.amber[300],
                  colors.amber[500],
                  colors.purple[300],
                  colors.purple[500],
                  colors.blue[300],
                  colors.blue[500],
                  colors.green[300],
                  colors.green[500],
                  colors.red[300],
                  colors.red[500]
                ],
                backgroundColor: 'transparent'
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.chartHeader}>
              <Badge
                className={classes.margin}
                color="primary"
                badgeContent={receiptCount}
                max={1000}
              >
                <Typography variant={'h5'} className={classes.chartHeader}>
                  Bestellungen nach Restaurant
                </Typography>
              </Badge>
            </div>
            <Chart
              chartType="PieChart"
              loader={<CircularIndeterminate />}
              data={[
                ['Restaurant', 'Anzahl'],
                ...ordersByGastronomy.map((o) => [o.name, o.count])
              ]}
              width="100%"
              height="400px"
              options={{
                animation: {
                  duration: 1000,
                  easing: 'out',
                  startup: true
                },
                backgroundColor: 'transparent'
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <FormControlLabel
              control={
                <Switch
                  value={showAll}
                  onChange={() => setShowAll((current) => !current)}
                  color="primary"
                />
              }
              label={'Alle Fehler anzeigen'}
            />
            <ReceiptTable
              receipts={receipts}
              isLoading={loadingOpenReceiptsData || loadingTodaysReceiptsData}
              renderPaper={false}
              options={{ search: true }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminMonitor;
