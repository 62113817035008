import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { AvailabilityTimeException } from 'models';
import React, { useEffect, useState } from 'react';
import { isValid, parse } from 'date-fns/esm';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isAfter } from 'date-fns';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { width: '100%', padding: theme.spacing(2) },
    input: {
      width: '100%',
      margin: theme.spacing(2, 0)
    }
  })
);

interface Props {
  onSave: (exception: AvailabilityTimeException) => void;
  onCancel: () => void;
  exception?: AvailabilityTimeException;
}

const defaultTimeRanges = [{ from: null, to: null }];

const AvailabilityTimeExceptionForm: React.FC<Props> = ({
  onSave,
  onCancel,
  exception
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [timeRanges, setTimeRanges] = useState<
    Array<{
      from: MaterialUiPickersDate | null;
      to: MaterialUiPickersDate | null;
    }>
  >([...defaultTimeRanges]);
  const [exceptionType, setExceptionType] = useState<string>('closed');
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (exception) {
      setStartDate(new Date(exception.dateRange.begin_date));
      setEndDate(new Date(exception.dateRange.end_date));
      setTimeRanges(
        exception.timeRanges.map((range) => ({
          from: parse(range.time_from, 'HHmm', new Date()),
          to: parse(range.time_to, 'HHmm', new Date())
        }))
      );
      setExceptionType(exception.timeRanges.length > 0 ? 'open' : 'closed');
    }
  }, [exception]);

  const dateToTimeString = (input: MaterialUiPickersDate) => {
    if (input) {
      const date = new Date(input.getTime());
      return format(date, 'HHmm');
    }
    return '';
  };

  const save = () => {
    const err = [];
    if (startDate === null || !isValid(startDate)) {
      err.push('startDate');
    }
    if (endDate === null || !isValid(endDate)) {
      err.push('endDate');
    }
    if (startDate && endDate) {
      if (isAfter(startDate, endDate)) {
        err.push('startDate');
        err.push('endDate');
      }

      timeRanges.forEach((range, index) => {
        if (range.from !== null || range.to !== null) {
          if (!isValid(range.from)) {
            err.push('timeRange-' + index + '-from');
          }
          if (!isValid(range.to)) {
            err.push('timeRange-' + index + '-to');
          }
        }
      });

      setErrors(err);
      if (err.length > 0) {
        return;
      }

      const ranges = timeRanges
        .filter((range) => range.from && range.to)
        .map((range) => {
          return {
            time_from: range.from ? dateToTimeString(range.from) : '',
            time_to: range.to ? dateToTimeString(range.to) : ''
          };
        });
      if (exceptionType === 'open' && ranges.length === 0) {
        setErrors(['timeRange-0-from', 'timeRange-0-to']);
        return;
      }

      const newException: AvailabilityTimeException = {
        dateRange: {
          begin_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd')
        },
        timeRanges: ranges
      };

      onSave(newException);
      onCancel();
    }
  };

  return (
    <form noValidate className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            margin="dense"
            id="date-from"
            label="Start Datum"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            className={classes.input}
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            error={errors.includes('startDate')}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            margin="dense"
            id="date-to"
            label="End Datum"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            className={classes.input}
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            error={errors.includes('endDate')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.input}>
            <FormLabel component="legend">Restaurant hat:</FormLabel>
            <RadioGroup
              name="qr-code-type"
              value={exceptionType}
              onChange={(event) => {
                setExceptionType(event.target.value);
                if (event.target.value === 'closed') {
                  setTimeRanges([]);
                } else {
                  setTimeRanges([...defaultTimeRanges]);
                }
              }}
            >
              <FormControlLabel
                value="closed"
                control={<Radio />}
                label="Geschlossen"
              />
              <FormControlLabel
                value="open"
                control={<Radio />}
                label="Geöffnet"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {exceptionType === 'open' && (
          <>
            {timeRanges.map((timeRange, index) => (
              <>
                <Grid item xs={6} key={index}>
                  <KeyboardTimePicker
                    id={'timeRange-' + index + '-from'}
                    error={errors.includes('timeRange-' + index + '-from')}
                    ampm={false}
                    variant="dialog"
                    clearable={true}
                    label="Zeit von"
                    minutesStep={15}
                    value={timeRanges[index].from}
                    inputVariant="outlined"
                    className={classes.input}
                    onChange={(date) => {
                      if (date) {
                        setTimeRanges((prev) => {
                          const ranges = [...prev];
                          ranges[index].from = date;
                          return ranges;
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardTimePicker
                    id={'timeRange-' + index + '-to'}
                    error={errors.includes('timeRange-' + index + '-to')}
                    ampm={false}
                    variant="dialog"
                    clearable={true}
                    label="Zeit bis"
                    minutesStep={15}
                    value={timeRanges[index].to}
                    inputVariant="outlined"
                    className={classes.input}
                    onChange={(date) => {
                      if (date) {
                        setTimeRanges((prev) => {
                          const ranges = [...prev];
                          ranges[index].to = date;
                          return ranges;
                        });
                      }
                    }}
                  />
                </Grid>
              </>
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.input}
                onClick={() =>
                  setTimeRanges((prev) => {
                    return [...prev, { from: null, to: null }];
                  })
                }
              >
                + Zeit
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onCancel}
            className={classes.input}
          >
            Verwerfen
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
            className={classes.input}
          >
            Speichern
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AvailabilityTimeExceptionForm;
