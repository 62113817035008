const tinyMceKey = 'cum5p4gfrrrn4s0opeoav1ydk7w4806gtbyickc8un4mfl0p';

const envProd = {
  stage: 'production',
  graphqlEndpoint: 'https://v2.api.gets.by/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:6c1bf450-cfad-4cdb-aa01-20bf80b9bd1a',
  userPoolId: 'eu-central-1_ZRmQQzc4G',
  userPoolWebClientId: '49ds79t4ovahqsu6898u4pd3o1',
  s3UploadBucket: 'getsby-app-production',
  s3ImageUploadBucket: 'getsby-images-production',
  s3ImageBaseUrl: 'https://image.gets.by',
  downloadCounterDisplayEndpoint:
    'https://60ci75396j.execute-api.eu-central-1.amazonaws.com/production/generate',
  sentryDsn: 'https://56dd49c03fdf45fab8bb00acc3e75a56@sentry.io/1808541',
  orderScreenUrl: 'https://orderscreen.gets.by',
  webAppUrl: 'https://getsby.at',
  gastroStationUrl:
    'https://play.google.com/store/apps/details?id=by.gets.pickupstation',
  gmaps_key: 'AIzaSyC_3nj3TwqYFRF0OO1iiw1_MHSZAqIiFEA',
  rnUserPoolWebClientId: '2ci7cs9cphb3pk447339q21b9r',
  primaryColor: '#F0C314',
  tinyMceKey
};

const envDev = {
  stage: 'dev',
  graphqlEndpoint: 'https://v2-dev.api.gets.by/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:541ebaf6-bbd2-4383-81be-ff58904831a1',
  userPoolId: 'eu-central-1_RETO432t8',
  userPoolWebClientId: '5nbnnt9ft4792h04grncuh065a',
  s3UploadBucket: 'getsby-app-dev',
  s3ImageUploadBucket: 'getsby-images-dev',
  s3ImageBaseUrl: 'https://dev.image.gets.by',
  downloadCounterDisplayEndpoint:
    'https://sa89qunc75.execute-api.eu-central-1.amazonaws.com/dev/generate',
  sentryDsn: 'https://56dd49c03fdf45fab8bb00acc3e75a56@sentry.io/1808541',
  orderScreenUrl: 'https://dev.orderscreen.gets.by',
  webAppUrl: 'https://dev.web.gets.by',
  gastroStationUrl:
    'https://play.google.com/store/apps/details?id=by.gets.pickupstation.dev',
  gmaps_key: 'AIzaSyC_3nj3TwqYFRF0OO1iiw1_MHSZAqIiFEA',
  rnUserPoolWebClientId: '3u315asmva3n601cbpoimlq73k',
  primaryColor: '#8B5CF6',
  tinyMceKey
};

const envDemo = {
  stage: 'demo',
  graphqlEndpoint: 'https://v2-demo.api.gets.by/graphql',
  region: 'eu-central-1',
  identityPoolId: 'eu-central-1:dece8f0a-07f4-4135-bf56-ce8188b29494',
  userPoolId: 'eu-central-1_6PvnyTilK',
  userPoolWebClientId: '3vvhm64dn6dpme036kja8o4uk3',
  s3UploadBucket: 'getsby-app-demo',
  s3ImageUploadBucket: 'getsby-images-demo',
  s3ImageBaseUrl: 'https://demo.image.gets.by',
  downloadCounterDisplayEndpoint:
    'https://nh38vzufn5.execute-api.eu-central-1.amazonaws.com/demo/generate',
  sentryDsn: 'https://56dd49c03fdf45fab8bb00acc3e75a56@sentry.io/1808541',
  orderScreenUrl: 'https://demo.orderscreen.gets.by',
  webAppUrl: 'https://demo.web.gets.by',
  gastroStationUrl:
    'https://play.google.com/store/apps/details?id=by.gets.pickupstation.demo',
  gmaps_key: 'AIzaSyC_3nj3TwqYFRF0OO1iiw1_MHSZAqIiFEA',
  rnUserPoolWebClientId: '3ch401vmvnjpnaeff42e366dvp',
  primaryColor: '#10B981',
  tinyMceKey
};

let Config = envDev;

switch (process.env.REACT_APP_ENV) {
  case 'DEV':
    Config = envDev;
    break;
  case 'DEMO':
    Config = envDemo;
    break;
  case 'PROD':
    Config = envProd;
    break;
  default:
    Config = envDev;
}

export default Config;
