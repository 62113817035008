export function hasPnpFeature(
  model: GastronomyFeeModelFragment | GastronomyFeeFragment
): boolean {
  return model.take_away || model.self_service || model.drive_in || false;
}

export function hasPpFeature(
  model: GastronomyFeeModelFragment | GastronomyFeeFragment
): boolean {
  return model.dine_in || false;
}

export function hasOrderTypeFeature(
  model: GastronomyFeeModelFragment | GastronomyFeeFragment,
  orderType: TableType
): boolean {
  if (!orderType) {
    return false;
  }

  switch (orderType) {
    case 'DINEIN':
      return model.dine_in || false;
    case 'TAKEAWAY':
      return model.take_away || false;
    case 'SELFSERVICE':
      return model.self_service || false;
    case 'DRIVEIN':
      return model.drive_in || false;
    case 'DELIVERY':
      return model.delivery || false;
    default:
      return false;
  }
}
