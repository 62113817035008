import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import ProductModifierTableRow from './ProductModifierTableRow';

interface Props {
  productModifier?: ModifierWithModifierItemsAndProductsFragment[];
}

const ProductModifierTable: React.FC<Props> = ({ productModifier }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Admin Titel</TableCell>
          <TableCell>Titel</TableCell>
          <TableCell>Beschreibung</TableCell>
          <TableCell>Min</TableCell>
          <TableCell>Max</TableCell>
          <TableCell>Anzahl Optionen</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Verlinkte Produkte</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productModifier && (
          <>
            {productModifier.map((pm, i) => (
              <ProductModifierTableRow modifier={pm} key={i} />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default ProductModifierTable;
