import {
  Avatar,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';
import CircularIndeterminate from './CircularIndeterminate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3)
    },
    containerBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    heading: {
      textAlign: 'center'
    },
    avatar: {
      height: 60,
      width: 60,
      backgroundColor: theme.palette.primary.main,
      margin: '0 auto'
    },
    column: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

interface Props {
  icon?: JSX.Element;
  title: string;
  value: string | number;
  valueLoading: boolean;
}

const InfoCard: React.FC<Props> = ({ icon, title, value, valueLoading }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        {icon && (
          <Grid item lg={4} sm={12} className={classes.column}>
            <Avatar className={classes.avatar}>{icon}</Avatar>
          </Grid>
        )}
        <Grid item lg={icon ? 8 : 12} sm={12} className={classes.column}>
          <Typography variant="h4" className={classes.heading}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.containerBottom}>
        {valueLoading && <CircularIndeterminate />}
        {!valueLoading && <Typography variant="h4">{value}</Typography>}
      </div>
    </Paper>
  );
};

export default InfoCard;
