import { useApolloClient } from '@apollo/client';
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import Joi from 'joi';
import * as JoiPhoneNumber from 'joi-phone-number';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';
import LocationAutocomplete from './locationAutocomplete';
import PasswordInput from './PasswordInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    avatar: {
      width: 100,
      height: 100
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0)
    }
  })
);

interface Props {
  onClose: () => void;
  showCloseButton?: boolean;
}

interface Values {
  name: string;
  city: string;
  country: string;
  street: string;
  type: string;
  zip: string;
  phone?: string;
  website?: string;
  owner_firstname?: string;
  owner_lastname?: string;
  invoice_email?: string;
  uid?: string;
  lng: string;
  lat: string;
  place_id?: string;
  logo_image?: string;
  header_image?: string;
  newPassword?: string;
}

const getInitialValues = (
  gastronomy: GastronomyFragment | null | undefined
): Values => {
  return {
    name: gastronomy ? gastronomy.name || '' : '',
    type: gastronomy ? gastronomy.type || '' : '',
    street: gastronomy ? gastronomy.street || '' : '',
    zip: gastronomy ? gastronomy.zip || '' : '',
    city: gastronomy ? gastronomy.city || '' : '',
    country: gastronomy ? gastronomy.country || 'AT' : 'AT',
    phone: gastronomy ? gastronomy.phone || '' : '',
    website: gastronomy ? gastronomy.website || '' : '',
    owner_firstname: gastronomy ? gastronomy.owner_firstname || '' : '',
    owner_lastname: gastronomy ? gastronomy.owner_lastname || '' : '',
    invoice_email: gastronomy ? gastronomy.invoice_email || '' : '',
    uid: gastronomy ? gastronomy.uid || '' : '',
    lng: gastronomy && gastronomy.lng ? gastronomy.lng.toString() : '',
    lat: gastronomy && gastronomy.lat ? gastronomy.lat.toString() : '',
    place_id: gastronomy ? gastronomy.place_id || '' : '',
    newPassword: ''
  };
};

const MasterDataForm: React.FC<Props> = ({
  onClose,
  showCloseButton = false
}) => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const classes = useStyles();
  const isAdmin = useRecoilValue(isAdminSelector);
  const [values, setValues] = useState<Values>(getInitialValues(gastronomy));
  const [errors, setErrors] = useState<string[]>([]);
  const [
    placeResult,
    setPlaceResult
  ] = useState<google.maps.places.PlaceResult>();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setValues(getInitialValues(gastronomy));
  }, [gastronomy]);

  const handleChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const validateValues = () => {
    setErrors([]);
    const CustomJoi = Joi.extend(JoiPhoneNumber);

    const joiSchemaInput = {
      name: CustomJoi.string().min(2).required(),
      type: CustomJoi.string().min(3).required(),
      street: CustomJoi.string().min(3).required(),
      zip: CustomJoi.number().min(1000).max(99999).required(),
      city: CustomJoi.string().min(2).required(),
      country: CustomJoi.string().required(),
      phone: CustomJoi.string()
        .phoneNumber({ format: 'e164', strict: true })
        .required(),
      website: CustomJoi.string().uri().optional().allow(''),
      owner_firstname: CustomJoi.string().min(2).required(),
      owner_lastname: CustomJoi.string().min(2).required(),
      invoice_email: CustomJoi.string()
        .email({ tlds: { allow: false } })
        .allow(''),
      uid: CustomJoi.string().min(6).max(20).allow(''),
      lng: CustomJoi.required(),
      lat: CustomJoi.required(),
      place_id: CustomJoi.string().allow('').optional(),
      newPassword: CustomJoi.string().min(8).allow('')
    };

    const schema = CustomJoi.object(joiSchemaInput);

    const { error } = schema.validate(
      {
        ...values
      },
      { abortEarly: false }
    );

    if (error) {
      const errFields: string[] = [];
      error.details.forEach((err: any) => {
        errFields.push(err.path[0].toString());
      });
      setErrors(errFields);

      addSnackbarMessages(
        [
          {
            type: 'error',
            message: 'Bitte überprüfen Sie Ihre Eingabe!'
          }
        ],
        setSnackbarMessages
      );

      return false;
    }

    return true;
  };

  const saveGastronomy = async () => {
    if (gastronomy) {
      try {
        if (validateValues()) {
          const { data } = await apolloClient.mutate({
            mutation: UpdateGastronomyMutationDocument,
            variables: {
              input: {
                ...values,
                uuid: gastronomy.uuid,
                lat: parseFloat(values.lat),
                lng: parseFloat(values.lng)
              }
            }
          });

          if (!data) {
            return;
          }

          const newGastronomy = data.updateGastronomy;

          setGastronomy(newGastronomy);

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Profil aktualisiert`
              }
            ],
            setSnackbarMessages
          );

          onClose();
        } else {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: 'Bitte überprüfen Sie Ihre Eingabe!'
              }
            ],
            setSnackbarMessages
          );

          return;
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const getAddressComponents = (components: any, type: string): string => {
    for (const key in components) {
      if (components.hasOwnProperty(key)) {
        if (type === components[key].types[0]) {
          return components[key].long_name;
        }
      }
    }

    return '';
  };

  const setPlace = (result: google.maps.places.PlaceResult) => {
    if (result && result.geometry) {
      console.log('place result received: ', result);
      if (result.address_components) {
        const addressComponents = result.address_components;

        setValues((current) => {
          return {
            ...current,
            name: result.name,
            phone: result.international_phone_number || '',
            street:
              getAddressComponents(addressComponents, 'route') +
              ' ' +
              getAddressComponents(addressComponents, 'street_number'),
            zip: getAddressComponents(addressComponents, 'postal_code'),
            city: getAddressComponents(addressComponents, 'locality'),
            country: getAddressComponents(addressComponents, 'country'),
            lat: result.geometry
              ? result.geometry.location.lat().toString()
              : '',
            lng: result.geometry
              ? result.geometry.location.lng().toString()
              : '',
            place_id: result.place_id,
            website: result.website || ''
          };
        });
      }

      setPlaceResult(result);
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container spacing={3}>
        {isAdmin && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                id="username"
                label="Benutzername (E-Mail)"
                placeholder="office@gets.by"
                className={classes.input}
                disabled={true}
                value={gastronomy ? gastronomy.username : ''}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                id="newPassword"
                label="Neues Passwort"
                onChange={handleChange('newPassword')}
                value={values.newPassword}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="body2">
            Bitte suchen Sie im nachstehenden Feld Ihren Gastronomiebetrieb:
          </Typography>
          <LocationAutocomplete
            setPlaceResult={setPlace}
            placeResult={placeResult}
          />
        </Grid>
        {isAdmin && (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                error={errors.includes('lng')}
                id="lng"
                label="Longitude"
                className={classes.input}
                onChange={handleChange('lng')}
                value={values.lng}
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={errors.includes('lat')}
                id="lat"
                label="Latitude"
                className={classes.input}
                onChange={handleChange('lat')}
                value={values.lat}
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={errors.includes('place_id')}
                id="place_id"
                label="Place ID"
                className={classes.input}
                onChange={handleChange('place_id')}
                value={values.place_id}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Divider className={classes.divider} />
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('name')}
            id="name"
            label="Restaurant Name"
            placeholder="getsby Wirt"
            className={classes.input}
            onChange={handleChange('name')}
            required={true}
            value={values.name}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('type')}
            id="type"
            label="Kurzbeschreibung"
            placeholder="Asiatische Delikatessen"
            className={classes.input}
            onChange={handleChange('type')}
            required={true}
            value={values.type}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            error={errors.includes('street')}
            id="street"
            label="Straße"
            placeholder="getsbyweg 1"
            className={classes.input}
            onChange={handleChange('street')}
            required={true}
            value={values.street}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            error={errors.includes('zip')}
            id="zip"
            label="PLZ"
            placeholder="1010"
            className={classes.input}
            onChange={handleChange('zip')}
            required={true}
            value={values.zip}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            error={errors.includes('city')}
            id="city"
            label="Ort"
            placeholder="Wien"
            className={classes.input}
            onChange={handleChange('city')}
            required={true}
            value={values.city}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="country-select">Land</InputLabel>
            <Select
              error={errors.includes('country')}
              value={values.country}
              onChange={handleChange('country')}
              required={true}
              disabled={!isAdmin}
              labelId="country-select"
              label="Land"
              margin="dense"
            >
              <MenuItem key={'AT'} value={'AT'}>
                Österreich
              </MenuItem>
              <MenuItem key={'DE'} value={'DE'}>
                Deutschland
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('phone')}
            id="phone"
            label="Restaurant Telefon"
            placeholder="+4366012345678"
            className={classes.input}
            onChange={handleChange('phone')}
            value={values.phone}
            margin="dense"
            helperText={
              errors.includes('phone')
                ? 'Bitte im Format +4366012345678'
                : false
            }
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('website')}
            id="website"
            label="Restaurant Webseite"
            placeholder="https://getsby.at/"
            className={classes.input}
            onChange={handleChange('website')}
            value={values.website}
            margin="dense"
            helperText={
              errors.includes('website')
                ? 'Bitte im Format https://getsby.at'
                : false
            }
            variant="outlined"
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('owner_firstname')}
            id="owner_firstname"
            label="Inhaber Vorname"
            placeholder="Max"
            className={classes.input}
            onChange={handleChange('owner_firstname')}
            value={values.owner_firstname}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('owner_lastname')}
            id="owner_lastname"
            label="Inhaber Nachname"
            placeholder="Mustermann"
            className={classes.input}
            onChange={handleChange('owner_lastname')}
            value={values.owner_lastname}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('uid')}
            id="uid"
            label="UID"
            placeholder="ATUXXXXXXXX"
            className={classes.input}
            onChange={handleChange('uid')}
            value={values.uid}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={errors.includes('invoice_email')}
            id="invoice_email"
            label="Rechnungs E-Mail"
            placeholder="accounting@gets.by"
            className={classes.input}
            onChange={handleChange('invoice_email')}
            value={values.invoice_email}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        {showCloseButton && (
          <Grid item xs={6}>
            <Button
              className={classes.input}
              onClick={onClose}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        )}
        <Grid item xs={showCloseButton ? 6 : 12}>
          <Button
            className={classes.input}
            onClick={saveGastronomy}
            variant="contained"
            color="primary"
          >
            Speichern
            <DoneRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default MasterDataForm;
