import {
  Avatar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IMG_BASE_PATH } from '../constants';
import { gastronomyState } from '../store/gastronomy';
import ProfileImagesForm from './ProfileImagesForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: 100,
      height: 100
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    marginBottom: {
      marginBottom: theme.spacing(1)
    }
  })
);

const ProfileImages: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [update, setUpdate] = useState<boolean>(false);

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {update ? (
        <ProfileImagesForm onClose={() => setUpdate(false)} showCloseButton />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.center}>
            <Typography variant="body1" className={classes.marginBottom}>
              Logo
            </Typography>

            <Avatar className={classes.avatar}>
              <img
                src={gastronomy.logo_image || ''}
                style={{ maxHeight: 100, width: 'auto' }}
                alt=""
              />
            </Avatar>
          </Grid>
          <Grid item xs={12} className={classes.center}>
            <Typography variant="body1" className={classes.marginBottom}>
              Header Bild
            </Typography>

            <img
              src={
                gastronomy.header_image || `${IMG_BASE_PATH}/food_pattern.svg`
              }
              style={{
                maxHeight: 'auto',
                width: '100%',
                maxWidth: 300
              }}
              alt=""
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.input}
              onClick={() => setUpdate(true)}
              variant="outlined"
            >
              Bearbeiten
              <EditRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProfileImages;
