import { useApolloClient, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import Joi from 'joi';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getProductGroupLabel } from '../helpers/MiscHelper';
import { getReceiptStatusLabel } from '../helpers/OrderHelper';
import {
  AddPrinterMutationDocument,
  UpdatePrinterMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    card: {
      marginBottom: theme.spacing(1)
    }
  })
);

const schemaValues = Joi.object({
  name: Joi.string().min(3),
  description: Joi.string().allow(''),
  connection: Joi.string(),
  ip: Joi.string().ip().allow(''),
  port: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  address: Joi.string()
    .regex(/^([0-9a-fA-F]{2}[:-]?){5}([0-9a-fA-F]{2})$/)
    .allow(''),
  brand: Joi.string().allow(''),
  model: Joi.string().allow(''),
  on_status: Joi.string(),
  default_for: Joi.string().allow('')
});

const schemaConfig = Joi.object({
  printLogo: Joi.boolean(),
  printSize: Joi.string(),
  charsOnLine: Joi.string().pattern(/^[0-9]+$/),
  charset: Joi.string(),
  checkPaperStatus: Joi.boolean(),
  printCollectiveReceipt: Joi.boolean()
});

interface Props {
  printer?: PrinterFragment;
  onClose: () => void;
}

type FormValues = Omit<
  PrinterAddInput,
  'port' | 'user_uuid' | 'default_for'
> & {
  port: string;
  default_for: '' | 'ALL' | 'DRINKS' | 'FOOD';
};

type ConfigFormValues = Omit<PrinterConfigInput, 'charsOnLine'> & {
  charsOnLine: string;
};

const PrinterForm: React.FC<Props> = ({ printer, onClose }) => {
  const classes = useStyles();
  const [values, setValues] = useState<FormValues>({
    name: printer ? printer.name : '',
    description: printer && printer.description ? printer.description : '',
    connection: printer ? printer.connection : 'ETHERNET',
    ip: printer && printer.ip ? printer.ip : '',
    port: printer && printer.port ? printer.port.toString() : '9100',
    address: printer && printer.address ? printer.address : '',
    brand: printer && printer.brand ? printer.brand : '',
    model: printer && printer.model ? printer.model : '',
    on_status: printer ? printer.on_status : 'ACCEPTED',
    default_for: printer && printer.default_for ? printer.default_for : ''
  });
  const [config, setConfig] = useState<ConfigFormValues>({
    printLogo:
      printer &&
      printer.printer_config &&
      typeof printer.printer_config.printLogo === 'boolean'
        ? printer.printer_config.printLogo
        : true,
    printSize:
      printer && printer.printer_config && printer.printer_config.printSize
        ? printer.printer_config.printSize
        : 'PRINTING_SIZE_80_MM',
    charsOnLine:
      printer && printer.printer_config && printer.printer_config.charsOnLine
        ? printer.printer_config.charsOnLine.toString()
        : '48',
    charset:
      printer && printer.printer_config && printer.printer_config.charset
        ? printer.printer_config.charset
        : 'windows-1252',
    checkPaperStatus:
      printer &&
      printer.printer_config &&
      typeof printer.printer_config.checkPaperStatus === 'boolean'
        ? printer.printer_config.checkPaperStatus
        : true,
    printCollectiveReceipt:
      printer &&
      printer.printer_config &&
      typeof printer.printer_config.printCollectiveReceipt === 'boolean'
        ? printer.printer_config.printCollectiveReceipt
        : false
  });
  const gastronomy = useRecoilValue(gastronomyState);
  const [errors, setErrors] = useState<string[]>([]);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();
  const [updatePrinter] = useMutation(UpdatePrinterMutationDocument);

  const handleChange = (name: string) => (event: any) => {
    setValues((prev) => {
      if (name === 'connection') {
        if (event.target.value === 'ETHERNET') {
          prev.address = '';
        }
        if (event.target.value === 'BLUETOOTH') {
          prev.ip = '';
        }
      }

      return {
        ...prev,
        [name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      };
    });
  };

  const handleConfigChange = (name: string) => (event: any) => {
    setConfig((prev) => ({
      ...prev,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const validateInput = (): boolean => {
    const result = schemaValues.validate(values);
    const configResult = schemaConfig.validate(config);
    return processJoiResult(result) && processJoiResult(configResult);
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation errror', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const savePrinter = async () => {
    setErrors([]);
    if (gastronomy) {
      try {
        if (!validateInput()) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: 'Bitte überprüfen Sie Ihre Eingabe!'
              }
            ],
            setSnackbarMessages
          );

          return;
        }

        if (printer) {
          const result = await updatePrinter({
            variables: {
              input: {
                ...values,
                port: values.port ? parseInt(values.port, 10) : undefined,
                default_for: values.default_for || undefined,
                user_uuid: gastronomy.uuid,
                uuid: printer.uuid,
                config: {
                  ...config,
                  charsOnLine: config.charsOnLine
                    ? parseInt(config.charsOnLine, 10)
                    : undefined
                }
              }
            }
          });

          if (result.errors) {
            addSnackbarMessages(
              result.errors.map((error) => ({
                type: 'error',
                message: error.message
              })),
              setSnackbarMessages
            );
          } else {
            addSnackbarMessages(
              [
                {
                  type: 'success',
                  message: `Drucker ${values.name} aktualisiert`
                }
              ],
              setSnackbarMessages
            );
          }
        } else {
          if (gastronomy.uuid) {
            const result = await apolloClient.mutate({
              mutation: AddPrinterMutationDocument,
              variables: {
                input: {
                  ...values,
                  port: values.port ? parseInt(values.port, 10) : undefined,
                  default_for: values.default_for || undefined,
                  user_uuid: gastronomy.uuid,
                  config: {
                    ...config,
                    charsOnLine: config.charsOnLine
                      ? parseInt(config.charsOnLine, 10)
                      : undefined
                  }
                }
              },
              refetchQueries: ['getPrintersQuery']
            });

            if (result.errors) {
              addSnackbarMessages(
                result.errors.map((error) => ({
                  type: 'error',
                  message: error.message
                })),
                setSnackbarMessages
              );
            } else {
              addSnackbarMessages(
                [
                  {
                    type: 'success',
                    message: `Drucker ${values.name} hinzugefügt`
                  }
                ],
                setSnackbarMessages
              );
            }
          }
        }

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <form noValidate autoComplete="off">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item>
              <TextField
                error={errors.includes('name')}
                id="name"
                label="Name"
                placeholder="Drucker 1"
                className={classes.input}
                onChange={handleChange('name')}
                required={true}
                value={values.name}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={errors.includes('description')}
                id="description"
                label="Beschreibung"
                placeholder="Thermodrucker in der Küche"
                className={classes.input}
                onChange={handleChange('description')}
                value={values.description}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={errors.includes('brand')}
                id="brand"
                label="Hersteller"
                placeholder="Epson"
                className={classes.input}
                onChange={handleChange('brand')}
                value={values.brand}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={errors.includes('model')}
                id="model"
                label="Modell"
                placeholder="TM-20"
                className={classes.input}
                onChange={handleChange('model')}
                value={values.model}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormControl
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="default-for-select">Standard</InputLabel>
                <Select
                  error={errors.includes('default_for')}
                  value={values.default_for}
                  onChange={handleChange('default_for')}
                  labelId="default-for-select"
                  label="Standard"
                  margin="dense"
                >
                  <MenuItem key="" value="">
                    -
                  </MenuItem>
                  <MenuItem key="ALL" value="ALL">
                    {getProductGroupLabel('ALL')}
                  </MenuItem>
                  <MenuItem key="DRINKS" value="DRINKS">
                    {getProductGroupLabel('DRINKS')}
                  </MenuItem>
                  <MenuItem key="FOOD" value="FOOD">
                    {getProductGroupLabel('FOOD')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="on-status-select">Bei Status</InputLabel>
                <Select
                  error={errors.includes('on_status')}
                  value={values.on_status}
                  onChange={handleChange('on_status')}
                  labelId="on-status-select"
                  label="Bei Status"
                  required={true}
                  margin="dense"
                >
                  <MenuItem key="RECEIVED" value="RECEIVED">
                    {getReceiptStatusLabel('RECEIVED')}
                  </MenuItem>
                  <MenuItem key="ACCEPTED" value="ACCEPTED">
                    {getReceiptStatusLabel('ACCEPTED')}
                  </MenuItem>
                  <MenuItem key="READY" value="READY">
                    {getReceiptStatusLabel('READY')}
                  </MenuItem>
                  <MenuItem key="COMPLETED" value="COMPLETED">
                    {getReceiptStatusLabel('COMPLETED')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <FormControl
                  className={classes.input}
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel id="connection-select">Verbindung</InputLabel>
                  <Select
                    error={errors.includes('connection')}
                    value={values.connection}
                    onChange={handleChange('connection')}
                    labelId="connection-select"
                    label="Verbindung"
                    required={true}
                    margin="dense"
                  >
                    <MenuItem key="ETHERNET" value="ETHERNET">
                      Netzwerk
                    </MenuItem>
                    <MenuItem key="BLUETOOTH" value="BLUETOOTH">
                      Bluetooth
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {values.connection === 'ETHERNET' && (
                <>
                  <Grid item>
                    <TextField
                      error={errors.includes('ip')}
                      id="ip"
                      label="IP Adresse"
                      placeholder="192.168.0.1"
                      className={classes.input}
                      onChange={handleChange('ip')}
                      value={values.ip}
                      margin="dense"
                      variant="outlined"
                      inputMode="numeric"
                      required
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      error={errors.includes('port')}
                      id="port"
                      label="Port"
                      placeholder="9100"
                      className={classes.input}
                      onChange={handleChange('port')}
                      value={values.port}
                      margin="dense"
                      variant="outlined"
                      inputMode="numeric"
                      required
                    />
                  </Grid>
                </>
              )}
              {values.connection === 'BLUETOOTH' && (
                <Grid item>
                  <TextField
                    error={errors.includes('address')}
                    id="address"
                    label="MAC Adresse"
                    placeholder="00:11:22:33:44:55"
                    className={classes.input}
                    onChange={handleChange('address')}
                    value={values.address}
                    margin="dense"
                    variant="outlined"
                    inputMode="numeric"
                    required
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <FormControl
                  className={classes.input}
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel id="charset">Charset</InputLabel>
                  <Select
                    error={errors.includes('charset')}
                    value={config.charset}
                    onChange={handleConfigChange('charset')}
                    labelId="charset"
                    label="Charset"
                    required={true}
                    margin="dense"
                  >
                    <MenuItem key="IBM850" value="IBM850">
                      Intl(IBM850)
                    </MenuItem>
                    <MenuItem key="IBM437" value="IBM437">
                      US(IBM437)
                    </MenuItem>
                    <MenuItem key="IBM00858" value="IBM00858">
                      EUR(IBM00858)
                    </MenuItem>
                    <MenuItem key="windows-1252" value="windows-1252">
                      Windows(windows-1252)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl
                  className={classes.input}
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel id="print-size">Papierbreite</InputLabel>
                  <Select
                    error={errors.includes('printSize')}
                    value={config.printSize}
                    onChange={handleConfigChange('printSize')}
                    labelId="print-size"
                    label="Papierbreite"
                    required={true}
                    margin="dense"
                  >
                    <MenuItem
                      key="PRINTING_SIZE_80_MM"
                      value="PRINTING_SIZE_80_MM"
                    >
                      80mm
                    </MenuItem>
                    <MenuItem
                      key="PRINTING_SIZE_76_MM"
                      value="PRINTING_SIZE_76_MM"
                    >
                      76mm
                    </MenuItem>
                    <MenuItem
                      key="PRINTING_SIZE_58_MM"
                      value="PRINTING_SIZE_58_MM"
                    >
                      58mm
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  error={errors.includes('charsOnLine')}
                  id="chars-on-line"
                  label="Buchstaben/Zeile"
                  placeholder="48"
                  className={classes.input}
                  onChange={handleConfigChange('charsOnLine')}
                  value={config.charsOnLine}
                  margin="dense"
                  variant="outlined"
                  inputMode="numeric"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        typeof config.printLogo === 'boolean'
                          ? config.printLogo
                          : true
                      }
                      value={config.printLogo}
                      onChange={handleConfigChange('printLogo')}
                      color="primary"
                    />
                  }
                  label={'Logo Drucken'}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        typeof config.printCollectiveReceipt === 'boolean'
                          ? config.printCollectiveReceipt
                          : false
                      }
                      value={config.printCollectiveReceipt}
                      onChange={handleConfigChange('printCollectiveReceipt')}
                      color="primary"
                    />
                  }
                  label={
                    'Sammelbeleg drucken (Wenn Bestellung angenommen wird)'
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        typeof config.checkPaperStatus === 'boolean'
                          ? config.checkPaperStatus
                          : true
                      }
                      value={config.checkPaperStatus}
                      onChange={handleConfigChange('checkPaperStatus')}
                      color="primary"
                    />
                  }
                  label={'Papier Status überprüfen'}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Button
                className={classes.input}
                onClick={onClose}
                variant="outlined"
              >
                Verwerfen
                <ClearRounded className={classes.rightIcon} />
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                className={classes.input}
                onClick={savePrinter}
                variant="contained"
                color="primary"
              >
                Speichern
                <DoneRounded className={classes.rightIcon} />
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

export default PrinterForm;
