import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import React from 'react';
import './App.css';
import Config from './config';
import { setAmplifyTranslations } from './helpers/AmplifyTranslations';
import {
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloClient,
  ApolloProvider,
  HttpOptions
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { AwsClient } from 'aws4fetch';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  createMuiTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery
} from '@material-ui/core';
import { getsbySecondary, getsbyPrimary } from './helpers/Color';
import deLocale from 'date-fns/locale/de';
import UserInitialization from './components/UserInitialization';

Amplify.configure({
  Auth: {
    identityPoolId: Config.identityPoolId,
    region: Config.region,
    userPoolId: Config.userPoolId,
    userPoolWebClientId: Config.userPoolWebClientId,
    mandatorySignIn: false
  },
  AWSS3: {
    bucket: Config.s3UploadBucket,
    region: Config.region
  }
});

setAmplifyTranslations();

const App: React.FC = () => {
  let client: ApolloClient<object> | undefined = undefined;

  const initializeClient = () => {
    const aws = new AwsClient({
      accessKeyId: '',
      secretAccessKey: '',
      retries: 0
    });

    const awsFetch = async (uri: string, options: any) => {
      const credentials = await Auth.currentCredentials();
      options.aws = {
        ...credentials,
        region: 'eu-central-1',
        service: 'execute-api'
      };
      return aws.fetch(uri, options);
    };

    const httpConfig: HttpOptions = {
      uri: Config.graphqlEndpoint,
      fetch: awsFetch
    };

    const httpLink = new HttpLink(httpConfig);

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, response }) => {
        if (graphQLErrors) {
          graphQLErrors.map((graphQlError) => {
            const event: Sentry.Event = {
              message: 'ApolloError: ' + graphQlError.message,
              extra: graphQlError as any
            };

            Sentry.captureEvent(event);

            console.log(
              `[GraphQL error]: Message: ${graphQlError.message}, Location: ${graphQlError.locations}, Path: ${graphQlError.path}`
            );
          });
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          const event: Sentry.Event = {
            message: `[GraphQL network: ${operation.operationName}]: ${networkError.message}`,
            extra: {
              operation,
              response,
              graphQLErrors,
              networkError
            }
          };
          Sentry.captureEvent(event);
        }
      }
    );

    const retryLink = new RetryLink({
      attempts: (count, _operation, error) => {
        if (error && error.statusCode === 503) {
          location.href = '/maintenance';
        }

        return !!error && count < 15;
      },
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      }
    });

    const link = ApolloLink.from([errorLink, retryLink, httpLink]);
    const imCache = new InMemoryCache({
      typePolicies: {
        Printer: { keyFields: ['uuid'] },
        Gastronomy: { keyFields: ['uuid'] },
        ProductGroup: { keyFields: ['uuid'] },
        Product: { keyFields: ['uuid'] },
        Receipt: { keyFields: ['uuid'] },
        Modifier: { keyFields: ['uuid'] },
        ModifierItem: { keyFields: ['uuid'] },
        Table: { keyFields: ['uuid'] },
        QrCodeLookup: { keyFields: ['url'] },
        Category: { keyFields: ['uuid'] }
      }
    });

    const clientConfig = {
      link,
      cache: imCache.restore({})
    };

    client = new ApolloClient(clientConfig);
  };

  if (!client) {
    initializeClient();
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getsbyTheme = responsiveFontSizes(
    createMuiTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light',
        primary: getsbyPrimary,
        secondary: getsbySecondary
      }
    })
  );

  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <ThemeProvider theme={getsbyTheme}>
          <CssBaseline />
          {client && (
            <ApolloProvider client={client}>
              <UserInitialization />
            </ApolloProvider>
          )}
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default App;
