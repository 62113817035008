import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import {
  AccountCircleRounded,
  AddBoxRounded,
  ArrowBackRounded,
  ExpandLessRounded,
  SearchRounded
} from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import Config from '../config';
import { gastronomyState } from '../store/gastronomy';
import { ReactComponent as GetsbyLogo } from './../assets/getsby_logo.svg';

interface Props {
  appBarColors?: AppBarColors;
  appBarLogoFile?: File | null;
  appBarLogo?: string;
  primaryColor?: ColorPalette;
  secondaryColor?: ColorPalette;
  titleOverride?: string;
  disableLink?: boolean;
}

const getsbyYellow = '#f0c314';
const getsbyGray = '#232020';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phone: {
      margin: theme.spacing(2, 'auto'),
      width: '100%',
      maxWidth: '20rem',
      height: '100%',
      maxHeight: '36rem',
      border: 'solid',
      borderWidth: 10,
      borderColor: '#333',
      borderRadius: 25,
      overflow: 'hidden',
      backgroundColor: 'white',
      color: 'black'
    },
    appBarPreview: {
      padding: theme.spacing(1),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '50px'
    },
    headerImagePreview: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      color: 'white'
    },
    categories: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '50px',
      boxShadow:
        '0 0 transparent, 0 0 transparent, 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)'
    },
    searchIcon: {
      margin: theme.spacing(1)
    },
    categoryButton: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 5,
      fontWeight: 'bold'
    },
    body: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(2)
    },
    productGroup: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '1.25rem'
    },
    product: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      border: '1px solid #eee',
      borderRadius: 5,
      borderLeftWidth: 5
    },
    productPricing: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-end',
      fontWeight: 'bold'
    },
    cartButtonWrapper: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1)
    },
    cartButton: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),

      borderRadius: 5,
      fontWeight: 'bold'
    },
    cartAmount: {
      padding: theme.spacing(0.5, 1.25),
      borderRadius: 100
    },
    linkContainer: {
      textAlign: 'center'
    }
  })
);

const CustomThemePreview: React.FC<Props> = ({
  appBarColors,
  appBarLogo,
  appBarLogoFile,
  primaryColor,
  secondaryColor,
  titleOverride,
  disableLink = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const gastronomy = useRecoilValue(gastronomyState);
  const calculatedPrimaryColor = useMemo(() => {
    return (primaryColor && primaryColor._500) || getsbyYellow;
  }, [primaryColor]);
  const calculatedSecondaryColor = useMemo(() => {
    return (secondaryColor && secondaryColor._500) || getsbyGray;
  }, [secondaryColor]);
  const gastronomyLink = useMemo(() => {
    return gastronomy
      ? `${Config.webAppUrl}/speisekarte/${gastronomy.url_path_id}`
      : Config.webAppUrl;
  }, [gastronomy]);

  const renderProduct = (active = false): React.ReactNode => (
    <div
      className={classes.product}
      style={{
        borderColor: active ? calculatedPrimaryColor : '#eee'
      }}
    >
      <div>Produkt</div>
      <div
        className={classes.productPricing}
        style={{
          color: calculatedPrimaryColor
        }}
      >
        4.20 € <AddBoxRounded style={{ marginLeft: theme.spacing(1) }} />
      </div>
    </div>
  );

  const handlePhoneClick = () => {
    if (!disableLink) {
      window.open(gastronomyLink, '_blank');
    }
  };

  return (
    <>
      <div
        className={classes.phone}
        onClick={handlePhoneClick}
        style={{ cursor: disableLink ? 'default' : 'pointer' }}
      >
        <>
          <div
            className={classes.appBarPreview}
            style={{
              backgroundColor:
                (appBarColors && appBarColors.backgroundColor) || 'white',
              borderBottom:
                appBarColors && appBarColors.backgroundColor
                  ? ''
                  : '1px solid #ddd',
              color: (appBarColors && appBarColors.primaryColor) || getsbyYellow
            }}
          >
            <ArrowBackRounded />
            {appBarLogoFile || appBarLogo ? (
              <img
                src={
                  appBarLogoFile
                    ? URL.createObjectURL(appBarLogoFile)
                    : appBarLogo
                }
                style={{ maxHeight: 40, width: 'auto', maxWidth: 100 }}
              />
            ) : (
              <GetsbyLogo
                style={{ maxHeight: 40, width: 'auto', maxWidth: 100 }}
              />
            )}
            <AccountCircleRounded />
          </div>
          {titleOverride ? (
            <div
              className={classes.headerImagePreview}
              style={{
                backgroundImage: `
          linear-gradient(
            rgba(0, 0, 0, 0.6), 
            rgba(0, 0, 0, 0.6)
            ), url('')`
              }}
            >
              <div>{titleOverride}</div>
            </div>
          ) : (
            <>
              {gastronomy && (
                <div
                  className={classes.headerImagePreview}
                  style={{
                    backgroundImage: `
                linear-gradient(
                  rgba(0, 0, 0, 0.6), 
                  rgba(0, 0, 0, 0.6)
                  ), url(${gastronomy.header_image || ''})`
                  }}
                >
                  <div>{gastronomy.name}</div>
                  <div>{gastronomy.type}</div>
                </div>
              )}
            </>
          )}
          <div className={classes.categories}>
            <SearchRounded
              className={classes.searchIcon}
              style={{
                color: calculatedPrimaryColor
              }}
            />
            <div
              className={classes.categoryButton}
              style={{
                backgroundColor: calculatedPrimaryColor,
                color: calculatedSecondaryColor
              }}
            >
              Speisen
            </div>
            <div
              className={classes.categoryButton}
              style={{
                color: calculatedPrimaryColor
              }}
            >
              Getränke
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.productGroup}>
              Speisen <ExpandLessRounded />
            </div>
            {renderProduct()}
            {renderProduct(true)}
            {renderProduct()}
            {renderProduct()}
            <div className={classes.cartButtonWrapper}>
              <div
                className={classes.cartButton}
                style={{
                  backgroundColor: calculatedPrimaryColor,
                  color: (secondaryColor && secondaryColor._500) || getsbyGray
                }}
              >
                <div
                  className={classes.cartAmount}
                  style={{
                    backgroundColor:
                      (secondaryColor && secondaryColor._500) || getsbyGray,
                    color: calculatedPrimaryColor
                  }}
                >
                  1
                </div>
                <div>Warenkorb</div>
                <div>4.20 €</div>
              </div>
            </div>
          </div>
        </>
      </div>

      {!disableLink && (
        <div className={classes.linkContainer}>
          <a href={gastronomyLink} target="_blank" rel="noopener noreferrer">
            Aktuellen Webauftritt ansehen
          </a>
        </div>
      )}
    </>
  );
};

export default CustomThemePreview;
