import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import React from 'react';
import { captureSilentError } from '../helpers/ErrorHelper';
import CircularIndeterminate from './CircularIndeterminate';
import { Autocomplete } from '@material-ui/lab';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { useSetRecoilState } from 'recoil';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  AdminGetGastronomiesListQueryDocument,
  GetGastronomyQueryDocument
} from '../services/graphql/typed-operations';
import { useRecoilState } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { isStorageAvailable } from '../helpers/StorageHelper';
import { STORAGE_GASTRONOMY_KEY } from '../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    }
  })
);

const GastronomySelect: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);

  const { data, loading } = useQuery(AdminGetGastronomiesListQueryDocument);
  const [getGastronomy] = useLazyQuery(GetGastronomyQueryDocument, {
    onCompleted: (data) => {
      if (data) {
        const gastronomy = data.getGastronomy;

        console.debug('Gastronomy', gastronomy);

        if (gastronomy) {
          if (isStorageAvailable()) {
            localStorage.setItem(
              STORAGE_GASTRONOMY_KEY,
              JSON.stringify(gastronomy)
            );
          }

          setGastronomy(gastronomy);

          addSnackbarMessages(
            [
              {
                type: 'info',
                message: `Du bist jetzt als ${gastronomy.name} angemeldet`
              }
            ],
            setSnackbarMessages
          );
        }
      }
    }
  });

  const fetchGastronomy = async (uuid: string) => {
    setIsLoading(true);
    try {
      getGastronomy({ variables: { params: { uuid } } });
    } catch (error) {
      captureSilentError(error);

      clearGastronomy();
    }
    setIsLoading(false);
  };

  const clearGastronomy = () => {
    if (isStorageAvailable()) {
      localStorage.removeItem(STORAGE_GASTRONOMY_KEY);
    }
    setGastronomy(undefined);
  };

  const handleChange = async (
    _event: ChangeEvent<unknown>,
    value: { uuid?: string } | null
  ) => {
    if (data && value) {
      const selectedGastronomy = data.adminGetGastronomies.find(
        (gastronomy) => gastronomy.uuid === value.uuid
      );

      if (selectedGastronomy && selectedGastronomy.uuid) {
        await fetchGastronomy(selectedGastronomy.uuid);
      } else {
        clearGastronomy();
      }
    } else {
      clearGastronomy();
    }
  };

  return (
    <>
      {isLoading || loading ? (
        <CircularIndeterminate />
      ) : (
        <Autocomplete
          id="gastronomy-select"
          options={data ? data.adminGetGastronomies : []}
          getOptionLabel={(option) => `${option.name} (${option.zip})`}
          className={classes.formControl}
          value={gastronomy || null}
          getOptionSelected={(option, value) => option.uuid === value.uuid}
          renderInput={(params) => <TextField {...params} label="Restaurant" />}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default GastronomySelect;
