import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonContainer: {
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    addButton: {
      width: '100%',
      height: 50
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  printer: PrinterFragment;
}

const PrinterProductsDialog: React.FC<Props> = ({
  onClose,
  printer,
  ...other
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          Verlinkte Produktgruppen & Produkte von {printer.name}
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {printer.productGroups && printer.productGroups.length > 0 && (
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Produktgruppen</ListSubheader>
              }
            >
              {printer.productGroups.map((pg) => (
                <ListItem divider key={pg.uuid}>
                  <ListItemText>{pg.title}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
          {printer.products && printer.products.length > 0 && (
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Produkte</ListSubheader>
              }
            >
              {printer.products.map((p) => (
                <ListItem divider key={p.uuid}>
                  <ListItemText>{p.title}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrinterProductsDialog;
