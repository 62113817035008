import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { GetPrintersQueryDocument } from '../services/graphql/typed-operations';
import CircularIndeterminate from './CircularIndeterminate';
import PrinterLineItem from './PrinterLineItem';
import PrinterForm from './PrinterForm';
import Config from '../config';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    button: {
      margin: theme.spacing(1)
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const GastronomyPrinters: React.FC = () => {
  const classes = useStyles();
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const gastronomy = useRecoilValue(gastronomyState);

  const { data, loading } = useQuery(GetPrintersQueryDocument, {
    variables: { user_uuid: gastronomy ? gastronomy.uuid : 'not set' },
    skip: !gastronomy
  });

  if (!gastronomy || !gastronomy.pickup_version) {
    return (
      <div>
        <Alert variant="filled" severity="info">
          <AlertTitle>Hinweis</AlertTitle>
          Diese Einstellungen können nur in Verbindung mit unserer GASTRO
          Terminal App vorgenommen werden.
        </Alert>
        <Typography variant="body2" className={classes.margin}>
          Die GASTRO Terminal App ist optional auf jedem Android-Gerät
          installierbar und ermöglicht Ihnen Ihre vorhandenen Netzwerk- oder
          Bluetooth-Bondrucker mit der App zu verwenden.
        </Typography>
        <a href={Config.gastroStationUrl} target="_blank" rel="noreferrer">
          <img
            src={process.env.PUBLIC_URL + '/img/google-play-badge.png'}
            style={{ height: 'auto', width: '100%', maxWidth: '200px' }}
            alt=""
          />
        </a>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {data &&
            data.getPrinters.map((printer) => (
              <PrinterLineItem printer={printer} key={printer.uuid} />
            ))}
          {showAddForm && <PrinterForm onClose={() => setShowAddForm(false)} />}
          {!showAddForm && (
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => setShowAddForm(true)}
            >
              Neuer Drucker
              <AddIcon className={classes.rightIcon} />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default GastronomyPrinters;
