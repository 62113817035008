import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  DeleteProductModifierMutationDocument,
  GetProductModifierLinksQueryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { hasPosSelector } from '../store/gastronomy';
import ModifierItemForm from './ModifierItemForm';
import ModifierItemListItem from './ModifierItemListItem';
import ModifierForm from './ModifierForm';
import ModifierLinkDialog from './ModifierLinkDialog';
import ModifierStatusSwitch from './ModifierStatusSwitch';
import { LinkRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      marginTop: theme.spacing(3),
      width: '100%'
    },
    modifierItemList: {
      padding: 0,
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      width: '100%'
    }
  })
);

interface Props {
  modifier: ModifierWithModifierItemsFragment;
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}

const ModifierPanel: React.FC<Props> = ({
  modifier,
  handleChange,
  expanded
}) => {
  const classes = useStyles();
  const [update, setUpdate] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [showLinkModifierDialog, setShowLinkModifierDialog] = useState(false);
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const { data: modifierLinkData } = useQuery(
    GetProductModifierLinksQueryDocument,
    {
      variables: { modifier_uuid: modifier.uuid }
    }
  );

  const onDeleteModifier = async (
    modifier: ModifierWithModifierItemsFragment
  ) => {
    try {
      await apolloClient.mutate({
        mutation: DeleteProductModifierMutationDocument,
        variables: { uuid: modifier.uuid },
        refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Optionsgruppe ${modifier.title} gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      <ModifierLinkDialog
        open={showLinkModifierDialog}
        modifier={modifier}
        onClose={() => {
          setShowLinkModifierDialog(false);
        }}
      />
      <Accordion
        expanded={expanded === `modifier-${modifier.uuid}`}
        onChange={handleChange(`modifier-${modifier.uuid}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${modifier.uuid}-content`}
          id={`panel-${modifier.uuid}-header`}
        >
          <Typography className={classes.heading}>
            {modifier.title} {update && 'bearbeiten'}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Min: {modifier.min} Max: {modifier.max}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {modifierLinkData?.getProductModifierLinks &&
              modifierLinkData.getProductModifierLinks.length > 1 && (
                <Grid item sm={12}>
                  <Chip
                    icon={<WarningIcon />}
                    label="Diese Optionsgruppe ist mit mehreren Produkten verknüpft!"
                    color="primary"
                  />
                </Grid>
              )}
            {!update && (
              <>
                <Grid item sm={3}>
                  <ModifierStatusSwitch modifier={modifier} />
                </Grid>
                <Grid item sm={3}>
                  <Button
                    className={classes.input}
                    onClick={() => setShowLinkModifierDialog(true)}
                    variant="outlined"
                  >
                    Verlinken
                    <LinkRounded className={classes.rightIcon} />
                  </Button>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    className={classes.input}
                    onClick={() => setUpdate(true)}
                    variant="outlined"
                  >
                    Bearbeiten
                    <EditRounded className={classes.rightIcon} />
                  </Button>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    disabled={hasPos}
                    className={classes.input}
                    onClick={() => onDeleteModifier(modifier)}
                    variant="outlined"
                  >
                    Löschen
                    <DeleteIcon className={classes.rightIcon} />
                  </Button>
                </Grid>
              </>
            )}
            {update && (
              <ModifierForm
                modifier={modifier}
                onClose={() => setUpdate(false)}
              />
            )}
            <Grid item sm={12}>
              <List className={classes.modifierItemList}>
                {modifier.modifierItems &&
                  modifier.modifierItems.map((modifierItem) => (
                    <ModifierItemListItem
                      key={modifierItem.uuid}
                      modifierItem={modifierItem}
                    />
                  ))}
                {isFormVisible && (
                  <ModifierItemForm
                    modifierUuid={modifier.uuid}
                    onClose={() => setIsFormVisible(false)}
                  />
                )}
                {!isFormVisible && (
                  <Button
                    disabled={hasPos}
                    variant="outlined"
                    onClick={() => setIsFormVisible(true)}
                    className={classes.addButton}
                  >
                    Option hinzufügen
                    <AddIcon className={classes.rightIcon} />
                  </Button>
                )}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ModifierPanel;
