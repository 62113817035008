import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import SimpleDialog from '../components/SimpleDialog';
import { Alert } from '@material-ui/lab';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { gastronomyState, tablesState } from '../store/gastronomy';
import { AddTablesMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { useApolloClient } from '@apollo/client';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';

interface Props {
  show: boolean;
  close: () => void;
}

export interface TableRangeInput {
  prefix: string;
  from: string;
  to: string;
  suffix: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(2, 0)
    },
    padding: {
      paddingRight: theme.spacing(2)
    }
  })
);

const TableRangeTool: React.FC<Props> = ({ show, close }: Props) => {
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setTables = useSetRecoilState(tablesState);
  const classes = useStyles();
  const apolloClient = useApolloClient();

  const [values, setValues] = useState<TableRangeInput>({
    prefix: '',
    from: '',
    to: '',
    suffix: ''
  });
  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (name: string) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setValues({
      ...values,
      [name]: value
    });
  };

  const validateInput = (): boolean => {
    const fieldErrors: string[] = [];

    let hasErrors = false;

    if (values.from.length === 0) {
      fieldErrors.push('from');
      hasErrors = true;
    }
    if (values.to.length === 0) {
      fieldErrors.push('to');
      hasErrors = true;
    }
    if (+values.to < +values.from) {
      fieldErrors.push('from');
      fieldErrors.push('to');
      hasErrors = true;
    }

    setErrors(fieldErrors);
    return !hasErrors;
  };

  const addTables = async () => {
    if (gastronomy) {
      try {
        const input: TableAddInput[] = [];

        if (!validateInput()) {
          return;
        }

        for (let i = +values.from; i <= +values.to; i++) {
          const table: TableAddInput = {
            user_uuid: gastronomy.uuid,
            table_number: `${values.prefix}${i}${values.suffix}`,
            table_type: 'DINEIN'
          };

          input.push(table);
        }

        const { data } = await apolloClient.mutate({
          mutation: AddTablesMutationDocument,
          variables: { input },
          refetchQueries: ['getTablesQuery']
        });

        if (!data) {
          return;
        }

        setTables((tables) => [...tables, ...data.addTables]);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `${data.addTables.length} Tische hinzugefügt`
            }
          ],
          setSnackbarMessages
        );

        close();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <SimpleDialog
      open={show}
      title={`Mehrere Tische hinzufügen`}
      text={
        <>
          <Alert variant="filled" severity="info" className={classes.margin}>
            Mit diesem Tool können Sie bis zu 100 Tische mit optionalen
            Textzusätzen erstellen!
            <br />
            Beispiel: S - 1 - 5 - A {'-->'} S1A, S2A, S3A, S4A, S5A
          </Alert>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                id="prefix"
                label="Präfix"
                placeholder="S"
                onChange={handleChange('prefix')}
                required={false}
                value={values.prefix}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="from"
                label="Start"
                placeholder="1"
                onChange={handleChange('from')}
                required={true}
                value={values.from}
                margin="dense"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                error={errors.includes('from')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="to"
                label="Ende"
                placeholder="100"
                onChange={handleChange('to')}
                required={true}
                value={values.to}
                margin="dense"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                error={errors.includes('to')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="suffix"
                label="Suffix"
                placeholder="A"
                onChange={handleChange('suffix')}
                required={false}
                value={values.suffix}
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      }
      onPositiveClose={addTables}
      onNegativeClose={close}
      buttonPositiveLabel={'Hinzufügen'}
      buttonNegativeLabel={'Zurück'}
    />
  );
};

export default TableRangeTool;
