import { ShareRounded } from '@material-ui/icons';
import React from 'react';
import Config from '../config';
import AppQrCode from './AppQrCode';
import CopyTextToClipboard from './CopyTextToClipboard';
import DownloadAppQrCode from './DownloadAppQrCode';
import TextPopper from './TextPopper';

interface Props {
  gastronomy: GastronomyFragment | null;
  appLink: string;
  showEmbedSection?: boolean;
}

const GastronomyShareList: React.FC<Props> = ({
  gastronomy,
  appLink,
  showEmbedSection = true
}) => {
  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      <AppQrCode type={'url'} value={appLink} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <TextPopper
          text={appLink}
          textDescription="App Link"
          copy={true}
          icon={<ShareRounded />}
        />
        {gastronomy.name && (
          <DownloadAppQrCode
            title={gastronomy.name}
            type={'url'}
            value={appLink}
          />
        )}
      </div>
      {gastronomy.url_path_id && showEmbedSection && (
        <>
          <CopyTextToClipboard
            text={`${Config.webAppUrl}/speisekarte/${gastronomy.url_path_id}`}
            textDescription="Link zur Speisekarte"
            copy={true}
          />
          <div style={{ textAlign: 'center' }}>
            <a
              href={`${Config.webAppUrl}/speisekarte/${gastronomy.url_path_id}`}
              id="order-with-getsby-button"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '100%',
                maxWidth: '350px',
                display: 'inline-block'
              }}
            >
              <img
                src="https://image.gets.by/general/getsby_order_button.svg"
                width="100%"
                alt="Online Bestellen mit getsby"
              />
            </a>
          </div>
          <CopyTextToClipboard
            text={`<a href="${Config.webAppUrl}/speisekarte/${gastronomy.url_path_id}" style="display: inline-block; max-width: 350px; width: 100%" target="_blank" rel="noopener noreferrer"><img src="https://image.gets.by/general/getsby_order_button.svg" width="100%" alt="Online Bestellen mit getsby"/></a>`}
            textDescription="Bestell Button zum Einbetten"
            copy={true}
          />
        </>
      )}
    </>
  );
};

export default GastronomyShareList;
