import { useQuery } from '@apollo/client';
import getsby from '@getsby-devs/getsby-commons';
import {
  Card,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { endOfDay, format, startOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import { GetPreordersListDocument } from '../../services/graphql/typed-operations';
import { gastronomyState } from '../../store/gastronomy';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1),
      width: '100%'
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    datePicker: {
      marginRight: theme.spacing(2)
    },
    chartHeader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    }
  })
);

const GastronomyTableOrders: React.FC = () => {
  const [fromDate, setFromDate] = useState<Date>(startOfDay(new Date()));
  const gastronomy = useRecoilValue(gastronomyState);
  const classes = useStyles();

  const { data, loading, error } = useQuery(GetPreordersListDocument, {
    variables: {
      params: {
        status: [
          'PENDING_RECEIVED',
          'RECEIVED',
          'PENDING_ACCEPTED',
          'ACCEPTED'
        ],
        datetime_from: startOfDay(fromDate).toISOString(),
        datetime_to: endOfDay(fromDate).toISOString(),
        filterByServiceDate: true,
        user_uuid: gastronomy?.uuid || 'NOT SET'
      }
    },
    fetchPolicy: 'network-only',
    skip: !gastronomy
  });

  const tables = useMemo(() => {
    console.log('new data', data);
    const resArray: any[] = [];
    if (data?.getReceipts) {
      for (const receipt of data.getReceipts) {
        const tableIndex = resArray.findIndex(
          (p) =>
            p.table_uuid === receipt.table?.uuid &&
            p.requested_pickup_time === receipt.requested_pickup_time
        );
        if (tableIndex === -1) {
          resArray.push({
            table_uuid: receipt.table?.uuid,
            table_number: receipt.table?.table_number,
            requested_pickup_time: receipt.requested_pickup_time,
            receipts: [receipt]
          });
        } else {
          resArray[tableIndex].receipts.push(receipt);
        }
      }
    }
    resArray.forEach((table) => {
      const orderItems = table.receipts.flatMap(
        (receipt: any) => receipt.orderItems
      );
      table.groupedOrderItems = getsby.groupOrderItems(orderItems);
    });
    resArray.sort((a, b) =>
      a.table_number.localeCompare(b.table_number, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    );
    return resArray;
  }, [data]);

  console.log('tables', tables);

  return (
    <>
      <HeaderContainer title={'Offene Bestellungen nach Tischen'} />
      <Paper className={classes.toolbarContainer}>
        <div>
          <DatePicker
            views={['date', 'month', 'year']}
            label="Datum"
            value={fromDate}
            onChange={(date) => {
              if (date) setFromDate(date);
            }}
            className={classes.datePicker}
          />
        </div>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading && <CircularIndeterminate />}
          {error && <div>{error.message}</div>}
          {tables &&
            tables.map((table, index) => (
              <Card key={index} style={{ marginBottom: 10, padding: 10 }}>
                <Typography variant="h4">{`${table.table_number} ${
                  table.request_pickup_time !== null &&
                  format(new Date(table.requested_pickup_time), '(HH:mm)')
                }`}</Typography>
                <List dense={true}>
                  {table.groupedOrderItems.map((item: any, index: number) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <span style={{ fontWeight: 'bold' }}>
                            {`${item.amount}x ${item.productTitle}`}
                          </span>
                        }
                        secondary={item.modifierItems.map(
                          (mod: any, index: number) => (
                            <p key={index} style={{ marginBlock: 0 }}>
                              - {mod.modifier.title}: {mod.title}
                            </p>
                          )
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </Card>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyTableOrders;
