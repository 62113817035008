import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Tooltip
} from '@material-ui/core';
import React from 'react';
import DesktopWindowsRounded from '@material-ui/icons/DesktopWindowsRounded';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';

interface Props {
  note: CustomerServiceNoteFragment;
}

const CustomerServiceNote: React.FC<Props> = ({ note }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          {note.source === 'SYSTEM' ? (
            <Tooltip title="System">
              <DesktopWindowsRounded />
            </Tooltip>
          ) : (
            <Tooltip title="Benutzer">
              <AccountCircleRounded />
            </Tooltip>
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={note.note}
        secondary={
          <>
            {note.author
              ? note.author.concat(
                  ' - ',
                  new Date(note.created_at).toLocaleString()
                )
              : new Date(note.created_at).toLocaleString()}
          </>
        }
      />
    </ListItem>
  );
};

export default CustomerServiceNote;
