import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    }
  })
);

interface Props {
  label: string;
  id: string;
  value?: string;
  required?: boolean;
  helperText?: string;
  onChange: (event: any) => void;
}

const PasswordInput: React.FC<Props> = ({
  label,
  id,
  value = '',
  onChange,
  required = false,
  helperText = ''
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      id={id}
      type={showPassword ? 'text' : 'password'}
      value={value}
      onChange={onChange}
      required={required}
      variant="outlined"
      margin="dense"
      label={label}
      className={classes.input}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordInput;
