import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { forwardRef, useEffect, useState } from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import DownloadQrCodeZip from '../../components/DownloadQrCodeZip';
import HeaderContainer from '../../components/HeaderContainer';
import RoomAddForm from '../../components/RoomAddForm';
import RoomCard from '../../components/RoomCard';
import SimpleDialog from '../../components/SimpleDialog';
import TableTable from '../../components/TableTable';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../../helpers/ErrorHelper';
import FilterChip from '../../components/FilterChip';
import { getOrderTypeLabel } from '../../helpers/OrderHelper';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import QRCode from 'qrcode';
import Config from '../../config';
import { getShortUuid } from '../../helpers/UUIDShortener';
import { saveAs } from 'file-saver';
import { TABLE_TYPES } from '../../constants';
import OrderTypeDisabledUntilSwitch from '../../components/OrderTypeDisabledUntilSwitch';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../../store/app';
import { isAdminSelector } from '../../store/auth';
import {
  gastronomyState,
  hasPosSelector,
  removeFromState,
  roomsState,
  tablesState
} from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import {
  DeleteRoomMutationDocument,
  GetRoomsQueryDocument,
  GetTablesQueryDocument
} from '../../services/graphql/typed-operations';
import { MemoryRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import TableRangeTool from '../../components/TableRangeTool';
import { Alert, AlertTitle } from '@material-ui/lab';
import { hasPpFeature } from '../../helpers/GastronomyFeeModelHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1)
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    addButtonContainer: {
      justifyContent: 'center',
      marginTop: theme.spacing(3)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    toolbarButton: {
      width: '100%'
    }
  })
);

const GastronomyTables: React.FC = () => {
  const classes = useStyles();
  const [tables, setTables] = useRecoilState(tablesState);
  const [rooms, setRooms] = useRecoilState(roomsState);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [showRoomAddForm, setShowRoomAddForm] = useState<boolean>(false);
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showAddTablesDialog, setShowAddTablesDialog] = useState<boolean>(
    false
  );
  const [selectedRoom, setSelectedRoom] = useState<RoomFragment | null>();
  const [tableFilters, setTableFilters] = useState<TableType[]>([]);
  const isAdmin = useRecoilValue(isAdminSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const ALLOWED_TABLE_TYPES: TableType[] = hasPos ? TABLE_TYPES : ['DINEIN'];
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchTables = async () => {
      if (gastronomy && gastronomy.uuid) {
        setIsLoading(true);
        try {
          const params: TableSearchParams = {
            user_uuid: gastronomy.uuid,
            status: 'ALL',
            table_types: tableFilters.length
              ? tableFilters
              : ALLOWED_TABLE_TYPES
          };

          const { data } = await apolloClient.query({
            query: GetTablesQueryDocument,
            variables: { params }
          });

          if (!data) {
            throw new Error('API Fehler!');
          }

          console.debug('Tables', data.getTables);

          setTables(data.getTables);
        } catch (error) {
          captureSilentError(error);
        }
        setIsLoading(false);
      }
    };

    const fetchRooms = async () => {
      if (gastronomy) {
        try {
          const { data } = await apolloClient.query({
            query: GetRoomsQueryDocument,
            variables: { params: { user_uuid: gastronomy.uuid } }
          });

          if (!data) {
            throw new Error('API Fehler!');
          }

          console.debug('Rooms', data.getRooms);

          setRooms(data.getRooms);
        } catch (error) {
          captureSilentError(error);
        }
      }
    };

    fetchRooms();
    fetchTables();
  }, [gastronomy, tableFilters]);

  const deleteRoom = async (room: RoomFragment) => {
    setOpenDeleteDialog(false);

    try {
      await apolloClient.mutate({
        mutation: DeleteRoomMutationDocument,
        variables: { uuid: room.uuid }
      });

      removeFromState(room, setRooms);

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Raum ${room.title} erfolgreich gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const selectRoom = (room: RoomFragment) => {
    setSelectedRoom(room);
    setOpenDeleteDialog(true);
  };

  const handleTableFilters = (tableType: TableType) => {
    if (tableFilters.includes(tableType)) {
      setTableFilters((prev) => prev.filter((t) => t !== tableType));
    } else {
      setTableFilters((prev) => [...prev, tableType]);
    }
  };

  const filterTables = (tables: TableFragment[]) => {
    return tables.filter((t) =>
      tableFilters.length > 0
        ? tableFilters.includes(t.table_type_override || t.table_type)
        : true
    );
  };

  const downloadPdf = async () => {
    if (gastronomy) {
      try {
        const records = [];

        for (const table of tables) {
          const qrCode = await QRCode.toDataURL(
            `${Config.webAppUrl}?t=${getShortUuid(table.uuid)}`
          );

          records.push({ table_number: table.table_number, qrCode });
        }

        const body = { tables: records };
        const res = await fetch(`https://pdfservice.gets.by/genpdf/`, {
          method: 'post',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' }
        });
        if (!res.ok) {
          throw Error('Request failed with server error status');
        }
        const data = await res.blob();
        await saveAs(data, 'QR-Codes.pdf');
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  if (gastronomy?.gastronomyFee && !hasPpFeature(gastronomy.gastronomyFee)) {
    return (
      <>
        <HeaderContainer title={'Tische'} />
        <Alert variant="filled" severity="info">
          <AlertTitle>Hinweis</AlertTitle>
          Tischbestellung ist in Ihrem gewählten Tarif nicht inkludiert! <br />
          Sie können Ihren Tarif jederzeit in Ihrem{' '}
          <Link to="/profile#payment-fee" style={{ color: 'white' }}>
            Profil
          </Link>{' '}
          ändern.
        </Alert>
      </>
    );
  }

  return (
    <>
      {selectedRoom && (
        <SimpleDialog
          open={openDeleteDialog}
          title={`Raum ${selectedRoom.title} löschen`}
          text={'Möchten Sie diesen Raum wirklich löschen?'}
          onPositiveClose={() => deleteRoom(selectedRoom)}
          onNegativeClose={() => {
            setSelectedRoom(null);
            setOpenDeleteDialog(false);
          }}
          buttonPositiveLabel={'Löschen'}
          buttonNegativeLabel={'Zurück'}
        />
      )}

      <TableRangeTool
        show={showAddTablesDialog}
        close={() => setShowAddTablesDialog(false)}
      />

      <HeaderContainer title={'Tische'} />
      <Paper className={classes.toolbarContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {ALLOWED_TABLE_TYPES.map((t, i) => (
              <FilterChip
                key={i}
                text={getOrderTypeLabel(t)}
                active={tableFilters.includes(t)}
                onChange={() => handleTableFilters(t)}
              />
            ))}
          </Grid>
          {isAdmin && (
            <Grid item xs={12}>
              <Button
                disabled={hasPos}
                variant="contained"
                color="default"
                onClick={() => setShowRoomAddForm(true)}
                className={classes.toolbarButton}
              >
                Raum hinzufügen
                <AddIcon className={classes.rightIcon} />
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={3} sm={6}>
            <Button
              variant="contained"
              color="default"
              onClick={() => setShowAddTablesDialog(true)}
              className={classes.toolbarButton}
              disabled={hasPos}
            >
              Mehrere Tische hinzufügen
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {tables && (
              <DownloadQrCodeZip
                tables={tables}
                classes={classes.toolbarButton}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button
              variant="contained"
              color="default"
              onClick={downloadPdf}
              className={classes.toolbarButton}
            >
              Tisch Sticker herunterladen
              <SaveAltRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button
              variant="contained"
              color="default"
              className={classes.toolbarButton}
              component={forwardRef<HTMLAnchorElement>((props, ref) => (
                <Link ref={ref} to={`/qr-codes`} {...props} />
              ))}
            >
              Programmierbare Qr-Codes verknüpfen
              <MemoryRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          {ALLOWED_TABLE_TYPES.map((t, i) => (
            <Grid item xs={12} key={i}>
              <OrderTypeDisabledUntilSwitch tableType={t} tables={tables} />
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {showRoomAddForm && (
            <RoomAddForm
              onClose={() => setShowRoomAddForm(false)}
              onSave={() => setShowRoomAddForm(false)}
            />
          )}
          {rooms &&
            rooms.map((room) => (
              <RoomCard
                key={room.uuid}
                room={room}
                tables={filterTables(
                  tables.filter(
                    (table) => table.room && table.room.uuid === room.uuid
                  )
                )}
                onSelectRoom={() => selectRoom(room)}
                allowedTableTypes={ALLOWED_TABLE_TYPES}
              />
            ))}
          <Paper className={classes.paper}>
            {isLoading ? (
              <CircularIndeterminate />
            ) : (
              <TableTable
                tables={filterTables(tables.filter((table) => !table.room))}
                addButtonLabel="Tisch hinzufügen"
                allowedTableTypes={ALLOWED_TABLE_TYPES}
                tableNumberLabel={'Tischnummer'}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyTables;
