import React, { useState, useEffect } from 'react';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../helpers/ErrorHelper';
import {
  makeStyles,
  createStyles,
  Theme,
  List,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import CircularIndeterminate from './CircularIndeterminate';
import CustomerServiceNote from './CustomerServiceNote';
import AddIcon from '@material-ui/icons/Add';
import SearchRounded from '@material-ui/icons/SearchRounded';
import CloseIcon from '@material-ui/icons/Close';
import { useSetRecoilState } from 'recoil';
import { snackbarMessagesState } from '../store/app';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  AddCustomerServiceNoteMutationDocument,
  GetCustomerServiceNotesQueryDocument
} from '../services/graphql/typed-operations';
import { userAtom } from '../store/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1)
    },
    list: {
      width: '100%',
      flexGrow: 1,
      overflow: 'auto'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    container: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

const CustomerServiceNotes: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const user = useRecoilValue(userAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<CustomerServiceNoteFragment[]>([]);
  const [filteredNotes, setFilteredNotes] = useState<
    CustomerServiceNoteFragment[]
  >([]);
  const [noteValue, setNoteValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const [getCustomerServiceNotesQuery] = useLazyQuery(
    GetCustomerServiceNotesQueryDocument,
    {
      onCompleted: (data) => {
        if (data) {
          const result = data.getCustomerServiceNotes;

          if (result) {
            setNotes(result);
          }
        }
      }
    }
  );

  useEffect(() => {
    const fetchCustomerServiceNotes = async () => {
      if (gastronomy) {
        setIsLoading(true);

        try {
          getCustomerServiceNotesQuery({
            variables: { params: { user_uuid: gastronomy.uuid } }
          });
        } catch (error) {
          captureSilentError(error);
        }

        setIsLoading(false);
      }
    };

    fetchCustomerServiceNotes();
  }, [gastronomy]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      addNote();
    }
  };

  const handleSearch = () => {
    if (searchValue.length > 0) {
      setFilteredNotes(
        notes.filter((note) => note.note.match(new RegExp(searchValue, 'i')))
      );
    } else {
      setFilteredNotes([]);
    }
  };

  const addNote = async () => {
    if (gastronomy && noteValue.length) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: AddCustomerServiceNoteMutationDocument,
          variables: {
            input: {
              user_uuid: gastronomy.uuid,
              note: noteValue,
              author: user ? user.email : undefined
            }
          }
        });

        if (data) {
          setNotes((prev) => [data.addCustomerServiceNote, ...prev]);
          setNoteValue('');
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <>
      <TextField
        label="Neue Notiz"
        className={classes.input}
        onChange={(event: any) => setNoteValue(event.target.value)}
        value={noteValue}
        margin="dense"
        multiline={true}
        rows={3}
        variant="outlined"
        onKeyDown={handleKeyDown}
      />
      <Button
        onClick={addNote}
        className={classes.input}
        color="primary"
        variant="contained"
      >
        Hinzufügen
        <AddIcon className={classes.rightIcon} />
      </Button>
      <TextField
        className={classes.input}
        label="Suche"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchValue.length > 0 && (
                <IconButton onClick={() => setSearchValue('')} edge="end">
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        value={searchValue}
        onChange={(event: any) => setSearchValue(event.target.value)}
        onKeyUp={handleSearch}
      />
      <List className={classes.list}>
        {isLoading ? (
          <CircularIndeterminate />
        ) : (
          <>
            {searchValue.length
              ? filteredNotes.map((note) => (
                  <CustomerServiceNote note={note} key={note.uuid} />
                ))
              : notes.map((note) => (
                  <CustomerServiceNote note={note} key={note.uuid} />
                ))}
          </>
        )}
      </List>
    </>
  );
};

export default CustomerServiceNotes;
