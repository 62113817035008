import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    checkBoard: {
      border: '1px solid grey',
      backgroundImage: `linear-gradient(45deg, #aaa 25%, transparent 25%), linear-gradient(-45deg, #aaa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaa 75%), linear-gradient(-45deg, transparent 75%, #aaa 75%)`,
      backgroundSize: '20px 20px',
      backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
    }
  })
);

interface Props {
  title: string;
  imageUrl?: string;
  loading?: boolean;
  isOpen: boolean;
  appBarColor?: string;
  onClose: () => void;
  onSave: (file: File) => void;
  onDelete: () => void;
}

const PickImageDialog: React.FC<Props> = ({
  title,
  imageUrl,
  loading,
  isOpen,
  appBarColor,
  onClose,
  onSave,
  onDelete,
  ...other
}) => {
  const classes = useStyles();
  const [image, setImage] = useState<File | null>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    onClose();
  };

  const handleOnSave = () => {
    if (image) {
      onSave(image);
    }
  };

  const handleOnDelete = () => {
    onDelete();
  };

  const onPick = async () => {
    if (imageInputRef.current && imageInputRef.current.files) {
      const file = imageInputRef.current.files.item(0);
      console.log('file', file);
      if (file) {
        setImage(file);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {loading && <LinearProgress />}
        <img
          src={
            (image && URL.createObjectURL(image)) ||
            imageUrl ||
            process.env.PUBLIC_URL + '/img/UndrawPhoto.svg'
          }
          style={{
            width: '100%',
            height: 'auto',
            backgroundColor: appBarColor || 'inherit'
          }}
          alt=""
        />
        <input
          ref={imageInputRef}
          type="file"
          accept="image/png,image/svg+xml"
          onChange={() => {
            onPick();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Abbrechen
          <ClearRounded className={classes.rightIcon} />
        </Button>
        <Button disabled={loading || !imageUrl} onClick={handleOnDelete}>
          Löschen
          <DeleteIcon className={classes.rightIcon} />
        </Button>
        <Button
          disabled={!image || loading}
          onClick={handleOnSave}
          color="primary"
        >
          Speichern
          <DoneRounded className={classes.rightIcon} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickImageDialog;
