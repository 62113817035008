import {
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Theme,
  Tooltip
} from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import ChatRounded from '@material-ui/icons/ChatRounded';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import InfoRounded from '@material-ui/icons/InfoRounded';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: { zIndex: 1500 },
    wrapper: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      marginLeft: theme.spacing(2)
    }
  })
);

interface Props {
  text: string;
  textDescription: string;
  placement?: PopperPlacementType;
  copy?: boolean;
  type?: 'INFO' | 'MESSAGE';
  icon?: JSX.Element;
}

const TextPopper: React.FC<Props> = ({
  text,
  textDescription,
  copy,
  type,
  placement,
  icon
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const input = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((current) => (current ? null : event.currentTarget));
  };

  const copyToClipboard = () => {
    if (input.current) {
      input.current.select();

      document.execCommand('copy');

      setAnchorEl(null);
    }
  };

  return (
    <>
      <Tooltip title={textDescription}>
        <IconButton onClick={handleClick}>
          {icon}
          {!icon && (
            <>{type === 'MESSAGE' ? <ChatRounded /> : <InfoRounded />}</>
          )}
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        placement={placement || 'bottom'}
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.wrapper}>
              {!copy && text}
              {copy && (
                <>
                  <TextField
                    inputRef={input}
                    label={textDescription}
                    value={text}
                    margin="dense"
                    InputProps={{
                      readOnly: true
                    }}
                    variant="outlined"
                  />
                  <div className={classes.button}>
                    {document.queryCommandSupported('copy') && (
                      <Tooltip title="In Zwischenablage kopieren">
                        <IconButton onClick={copyToClipboard}>
                          <FileCopyRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default TextPopper;
