import { useQuery } from '@apollo/client';
import {
  Chip,
  createStyles,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getStatusColor, getStatusLabel } from '../helpers/GastronomyHelper';
import { GetGastronomyQueryDocument } from '../services/graphql/typed-operations';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1)
    }
  })
);

const GastronomyStatusLabel: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [color, setColor] = useState<string>();
  const [statusLabel, setStatusLabel] = useState<string>('');

  // todo: this should only query the status!!!
  const { data } = useQuery(GetGastronomyQueryDocument, {
    variables: { params: { uuid: gastronomy ? gastronomy.uuid : 'not set' } },
    skip: !gastronomy,
    pollInterval: 30000
  });

  useEffect(() => {
    if (data) {
      const result = data.getGastronomy;

      if (result) {
        setColor(getStatusColor(result));
        setStatusLabel(getStatusLabel(result));
      }
    }
  }, [data]);

  return (
    <>
      {gastronomy && (
        <>
          <Tooltip title={statusLabel} placement="right">
            <Chip
              label={gastronomy.name}
              className={classes.chip}
              style={{ backgroundColor: color, color: 'white' }}
            />
          </Tooltip>
        </>
      )}
    </>
  );
};

export default GastronomyStatusLabel;
