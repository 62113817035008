import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import React, { Suspense, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import { isWinterHolidaySeason } from '../helpers/FormatHelper';
import GastronomyOpenOrders from './gastronomy/GastronomyOpenOrders';
import GastronomyInvoices from './gastronomy/GastronomyInvoices';
import GastronomyModifier from './gastronomy/GastronomyModifier';
import GastronomyOpeningTimes from './gastronomy/GastronomyOpeningTimes';
import GastronomyOrderOverview from './gastronomy/GastronomyOrderOverview';
import GastronomyOrders from './gastronomy/GastronomyOrders';
import GastronomyProducts from './gastronomy/GastronomyProducts';
import GastronomyProfile from './gastronomy/GastronomyProfile';
import GastronomyReceipt from './gastronomy/GastronomyReceipt';
import GastronomyReport from './gastronomy/GastronomyReport';
import GastronomyTips from './gastronomy/GastronomyTips';
import SnackbarContainer from './SnackbarContainer';
import CircularIndeterminate from '../components/CircularIndeterminate';
import { lazyRetry } from '../helpers/lazyRetry';
import CompleteProfile from './CompleteProfile';
import ProtectedRoute from '../components/ProtectedRoute';
import GastronomyStripeCustomReauth from './gastronomy/GastronomyStripeCustomReauth';
import AuthComponent from './AuthComponent';
import MenuIcon from '@material-ui/icons/Menu';
import GastronomyTables from './gastronomy/GastronomyTables';
import { DRAWER_WIDTH, STORAGE_KEY_APP_DATA } from '../constants';
import GastronomyOrderTypeSettings from './gastronomy/GastronomyOrderTypeSettings';
import ProfileProgressBanner from '../components/ProfileProgressBanner';
import ProfileStatusBanner from '../components/ProfileStatusBanner';
import GastronomyOrderType from './gastronomy/GastronomyOrderType';
import SideMenu from '../components/SideMenu';
import MatrixConfig from '../components/MatrixConfig';
import Config from '../config';
import { userAtom } from '../store/auth';
import { useRecoilValue } from 'recoil';
import { isStorageAvailable } from '../helpers/StorageHelper';
import GastronomyOpenOrdersNotification from '../components/GastronomyOpenOrdersNotification';
import AdminMonitor from './admin/AdminMonitor';
import GastronomyCategories from './gastronomy/GastronomyCategories';
import GastronomyTableOrders from './gastronomy/GastronomyTableOrders';
import GastronomyMessageLogNotification from '../components/GastronomyMessageLogNotification';
import BonusProgramCheck from './BonusProgramCheck';
import AdminCertificates from './admin/AdminCertificates';

const GastronomyTutorial = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyTutorial'))
);
const GastronomyGtc = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyGtc'))
);
const GastronomyPrivacyPolicy = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyPrivacyPolicy'))
);
const GastronomyGdpr = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyGdpr'))
);
const GastronomyPrinters = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyPrinters'))
);
const GastronomyQrCodes = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyQrCodes'))
);
const GastronomyGuestRegistration = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyGuestRegistration'))
);
const GastronomyBonusProgram = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyBonusProgram'))
);
const Newsletter = React.lazy(() => lazyRetry(() => import('./Newsletter')));
const ProfileWizard = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/ProfileWizard'))
);
const GastronomyCustomTheme = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyCustomTheme'))
);
const GastronomyMessageLogs = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyMessageLogs'))
);
const GastronomyApps = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyApps'))
);
const GastronomyPages = React.lazy(() =>
  lazyRetry(() => import('./gastronomy/GastronomyPages'))
);

const AdminRegisterGastronomy = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminRegisterGastronomy'))
);
const AdminCopyGastronomy = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminCopyGastronomy'))
);
const AdminCouponCodes = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminCouponCodes'))
);
const AdminFeedback = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminFeedback'))
);
const AdminUserProfile = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminUserProfile'))
);
const AdminUsers = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminUsers'))
);
const AdminOrderOverview = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminOrderOverview'))
);
const AdminGastronomies = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminGastronomies'))
);
const AdminFaultyOrders = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminFaultyOrders'))
);
const AdminSupport = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminSupport'))
);
const AdminRevenueSplit = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminRevenueSplit'))
);
const AdminTranslateMenu = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminTranslateMenu'))
);
const AdminApps = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminApps'))
);
const AdminAppPages = React.lazy(() =>
  lazyRetry(() => import('./admin/AdminAppPages'))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    appBar: {
      marginLeft: DRAWER_WIDTH,
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`
      },
      backgroundColor: theme.palette.primary.main
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: 'hidden'
    },
    title: {
      display: 'flex',
      marginLeft: theme.spacing(2)
    },
    appBarLogo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.palette.secondary.main
    }
  })
);

const Layout: React.FC = () => {
  const classes = useStyles();
  const user = useRecoilValue(userAtom);
  const [hideMenu] = useState<boolean>(
    isStorageAvailable()
      ? localStorage.getItem(STORAGE_KEY_APP_DATA) !== null
      : false
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const Routes: React.ReactNode = (
    <Switch>
      <Route exact path="/auth" component={AuthComponent} />
      <ProtectedRoute path="/tutorial" component={GastronomyTutorial} exact />
      <Route path="/gtc" component={GastronomyGtc} exact />
      <Route path="/privacy-policy" component={GastronomyPrivacyPolicy} exact />
      <Route path="/gdpr" component={GastronomyGdpr} exact />
      <Route path="/order/:uuid" component={GastronomyReceipt} exact />

      <ProtectedRoute
        path="/complete-signup"
        component={CompleteProfile}
        exact
      />
      <ProtectedRoute path="/profile-wizard" component={ProfileWizard} exact />
      <ProtectedRoute
        path="/categories"
        component={GastronomyCategories}
        exact
      />
      <ProtectedRoute path="/products" component={GastronomyProducts} exact />
      <ProtectedRoute
        path="/stripe-custom-reauth"
        component={GastronomyStripeCustomReauth}
        exact
      />
      <ProtectedRoute path="/modifier" component={GastronomyModifier} exact />
      <ProtectedRoute path="/profile" component={GastronomyProfile} exact />
      <ProtectedRoute
        path="/guest-registration"
        component={GastronomyGuestRegistration}
        exact
      />
      <ProtectedRoute path="/bonus" component={GastronomyBonusProgram} exact />
      <Route path="/bonus/check" component={BonusProgramCheck} exact />
      <ProtectedRoute
        path="/newsletter"
        render={(props) => <Newsletter {...props} type={'GASTRONOMY'} />}
        exact
      />
      <ProtectedRoute
        path="/newsletter/app"
        render={(props) => <Newsletter {...props} type={'APP'} />}
        exact
      />
      <ProtectedRoute path="/orders" component={GastronomyOrders} exact />
      <ProtectedRoute
        path="/tableorders"
        component={GastronomyTableOrders}
        exact
      />
      <ProtectedRoute
        path="/order-overview"
        component={GastronomyOrderOverview}
        exact
      />
      <ProtectedRoute
        path="/opening-times"
        component={GastronomyOpeningTimes}
        exact
      />
      <ProtectedRoute
        path="/messages"
        component={GastronomyMessageLogs}
        exact
      />
      <ProtectedRoute path="/printers" component={GastronomyPrinters} exact />
      <ProtectedRoute path="/tips" component={GastronomyTips} exact />
      <ProtectedRoute
        path="/order-types"
        component={GastronomyOrderTypeSettings}
        exact
      />
      <ProtectedRoute path="/tables" component={GastronomyTables} exact />
      <ProtectedRoute path="/dinein" component={GastronomyTables} exact />
      <ProtectedRoute
        path="/delivery"
        render={(props) => (
          <GastronomyOrderType {...props} tableType={'DELIVERY'} />
        )}
        exact
      />
      <ProtectedRoute
        path="/takeaway"
        render={(props) => (
          <GastronomyOrderType {...props} tableType={'TAKEAWAY'} />
        )}
        exact
      />
      <ProtectedRoute
        path="/selfservice"
        render={(props) => (
          <GastronomyOrderType {...props} tableType={'SELFSERVICE'} />
        )}
        exact
      />
      <ProtectedRoute
        path="/drivein"
        render={(props) => (
          <GastronomyOrderType {...props} tableType={'DRIVEIN'} />
        )}
        exact
      />
      <ProtectedRoute path="/qr-codes" component={GastronomyQrCodes} exact />
      <ProtectedRoute
        path="/receipt/:uuid"
        component={GastronomyReceipt}
        exact
      />
      <ProtectedRoute
        path="/report/:year?/:month?"
        component={GastronomyReport}
        exact
      />
      <ProtectedRoute path="/invoices" component={GastronomyInvoices} exact />
      <ProtectedRoute path="/theme" component={GastronomyCustomTheme} exact />
      <ProtectedRoute path="/apps" component={GastronomyApps} exact />
      <ProtectedRoute path="/pages" component={GastronomyPages} exact />
      <ProtectedRoute path="/admin/users" component={AdminUsers} exact />
      <ProtectedRoute path="/admin/feedback" component={AdminFeedback} exact />
      <ProtectedRoute
        path="/admin/gastronomies"
        component={AdminGastronomies}
        exact
      />
      <ProtectedRoute
        path="/admin/gastronomies/register"
        component={AdminRegisterGastronomy}
        exact
      />
      <ProtectedRoute
        path="/admin/gastronomies/copy"
        component={AdminCopyGastronomy}
        exact
      />
      <ProtectedRoute
        path="/admin/order-overview"
        component={AdminOrderOverview}
        exact
      />
      <ProtectedRoute
        path="/admin/faulty-orders"
        component={AdminFaultyOrders}
        exact
      />
      <ProtectedRoute path="/admin/monitor" component={AdminMonitor} exact />
      <ProtectedRoute path="/admin/support" component={AdminSupport} exact />
      <ProtectedRoute
        path="/admin/coupon-codes"
        component={AdminCouponCodes}
        exact
      />
      <ProtectedRoute
        path="/admin/user-profile/:uuid"
        component={AdminUserProfile}
        exact
      />
      <ProtectedRoute
        path="/admin/revenue-split"
        component={AdminRevenueSplit}
        exact
      />
      <ProtectedRoute
        path="/admin/translate-menu"
        component={AdminTranslateMenu}
        exact
      />
      <ProtectedRoute path="/admin/apps" component={AdminApps} exact />
      <ProtectedRoute
        path="/admin/apps/:appUuid/pages"
        component={AdminAppPages}
        exact
      />
      <ProtectedRoute
        path="/admin/apps/:appUuid/newsletter"
        render={(props) => (
          <Newsletter
            {...props}
            type={'APP'}
            appUuid={props.match.params.appUuid}
          />
        )}
        exact
      />
      <ProtectedRoute
        path="/pos/matrix-config"
        component={MatrixConfig}
        exact
      />
      <ProtectedRoute path="/" component={GastronomyOpenOrders} exact />
      <ProtectedRoute
        path="/admin/certificates"
        component={AdminCertificates}
        exact
      />
    </Switch>
  );

  const AppBarLogo: React.ReactNode = (
    <Link to="/" className={classes.appBarLogo}>
      <img
        src={process.env.PUBLIC_URL + '/img/getsby_logo_grey.svg'}
        style={{ maxHeight: 40, width: 'auto' }}
        alt="getsby Logo"
      />
      <Typography variant="h6" className={classes.title}>
        Dashboard
      </Typography>
    </Link>
  );

  const onChangeMenuOpen = (value?: boolean) => {
    if (typeof value === 'boolean') {
      setIsMenuOpen(value);
    } else {
      setIsMenuOpen((current) => !current);
    }
  };

  return (
    <div className={classes.root}>
      {!hideMenu && (
        <>
          {user ? (
            <>
              <AppBar position="fixed" className={classes.appBar}>
                {isWinterHolidaySeason() && (
                  <Snowfall color="#ffffff" snowflakeCount={100} />
                )}
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={() => onChangeMenuOpen()}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  {AppBarLogo}
                  <div className={classes.grow} />
                </Toolbar>
              </AppBar>
              <SideMenu
                isMenuOpen={isMenuOpen}
                onChangeMenuOpen={onChangeMenuOpen}
              />
            </>
          ) : (
            <AppBar
              position="fixed"
              style={{
                width: '100%',
                zIndex: 99,
                backgroundColor: Config.primaryColor
              }}
            >
              {isWinterHolidaySeason() && (
                <Snowfall color="#ffffff" snowflakeCount={100} />
              )}
              <Toolbar>{AppBarLogo}</Toolbar>
            </AppBar>
          )}
        </>
      )}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <SnackbarContainer />
        <ProfileProgressBanner />
        <ProfileStatusBanner />
        <GastronomyOpenOrdersNotification />
        <GastronomyMessageLogNotification />
        <Suspense fallback={<CircularIndeterminate />}>{Routes}</Suspense>
      </main>
    </div>
  );
};

export default Layout;
