import { useApolloClient, useQuery } from '@apollo/client';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SortInput } from 'models';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../helpers/ErrorHelper';
import {
  GetProductGroupsQueryDocument,
  SortProductGroupsMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState, hasPosSelector } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';
import ProductGroupForm from './ProductGroupForm';
import ProductGroupCard from './ProductGroupCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    addButtonContainer: {
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const ProductGroupTab: React.FC = () => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [productGroups, setProductGroups] = useState<ProductGroupFragment[]>(
    []
  );
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const { data, loading } = useQuery(GetProductGroupsQueryDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'not set',
        status: 'ALL',
        product_status: 'ALL',
        showEmpty: true
      }
    },
    skip: !gastronomy,
    onError: (error) => {
      captureSilentError(error);
    }
  });

  useEffect(() => {
    if (data) {
      setProductGroups(
        [...data.getProductGroups].sort(
          (a, b) => (a.order_nr || 0) - (b.order_nr || 0)
        )
      );
    }
  }, [data]);

  const saveSort = async (result: DropResult) => {
    if (result.destination) {
      const [removed] = productGroups.splice(result.source.index, 1);
      productGroups.splice(result.destination.index, 0, removed);

      try {
        await apolloClient.mutate({
          mutation: SortProductGroupsMutationDocument,
          variables: {
            input: productGroups.map((item, index) => {
              const input: SortInput = {
                uuid: item.uuid,
                order_nr: index
              };

              return input;
            })
          }
        });

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Produktgruppen sortiert`
            }
          ],
          setSnackbarMessages
        );
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  if (loading) {
    return <CircularIndeterminate />;
  }

  if (data) {
    return (
      <>
        {!showForm && (
          <div className={classes.addButtonContainer}>
            <Button
              disabled={hasPos}
              variant="outlined"
              className={classes.addButton}
              onClick={() => setShowForm(true)}
            >
              Produktgruppe hinzufügen
              <AddIcon className={classes.rightIcon} />
            </Button>
          </div>
        )}
        {gastronomy && gastronomy.uuid && showForm && (
          <ProductGroupForm
            user_uuid={gastronomy.uuid}
            onClose={() => setShowForm(false)}
            currentOrderNr={data ? data.getProductGroups.length : 0}
          />
        )}
        <div className={classes.root}>
          <DragDropContext onDragEnd={saveSort}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data &&
                    productGroups.map((productGroup, index) => (
                      <Draggable
                        key={productGroup.uuid}
                        draggableId={productGroup.uuid}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ProductGroupCard
                              productGroup={productGroup}
                              key={productGroup.uuid}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    );
  }
  return null;
};

export default ProductGroupTab;
