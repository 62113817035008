import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import ReorderRounded from '@material-ui/icons/ReorderRounded';
import { SortInput } from 'models';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { getsbyPrimary } from '../helpers/Color';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  GetProductsQueryDocument,
  SortProductsMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: 0
    },
    avatar: {
      backgroundColor: getsbyPrimary[500],
      color: theme.palette.secondary.main
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(2, 0),
      width: '100%'
    }
  })
);

interface Props {
  productGroup: FullProductGroupFragment;
  onClose: () => void;
}

const ProductSortTable: React.FC<Props> = ({ productGroup, onClose }) => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();
  const [items, setItems] = useState<FullProductFragment[]>([]);

  const { loading } = useQuery(GetProductsQueryDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'not set',
        product_group_uuid: productGroup.uuid,
        status: 'ALL'
      }
    },
    skip: !gastronomy,
    onCompleted: (data) => {
      if (!data) {
        return;
      }
      setItems(data.getProducts);
    }
  });

  const saveProducts = async (products: FullProductFragment[]) => {
    if (!products || products.length === 0) {
      return;
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: SortProductsMutationDocument,
        variables: {
          input: products.map((item, index) => {
            const input: SortInput = {
              uuid: item.uuid,
              order_nr: index
            };

            return input;
          })
        },
        refetchQueries: ['getProductsQuery']
      });

      if (!data) {
        return;
      }

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Produkte sortiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
    saveProducts(newItems);
  };

  const reorder = (
    list: FullProductFragment[],
    startIndex: number,
    endIndex: number
  ): FullProductFragment[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {items.length > 0 && (
            <List className={classes.list}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {items.map((product, index) => (
                        <Draggable
                          key={product.uuid}
                          draggableId={product.uuid}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              divider
                            >
                              <ListItemIcon>
                                <Tooltip title="Zum Sortieren bewegen">
                                  <ReorderRounded />
                                </Tooltip>
                              </ListItemIcon>
                              <ListItemText primary={product.title} />
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </List>
          )}
        </>
      )}
      <Button onClick={onClose} variant="outlined" className={classes.button}>
        Zurück
        <ClearRounded className={classes.rightIcon} />
      </Button>
    </>
  );
};

export default ProductSortTable;
