import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import GastronomyConfig from '../../components/GastronomyConfig';
import GastronomyStatus from '../../components/GastronomyStatus';
import HeaderContainer from '../../components/HeaderContainer';
import Config from '../../config';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../../helpers/ErrorHelper';
import { getShortUuid } from '../../helpers/UUIDShortener';
import SimpleDialog from '../../components/SimpleDialog';
import GastronomyFeeList from '../../components/GastronomyFeeList';
import { Alert, AlertTitle } from '@material-ui/lab';
import GastronomyShareList from '../../components/GastronomyShareList';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import GastronomyUnreachableOverrideStatus from '../../components/GastronomyUnreachableOverrideStatus';
import AddIcon from '@material-ui/icons/Add';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import ContactConfig from '../../components/ContactConfig';
import ProfileImages from '../../components/ProfileImages';
import MasterData from '../../components/MasterData';
import ContactConfigForm from '../../components/ContactConfigForm';
import GastronomyFeeForm from '../../components/GastronomyFeeForm';
import BankAccount from '../../components/BankAccount';
import { getCurrentBasePath } from '../../helpers/MiscHelper';
import StripeAccountLinks from '../../components/StripeAccountLinks';
import DoneRounded from '@material-ui/icons/DoneRounded';
import ClearRounded from '@material-ui/icons/ClearRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getStripeAccountRequirementsStatusLabel,
  isOnlyBankAccountMissing
} from '../../helpers/GastronomyHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarMessagesState } from '../../store/app';
import { isAdminSelector } from '../../store/auth';
import { gastronomyState } from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import {
  CheckStripeAccountRequirementsQueryDocument,
  TriggerStripeAccountLinksMutationDocument
} from '../../services/graphql/typed-operations';
import TestOrderButton from '../../components/TestOrderButton';
import Printers from '../../components/Printers';
import GetsbyKassa from '../../components/GetsbyKassa';
import GastronomyFeatures from '../../components/GastronomyFeatures';
import GastronomyCustomThemeForm from '../../components/GastronomyCustomThemeForm';
import DisabledPaymentMethods from '../../components/DisabledPaymentMethods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    padding: {
      padding: theme.spacing(3, 0)
    },
    summary: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

const GastronomyProfile: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [appLink, setAppLink] = useState<string | null>(null);
  const [
    stripeAccountRequirements,
    setStripeAccountRequirements
  ] = useState<StripeAccountRequirementsResponse | null>(null);
  const [
    triggerStripeAccountLinksIsLoading,
    setTriggerStripeAccountLinksIsLoading
  ] = useState<boolean>(false);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [dialogStep, setDialogStep] = useState<number>(0);
  const query = useUrlSearchParams();
  const accountLinksReturn = query.get('account-links-return');
  const [
    refreshStripeAccountLinks,
    setRefreshStripeAccountLinks
  ] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const locationHref = location.href;

    if (locationHref.length) {
      const panelId = locationHref.substring(
        locationHref.indexOf('#') + 1,
        locationHref.length
      );

      setExpanded(panelId);

      const element = document.getElementById(panelId);

      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  useEffect(() => {
    if (stripeAccountRequirements) {
      if (
        accountLinksReturn !== null &&
        stripeAccountRequirements.status === 'ACTION_REQUIRED' &&
        !stripeAccountRequirements.bankAccount
      ) {
        setDialogStep(3);
      }
    }
  }, [stripeAccountRequirements]);

  const checkStripeAccountRequirements = async () => {
    if (gastronomy) {
      try {
        const { data } = await apolloClient.query({
          query: CheckStripeAccountRequirementsQueryDocument,
          variables: { params: { user_uuid: gastronomy.uuid } },
          fetchPolicy: 'network-only'
        });

        if (data) {
          const result = data.checkStripeAccountRequirements;
          console.log('accountRequirements', result);

          setStripeAccountRequirements(result);
        }
      } catch (error) {
        captureSilentError(error);
      }
    }
  };

  useEffect(() => {
    const getAppLink = () => {
      const link =
        gastronomy && gastronomy.uuid
          ? `${Config.webAppUrl}?g=${getShortUuid(gastronomy.uuid)}`
          : null;

      setAppLink(link);
    };

    getAppLink();
    checkStripeAccountRequirements();
  }, [gastronomy]);

  const onlyBankAccountMissing = useMemo(
    () => isOnlyBankAccountMissing(stripeAccountRequirements),
    [stripeAccountRequirements]
  );

  const triggerStripeAccountLinks = async (): Promise<void> => {
    if (gastronomy && stripeAccountRequirements) {
      setTriggerStripeAccountLinksIsLoading(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: TriggerStripeAccountLinksMutationDocument,
          variables: {
            input: {
              user_uuid: gastronomy.uuid,
              type:
                stripeAccountRequirements.status === 'NO_ACCOUNT'
                  ? 'ACCOUNT_ONBOARDING'
                  : 'ACCOUNT_UPDATE',
              collect: stripeAccountRequirements.type || 'CURRENTLY_DUE',
              refreshUrl: `${getCurrentBasePath()}/stripe-custom-reauth`,
              returnUrl: `${getCurrentBasePath()}/profile?account-links-return`
            }
          }
        });

        if (data && data.triggerStripeAccountLinks.url) {
          window.location.href = data.triggerStripeAccountLinks.url;
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      setTriggerStripeAccountLinksIsLoading(false);
    }
  };

  const onSelectGastronomyFeeClick = () => {
    if (gastronomy) {
      setDialogStep(1);
    }
  };

  const onSelectAddBankAccountClick = () => {
    if (stripeAccountRequirements && !stripeAccountRequirements.bankAccount) {
      setDialogStep(3);
    }
  };

  const onBankAccountSubmit = (success: boolean) => {
    if (success) {
      checkStripeAccountRequirements();
      setRefreshStripeAccountLinks(true);
    }
    setDialogStep(0);
  };

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      <HeaderContainer title={'Profil'} />

      <SimpleDialog
        open={dialogStep === 1}
        title={
          !gastronomy.contactConfig
            ? 'Tarif wählen - Schritt 1/2'
            : 'Aktuellen Tarif ändern'
        }
        text={
          <GastronomyFeeForm
            showCloseButton
            currentFeeModelUuid={
              gastronomy.gastronomyFee?.gastronomy_fee_model_uuid
            }
            onClose={(success) => {
              if (success) {
                setDialogStep(!gastronomy.contactConfig ? 2 : 0);
              } else {
                setDialogStep(0);
              }
            }}
          />
        }
      />

      <SimpleDialog
        open={dialogStep === 2}
        title={`Kontakt & Benachrichtigungen - Schritt 2/2`}
        text={
          <div style={{ width: '100%' }}>
            <ContactConfigForm
              showCloseButton
              onClose={(success) => {
                if (success) {
                  setExpanded('account-verification');
                  triggerStripeAccountLinks();
                }
                setDialogStep(0);
              }}
            />
          </div>
        }
      />

      <SimpleDialog
        open={dialogStep === 3}
        title={`Bankkonto`}
        text={
          <div style={{ width: '100%' }}>
            <BankAccount onClose={onBankAccountSubmit} showCloseButton />
          </div>
        }
      />

      <Accordion
        expanded={expanded === 'master-data'}
        onChange={handleChange('master-data')}
        id="master-data"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Stammdaten
            </Typography>
            <Typography variant="body2">
              Hier finden Sie Namen, Adresse, etc. Ihres Restaurants
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <MasterData />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'contact-config'}
        onChange={handleChange('contact-config')}
        id="contact-config"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Kontakt & Benachrichtigungen
            </Typography>
            <Typography variant="body2">
              Hier können Sie bestimmen, wie Sie Ihre Bestellbenachrichtigung
              erhalten
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <ContactConfig />
          <TestOrderButton />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'profile-images'}
        onChange={handleChange('profile-images')}
        id="profile-images"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Restaurant Bilder
            </Typography>
            <Typography variant="body2">
              Hier finden Sie ihr Logo & Header Bild
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileImages />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'payment-fee'}
        onChange={handleChange('payment-fee')}
        id="payment-fee"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Tarif & Bestellfunktion
            </Typography>
            <Typography variant="body2">
              Hier finden Sie Ihren getsby Tarif
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {gastronomy && gastronomy.gastronomyFee ? (
                <GastronomyFeeList />
              ) : (
                <>
                  <Alert
                    variant="filled"
                    severity="info"
                    className={classes.margin}
                  >
                    <AlertTitle>Bestellfunktion deaktiviert!</AlertTitle>
                    Aktivieren Sie die Bestellfunktion in nur wenigen Schritten.
                  </Alert>
                </>
              )}
              <Button
                className={classes.input}
                onClick={onSelectGastronomyFeeClick}
                variant="contained"
                color="primary"
              >
                {gastronomy.gastronomyFee
                  ? 'Aktuellen Tarif ändern'
                  : 'Bestellfunktion aktivieren'}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'account-verification'}
        onChange={handleChange('account-verification')}
        id="account-verification"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Profil Verifizierung
            </Typography>
            <Typography variant="body2">
              Hier finden Sie den Status Ihrer Profil Verifizierung
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {gastronomy.gastronomyFee ? (
                <>
                  {stripeAccountRequirements && (
                    <>
                      {stripeAccountRequirements.status === 'NO_ACCOUNT' ? (
                        <>
                          <Alert
                            variant="filled"
                            severity="info"
                            className={classes.margin}
                          >
                            <AlertTitle>
                              Nur noch ein Schritt erforderlich!
                            </AlertTitle>
                            Verifizieren Sie jetzt Ihr Profil, um getsby im
                            vollen Umfang nutzen zu können!
                          </Alert>
                          {triggerStripeAccountLinksIsLoading ? (
                            <CircularIndeterminate />
                          ) : (
                            <Button
                              className={classes.input}
                              onClick={() => {
                                triggerStripeAccountLinks();
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Profil jetzt verifizieren
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <List>
                            <ListItem>
                              <ListItemText
                                primary="Status"
                                secondary={getStripeAccountRequirementsStatusLabel(
                                  stripeAccountRequirements.status,
                                  onlyBankAccountMissing
                                )}
                              />
                              <ListItemSecondaryAction>
                                {stripeAccountRequirements.status ===
                                  'COMPLETED' || onlyBankAccountMissing ? (
                                  <DoneRounded />
                                ) : (
                                  <ClearRounded />
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                          <StripeAccountLinks
                            refresh={refreshStripeAccountLinks}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Alert
                    variant="filled"
                    severity="info"
                    className={classes.margin}
                  >
                    <AlertTitle>Bestellfunktion nicht aktiviert!</AlertTitle>
                    Sie haben noch keinen Tarif für die Bestellfunktion gewählt!
                  </Alert>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.input}
                    onClick={() => setExpanded('payment-fee')}
                  >
                    Tarif jetzt wählen
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'bank-account'}
        onChange={handleChange('bank-account')}
        id="bank-account"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Bankkonto
            </Typography>
            <Typography variant="body2">
              Hier finden Sie Ihr verknüpftes Bankkonto
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {!stripeAccountRequirements ||
            stripeAccountRequirements.status === 'NO_ACCOUNT' ? (
              <Grid item xs={12}>
                <Alert
                  variant="filled"
                  severity="info"
                  className={classes.margin}
                >
                  <AlertTitle>Profil nicht verifiziert!</AlertTitle>
                  Sie haben Ihr Profil noch nicht verifiziert!
                </Alert>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.input}
                  onClick={() => setExpanded('account-verification')}
                >
                  Profil jetzt verifizieren
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Aktives Bankkonto"
                        secondary={
                          stripeAccountRequirements &&
                          stripeAccountRequirements.bankAccount
                            ? `${stripeAccountRequirements.bankAccount.bankName} ...${stripeAccountRequirements.bankAccount.last4}`
                            : 'Kein Bankkonto'
                        }
                      />
                      <ListItemSecondaryAction>
                        {stripeAccountRequirements &&
                        stripeAccountRequirements.bankAccount ? (
                          <DoneRounded />
                        ) : (
                          <ClearRounded />
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  {stripeAccountRequirements &&
                    !stripeAccountRequirements.bankAccount && (
                      <Button
                        className={classes.input}
                        onClick={onSelectAddBankAccountClick}
                        variant="contained"
                        color="primary"
                      >
                        Bankkonto hinzufügen
                        <AddIcon className={classes.rightIcon} />
                      </Button>
                    )}
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {isAdmin && (
        <Accordion
          expanded={expanded === 'payment'}
          onChange={handleChange('payment')}
          id="payment"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              <Typography variant="h6" component="h2">
                Bezahlmethoden
              </Typography>
              <Typography variant="body2">
                Hier finden Sie Ihre unterstützten Bezahlmethoden
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <DisabledPaymentMethods />
          </AccordionDetails>
        </Accordion>
      )}

      {isAdmin && (
        <>
          <Accordion
            expanded={expanded === 'features'}
            onChange={handleChange('features')}
            id="features"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summary}>
                <Typography variant="h6" component="h2">
                  Funktionsübersicht
                </Typography>
                <Typography variant="body2">
                  Wofür möchte das Restaurant getsby nutzen?
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <GastronomyFeatures />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'profile-config'}
            onChange={handleChange('profile-config')}
            id="profile-config"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summary}>
                <Typography variant="h6" component="h2">
                  Konfiguration
                </Typography>
                <Typography variant="body2">
                  Trinkgeld, Abholzeiten, etc. können hier konfiguriert werden.
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <GastronomyConfig />
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <Accordion
        expanded={expanded === 'printers'}
        onChange={handleChange('printers')}
        id="printers"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Druckerkonfiguration
            </Typography>
            <Typography variant="body2">
              Hier können Sie Ihre vorhandenen Netzwerk- oder
              Bluetooth-Bondrucker mit der GASTRO Terminal App verbinden und
              konfigurieren.
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Printers />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'theme'}
        onChange={handleChange('theme')}
        id="theme"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Theme
            </Typography>
            <Typography variant="body2">
              Hier können Sie das Erscheinungsbild Ihres Webauftritts an Ihre
              Anforderungen anpassen.
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <GastronomyCustomThemeForm />
        </AccordionDetails>
      </Accordion>

      {gastronomy.posConfig &&
        (gastronomy.posConfig.pos_type === 'GETSBY' ||
          gastronomy.posConfig.pos_type === 'GETSBYKASSA') && (
          <Accordion
            expanded={expanded === 'getsbyKassa'}
            onChange={handleChange('getsbyKassa')}
            id="getsbyKassa"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.summary}>
                <Typography variant="h6" component="h2">
                  Registrierkasse
                </Typography>
                <Typography variant="body2">
                  Hier finden Sie alle Einstellungen Ihrer getsby
                  Registrierkasse
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {!stripeAccountRequirements ||
              stripeAccountRequirements.status !== 'COMPLETED' ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Alert
                      variant="filled"
                      severity="info"
                      className={classes.margin}
                    >
                      <AlertTitle>Profil nicht verifiziert!</AlertTitle>
                      Sie haben Ihr Profil noch nicht verifiziert!
                    </Alert>

                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.input}
                      onClick={() => setExpanded('account-verification')}
                    >
                      Profil jetzt verifizieren
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <GetsbyKassa />
              )}
            </AccordionDetails>
          </Accordion>
        )}

      <Accordion
        expanded={expanded === 'profile-status'}
        onChange={handleChange('profile-status')}
        id="profile-status"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              System Einstellungen & Status
            </Typography>
            <Typography variant="body2">
              Hier finden Sie den aktuellen Status ihres getsby Profiles
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!gastronomy.status && (
                <Alert
                  variant="filled"
                  severity="info"
                  className={classes.margin}
                >
                  <AlertTitle>Hinweis</AlertTitle>
                  Wenn Sie Ihr Restaurant in der getsby Übersicht listen
                  möchten, wenden Sie sich bitte an{' '}
                  <a style={{ color: 'white' }} href="mailto:office@gets.by">
                    office@gets.by
                  </a>
                </Alert>
              )}
              {gastronomy.unreachable_override && (
                <Alert
                  variant="filled"
                  severity="warning"
                  className={classes.margin}
                >
                  <AlertTitle>Achtung!</AlertTitle>
                  Ihr Restaurant kann im Moment keine Bestellungen empfangen!
                </Alert>
              )}
              <List>
                <ListItem divider>
                  <ListItemText primary={'Bei getsby gelistet'} />
                  <ListItemSecondaryAction>
                    <GastronomyStatus gastronomy={gastronomy} />
                  </ListItemSecondaryAction>
                </ListItem>
                {gastronomy.gastronomyFee && (
                  <ListItem>
                    <ListItemText primary={'Kann Bestellungen empfangen'} />
                    <ListItemSecondaryAction>
                      <GastronomyUnreachableOverrideStatus
                        gastronomy={gastronomy}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'share-profile'}
        onChange={handleChange('share-profile')}
        id="share-profile"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.summary}>
            <Typography variant="h6" component="h2">
              Teilen
            </Typography>
            <Typography variant="body2">
              Ihr persönlicher Link, Button & QR-Code zum Teilen
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {appLink && gastronomy && (
                <GastronomyShareList
                  gastronomy={gastronomy}
                  appLink={appLink}
                  showEmbedSection={
                    gastronomy.gastronomyFee || isAdmin ? true : false
                  }
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GastronomyProfile;
