import {
  Avatar,
  Card,
  CardContent,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import PictureInPictureRounded from '@material-ui/icons/PictureInPictureRounded';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getsbyPrimary } from '../helpers/Color';
import { isAdminSelector } from '../store/auth';
import { hasPosSelector } from '../store/gastronomy';
import RoomUpdateForm from './RoomUpdateForm';
import TableTable from './TableTable';
import TextPopper from './TextPopper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3)
    },
    margin: {
      margin: theme.spacing(2, 0),
      alignItems: 'center'
    },
    title: {
      fontSize: 14
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    avatar: {
      margin: theme.spacing(1),
      color: theme.palette.secondary.main,
      backgroundColor: getsbyPrimary[500]
    }
  })
);

interface Props {
  room: RoomFragment;
  tables: TableFragment[];
  onSelectRoom: (room: RoomFragment) => void;
  allowedTableTypes: TableType[];
}

const RoomCard: React.FC<Props> = ({
  room,
  tables,
  onSelectRoom,
  allowedTableTypes
}) => {
  const classes = useStyles();
  const [update, setUpdate] = useState<boolean>(false);
  const hasPos = useRecoilValue(hasPosSelector);
  const isAdmin = useRecoilValue(isAdminSelector);

  return (
    <Card className={classes.card}>
      <CardContent>
        {update && (
          <RoomUpdateForm room={room} onClose={() => setUpdate(false)} />
        )}
        {!update && (
          <>
            <div className={classes.heading}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar className={classes.avatar}>
                  <PictureInPictureRounded />
                </Avatar>
                <Typography variant={'h5'} className={classes.paddingRight}>
                  Raum - {room.title}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextPopper
                  text={room.uuid}
                  placement={'left'}
                  textDescription="UUID"
                  copy={true}
                />
                <Tooltip title="Bearbeiten">
                  <IconButton onClick={() => setUpdate(true)}>
                    <EditRounded />
                  </IconButton>
                </Tooltip>
                {isAdmin && (
                  <Tooltip title="Löschen">
                    <span>
                      <IconButton
                        disabled={hasPos}
                        onClick={() => onSelectRoom(room)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
            {room.description && (
              <div className={classes.heading}>
                <Typography variant="subtitle1" component="em">
                  {room.description}
                </Typography>
              </div>
            )}
            <TableTable
              tables={tables}
              room={room}
              addButtonLabel="Tisch hinzufügen"
              allowedTableTypes={allowedTableTypes}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RoomCard;
