import {
  Card,
  CardContent,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import { getPaymentTypeLabel } from '../helpers/MiscHelper';
import {
  getOrderTypeLabel,
  getReceiptPaymentLabel,
  getReceiptStatusLabel,
  getStatusColor,
  translateReceiptError
} from '../helpers/OrderHelper';
import ReceiptItems from './ReceiptItems';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useRecoilValue } from 'recoil';
import { isAdminSelector } from '../store/auth';
import { SearchRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    avatar: {
      margin: 10,
      height: 40,
      width: 40,
      backgroundColor: 'transparent'
    },
    orderStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    },
    statusBanner: {
      textAlign: 'center',
      padding: theme.spacing(1, 0),
      marginLeft: theme.spacing(2) * -1,
      marginRight: theme.spacing(2) * -1
    },
    button: {
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    cancelButton: {
      margin: theme.spacing(2, 0),
      width: '100%',
      color: theme.palette.error.dark
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    list: { marginTop: theme.spacing(4) }
  })
);

interface Props {
  receipt: FullReceiptFragment;
  showCoupons?: boolean;
}

const ReceiptCard: React.FC<Props> = ({ receipt, showCoupons = false }) => {
  const classes = useStyles();
  const isAdmin = useRecoilValue(isAdminSelector);
  const theme = useTheme();

  const getStatusMessage = (): React.ReactNode => (
    <div
      className={classes.statusBanner}
      style={{
        backgroundColor: getStatusColor(receipt.status),
        color: 'white'
      }}
    >
      <Typography variant="h5">
        {receipt && (
          <>
            {receipt.table_type && getOrderTypeLabel(receipt.table_type)}{' '}
            Bestellung - {receipt.pickup_code}
            {receipt.table_type === 'DINEIN' && receipt.table && (
              <>
                <br />
                Tisch/Platz: {receipt.table.table_number}
              </>
            )}
            <br />
            <b>
              {receipt.status === 'PENDING_RECEIVED' && 'Wird übermittelt...'}
              {receipt.status === 'RECEIVED' && 'Warten auf Bestätigung...'}
              {receipt.status === 'PENDING_ACCEPTED' && 'Wird bestätigt...'}
              {receipt.status === 'ACCEPTED' && 'In Arbeit...'}
              {receipt.status === 'PENDING_ERROR' && 'Wird storniert...'}
              {receipt.status === 'ERROR' && 'Storniert'}
              {receipt.status === 'PENDING_READY' && 'Wird bereit...'}
              {receipt.status === 'READY' && 'Bereit'}
              {receipt.status === 'PENDING_COMPLETED' &&
                'Wird abgeschlossen...'}
              {receipt.status === 'COMPLETED' && 'Abgeschlossen'}
            </b>
          </>
        )}
      </Typography>
    </div>
  );

  return (
    <Card className={classes.root}>
      <CardContent>
        {getStatusMessage()}

        {receipt.requested_pickup_time && (
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              margin: theme.spacing(2, 0)
            }}
          >
            Vorbestellt für{' '}
            {new Date(receipt.requested_pickup_time).toLocaleString()}
          </Typography>
        )}
        {receipt.estimated_pickup_time && (
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              margin: theme.spacing(2, 0)
            }}
          >
            Bereit um{' '}
            {new Date(receipt.estimated_pickup_time).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </Typography>
        )}

        {receipt.error && receipt.error.message && (
          <Alert variant="filled" severity="error" className={classes.margin}>
            <AlertTitle>Grund</AlertTitle>
            {translateReceiptError(receipt)}
          </Alert>
        )}
        {receipt.note && (
          <div className={classes.margin}>
            <Typography variant="body2" color="textSecondary" component="p">
              Nachricht: {receipt.note}
            </Typography>
          </div>
        )}
        {receipt.phone && (
          <div className={classes.margin}>
            <Typography variant="body2" color="textSecondary" component="p">
              Telefonnummer für Rückfragen:{' '}
              <a
                style={{ color: theme.palette.primary.main }}
                href={`tel:${receipt.phone}`}
              >
                {receipt.phone}
              </a>
            </Typography>
          </div>
        )}
        {receipt.delivery_address && (
          <div className={classes.margin}>
            <Typography variant="body2" color="textSecondary" component="p">
              Lieferadresse:
              <br />
              {receipt.delivery_address.firstname}
              <br />
              {receipt.delivery_address.street}
              <br />
              {receipt.delivery_address.zip} {receipt.delivery_address.city}
              <br />
              {receipt.delivery_address.phone && (
                <>
                  Telefon:{' '}
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href={`tel:${receipt.delivery_address.phone}`}
                  >
                    {receipt.delivery_address.phone}
                  </a>
                  <br />
                </>
              )}
              {receipt.delivery_address.note && (
                <>Anmerkung: {receipt.delivery_address.note}</>
              )}
            </Typography>
          </div>
        )}

        <ReceiptItems
          receipt={receipt}
          showModifier={true}
          showComments={true}
          showCoupons={
            showCoupons ||
            isAdmin ||
            (!isAdmin && receipt.payment_type === 'CASH')
          }
        />

        <List dense className={classes.list}>
          {isAdmin && (
            <ListItem divider>
              <ListItemText
                primary="Restaurant"
                secondary={receipt.gastronomy && receipt.gastronomy.name}
              />
            </ListItem>
          )}
          <ListItem divider>
            <ListItemText
              primary="Erstellt"
              secondary={
                receipt.created_at &&
                new Date(receipt.created_at).toLocaleString()
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary="Letzte Aktion"
              secondary={
                receipt.updated_at &&
                new Date(receipt.updated_at).toLocaleString()
              }
            />
          </ListItem>
          {(receipt.status === 'PENDING_RECEIVED' ||
            receipt.status === 'RECEIVED') && (
            <ListItem divider>
              <ListItemText primary="Läuft ab" />
              <ListItemSecondaryAction>
                {receipt.expires_at &&
                  new Date(receipt.expires_at).toLocaleString()}
              </ListItemSecondaryAction>
            </ListItem>
          )}
          <ListItem divider>
            <ListItemText
              primary="Bestellart"
              secondary={getOrderTypeLabel(receipt.table_type)}
            />
          </ListItem>
          {receipt.table_type === 'DINEIN' && (
            <ListItem divider>
              <ListItemText
                primary="Tisch/Platz"
                secondary={receipt.table && receipt.table.table_number}
              />
            </ListItem>
          )}
          <ListItem divider>
            <ListItemText
              primary="Aktueller Status"
              secondary={getReceiptStatusLabel(receipt.status)}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary="Verwendete Bezahlmethode"
              secondary={getPaymentTypeLabel(receipt.payment_type)}
            />
          </ListItem>
          <ListItem divider={isAdmin}>
            <ListItemText
              primary="Status der Bezahlung"
              secondary={getReceiptPaymentLabel(receipt.payment_status)}
            />
            {isAdmin && receipt.charge_id && (
              <ListItemSecondaryAction>
                <IconButton
                  component={forwardRef<HTMLAnchorElement>((props, ref) => (
                    <a
                      ref={ref}
                      href={`https://dashboard.stripe.com/${
                        process.env.REACT_APP_ENV !== 'PROD' ? 'test/' : ''
                      }search?query=${receipt.charge_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      {...props}
                    />
                  ))}
                >
                  <SearchRounded />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {isAdmin && (
            <>
              <ListItem divider>
                <ListItemText
                  primary="Benutzer"
                  secondary={receipt.app_user_uuid}
                />
                {receipt.app_user_uuid && (
                  <ListItemSecondaryAction>
                    <IconButton
                      component={forwardRef<HTMLAnchorElement>((props, ref) => (
                        <Link
                          ref={ref}
                          to={`/admin/user-profile/${receipt.app_user_uuid}`}
                          {...props}
                        />
                      ))}
                    >
                      <SearchRounded />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="E-Mail des Benutzers"
                  secondary={receipt.email}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Telefonnummer des Benutzers"
                  secondary={receipt.phone}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Systeminformation"
                  secondary={
                    receipt.origin
                      ? `${receipt.origin.type} (${receipt.origin.version})`
                      : 'APP'
                  }
                />
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default ReceiptCard;
