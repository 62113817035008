import { useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import React, { useState } from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import { GetCertificatesDocument } from '../../services/graphql/typed-operations';
import AddIcon from '@material-ui/icons/Add';
import CertificateLineItem from '../../components/CertificateLineItem';
import CertificateForm from '../../components/CertificateForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    addButton: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const AdminCertificates: React.FC = () => {
  const classes = useStyles();

  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const { data, loading } = useQuery(GetCertificatesDocument);

  return (
    <>
      <HeaderContainer title={'SSL Zertifikate'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {loading ? (
              <CircularIndeterminate />
            ) : showAddForm ? (
              <CertificateForm onClose={() => setShowAddForm(false)} />
            ) : (
              <>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Common name</TableCell>
                      <TableCell>Alt names</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Expires</TableCell>
                      <TableCell>getsby</TableCell>
                      <TableCell>vhost</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.getCertificates.map((cert) => (
                      <CertificateLineItem key={cert.uuid} certificate={cert} />
                    ))}
                  </TableBody>
                </Table>
                <Button
                  color="default"
                  variant="outlined"
                  className={classes.addButton}
                  onClick={() => setShowAddForm(true)}
                >
                  Neues Zertifikat hinzufügen
                  <AddIcon className={classes.rightIcon} />
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminCertificates;
