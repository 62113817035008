import { useApolloClient } from '@apollo/client';
import {
  Avatar,
  createStyles,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  Paper,
  Badge,
  Chip,
  useTheme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { AlarmRounded, ReorderRounded } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListRounded from '@material-ui/icons/ListRounded';
import SortRounded from '@material-ui/icons/SortRounded';
import { difference } from 'lodash';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getsbyPrimary } from '../helpers/Color';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  getAvailabilityTimesCount,
  getProductGroupIcon
} from '../helpers/MiscHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import {
  DeleteProductGroupMutationDocument,
  UpdateProductGroupMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState, hasPosSelector } from '../store/gastronomy';
import ProductGroupStatus from './ProductGroupStatus';
import ProductGroupForm from './ProductGroupForm';
import ProductSortTable from './ProductSortTable';
import ProductTable from './ProductTable';
import ServiceTimesDialog from './ServiceTimesDialog';
import SimpleDialog from './SimpleDialog';
import TextPopper from './TextPopper';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3)
    },
    margin: {
      margin: theme.spacing(2, 0),
      alignItems: 'center'
    },
    title: {
      fontSize: 14
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    avatar: {
      margin: theme.spacing(1),
      color: theme.palette.secondary.main,
      backgroundColor: getsbyPrimary[500]
    },
    divider: {
      marginBottom: theme.spacing(3)
    },
    description: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    accordion: {
      margin: theme.spacing(1, 0)
    }
  })
);

interface Props {
  productGroup: FullProductGroupFragment;
}

const ProductGroupCard: React.FC<Props> = ({ productGroup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [update, setUpdate] = useState<boolean>(false);
  const [sort, setSort] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const hasPos = useRecoilValue(hasPosSelector);
  const [isAvailabilityModalOpen, setAvailabilityModalOpen] = useState<boolean>(
    false
  );
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveProductGroupAvailability = async (
    availability?: AvailabilityTimes | null
  ) => {
    try {
      const input: ProductGroupUpdateInput = {
        uuid: productGroup.uuid,
        user_uuid: productGroup.user_uuid,
        availability: availability
          ? ({
              ...availability,
              cc: gastronomy?.country || 'AT'
            } as AvailabilityTimesInput)
          : null
      };

      const { data } = await apolloClient.mutate({
        mutation: UpdateProductGroupMutationDocument,
        variables: { input }
      });

      if (data) {
        const newProductGroup = data.updateProductGroup;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Verfügbarkeit von ${newProductGroup.title} geändert`
            }
          ],
          setSnackbarMessages
        );
      }
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const getProductGroupLabel = (): React.ReactNode => {
    const gastronmyOrderTypes = gastronomy ? gastronomy.options.tableTypes : [];

    const diff = difference(
      gastronmyOrderTypes,
      productGroup.orderTypeExclusions || []
    );

    return (
      <>
        {diff.map((d, i) => (
          <Chip
            key={i}
            size="small"
            label={getOrderTypeLabel(d)}
            style={{ margin: theme.spacing(1) }}
          />
        ))}
      </>
    );
  };

  const getAvailabilityLabel = (): React.ReactNode => {
    const count = getAvailabilityTimesCount(productGroup.availability);

    if (count > 0) {
      return (
        <Tooltip title="Eingeschränkte Verfügbarkeit">
          <Chip
            size="small"
            label={count}
            icon={<AlarmRounded />}
            style={{ margin: theme.spacing(1) }}
          />
        </Tooltip>
      );
    }
  };

  const deleteProductGroup = async () => {
    setOpenDeleteDialog(false);

    try {
      await apolloClient.mutate({
        mutation: DeleteProductGroupMutationDocument,
        variables: { uuid: productGroup.uuid },
        refetchQueries: ['getProductGroupsQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Produktgruppe ${productGroup.title} erfolgreich gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      <SimpleDialog
        open={openDeleteDialog}
        title={`Produktgruppe ${productGroup.title} löschen`}
        text={'Möchten Sie diese Produktgruppe wirklich löschen?'}
        onPositiveClose={() => deleteProductGroup()}
        onNegativeClose={() => {
          setOpenDeleteDialog(false);
        }}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      {isAvailabilityModalOpen && (
        <ServiceTimesDialog
          open={isAvailabilityModalOpen}
          onClose={() => {
            setAvailabilityModalOpen(false);
          }}
          availabilityTimes={productGroup.availability}
          title={`Verfügbarkeit von ${productGroup.title} einschränken`}
          onSave={saveProductGroupAvailability}
          showLimit={false}
        />
      )}
      <Accordion
        expanded={expanded === `panel-${productGroup.uuid}`}
        onChange={handleChange(`panel-${productGroup.uuid}`)}
        className={classes.accordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Zum Sortieren bewegen">
                <ReorderRounded style={{ color: theme.palette.grey[500] }} />
              </Tooltip>
              <Avatar
                className={classes.avatar}
                style={
                  productGroup.status === 'INACTIVE' ||
                  productGroup.status_override === 'INACTIVE'
                    ? { backgroundColor: grey[500] }
                    : {}
                }
              >
                {getProductGroupIcon(productGroup.product_group_type)}
              </Avatar>
              <Typography variant={'h6'}>
                {hasPos && productGroup.alias
                  ? `${productGroup.alias} (${productGroup.title})`
                  : productGroup.title}
              </Typography>
              <div>
                {!productGroup.category_uuid ? (
                  <Chip
                    icon={<WarningIcon />}
                    color="primary"
                    size="small"
                    label={'Keine Kategorie'}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.error.main
                    }}
                  />
                ) : (
                  <Chip
                    color="primary"
                    size="small"
                    label={productGroup.category_name}
                    style={{
                      margin: theme.spacing(1),
                      backgroundColor: theme.palette.primary.main
                    }}
                  />
                )}
                {getProductGroupLabel()}
                {getAvailabilityLabel()}
              </div>
            </div>
          </>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <Divider className={classes.divider} />
            {update ? (
              <ProductGroupForm
                productGroup={productGroup}
                user_uuid={productGroup.user_uuid}
                onClose={() => setUpdate(false)}
              />
            ) : (
              <>
                <div className={classes.heading}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <ProductGroupStatus productGroup={productGroup} />
                    <TextPopper
                      text={productGroup.uuid}
                      placement={'left'}
                      textDescription="UUID"
                      copy={true}
                    />
                    <Tooltip title="Bearbeiten">
                      <IconButton onClick={() => setUpdate(true)}>
                        <EditRounded />
                      </IconButton>
                    </Tooltip>
                    {sort ? (
                      <Tooltip title="Produkte">
                        <IconButton onClick={() => setSort(false)}>
                          <ListRounded />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Produkte sortieren">
                        <IconButton onClick={() => setSort(true)}>
                          <SortRounded />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Verfügbarkeit">
                      <IconButton
                        onClick={() => {
                          setAvailabilityModalOpen(true);
                        }}
                      >
                        <Badge
                          color="primary"
                          badgeContent={
                            productGroup.availability
                              ? getAvailabilityTimesCount(
                                  productGroup.availability
                                )
                              : 0
                          }
                        >
                          <AlarmRounded />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen">
                      <span>
                        <IconButton
                          disabled={hasPos}
                          onClick={() => setOpenDeleteDialog(true)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {productGroup.description && (
                  <Paper className={classes.description}>
                    <Typography variant="subtitle1" component="em">
                      {productGroup.description}
                    </Typography>
                  </Paper>
                )}

                {sort ? (
                  <ProductSortTable
                    productGroup={productGroup}
                    onClose={() => setSort(false)}
                  />
                ) : (
                  <>
                    {expanded && <ProductTable productGroup={productGroup} />}
                  </>
                )}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProductGroupCard;
