import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Config from '../config';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getPosTypeLabel } from '../helpers/MiscHelper';
import {
  GetPagesQueryDocument,
  UpdateGastronomyMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    list: {
      width: '100%'
    },
    container: {
      width: '100%'
    }
  })
);

interface Props {
  onClose: () => void;
}

interface GastronomyConfigUpdateFormInput {
  pos_type: PosType;
  autoAccept: boolean;
  allowCancellation: boolean;
  status: GastronomyState;
  tipPercentage: string;
  pickupTimes: string;
  serviceInformation: string;
  deliveryInformation: string;
  orderInformation: string;
  hideGastronomyNameInHeader?: boolean;
  tableOrderIsRoomService?: boolean;
  enableMenuTranslation?: boolean;
  disableNewCustomerCoupon?: boolean;
  qrCodeRoute?: string;
}

const getInitialValues = (
  gastronomy: FullGastronomyFragment | null | undefined
): GastronomyConfigUpdateFormInput => {
  return {
    pos_type:
      gastronomy && gastronomy.posConfig
        ? gastronomy.posConfig.pos_type || 'GETSBY'
        : 'GETSBY',
    autoAccept:
      gastronomy && gastronomy.posConfig && gastronomy.posConfig.config
        ? !!gastronomy.posConfig.config.autoAccept
        : false,
    allowCancellation:
      gastronomy && gastronomy.posConfig && gastronomy.posConfig.config
        ? !!gastronomy.posConfig.config.allowCancellation
        : true,
    status: gastronomy?.status || 'DISABLED',
    tipPercentage:
      gastronomy && gastronomy.options.tipPercentage
        ? gastronomy.options.tipPercentage.toString()
        : '0',
    pickupTimes:
      gastronomy && gastronomy.options.pickupTimes
        ? gastronomy.options.pickupTimes.toString()
        : '',
    serviceInformation:
      gastronomy && gastronomy.options.serviceInformation
        ? gastronomy.options.serviceInformation.toString()
        : '',
    deliveryInformation:
      gastronomy && gastronomy.options.deliveryInformation
        ? gastronomy.options.deliveryInformation.toString()
        : '',
    orderInformation:
      gastronomy && gastronomy.options.orderInformation
        ? gastronomy.options.orderInformation
        : '',
    hideGastronomyNameInHeader:
      gastronomy && gastronomy.options.hideGastronomyNameInHeader
        ? gastronomy.options.hideGastronomyNameInHeader
        : false,
    tableOrderIsRoomService:
      gastronomy && gastronomy.options.tableOrderIsRoomService
        ? gastronomy.options.tableOrderIsRoomService
        : false,
    enableMenuTranslation:
      gastronomy && gastronomy.options.enableMenuTranslation
        ? !!gastronomy.options.enableMenuTranslation
        : false,
    disableNewCustomerCoupon:
      gastronomy && gastronomy.options.disableNewCustomerCoupon
        ? !!gastronomy.options.disableNewCustomerCoupon
        : false,
    qrCodeRoute:
      gastronomy && gastronomy.options.qrCodeRoute
        ? gastronomy.options.qrCodeRoute
        : ''
  };
};

const GastronomyConfigUpdateForm: React.FC<Props> = ({ onClose }) => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);
  const isAdmin = useRecoilValue(isAdminSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [values, setValues] = useState<GastronomyConfigUpdateFormInput>(
    getInitialValues(gastronomy)
  );
  const apolloClient = useApolloClient();

  const { data } = useQuery(GetPagesQueryDocument, {
    variables: {
      params: { user_uuid: gastronomy ? gastronomy.uuid : 'NOT_SET' }
    },
    skip: !gastronomy
  });

  useEffect(() => {
    setValues(getInitialValues(gastronomy));
  }, [gastronomy]);

  const handleChange = (name: string) => (event: any) => {
    validateInput(name, event.target.value);

    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const validateInput = (name: string, value: string): boolean => {
    let isValid = true;

    if (name === 'tipPercentage') {
      if (!value) {
        isValid = false;
      }
      if (parseInt(value, 10) < 0 || parseInt(value, 10) > 100) {
        isValid = false;
      }
    }

    if (name === 'pickupTimes' && value.length > 0) {
      const pickupTimes = value.replace(/\s/g, '');
      const matches = pickupTimes.match(/[\d|,]+\d/);

      isValid =
        matches && matches[0].length === pickupTimes.length ? true : false;

      pickupTimes.split(',').forEach((time) => {
        if (time.length === 0) {
          isValid = false;
        }
      });
    }

    if (isValid) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors([...errors, name]);
    }

    return isValid;
  };

  const saveGastronomy = async () => {
    if (gastronomy) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              status: values.status,
              options: {
                tipPercentage: parseInt(values.tipPercentage, 10),
                pickupTimes: values.pickupTimes.length
                  ? [
                      values.pickupTimes
                        .split(',')
                        .map((str) => parseInt(str, 10))
                    ]
                  : [],
                serviceInformation: values.serviceInformation,
                deliveryInformation: values.deliveryInformation,
                orderInformation: values.orderInformation,
                hideGastronomyNameInHeader: values.hideGastronomyNameInHeader,
                tableOrderIsRoomService: values.tableOrderIsRoomService,
                enableMenuTranslation: values.enableMenuTranslation,
                disableNewCustomerCoupon: values.disableNewCustomerCoupon,
                qrCodeRoute: values.qrCodeRoute
              },
              posConfig: {
                pos_type: values.pos_type,
                config: {
                  autoAccept: values.autoAccept,
                  allowCancellation: values.allowCancellation
                }
              }
            }
          }
        });

        if (!data) {
          return;
        }

        const newGastronomy = data.updateGastronomy;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Profil aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        setGastronomy(newGastronomy);

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List className={classes.list}>
            <ListItem divider>
              <ListItemText primary="Integration" />
              <ListItemSecondaryAction>
                <FormControl className={classes.input}>
                  <NativeSelect
                    disabled={!isAdmin}
                    value={values.pos_type}
                    onChange={handleChange('pos_type')}
                    inputProps={{
                      name: 'pos_type',
                      id: 'pos_type'
                    }}
                  >
                    <option key={'GETSBY'} value={'GETSBY'}>
                      {getPosTypeLabel('GETSBY')}
                    </option>
                    <option key={'GKS'} value={'GKS'}>
                      {getPosTypeLabel('GKS')}
                    </option>
                    <option key={'OCTOBOX'} value={'OCTOBOX'}>
                      {getPosTypeLabel('OCTOBOX')}
                    </option>
                    <option key={'MATRIX'} value={'MATRIX'}>
                      {getPosTypeLabel('MATRIX')}
                    </option>
                    <option key={'MELZER'} value={'MELZER'}>
                      {getPosTypeLabel('MELZER')}
                    </option>
                  </NativeSelect>
                </FormControl>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Speisekarte" />
              <ListItemSecondaryAction>
                <FormControl className={classes.input}>
                  <NativeSelect
                    disabled={!isAdmin}
                    value={values.status}
                    onChange={handleChange('status')}
                    inputProps={{
                      name: 'status',
                      id: 'status'
                    }}
                  >
                    <option key={'DISABLED'} value={'DISABLED'}>
                      DISABLED
                    </option>
                    <option key={'MENUONLY'} value={'MENUONLY'}>
                      MENUONLY
                    </option>
                    <option key={'ENABLED'} value={'ENABLED'}>
                      ENABLED
                    </option>
                  </NativeSelect>
                </FormControl>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Bestellungen automatisch annehmen" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleChange('autoAccept')}
                  checked={values.autoAccept}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Trinkgeldvorauswahl" />
              <ListItemSecondaryAction>
                <TextField
                  error={errors.includes('tipPercentage')}
                  value={values.tipPercentage}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChange('tipPercentage')}
                  className={classes.input}
                  margin="dense"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Abholzeiten" />
              <ListItemSecondaryAction>
                <TextField
                  error={errors.includes('pickupTimes')}
                  placeholder="5, 10, 15, 20"
                  className={classes.input}
                  onChange={handleChange('pickupTimes')}
                  value={values.pickupTimes}
                  margin="dense"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Serviceinformation" />
              <ListItemSecondaryAction>
                <TextField
                  value={values.serviceInformation}
                  onChange={handleChange('serviceInformation')}
                  className={classes.input}
                  margin="dense"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Lieferinformation" />
              <ListItemSecondaryAction>
                <TextField
                  value={values.deliveryInformation}
                  onChange={handleChange('deliveryInformation')}
                  className={classes.input}
                  margin="dense"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Bestellinformation" />
              <ListItemSecondaryAction>
                <TextField
                  value={values.orderInformation}
                  onChange={handleChange('orderInformation')}
                  className={classes.input}
                  margin="dense"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Kein Restaurant Name in Speisekarte" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleChange('hideGastronomyNameInHeader')}
                  checked={values.hideGastronomyNameInHeader}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Zimmerservice" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleChange('tableOrderIsRoomService')}
                  checked={values.tableOrderIsRoomService}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Speisekarte automatisch übersetzen" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleChange('enableMenuTranslation')}
                  checked={values.enableMenuTranslation}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Neukunden Gutscheine deaktivieren" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleChange('disableNewCustomerCoupon')}
                  checked={values.disableNewCustomerCoupon}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="QR Code Umleitung" />
              <ListItemSecondaryAction>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="body1">
                    {Config.webAppUrl}
                    {`/${gastronomy?.url_path_id}`}
                  </Typography>
                  <Select
                    value={values.qrCodeRoute}
                    onChange={handleChange('qrCodeRoute')}
                    margin="dense"
                  >
                    <MenuItem value={''}>Keine Umleitung</MenuItem>
                    <MenuItem value={'/speisekarte'}>/speisekarte</MenuItem>
                    <MenuItem
                      value={'/corona'}
                      disabled={!gastronomy?.options.enableGuestRegistration}
                    >
                      /corona
                    </MenuItem>
                    <MenuItem
                      value={'/bonus'}
                      disabled={!gastronomy?.options.hasBonusProgram}
                    >
                      /bonus
                    </MenuItem>
                    <MenuItem
                      value={'/download-app'}
                      disabled={!gastronomy?.app}
                    >
                      /download-app
                    </MenuItem>
                    {data?.getPages.map((page) => (
                      <MenuItem key={page.uuid} value={page.url_path}>
                        {page.url_path}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={onClose}
            variant="outlined"
          >
            Verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={saveGastronomy}
            variant="contained"
            color="primary"
          >
            Speichern
            <DoneRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GastronomyConfigUpdateForm;
