import { ApolloClient } from '@apollo/client';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { GraphQLError } from 'graphql';
import { STORAGE_GASTRONOMY_KEY } from '../constants';
import { GetGastronomyQueryDocument } from '../services/graphql/typed-operations';
import { DashboardUser } from '../store/auth';
import { isStorageAvailable } from './StorageHelper';

export function getCognitoGroups(
  currentUserSession: CognitoUserSession
): string[] {
  const accessToken = currentUserSession.getAccessToken();

  if (accessToken) {
    const cognitoGroups = accessToken.payload['cognito:groups'];

    return cognitoGroups;
  } else {
    return [];
  }
}

export function getEmailFromCognitoUser(user: CognitoUser): Promise<string> {
  return new Promise<string>((resolve, _reject) => {
    user.getUserAttributes((_error, attributes) => {
      if (attributes) {
        for (const attribute of attributes) {
          if (attribute.getName() === 'email') {
            resolve(attribute.getValue());
          }
        }
      }
    });
  });
}

export async function loadInitialGastronomy(
  apolloClient: ApolloClient<any>,
  user: DashboardUser
) {
  try {
    const loadGastro = async (uuid: string) => {
      const result = await apolloClient.query({
        query: GetGastronomyQueryDocument,
        variables: { params: { uuid } }
      });
      return result.data.getGastronomy;
    };
    if (user.groups.includes('admin')) {
      try {
        if (isStorageAvailable()) {
          const item = localStorage.getItem(STORAGE_GASTRONOMY_KEY);

          if (!item) {
            return;
          }

          const gastronomyFromStorage = JSON.parse(item);

          if (gastronomyFromStorage.uuid) {
            return await loadGastro(gastronomyFromStorage.uuid);
          }
        }
      } catch (error) {
        localStorage.removeItem(STORAGE_GASTRONOMY_KEY);
      }
    } else if (user.groups.includes('gastro')) {
      return await loadGastro(user.uuid);
    }
  } catch (error) {
    console.log('error fetching inital gastronomy', error);

    const graphqlErrors = error.graphQLErrors || null;

    if (Array.isArray(graphqlErrors)) {
      for (const e of graphqlErrors as GraphQLError[]) {
        if (
          e.extensions &&
          e.extensions.code &&
          e.extensions.code === 'NOT_FOUND'
        ) {
          return;
        }
      }
    }

    throw error;
  }
}
