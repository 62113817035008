import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getPosTypeLabel } from '../helpers/MiscHelper';
import { gastronomyState } from '../store/gastronomy';

const GastronomyFeatures: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);

  if (!gastronomy) {
    return <></>;
  }

  if (!gastronomy.features) {
    return <>Keine Daten verfügbar</>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List>
          <ListItem divider>
            <ListItemText primary="Gästeregistrierung" />
            <ListItemSecondaryAction>
              {gastronomy.features?.guest_registration ? (
                <DoneRounded />
              ) : (
                <ClearRounded />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText primary="Bonuspunkte Program" />
            <ListItemSecondaryAction>
              {gastronomy.features?.bonus_program ? (
                <DoneRounded />
              ) : (
                <ClearRounded />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem divider>
            <ListItemText primary="Tischbestellung" />
            <ListItemSecondaryAction>
              {gastronomy.features?.dine_in ? (
                <DoneRounded />
              ) : (
                <ClearRounded />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {gastronomy.features?.dine_in && (
            <>
              <ListItem divider>
                <ListItemText primary="Benötigt ein getsby Terminal" />
                <ListItemSecondaryAction>
                  {gastronomy.features?.need_device ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Besitzt ein unterstütztes Kassensystem" />
                <ListItemSecondaryAction>
                  {gastronomy.features?.has_pos ? (
                    getPosTypeLabel(gastronomy.features.has_pos)
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
          <ListItem divider>
            <ListItemText primary="Take-Away" />
            <ListItemSecondaryAction>
              {gastronomy.features?.take_away ? (
                <DoneRounded />
              ) : (
                <ClearRounded />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Lieferung" />
            <ListItemSecondaryAction>
              {gastronomy.features?.delivery ? (
                <DoneRounded />
              ) : (
                <ClearRounded />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default GastronomyFeatures;
