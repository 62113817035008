import { useQuery } from '@apollo/client';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import ProductModifierTable from '../../components/ProductModifierTable';
import { GetProductModifierQueryDocument } from '../../services/graphql/typed-operations';
import { gastronomyState } from '../../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    padding: {
      padding: theme.spacing(0, 2)
    },
    margin: {
      margin: theme.spacing(2, 0)
    }
  })
);

const GastronomyModifier: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);

  const { data, loading } = useQuery(GetProductModifierQueryDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'NOT SET',
        status: 'ALL'
      }
    },
    skip: !gastronomy
  });

  return (
    <>
      <HeaderContainer title={'Produktoptionen'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {loading ? (
              <CircularIndeterminate />
            ) : (
              <>
                <ProductModifierTable
                  productModifier={data ? data.getProductModifier : []}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyModifier;
