import { useApolloClient } from '@apollo/client';
import Storage from '@aws-amplify/storage';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneRounded from '@material-ui/icons/DoneRounded';
import * as path from 'path';
import React, { useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Config from '../config';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  AdminUpdateProductMutationDocument,
  GetProductQueryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  onClose: () => void;
  product: FullProductFragment;
}

const ProductImageDialog: React.FC<Props> = ({
  onClose,
  product,
  ...other
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<File>();
  const gastronomy = useRecoilValue(gastronomyState);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const handleClose = () => {
    onClose();
  };

  const onPick = async () => {
    if (imageInputRef.current && imageInputRef.current.files) {
      const file = imageInputRef.current.files.item(0);
      console.log('file', file);
      if (file) {
        setImage(file);
      }
    }
  };

  const onDelete = async () => {
    if (gastronomy) {
      try {
        setLoading(true);

        const { data } = await apolloClient.query({
          query: GetProductQueryDocument,
          variables: {
            uuid: product.uuid
          }
        });

        if (!data) {
          return;
        }

        const productFromServer = data.getProduct;

        if (!productFromServer || !productFromServer.image_full) {
          return;
        }

        const fullKey = `${product.uuid}_product_${productFromServer.image_full}`;

        const fullResult = await Storage.remove(
          `images/${gastronomy.uuid}/${fullKey}`
        );

        console.debug(fullResult);

        const { data: newData } = await apolloClient.mutate({
          mutation: AdminUpdateProductMutationDocument,
          variables: {
            input: {
              uuid: product.uuid,
              image: '',
              image_full: ''
            }
          },
          refetchQueries: ['getProductsQuery']
        });

        if (!newData) {
          return;
        }

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Produktbild von ${product.title} gelöscht`
            }
          ],
          setSnackbarMessages
        );
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      setLoading(false);
    }
  };

  const onSave = async () => {
    if (gastronomy) {
      try {
        if (image) {
          setLoading(true);

          const fullKey = `${new Date().getTime()}_${
            product.uuid
          }_product${path.extname(image.name)}`;

          // const fullImage = await imageCompression(image.file, {
          //   maxSizeMB: 0.5,
          // });

          const fullResult = await Storage.put(fullKey, image, {
            contentType: image.type,
            bucket: Config.s3ImageUploadBucket,
            customPrefix: {
              public: ''
            }
          });

          console.log(fullResult);

          const { data } = await apolloClient.mutate({
            mutation: AdminUpdateProductMutationDocument,
            variables: {
              input: {
                uuid: product.uuid,
                image: `${Config.s3ImageBaseUrl}/${fullKey}`,
                image_full: `${Config.s3ImageBaseUrl}/${fullKey}`
              }
            }
          });

          if (!data) {
            throw new Error('API Fehler!');
          }

          const newProduct = data.adminUpdateProduct;

          setImage(undefined);
          if (imageInputRef.current) {
            imageInputRef.current.value = '';
          }

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Produktbild von ${newProduct.title} aktualisiert`
              }
            ],
            setSnackbarMessages
          );
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} {...other}>
      <DialogTitle id="simple-dialog-title"> {product.title}</DialogTitle>
      <DialogContent dividers>
        {loading && <LinearProgress />}
        <img
          src={
            (image && URL.createObjectURL(image)) ||
            product.image_full ||
            process.env.PUBLIC_URL + '/img/UndrawPhoto.svg'
          }
          style={{ width: '100%', height: 'auto' }}
          alt=""
        />
        <input
          ref={imageInputRef}
          type="file"
          accept="image/png,image/jpg,image/jpeg,image/svg+xml"
          onChange={(evt) => {
            onPick();
          }}
        />
      </DialogContent>
      <DialogActions>
        <>
          <Button disabled={loading} onClick={handleClose}>
            Abbrechen
            <ClearRounded className={classes.rightIcon} />
          </Button>
          <Button disabled={loading || !product.image} onClick={onDelete}>
            Löschen
            <DeleteIcon className={classes.rightIcon} />
          </Button>
          {image && (
            <Button disabled={loading} onClick={onSave} color="primary">
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ProductImageDialog;
