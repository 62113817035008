import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { isPaymentMethodEnabled } from '../helpers/GastronomyHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import DisabledPaymentMethodsForm from './DisabledPaymentMethodsForm';
import { isAdminSelector } from '../store/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3)
    },
    headerPadding: {
      padding: theme.spacing(0, 2)
    },
    padding: {
      padding: theme.spacing(2)
    },
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const DisabledPaymentMethods: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [update, setUpdate] = useState<boolean>(false);

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {update ? (
        <DisabledPaymentMethodsForm onClose={() => setUpdate(false)} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <List>
                <ListItem divider>
                  <ListItemText primary="Kartenzahlung" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, ['CARD']) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Apple Pay" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, [
                      'APPLE_PAY'
                    ]) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Google Pay" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, [
                      'GOOGLE_PAY'
                    ]) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="EPS" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, ['EPS']) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="Klarna" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, ['SOFORT']) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary="AliPay" />
                  <ListItemSecondaryAction>
                    {isPaymentMethodEnabled(gastronomy.options, ['ALIPAY']) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Barzahlung"
                    secondary={
                      <>
                        {gastronomy?.options?.enableCashPaymentForType &&
                          gastronomy.options.enableCashPaymentForType.length >
                            0 && (
                            <>
                              Konfiguriert für:{' '}
                              {gastronomy.options.enableCashPaymentForType
                                .map((t) => getOrderTypeLabel(t))
                                .join(', ')}
                            </>
                          )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    {gastronomy?.options?.enableCashPaymentForType &&
                    gastronomy.options.enableCashPaymentForType.length > 0 &&
                    isPaymentMethodEnabled(gastronomy.options, ['CASH']) ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.input}
              onClick={() => setUpdate(true)}
              variant="outlined"
              disabled={!isAdmin}
            >
              Bearbeiten
              <EditRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DisabledPaymentMethods;
