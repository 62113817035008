import { useApolloClient } from '@apollo/client';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ProfileProgressType } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { TABLE_TYPES } from '../constants';
import { getProfileProgress } from '../helpers/GastronomyHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      marginBottom: theme.spacing(1)
    }
  })
);

const ProfileProgressBanner: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);
  const [
    profileProgress,
    setProfileProgress
  ] = useState<ProfileProgressType | null>(null);
  const tableTypes = useMemo(() => {
    if (gastronomy && gastronomy.options) {
      return gastronomy.options.tableTypes || [];
    }

    return [];
  }, [gastronomy]);
  const classes = useStyles();
  const theme = useTheme();
  const apolloClient = useApolloClient();

  useEffect(() => {
    const getProgress = async () => {
      const result = await getProfileProgress(apolloClient, gastronomy);
      console.log('progress result', result);

      setProfileProgress(result);
    };

    if (gastronomy) {
      getProgress();
    } else {
      setProfileProgress(null);
    }
  }, [gastronomy]);

  if (location && location.pathname.includes('wizard')) {
    return <></>;
  }

  return (
    <>
      {profileProgress && profileProgress.progress < 100 && (
        <Alert
          variant="filled"
          severity="warning"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <AlertTitle>Achtung!</AlertTitle>
          Ihr Profil ist noch nicht vollständig eingerichtet!
          <br />
          <Link to="/profile-wizard" style={{ color: 'white' }}>
            Jetzt einrichten
          </Link>
        </Alert>
      )}
      {profileProgress &&
        profileProgress.progress > 90 &&
        tableTypes.length === 0 && (
          <Alert
            variant="filled"
            severity="warning"
            style={{ marginBottom: theme.spacing(2) }}
          >
            <AlertTitle>Achtung!</AlertTitle>
            Sie haben keine Bestellarten konfiguriert!
            <ul>
              {TABLE_TYPES.map((t, i) => (
                <li className={classes.listItem} key={i}>
                  <Link to={`/${t.toLowerCase()}`} style={{ color: 'white' }}>
                    {getOrderTypeLabel(t)} einrichten
                  </Link>
                </li>
              ))}
            </ul>
          </Alert>
        )}
    </>
  );
};

export default ProfileProgressBanner;
