import {
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { formatNumber } from '../helpers/FormatHelper';
import OrderSumOrderItems from './OrderSumOrderItems';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  })
);

interface Props {
  orderSums: OrderSumFragment[];
  statusFilter: ReceiptStatus[];
}

const OrderSumTable: React.FC<Props> = ({ orderSums, statusFilter }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {orderSums.map((orderSum, index) => (
        <Accordion
          expanded={expanded === index.toString()}
          onChange={handleChange(index.toString())}
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {format(new Date(orderSum.date), 'dd.MM.yyyy')}
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {formatNumber(orderSum.total / 100, '€')}
              {orderSum.cashTotal > 0 && (
                <> (Davon Bar: {formatNumber(orderSum.cashTotal / 100, '€')})</>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === index.toString() && (
              <OrderSumOrderItems
                date={orderSum.date}
                statusFilter={statusFilter}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default OrderSumTable;
