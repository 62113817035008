import { IconButton, Tooltip } from '@material-ui/core';
import { endOfDay, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import OrderReportTable from './OrderReportTable';
import PrintIcon from '@material-ui/icons/Print';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  AdminGetGroupedOrderItemsQueryDocument,
  GetPrintersQueryDocument,
  PrintBonDocument
} from '../services/graphql/typed-operations';

interface Props {
  date: string;
  statusFilter: ReceiptStatus[];
}

const OrderSumOrderItems: React.FC<Props> = ({
  date,
  statusFilter = ['COMPLETED']
}) => {
  const [orderItems, setOrderItems] = useState<GroupedOrderItemFragment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gastronomy = useRecoilValue(gastronomyState);
  const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const apolloClient = useApolloClient();

  const [getPrintersQuery] = useLazyQuery(GetPrintersQueryDocument, {
    onCompleted: (data) => {
      if (!data) {
        throw new Error('API Fehler!');
      }

      console.debug('Printers', data.getPrinters);

      setPrinters(data.getPrinters);
      setIsLoading(false);
    }
  });

  const [getGroupedOrderItemsQuery] = useLazyQuery(
    AdminGetGroupedOrderItemsQueryDocument,
    {
      onCompleted: (data) => {
        if (!data) {
          throw new Error('API Fehler!');
        }

        console.debug('Grouped OrderItems', data.adminGetGroupedOrderItems);

        setOrderItems(data.adminGetGroupedOrderItems);
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    if (gastronomy) {
      setIsLoading(true);
      getPrintersQuery({ variables: { user_uuid: gastronomy.uuid } });
    }
  }, [gastronomy]);

  useEffect(() => {
    if (gastronomy) {
      setIsLoading(true);
      const fromDate = new Date(date);
      const dateFrom = fromDate;
      dateFrom.setUTCHours(0, 0, 0, 0);

      const dateTo = endOfDay(fromDate);
      dateTo.setUTCHours(23, 59, 59, 999);

      const params: GroupedOrderItemSearchParams = {
        user_uuid: gastronomy.uuid,
        datetime_from: dateFrom.toISOString(),
        datetime_to: dateTo.toISOString(),
        groupFilter: 'TOTAL',
        statusFilter
      };

      getGroupedOrderItemsQuery({ variables: { params } });
    }
  }, [date, gastronomy, statusFilter]);

  const printBon = async () => {
    let total = 0;
    const minusArticles: GroupedOrderItemFragment[] = [];
    let minusTotal = 0;
    let design = ` 
{H1}{C}Tagesbericht
{H2}{C}${format(new Date(date), 'dd.MM.yyyy')}
{D}
`;
    for (const item of orderItems) {
      if (typeof item.total === 'number') {
        if (item.total >= 0) {
          const price = item.total;
          total += price;
          design +=
            item.amount +
            'x ' +
            (item.product_print_title || item.product) +
            ' {<>} ' +
            (price / 100).toFixed(2) +
            '€' +
            '\n';
          if (item.modifier) {
            design += '    - ' + (item.modifier_print || item.modifier) + '\n';
          }
        } else {
          minusArticles.push(item);
        }
      }
    }

    design += `
{D}
{B}Summe {<>} ${(total / 100).toFixed(2)}€

`;
    if (minusArticles.length > 0) {
      design += `\n \n`;
      for (const item of minusArticles) {
        if (typeof item.total === 'number') {
          const price = item.total;
          minusTotal += price;
          design +=
            item.amount +
            'x ' +
            (item.product_print_title || item.product) +
            ' {<>} ' +
            (price / 100).toFixed(2) +
            '€' +
            '\n';
          if (item.modifier) {
            design += '    - ' + (item.modifier_print || item.modifier) + '\n';
          }
        }
      }
      design += `
{D}
{B}Summe Gutscheine {<>} ${(minusTotal / 100).toFixed(2)}€
`;
    }
    design += `
      

Status: ${statusFilter.join(', ')}
 
Gedruckt am ${format(new Date(), 'dd.MM.yyyy HH:mm')} 
`;

    console.log('bon', design, printers);
    if (printers.length > 0) {
      try {
        const result = apolloClient.mutate({
          mutation: PrintBonDocument,
          variables: {
            printerUuid: printers[0].uuid,
            text: design
          }
        });

        console.log('Print result', result);
      } catch (error) {
        console.log('print error', error);
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div>
        {printers.length > 0 && (
          <Tooltip title="Testausdruck">
            <IconButton onClick={printBon}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <OrderReportTable
        orderItems={orderItems}
        isLoading={isLoading}
        renderPaper={false}
        options={{
          exportButton: true
        }}
      />
    </div>
  );
};

export default OrderSumOrderItems;
