import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { endOfDay, startOfMonth } from 'date-fns';
import React, { useState, Suspense } from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import TipTable from '../../components/TipTable';
import { getGroupedTips } from '../../helpers/OrderHelper';
import { lazyRetry } from '../../helpers/lazyRetry';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../../store/gastronomy';
import { useQuery } from '@apollo/client';
import { GetTipsQueryDocument } from '../../services/graphql/typed-operations';
const TipExport = React.lazy(() =>
  lazyRetry(() => import('../../components/TipExport'))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1),
      width: '100%'
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    datePicker: {
      marginRight: theme.spacing(2)
    },
    chartHeader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    }
  })
);

const GastronomyTips: React.FC = () => {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState<Date>(startOfMonth(new Date()));
  const [toDate, setToDate] = useState<Date>(endOfDay(new Date()));
  const gastronomy = useRecoilValue(gastronomyState);

  const { data, loading } = useQuery(GetTipsQueryDocument, {
    variables: {
      params: {
        datetime_from: fromDate.toISOString(),
        datetime_to: toDate.toISOString(),
        user_uuid: gastronomy?.uuid || 'NOT SET'
      }
    },
    skip: !gastronomy
  });

  return (
    <>
      <HeaderContainer title={'Trinkgeld'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.toolbarContainer}>
            <div>
              <DatePicker
                views={['date', 'month', 'year']}
                label="Von Datum"
                value={fromDate}
                onChange={(date: MaterialUiPickersDate) =>
                  setFromDate(date as Date)
                }
                className={classes.datePicker}
              />
              <DatePicker
                views={['date', 'month', 'year']}
                label="Bis Datum"
                value={toDate}
                onChange={(date: MaterialUiPickersDate) =>
                  setToDate(date as Date)
                }
                className={classes.datePicker}
              />
            </div>
            <Suspense fallback={<CircularIndeterminate />}>
              <TipExport
                tips={getGroupedTips(data?.getTips || [])}
                fromDate={fromDate}
                toDate={toDate}
                disabled={loading || data?.getTips.length === 0}
              />
            </Suspense>
          </Paper>
          {loading ? (
            <Paper className={classes.paper}>
              <CircularIndeterminate />
            </Paper>
          ) : (
            <TipTable tips={data?.getTips || []} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyTips;
