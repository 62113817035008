import { createStyles, Grid, makeStyles, Paper } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { endOfDay, startOfDay } from 'date-fns';
import React, { Suspense, useEffect, useState } from 'react';
import HeaderContainer from '../../components/HeaderContainer';
import ReceiptTable from '../../components/ReceiptTable';
import { captureSilentError } from '../../helpers/ErrorHelper';
import { lazyRetry } from '../../helpers/lazyRetry';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { GetReceiptsWithGastronomyQueryDocument } from '../../services/graphql/typed-operations';
const AdminOrderExport = React.lazy(() =>
  lazyRetry(() => import('../../components/AdminOrderExport'))
);

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1),
      width: '100%'
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    datePicker: {
      marginRight: theme.spacing(2)
    },
    chartHeader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    }
  })
);

const GastronomyOrders: React.FC = () => {
  const classes = useStyles();
  const [receipts, setReceipts] = useState<ReceiptWithGastronomyFragment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fromDate, setFromDate] = useState<Date>(startOfDay(new Date()));
  const [toDate, setToDate] = useState<Date>(endOfDay(new Date()));
  const gastronomy = useRecoilValue(gastronomyState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchOpenReceipts = async (): Promise<void> => {
      if (gastronomy && gastronomy.uuid) {
        setIsLoading(true);
        try {
          const params: ReceiptSearchParams = {
            status: [
              'PENDING_RECEIVED',
              'RECEIVED',
              'PENDING_ACCEPTED',
              'ACCEPTED',
              'PENDING_READY',
              'READY',
              'PENDING_COMPLETED',
              'COMPLETED',
              'PENDING_ERROR',
              'ERROR'
            ],
            datetime_from: fromDate.toISOString(),
            datetime_to: toDate.toISOString(),
            user_uuid: gastronomy.uuid
          };

          const { data } = await apolloClient.query({
            query: GetReceiptsWithGastronomyQueryDocument,
            variables: { params }
          });

          if (!data) {
            return;
          }

          console.debug('Receipts', data.getReceipts);

          setReceipts(data.getReceipts);
        } catch (error) {
          captureSilentError(error);
        }
        setIsLoading(false);
      }
    };

    fetchOpenReceipts();
  }, [gastronomy, fromDate, toDate]);

  return (
    <>
      <HeaderContainer title={'Bestellhistorie'} />
      <Paper className={classes.toolbarContainer}>
        <div>
          <DatePicker
            views={['date', 'month', 'year']}
            label="Von Datum"
            value={fromDate}
            onChange={(date) => {
              if (date) setFromDate(date);
            }}
            className={classes.datePicker}
          />
          <DatePicker
            views={['date', 'month', 'year']}
            label="Bis Datum"
            value={toDate}
            onChange={(date) => {
              if (date) setToDate(date);
            }}
            className={classes.datePicker}
          />
        </div>
        <Suspense fallback={<CircularIndeterminate />}>
          <AdminOrderExport
            receipts={receipts}
            fromDate={fromDate}
            toDate={toDate}
            disabled={!receipts || receipts.length === 0}
          />
        </Suspense>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <ReceiptTable
              receipts={receipts}
              isLoading={isLoading}
              renderPaper={false}
              options={{ search: true }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyOrders;
