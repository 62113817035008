import React, { useEffect, useState } from 'react';
import * as stripejs from '@stripe/stripe-js';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CircularIndeterminate from './CircularIndeterminate';
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { captureSilentError } from '../helpers/ErrorHelper';
import DoneRounded from '@material-ui/icons/DoneRounded';
import ClearRounded from '@material-ui/icons/ClearRounded';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { AddBankAccountMutationDocument } from '../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    submitButton: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

// Custom styling can be passed to options when creating an Element.
const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  style: {
    base: {
      color: '#32325d',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#32325d'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a'
      }
    }
  }
};

interface Props {
  onClose: (success: boolean) => void;
  showCloseButton?: boolean;
}

const BankAccountform: React.FC<Props> = ({
  onClose,
  showCloseButton = false
}) => {
  const stripe = useStripe();
  const gastronomy = useRecoilValue(gastronomyState);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('company');
  const elements = useElements();
  const classes = useStyles();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (gastronomy) {
      setEmail(gastronomy.invoice_email || gastronomy.username || '');
    }
  }, [gastronomy]);

  const handleChange = (event: stripejs.StripeIbanElementChangeEvent) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }

    if (event.bankName) {
      setBankName(event.bankName);
    } else {
      setBankName('');
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (elements && stripe) {
      setIsLoading(true);

      const ibanElement = elements.getElement(IbanElement);

      if (!ibanElement) {
        return;
      }

      const result = await stripe.createToken(ibanElement, {
        currency: 'EUR',
        account_holder_name: name,
        account_holder_type: accountType
      });

      setIsLoading(false);

      if (result.error) {
        setError(result.error.message || null);
      } else {
        setError(null);

        if (result && result.token && gastronomy) {
          try {
            const { data } = await apolloClient.mutate({
              mutation: AddBankAccountMutationDocument,
              variables: {
                input: {
                  user_uuid: gastronomy.uuid,
                  token: result.token.id
                }
              }
            });

            if (data) {
              addSnackbarMessages(
                [
                  {
                    type: 'success',
                    message: `Bankkonto erfolgreich hinzugefügt!`
                  }
                ],
                setSnackbarMessages
              );

              onClose(true);
            }
          } catch (error) {
            captureSilentError(error);
          }
        }
      }
    }
  };

  const CheckoutForm: React.ReactNode = (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">
            Auf dieses Bankkonto erhalten Sie in Zukunft Ihre Auszahlungen.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.input} variant="outlined">
            <InputLabel id="account-type-select">Unternehmenstyp</InputLabel>
            <Select
              value={accountType}
              onChange={(event) => setAccountType(event.target.value as string)}
              labelId="account-type-select"
              label="Unternehmenstyp"
              margin="dense"
            >
              <MenuItem key={'individual'} value={'individual'}>
                Einzelperson
              </MenuItem>
              <MenuItem key={'company'} value={'company'}>
                Unternehmen
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="name"
            name="name"
            type="text"
            label="Name"
            placeholder="Max Mustermann AG"
            helperText="Kontoinhaber"
            className={classes.input}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="email"
            name="name"
            type="email"
            label="E-Mail"
            placeholder="max.mustermann@getsby.at"
            className={classes.input}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            helperText="Für Rückfragen zu Zahlungen"
            required
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <label>IBAN</label>
          <IbanElement
            id="iban-element"
            options={IBAN_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
          <div id="bank-name" className={bankName ? 'visible' : ''}>
            {bankName}
          </div>
        </Grid>

        <Grid item xs={12}>
          {error && (
            <Alert variant="filled" severity="error" className={classes.alert}>
              <AlertTitle>Fehler!</AlertTitle>
              {error}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Alert variant="filled" severity="info" className={classes.alert}>
            <AlertTitle>Hinweis</AlertTitle>
            Mit der Angabe Ihrer IBAN autorisieren Sie (A) die getsby GmbH und
            (B) Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank
            zu senden und Ihr Konto zu belasten. Sie haben Anspruch auf eine
            Rückerstattung von Ihrer Bank unter den Bedingungen Ihrer
            Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von
            8 Wochen ab dem Datum auf dem Ihr Konto belastet wurde.
          </Alert>
        </Grid>
        {showCloseButton && (
          <Grid item xs={6}>
            <Button
              className={classes.input}
              onClick={() => onClose(false)}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        )}
        <Grid item xs={showCloseButton ? 6 : 12}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.submitButton}
            disabled={!!error}
          >
            Dieses Bankkonto hinzufügen
            <DoneRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <>
      {isLoading && <CircularIndeterminate />}
      {CheckoutForm}
    </>
  );
};

export default BankAccountform;
