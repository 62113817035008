import { useApolloClient } from '@apollo/client';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import EditRounded from '@material-ui/icons/EditRounded';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../helpers/ErrorHelper';
import {
  getStripeAccountRequirementsButtonLabel,
  isOnlyBankAccountMissing
} from '../helpers/GastronomyHelper';
import { getCurrentBasePath } from '../helpers/MiscHelper';
import {
  CheckStripeAccountRequirementsQueryDocument,
  TriggerStripeAccountLinksMutationDocument
} from '../services/graphql/typed-operations';
import { snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    margin: {
      margin: theme.spacing(2, 0)
    }
  })
);

interface Props {
  refresh?: boolean;
}

const StripeAccountLinks: React.FC<Props> = ({ refresh }) => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [
    stripeAccountRequirements,
    setStripeAccountRequirements
  ] = useState<StripeAccountRequirementsResponse | null>(null);
  const [
    triggerStripeAccountLinksIsLoading,
    setTriggerStripeAccountLinksIsLoading
  ] = useState<boolean>(false);
  const onlyBankAccountMissing = useMemo(
    () => isOnlyBankAccountMissing(stripeAccountRequirements),
    [stripeAccountRequirements]
  );
  const apolloClient = useApolloClient();

  useEffect(() => {
    const checkStripeAccountRequirements = async () => {
      if (gastronomy) {
        try {
          const { data } = await apolloClient.query({
            query: CheckStripeAccountRequirementsQueryDocument,
            variables: {
              params: { user_uuid: gastronomy.uuid }
            },
            fetchPolicy: 'network-only'
          });

          if (data) {
            const result = data.checkStripeAccountRequirements;
            console.log('accountRequirements', result);

            setStripeAccountRequirements(result);
          }
        } catch (error) {
          captureSilentError(error);
        }
      }
    };

    checkStripeAccountRequirements();
  }, [gastronomy, refresh]);

  const triggerStripeAccountLinks = async (): Promise<void> => {
    if (gastronomy && stripeAccountRequirements) {
      setTriggerStripeAccountLinksIsLoading(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: TriggerStripeAccountLinksMutationDocument,
          variables: {
            input: {
              user_uuid: gastronomy.uuid,
              type:
                stripeAccountRequirements.status === 'NO_ACCOUNT'
                  ? 'ACCOUNT_ONBOARDING'
                  : 'ACCOUNT_UPDATE',
              collect: stripeAccountRequirements.type || 'CURRENTLY_DUE',
              refreshUrl: `${getCurrentBasePath()}/stripe-custom-reauth`,
              returnUrl: `${getCurrentBasePath()}${
                location.pathname
              }?account-links-return`
            }
          }
        });

        if (data && data.triggerStripeAccountLinks.url) {
          window.location.href = data.triggerStripeAccountLinks.url;
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      setTriggerStripeAccountLinksIsLoading(false);
    }
  };

  return (
    <>
      {triggerStripeAccountLinksIsLoading ? (
        <CircularIndeterminate />
      ) : (
        <>
          {stripeAccountRequirements && (
            <>
              {!onlyBankAccountMissing &&
                stripeAccountRequirements.status === 'ACTION_REQUIRED' && (
                  <Alert
                    variant="filled"
                    severity="warning"
                    className={classes.margin}
                  >
                    <AlertTitle>Achtung!</AlertTitle>
                    Aktion erforderlich!
                  </Alert>
                )}
              {stripeAccountRequirements.status === 'PENDING_VERIFICATION' && (
                <Alert
                  variant="filled"
                  severity="info"
                  className={classes.margin}
                >
                  <AlertTitle>Hinweis</AlertTitle>
                  Bitte haben Sie etwas Geduld. Ihre Daten werden im Moment
                  verifiziert.
                </Alert>
              )}

              <Button
                className={classes.input}
                onClick={() => {
                  triggerStripeAccountLinks();
                }}
                variant={
                  stripeAccountRequirements.status === 'COMPLETED'
                    ? 'outlined'
                    : 'contained'
                }
                color={
                  stripeAccountRequirements.status === 'COMPLETED'
                    ? 'default'
                    : 'primary'
                }
              >
                {getStripeAccountRequirementsButtonLabel(
                  stripeAccountRequirements.status,
                  onlyBankAccountMissing
                )}
                {stripeAccountRequirements.status !== 'NO_ACCOUNT' && (
                  <EditRounded className={classes.rightIcon} />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default StripeAccountLinks;
