import {
  Avatar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import * as path from 'path';
import Config from '../config';
import Storage from '@aws-amplify/storage';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import { IMG_BASE_PATH } from '../constants';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    avatar: {
      width: 100,
      height: 100
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    input: {
      width: '100%'
    },
    fileInput: {
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    marginBottom: {
      marginBottom: theme.spacing(1)
    }
  })
);

interface Props {
  onClose: (success: boolean) => void;
  showCloseButton?: boolean;
}

interface Values {
  logo_image: string;
  header_image: string;
}

const getInitialValues = (
  gastronomy: GastronomyFragment | null | undefined
): Values => {
  return {
    logo_image: gastronomy ? gastronomy.logo_image || '' : '',
    header_image: gastronomy ? gastronomy.header_image || '' : ''
  };
};

const ProfileImagesForm: React.FC<Props> = ({
  onClose,
  showCloseButton = false
}) => {
  const classes = useStyles();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const [logoImage, setLogoImage] = useState<File>();
  const [headerImage, setHeaderImage] = useState<File>();
  const logoImageInputRef = useRef<HTMLInputElement>(null);
  const headerImageInputRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<Values>(getInitialValues(gastronomy));
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setValues(getInitialValues(gastronomy));
  }, [gastronomy]);

  const saveGastronomy = async () => {
    if (gastronomy) {
      try {
        if (!logoImage && !headerImage) {
          onClose(
            gastronomy.logo_image && gastronomy.header_image ? true : false
          );

          return;
        }

        if (logoImage && values.logo_image) {
          const result = await Storage.put(values.logo_image, logoImage, {
            contentType: logoImage.type,
            bucket: Config.s3ImageUploadBucket,
            customPrefix: {
              public: ''
            }
          });

          console.debug(result);
        }

        if (headerImage && values.header_image) {
          const result = await Storage.put(values.header_image, headerImage, {
            contentType: headerImage.type,
            bucket: Config.s3ImageUploadBucket,
            customPrefix: {
              public: ''
            }
          });

          console.debug(result);
        }

        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              // @ts-ignore
              logo_image: logoImage
                ? `${Config.s3ImageBaseUrl}/${values.logo_image}`
                : null,
              // @ts-ignore
              header_image: headerImage
                ? `${Config.s3ImageBaseUrl}/${values.header_image}`
                : null
            }
          }
        });

        if (!data) {
          onClose(false);

          return;
        }

        const newGastronomy: GastronomyFragment = data.updateGastronomy;

        setGastronomy(newGastronomy);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Profil aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        onClose(true);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const pickLogoImage = async (data: File) => {
    if (gastronomy) {
      setLogoImage(data);
      setValues({
        ...values,
        logo_image: `${new Date().getTime()}_${
          gastronomy.uuid
        }_logo${path.extname(data.name)}`
      });
    }
  };

  const pickHeaderImage = (data: File) => {
    if (gastronomy) {
      setHeaderImage(data);
      setValues({
        ...values,
        header_image: `${new Date().getTime()}_${
          gastronomy.uuid
        }_header${path.extname(data.name)}`
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.center}>
        <Typography variant="body1" className={classes.marginBottom}>
          Logo
        </Typography>
        {gastronomy && (
          <Avatar className={classes.avatar}>
            <img
              src={
                (logoImage && URL.createObjectURL(logoImage)) ||
                gastronomy.logo_image ||
                ''
              }
              style={{ maxHeight: 100, width: 'auto' }}
              alt=""
            />
          </Avatar>
        )}
        <Typography variant="body2">
          Bitte verwenden Sie ein quadratisches Bild.
        </Typography>
        <input
          ref={logoImageInputRef}
          type="file"
          accept="image/png,image/jpg,image/jpeg,image/svg+xml"
          onChange={(evt) => {
            if (evt.target.files) {
              pickLogoImage(evt.target.files[0]);
            }
          }}
          className={classes.fileInput}
        />
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <Typography variant="body1" className={classes.marginBottom}>
          Header Bild
        </Typography>
        {gastronomy && (
          <img
            src={
              (headerImage && URL.createObjectURL(headerImage)) ||
              gastronomy.header_image ||
              `${IMG_BASE_PATH}/food_pattern.svg`
            }
            style={{ maxHeight: 'auto', width: '100%', maxWidth: 300 }}
            alt=""
          />
        )}
        <Typography variant="body2">
          Bitte verwenden Sie ein Bild im Querformat.
        </Typography>
        <input
          ref={headerImageInputRef}
          type="file"
          accept="image/png,image/jpg,image/jpeg,image/svg+xml"
          onChange={(evt) => {
            if (evt.target.files) {
              pickHeaderImage(evt.target.files[0]);
            }
          }}
          className={classes.fileInput}
        />
      </Grid>
      {showCloseButton && (
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={() => onClose(false)}
            variant="outlined"
          >
            Verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      )}
      <Grid item xs={showCloseButton ? 6 : 12}>
        <Button
          className={classes.input}
          onClick={saveGastronomy}
          variant="contained"
          color="primary"
        >
          Speichern
          <DoneRounded className={classes.rightIcon} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileImagesForm;
