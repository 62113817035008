import { Color } from '@material-ui/core';
import Config from '../config';

const getsbyPrimary: Color = {
  50: Config.primaryColor,
  100: Config.primaryColor,
  200: Config.primaryColor,
  300: Config.primaryColor,
  400: Config.primaryColor,
  500: Config.primaryColor,
  600: Config.primaryColor,
  700: Config.primaryColor,
  800: Config.primaryColor,
  900: Config.primaryColor,
  A100: Config.primaryColor,
  A200: Config.primaryColor,
  A400: Config.primaryColor,
  A700: Config.primaryColor
};

const getsbySecondary: Color = {
  50: '#232020',
  100: '#232020',
  200: '#232020',
  300: '#232020',
  400: '#232020',
  500: '#232020',
  600: '#232020',
  700: '#232020',
  800: '#232020',
  900: '#232020',
  A100: '#232020',
  A200: '#232020',
  A400: '#232020',
  A700: '#232020'
};

export { getsbyPrimary, getsbySecondary };
