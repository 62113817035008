import { useApolloClient, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PictureInPictureRounded from '@material-ui/icons/PictureInPictureRounded';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getsbyPrimary } from '../helpers/Color';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getPrinterLabel } from '../helpers/MiscHelper';
import {
  AddRoomMutationDocument,
  GetPrintersQueryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { addToState, gastronomyState, roomsState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      marginTop: theme.spacing(3)
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    avatar: {
      margin: theme.spacing(1),
      color: theme.palette.secondary.main,
      backgroundColor: getsbyPrimary[500]
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    radioButton: {
      margin: theme.spacing(1)
    }
  })
);

const initialState: RoomAddInput = {
  title: '',
  description: '',
  user_uuid: ''
};

interface Props {
  onClose: () => void;
  onSave: () => void;
}

const RoomAddForm: React.FC<Props> = ({ onClose, onSave }) => {
  const classes = useStyles();
  const [values, setValues] = useState<RoomAddInput>(initialState);
  const gastronomy = useRecoilValue(gastronomyState);
  const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [roomPrinterId, setRoomPrinterId] = useState<string>('');
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setRooms = useSetRecoilState(roomsState);
  const apolloClient = useApolloClient();

  useQuery(GetPrintersQueryDocument, {
    variables: { user_uuid: gastronomy ? gastronomy.uuid : 'not set' },
    skip: !gastronomy,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      console.debug('Printers', data.getPrinters);

      setPrinters(data.getPrinters);
    }
  });

  const handleChange = (name: string) => (event: any) => {
    validateInput(name, event.target.value);

    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handlePrinterChange = (uuid: string) => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRoomPrinterId(uuid === roomPrinterId ? '' : uuid);
  };

  const validateInput = (name: string, value: string): boolean => {
    let isValid = true;

    if (name === 'title') {
      if (value.length === 0) {
        isValid = false;
      }
    }

    if (isValid) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors([...errors, name]);
    }

    return isValid;
  };

  const resetForm = () => {
    setValues(initialState);
    onClose();
  };

  const saveRoom = async () => {
    if (gastronomy) {
      try {
        if (!validateInput('title', values.title as string)) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: 'Bitte überprüfen Sie Ihre Eingabe!'
              }
            ],
            setSnackbarMessages
          );

          return;
        }

        const { data } = await apolloClient.mutate({
          mutation: AddRoomMutationDocument,
          variables: {
            input: {
              ...values,
              user_uuid: gastronomy.uuid,
              printerUuids: roomPrinterId ? [roomPrinterId] : []
            }
          }
        });

        if (!data) {
          return;
        }

        const newRoom = data.addRoom;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Raum ${newRoom.title} hinzugefügt`
            }
          ],
          setSnackbarMessages
        );

        resetForm();

        addToState(newRoom, setRooms);

        onSave();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.heading}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar className={classes.avatar}>
              <PictureInPictureRounded />
            </Avatar>
            <Typography variant={'h5'} className={classes.padding}>
              Neuen Raum anlegen
            </Typography>
          </div>
          <div>
            <Tooltip title="Schließen">
              <IconButton onClick={resetForm}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                error={errors.includes('title')}
                id="title"
                label="Titel"
                placeholder="Raum 1"
                className={classes.input}
                required={true}
                value={values.title}
                onChange={handleChange('title')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item sm={printers.length ? 4 : 8}>
              <TextField
                id="description"
                label="Beschreibung"
                placeholder="Raum im UG"
                className={classes.input}
                value={values.description}
                onChange={handleChange('description')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            {printers.length > 0 && (
              <Grid item sm={4}>
                <FormControl
                  component="fieldset"
                  className={classes.radioButton}
                >
                  <FormLabel component="legend">Druckerzuweisung</FormLabel>
                  <RadioGroup value={roomPrinterId}>
                    {printers.map((printer) => (
                      <FormControlLabel
                        key={printer.uuid}
                        value={printer.uuid}
                        control={
                          <Radio onClick={handlePrinterChange(printer.uuid)} />
                        }
                        label={getPrinterLabel(printer)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </form>

        <Button
          onClick={saveRoom}
          className={classes.input}
          variant="contained"
          color="primary"
        >
          Hinzufügen
          <AddIcon className={classes.rightIcon} />
        </Button>
      </CardContent>
    </Card>
  );
};

export default RoomAddForm;
