import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import {
  EditRounded,
  NotificationsActiveRounded,
  NotificationsOffRounded
} from '@material-ui/icons';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import ContactConfigUpdateForm from './ContactConfigForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const ContactConfig: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const gastronomy = useRecoilValue(gastronomyState);
  const [update, setUpdate] = useState<boolean>(false);

  const hasApp = !!(
    gastronomy &&
    gastronomy.pickup_version &&
    gastronomy.last_seen_at &&
    differenceInDays(new Date(), new Date(gastronomy.last_seen_at)) <= 7
  );

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {update ? (
        <ContactConfigUpdateForm
          onClose={() => setUpdate(false)}
          showCloseButton
        />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List>
              <ListItem divider>
                <ListItemText
                  primary="Kontakt für Rückfragen zu Bestellungen"
                  secondary={
                    gastronomy.contactConfig &&
                    gastronomy.contactConfig.contact_phone
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Benachrichtigungen per Dashboard"
                  secondary={
                    <>
                      <Link
                        to="/"
                        style={{ color: theme.palette.primary.main }}
                      >
                        Bestellungen anzeigen
                      </Link>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <NotificationsActiveRounded />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Benachrichtigungen per E-Mail"
                  secondary={
                    gastronomy.contactConfig &&
                    gastronomy.contactConfig.contact_email
                  }
                />
                <ListItemSecondaryAction>
                  {gastronomy.contactConfig &&
                  gastronomy.contactConfig.use_email ? (
                    <NotificationsActiveRounded />
                  ) : (
                    <NotificationsOffRounded
                      style={{ color: theme.palette.grey[500] }}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Benachrichtigungen per SMS"
                  secondary={
                    gastronomy.contactConfig &&
                    gastronomy.contactConfig.contact_sms
                  }
                />
                <ListItemSecondaryAction>
                  {gastronomy.contactConfig &&
                  gastronomy.contactConfig.use_sms ? (
                    <NotificationsActiveRounded />
                  ) : (
                    <NotificationsOffRounded
                      style={{ color: theme.palette.grey[500] }}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Benachrichtigungen per App"
                  secondary={
                    <>
                      {hasApp && (
                        <>
                          {gastronomy.pickup_version}
                          {gastronomy.pickup_version &&
                            gastronomy.last_seen_at && (
                              <>
                                {' '}
                                (Zuletzt online:{' '}
                                {new Date(
                                  gastronomy.last_seen_at
                                ).toLocaleString()}
                                )
                              </>
                            )}
                        </>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {hasApp &&
                  gastronomy.last_seen_at &&
                  differenceInMinutes(
                    new Date(),
                    new Date(gastronomy.last_seen_at)
                  ) <= 3 ? (
                    <NotificationsActiveRounded />
                  ) : (
                    <NotificationsOffRounded
                      style={{ color: theme.palette.grey[500] }}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.input}
              onClick={() => setUpdate(true)}
              variant="outlined"
            >
              Bearbeiten
              <EditRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContactConfig;
