import { Chip, useTheme } from '@material-ui/core';
import React from 'react';

interface Props {
  text: string;
  active: boolean;
  onChange: () => void;
}

const FilterChip: React.FC<Props> = (props: Props) => {
  const { text, active, onChange } = props;
  const theme = useTheme();

  return (
    <Chip
      color={active ? 'primary' : 'default'}
      variant={active ? 'default' : 'outlined'}
      label={text}
      onClick={onChange}
      style={{ margin: theme.spacing(1, 1, 1, 0) }}
    />
  );
};

export default FilterChip;
