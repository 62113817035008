import { useApolloClient } from '@apollo/client';
import { FormControlLabel, Switch } from '@material-ui/core';
import { captureException } from '@sentry/browser';
import React from 'react';
import { UpdateProductModifierMutationDocument } from '../services/graphql/typed-operations';

interface Props {
  modifier: {
    uuid: string;
    status: boolean;
  };
}

const ModifierStatusSwitch: React.FC<Props> = ({ modifier }) => {
  const apolloClient = useApolloClient();

  const toggleStatus = async (uuid: string, checked: boolean) => {
    try {
      await apolloClient.mutate({
        mutation: UpdateProductModifierMutationDocument,
        variables: { input: { uuid, status: checked } },
        refetchQueries: ['getProductModifierQuery']
      });
    } catch (error) {
      console.log('Error setting modifier status', error);
      captureException(error);
    }
  };
  return (
    <FormControlLabel
      control={
        <Switch
          checked={modifier.status}
          onChange={(evt, checked) => toggleStatus(modifier.uuid, checked)}
          color="primary"
        />
      }
      label="Aktiv"
    />
  );
};

export default ModifierStatusSwitch;
