import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { getShortUuid } from '../helpers/UUIDShortener';
import Config from './../config';
import * as QR from 'qrcode.react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    codeWrapper: {
      padding: theme.spacing(1)
    }
  })
);

interface Props {
  type: 'g' | 't' | 'url';
  value: string;
  invert?: boolean;
  size?: number;
  id?: string;
}

const AppQrCode: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { value, type, invert, id, size = 140 } = props;
  const color = {
    primary: '#000000',
    secondary: '#ffffff'
  };

  const url =
    type === 'url'
      ? value
      : `${Config.webAppUrl}?${type}=${getShortUuid(value)}`;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        className={classes.codeWrapper}
        style={{
          width: size + 16,
          height: size + 16,
          backgroundColor: invert ? color.primary : 'transparent'
        }}
      >
        <QR
          id={id}
          value={url}
          bgColor={invert ? color.primary : color.secondary}
          fgColor={invert ? color.secondary : color.primary}
          size={size}
        />
      </div>
    </div>
  );
};

export default AppQrCode;
