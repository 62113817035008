import { useApolloClient } from '@apollo/client';
import {
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import SubdirectoryArrowRight from '@material-ui/icons/SubdirectoryArrowRight';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { formatNumber } from '../helpers/FormatHelper';
import {
  DeleteProductModifierItemMutationDocument,
  UpdateProductModifierItemMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { hasPosSelector } from '../store/gastronomy';
import ModifierItemForm from './ModifierItemForm';

interface Props {
  modifierItem: ModifierItemFragment;
}

const ModifierItemListItem: React.FC<Props> = ({ modifierItem }) => {
  const [update, setUpdate] = useState<boolean>(false);
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const onDeleteModifierItem = async (modifierItem: ModifierItemFragment) => {
    try {
      await apolloClient.mutate({
        mutation: DeleteProductModifierItemMutationDocument,
        variables: { uuid: modifierItem.uuid },
        refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Option ${modifierItem.title} gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const toggleStatus = async (uuid: string, status: boolean) => {
    try {
      await apolloClient.mutate({
        mutation: UpdateProductModifierItemMutationDocument,
        variables: { input: { uuid, status } },
        refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Option ${modifierItem.title} geändert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      {!update && (
        <ListItem divider>
          <ListItemIcon>
            <SubdirectoryArrowRight />
          </ListItemIcon>
          <ListItemText
            primary={modifierItem.title}
            secondary={`+ ${formatNumber(
              modifierItem.price_modifier / 100,
              '€'
            )}`}
          />
          <ListItemSecondaryAction>
            <FormControlLabel
              control={
                <Switch
                  checked={modifierItem.status}
                  onChange={(evt, checked) =>
                    toggleStatus(modifierItem.uuid, checked)
                  }
                  color="primary"
                />
              }
              label="Aktiv"
            />
            <Tooltip title="Bearbeiten">
              <IconButton edge="end" onClick={() => setUpdate(true)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen">
              <IconButton
                disabled={hasPos}
                edge="end"
                onClick={() => onDeleteModifierItem(modifierItem)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {update && modifierItem.modifier_uuid && (
        <ModifierItemForm
          modifierUuid={modifierItem.modifier_uuid}
          modifierItem={modifierItem}
          onClose={() => setUpdate(false)}
        />
      )}
    </>
  );
};

export default ModifierItemListItem;
