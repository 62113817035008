import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { gastronomyState } from '../store/gastronomy';
import CustomThemeForm, { CustomThemeFormOutput } from './CustomThemeForm';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme
} from '@material-ui/core';
import { DeleteRounded, DoneRounded } from '@material-ui/icons';
import SimpleDialog from './SimpleDialog';
import { removeFromS3, uploadImageToS3 } from '../helpers/S3helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const GastronomyCustomThemeForm: React.FC = () => {
  const classes = useStyles();
  const [values, setValues] = useState<CustomThemeFormOutput | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);

  const [updateGastronomyMutation, { loading }] = useMutation(
    UpdateGastronomyMutationDocument
  );

  const onSaveTheme = async () => {
    if (gastronomy && values) {
      try {
        const input: GastronomyUpdateInput = {
          uuid: gastronomy.uuid,
          custom_theme: values.theme
        };

        if (
          values.appBarLogoFile &&
          input.custom_theme &&
          gastronomy.url_path_id
        ) {
          const imageUrl = await uploadImageToS3(
            values.appBarLogoFile,
            gastronomy.url_path_id,
            'appBarLogo'
          );
          input.custom_theme.appBarLogo = imageUrl;
        }
        if (
          values.deleteAppBarLogo &&
          input.custom_theme &&
          values.theme.appBarLogo
        ) {
          const result = await removeFromS3(values.theme.appBarLogo);
          if (result) {
            input.custom_theme.appBarLogo = null;
          }
        }

        console.log('input', input);

        const { data } = await updateGastronomyMutation({
          variables: {
            input
          }
        });

        if (!data) {
          return;
        }

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Theme aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        setGastronomy(data.updateGastronomy);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const onDeleteTheme = async () => {
    if (gastronomy) {
      try {
        const input: GastronomyUpdateInput = {
          uuid: gastronomy.uuid,
          custom_theme: null
        };

        console.log('input', input);

        const { data } = await updateGastronomyMutation({
          variables: { input }
        });

        if (!data) {
          return;
        }

        if (gastronomy?.custom_theme?.appBarLogo) {
          await removeFromS3(gastronomy.custom_theme.appBarLogo);
        }

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Theme gelöscht`
            }
          ],
          setSnackbarMessages
        );

        setGastronomy(data.updateGastronomy);
        setShowDeleteDialog(false);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const handleChange = (output: CustomThemeFormOutput) => {
    setValues(output);
  };

  return (
    <>
      <SimpleDialog
        open={showDeleteDialog}
        title={`Theme löschen`}
        text={'Möchten Sie das aktuelle Theme wirklich löschen?'}
        onPositiveClose={onDeleteTheme}
        onNegativeClose={() => setShowDeleteDialog(false)}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      <Grid container spacing={3}>
        <CustomThemeForm
          values={gastronomy?.custom_theme}
          onChange={handleChange}
        />
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => setShowDeleteDialog(true)}
          >
            Löschen
            <DeleteRounded className={classes.rightIcon} />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={loading || values?.hasErrors}
            onClick={onSaveTheme}
          >
            Speichern
            <DoneRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyCustomThemeForm;
