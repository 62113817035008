import { useMutation } from '@apollo/client';
import {
  IconButton,
  Switch,
  TableCell,
  Tooltip,
  useTheme
} from '@material-ui/core';
import {
  EditRounded,
  Delete as DeleteIcon,
  ReorderRounded
} from '@material-ui/icons';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  DeleteCategoryDocument,
  UpdateCategoryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import CategoryForm from './CategoryForm';
import SimpleDialog from './SimpleDialog';
import TextPopper from './TextPopper';

interface Props {
  category: Category;
}
const CategoryLineItem: React.FC<Props> = ({ category }) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const theme = useTheme();

  const [deleteMutation] = useMutation(DeleteCategoryDocument, {
    variables: { uuid: category.uuid },
    onError: (error) => {
      addSnackbarMessages(
        [
          {
            type: 'error',
            message: `Fehler: ${error.message}`
          }
        ],
        setSnackbarMessages
      );
    },
    onCompleted: () => {
      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Kategorie ${category.name} gelöscht`
          }
        ],
        setSnackbarMessages
      );
    },
    refetchQueries: ['getCategories', 'getProductGroupsQuery']
  });

  const [updateMutation, updateResult] = useMutation(UpdateCategoryDocument, {
    onError: (error) => {
      addSnackbarMessages(
        [
          {
            type: 'error',
            message: `Fehler: ${error.message}`
          }
        ],
        setSnackbarMessages
      );
    }
  });

  return (
    <>
      {openDeleteDialog && (
        <SimpleDialog
          open={openDeleteDialog}
          title={`Kategorie ${category.name} löschen`}
          text={'Möchten Sie diese Kategorie wirklich löschen?'}
          onPositiveClose={() => deleteMutation()}
          onNegativeClose={() => setOpenDeleteDialog(false)}
          buttonPositiveLabel={'Löschen'}
          buttonNegativeLabel={'Zurück'}
        />
      )}
      {!update && (
        <>
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Zum Sortieren bewegen">
                <ReorderRounded
                  style={{
                    color: theme.palette.grey[500],
                    marginRight: theme.spacing(2)
                  }}
                />
              </Tooltip>
              {category.name}
            </div>
          </TableCell>
          <TableCell>
            <Switch
              checked={category.status}
              onChange={(event, checked) => {
                updateMutation({
                  variables: { input: { uuid: category.uuid, status: checked } }
                });
              }}
              color="primary"
              disabled={updateResult.loading}
            />
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            <TextPopper
              text={category.uuid}
              placement={'left'}
              textDescription="UUID"
              copy={true}
            />
            <Tooltip title="Bearbeiten">
              <IconButton onClick={() => setUpdate(true)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen">
              <span>
                <IconButton onClick={() => setOpenDeleteDialog(true)}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </>
      )}
      {update && (
        <TableCell colSpan={3}>
          <CategoryForm
            category={category}
            onClose={() => setUpdate(false)}
            user_uuid={category.user_uuid}
          />
        </TableCell>
      )}
    </>
  );
};

export default CategoryLineItem;
