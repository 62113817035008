import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import Joi from 'joi';
import * as JoiPhoneNumber from 'joi-phone-number';
import React, { useEffect, useState } from 'react';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { Alert, AlertTitle } from '@material-ui/lab';
import Config from '../config';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import TestOrderButton from './TestOrderButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface ContactConfigInput {
  contact_email: string;
  contact_sms: string;
  contact_phone: string;
  use_email: boolean;
  use_sms: boolean;
}

interface Props {
  onClose: (success: boolean) => void;
  showCloseButton?: boolean;
}

const getInitialValues = (
  gastronomy: FullGastronomyFragment | null | undefined
): ContactConfigInput => {
  return {
    contact_email:
      gastronomy && gastronomy.contactConfig
        ? gastronomy.contactConfig.contact_email || ''
        : '',
    contact_sms:
      gastronomy && gastronomy.contactConfig
        ? gastronomy.contactConfig.contact_sms || ''
        : '',
    contact_phone:
      gastronomy && gastronomy.contactConfig
        ? gastronomy.contactConfig.contact_phone || ''
        : '',
    use_email:
      (gastronomy &&
        gastronomy.contactConfig &&
        !!gastronomy.contactConfig.use_email) ||
      false,
    use_sms:
      (gastronomy &&
        gastronomy.contactConfig &&
        !!gastronomy.contactConfig.use_sms) ||
      false
  };
};

const ContactConfigUpdateForm: React.FC<Props> = ({
  onClose,
  showCloseButton = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const [values, setValues] = useState<ContactConfigInput>(
    getInitialValues(gastronomy)
  );
  const [errors, setErrors] = useState<string[]>([]);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setValues(getInitialValues(gastronomy));
  }, [gastronomy]);

  const handleChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const validateValues = () => {
    const CustomJoi = Joi.extend(JoiPhoneNumber);

    const joiSchemaInput = {
      contact_email: values.use_email
        ? CustomJoi.array()
            .items(CustomJoi.string().email({ tlds: { allow: false } }))
            .required()
        : CustomJoi.array()
            .items(CustomJoi.string().email({ tlds: { allow: false } }))
            .allow('')
            .optional(),
      contact_sms: values.use_sms
        ? CustomJoi.array()
            .items(
              CustomJoi.string().phoneNumber({ format: 'e164', strict: true })
            )
            .required()
        : CustomJoi.array()
            .items(
              CustomJoi.string().phoneNumber({ format: 'e164', strict: true })
            )
            .allow('')
            .optional(),
      contact_phone: CustomJoi.array()
        .items(CustomJoi.string().phoneNumber({ format: 'e164' }))
        .required(),
      use_email: CustomJoi.boolean(),
      use_sms: CustomJoi.boolean()
    };

    const schema = CustomJoi.object(joiSchemaInput);

    const { error } = schema.validate(
      {
        ...values,
        contact_phone: values.contact_phone
          ? values.contact_phone.split(',')
          : '',
        contact_email: values.contact_email
          ? values.contact_email.split(',')
          : '',
        contact_sms: values.contact_sms ? values.contact_sms.split(',') : ''
      },
      { abortEarly: false }
    );

    if (error) {
      const errFields: string[] = [];
      error.details.forEach((err: any) => {
        errFields.push(err.path[0].toString());
      });
      setErrors(errFields);
      console.log('joi result', error);

      return false;
    }

    return true;
  };

  const saveGastronomy = async () => {
    if (gastronomy) {
      try {
        if (validateValues()) {
          const { data } = await apolloClient.mutate({
            mutation: UpdateGastronomyMutationDocument,
            variables: {
              input: {
                uuid: gastronomy.uuid,
                contactConfig: {
                  contact_email: values.contact_email,
                  contact_sms: values.contact_sms,
                  contact_phone: values.contact_phone,
                  use_email: values.use_email,
                  use_sms: values.use_sms
                }
              }
            }
          });

          if (!data) {
            return;
          }

          const newGastronomy: GastronomyFragment = data.updateGastronomy;

          console.debug('Gastronomy Update', newGastronomy);

          setGastronomy(newGastronomy);

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Profil aktualisiert`
              }
            ],
            setSnackbarMessages
          );

          onClose(true);
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const toggleUseEmail = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setValues((current) => {
      return { ...current, use_email: checked };
    });
  };

  const toggleUseSms = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setValues((current) => {
      return { ...current, use_sms: checked };
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          error={errors.includes('contact_phone')}
          id="contact_phone"
          label="Kontakt für Rückfragen zu Bestellungen"
          placeholder="+4366012345678"
          className={classes.input}
          onChange={handleChange('contact_phone')}
          value={values.contact_phone}
          margin="dense"
          variant="outlined"
          helperText={
            errors.includes('contact_phone')
              ? 'Bitte im Format +4366012345678'
              : 'Um mehrere Einträge anzugeben, können Sie diese bei der Eingabe mit “,” trennen. (z.B.: +4366012345678,+4366012345678)'
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Alert variant="filled" severity="info">
          <AlertTitle>Hinweis</AlertTitle>
          Geben Sie an über welche Kanäle Sie bei neuen Bestellungen
          benachrichtigt werden möchten.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={true} color="primary" />}
          label={
            <>
              Benachrichtigungen per{' '}
              <Link to="/" style={{ color: theme.palette.primary.main }}>
                Dashboard
              </Link>
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={errors.includes('contact_email')}
          id="conatct_email"
          label="E-Mail"
          placeholder="office@gets.by"
          className={classes.input}
          onChange={handleChange('contact_email')}
          value={values.contact_email}
          margin="dense"
          variant="outlined"
          helperText="Um mehrere Einträge anzugeben, können Sie diese bei der Eingabe mit “,” trennen. (z.B.: office@gets.by,support@gets.by)"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Checkbox
                  color="primary"
                  checked={values.use_email}
                  onChange={toggleUseEmail}
                />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={errors.includes('contact_sms')}
          id="contact_sms"
          label="SMS"
          placeholder="+4366012345678"
          className={classes.input}
          onChange={handleChange('contact_sms')}
          value={values.contact_sms}
          margin="dense"
          variant="outlined"
          helperText={
            errors.includes('contact_sms')
              ? 'Bitte im Format +4366012345678'
              : 'Um mehrere Einträge anzugeben, können Sie diese bei der Eingabe mit “,” trennen. (z.B.: +4366012345678,+4366012345678)'
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Checkbox
                  color="primary"
                  checked={values.use_sms}
                  onChange={toggleUseSms}
                />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!(gastronomy && gastronomy.pickup_version)}
              color="primary"
            />
          }
          label={
            <>
              Benachrichtigungen per{' '}
              <a
                href={Config.gastroStationUrl}
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.primary.main }}
              >
                App
              </a>
            </>
          }
        />
        <div>
          <Typography variant="body2">
            Die GASTRO Terminal App ist optional auf jedem Android-Gerät
            installierbar und ermöglicht Ihnen Ihre vorhandenen Netzwerk- oder
            Bluetooth-Bondrucker mit der App zu verwenden.
          </Typography>
          <a href={Config.gastroStationUrl} target="_blank" rel="noreferrer">
            <img
              src={process.env.PUBLIC_URL + '/img/google-play-badge.png'}
              style={{ height: 'auto', width: '100%', maxWidth: '200px' }}
              alt=""
            />
          </a>
        </div>
      </Grid>
      {showCloseButton ? (
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={() => onClose(false)}
            variant="outlined"
          >
            Verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TestOrderButton />
        </Grid>
      )}
      <Grid item xs={showCloseButton ? 6 : 12}>
        <Button
          className={classes.input}
          onClick={saveGastronomy}
          variant="contained"
          color="primary"
        >
          Speichern
          <DoneRounded className={classes.rightIcon} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactConfigUpdateForm;
