import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import { PageviewRounded } from '@material-ui/icons';
import React, { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import HeaderContainer from '../../components/HeaderContainer';
import ProductGroupTab from '../../components/ProductGroupTab';
import TriggerPosSyncButton from '../../components/TriggerPosSyncButton';
import UploadLogsFromTerminalButton from '../../components/UploadLogsFromTerminalButton';
// import TranslateMenuButton from '../../components/TranslateMenuButton';
import Config from '../../config';
import { gastronomyState } from '../../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    toolbarButton: {
      width: '100%'
    }
  })
);

const GastronomyProducts: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);

  return (
    <>
      <HeaderContainer title={'Speisekarte'} />
      <Paper className={classes.toolbarContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sm={6}>
            {gastronomy && (
              <Button
                variant="contained"
                color="default"
                className={classes.toolbarButton}
                component={forwardRef<HTMLAnchorElement>((props, ref) => (
                  <a
                    ref={ref}
                    href={`${Config.webAppUrl}/speisekarte/${gastronomy.url_path_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                  />
                ))}
              >
                Vorschau der Speisekarte
                <PageviewRounded className={classes.rightIcon} />
              </Button>
            )}
          </Grid>
          {/* <Grid item xs={12} md={3} sm={6}>
            <TranslateMenuButton />
          </Grid> */}
          <Grid item xs={12} md={3} sm={6}>
            <TriggerPosSyncButton />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <UploadLogsFromTerminalButton />
          </Grid>
        </Grid>
      </Paper>
      <ProductGroupTab />
    </>
  );
};

export default GastronomyProducts;
