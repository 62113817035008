import { IconButton, Paper, Tooltip } from '@material-ui/core';
import SearchRounded from '@material-ui/icons/SearchRounded';
import { cloneDeep } from 'lodash';
import MaterialTable, { Column, Options } from 'material-table';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import { gastronomyState } from '../store/gastronomy';

interface Props {
  receipts: ReceiptFragment[];
  isLoading: boolean;
  renderPaper?: boolean;
  options?: Options<ReceiptFragment>;
}

const ReceiptReportTable: React.FC<Props> = ({
  receipts = [],
  isLoading,
  renderPaper = true,
  options
}) => {
  const data = cloneDeep(receipts);
  const gastronomy = useRecoilValue(gastronomyState);

  const columns: Array<Column<ReceiptFragment>> = [
    {
      title: 'Bestelldatum',
      field: 'created_at',
      type: 'datetime',
      render: (rowData) =>
        rowData.created_at ? new Date(rowData.created_at).toLocaleString() : ''
    },
    {
      title: 'Leistungsdatum',
      type: 'datetime',
      defaultSort: 'asc',
      render: (rowData) =>
        new Date(
          rowData.requested_pickup_time ||
            rowData.estimated_pickup_time ||
            rowData.created_at
        ).toLocaleString()
    },
    {
      title: 'Trinkgeld',
      field: 'tip_amount',
      render: (rowData) =>
        rowData.tip_amount ? formatNumber(rowData.tip_amount / 100, '€') : ''
    },
    {
      title: 'Eingelöste Bonuspunkte',
      field: 'redeemed_bonus_amount',
      hidden: !gastronomy?.options.hasBonusProgram,
      render: (rowData) =>
        rowData.redeemed_bonus_amount
          ? formatNumber(rowData.redeemed_bonus_amount / 100, '€')
          : ''
    },
    {
      title: 'Zahlungsbetrag',
      render: (rowData) => {
        const sum = rowData.total_price;
        const tip = rowData.tip_amount || 0;
        const bonusAmount = rowData.redeemed_bonus_amount || 0;

        let total = sum + tip;

        if (sum === 0 && bonusAmount) {
          total = 0;
        }
        return formatNumber(total / 100, '€');
      }
    },
    {
      title: '',
      render: (rowData) => {
        const ReceiptLink = React.forwardRef<
          HTMLAnchorElement,
          Omit<LinkProps, 'innerRef' | 'to'>
        >((props, ref) => (
          <Link
            innerRef={ref as any}
            to={`/receipt/${rowData.uuid}?c=1`}
            target="_blank"
            {...props}
          />
        ));

        return (
          <Tooltip title="Details">
            <IconButton component={ReceiptLink}>
              <SearchRounded />
            </IconButton>
          </Tooltip>
        );
      }
    }
  ];

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={columns}
        isLoading={isLoading}
        data={data}
        localization={{
          toolbar: { searchTooltip: 'Suchen', searchPlaceholder: 'Suchen' },
          pagination: {
            labelRowsSelect: 'Zeilen',
            firstTooltip: 'Erste Seite',
            previousTooltip: 'Vorherige Seite',
            nextTooltip: 'Nächste Seite',
            lastTooltip: 'Letzte Seite'
          }
        }}
        options={{
          showTitle: false,
          showEmptyDataSourceMessage: false,
          sorting: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 50,
          draggable: false,
          padding: 'dense',
          search: false,
          emptyRowsWhenPaging: false,
          ...options
        }}
        components={{
          Container: (props) =>
            renderPaper ? (
              <Paper>{props.children}</Paper>
            ) : (
              <>{props.children}</>
            )
        }}
      />
    </div>
  );
};

export default ReceiptReportTable;
