import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import HeaderContainer from '../../components/HeaderContainer';
import ReceiptTable from '../../components/ReceiptTable';
import TestOrderButton from '../../components/TestOrderButton';
import { isAdminSelector } from '../../store/auth';
import { gastronomyState, openReceiptsState } from '../../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0)
    },
    printerChip: {
      marginLeft: theme.spacing(1)
    }
  })
);

const GastronomyOpenOrders: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const isAdmin = useRecoilValue(isAdminSelector);
  const openReceipts = useRecoilValue(openReceiptsState);

  if (isAdmin && !gastronomy) {
    return <Redirect to={'/admin/monitor'} />;
  }

  if (gastronomy?.status === 'ENABLED') {
    return (
      <>
        <HeaderContainer title={'Offene Bestellungen'} />
        <div className={classes.root}>
          <Alert variant="filled" severity="info" className={classes.margin}>
            <AlertTitle>Hinweis</AlertTitle>
            Sobald Sie eine neue Bestellung empfangen, wird diese automatisch
            gelistet.
            <br />
            Abgeschlossene Bestellungen finden Sie in der{' '}
            <Link to="/orders" style={{ color: 'white' }}>
              Bestellhistorie
            </Link>
            , hier{' '}
            <Link to="/tableorders" style={{ color: 'white' }}>
              offene Bestellungen nach Tischen
            </Link>
            .
          </Alert>

          <TestOrderButton />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <ReceiptTable
                  receipts={openReceipts}
                  isLoading={false}
                  renderPaper={false}
                  options={{ search: true }}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return (
      <HeaderContainer title={'Die Bestellfunktion ist derzeit deaktiviert.'} />
    );
  }
};

export default GastronomyOpenOrders;
