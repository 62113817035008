import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import useSound from 'use-sound';
import { captureSilentError } from '../helpers/ErrorHelper';
import { GetReceiptsWithGastronomyQueryDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState, openReceiptsState } from '../store/gastronomy';
import Ring from './../assets/ring.mp3';

const GastronomyOpenOrdersNotification: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);
  const [playSound, setPlaySound] = useState<boolean>(false);
  const [play, exposedData] = useSound(Ring, { volume: 1, interrupt: true });
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [openReceipts, setOpenReceipts] = useRecoilState(openReceiptsState);
  const location = useLocation();
  const isAdmin = useRecoilValue(isAdminSelector);
  const isEnabled = useMemo(() => {
    console.log('enable play sound', isAdmin, location.pathname);
    if (!isAdmin) {
      return true;
    }
    if (isAdmin && location.pathname === '/') {
      return true;
    }
    return false;
  }, [isAdmin, location]);

  // todo: implement endpoint that checks for new receipts
  const { data } = useQuery(GetReceiptsWithGastronomyQueryDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'not set',
        status: [
          'PENDING_RECEIVED',
          'RECEIVED',
          'PENDING_ACCEPTED',
          'ACCEPTED',
          'PENDING_READY',
          'READY'
        ]
      }
    },
    skip: !gastronomy || !isEnabled,
    fetchPolicy: 'network-only',
    pollInterval: 30000,
    onError: (error) => {
      captureSilentError(error);
    }
  });

  useEffect(() => {
    if (data) {
      const result = data.getReceipts;

      console.log('Receipts', result);

      let hasNew = false;

      for (const receipt of result) {
        if (
          receipt.status === 'PENDING_RECEIVED' ||
          receipt.status === 'RECEIVED'
        ) {
          hasNew = true;
          break;
        }
      }

      if (hasNew) {
        addSnackbarMessages(
          [
            {
              type: 'info',
              message: `Sie haben offene Bestellungen!`
            }
          ],
          setSnackbarMessages
        );
      }

      setOpenReceipts(result);
    }
  }, [data]);

  useEffect(() => {
    if (playSound) {
      if (!exposedData.isPlaying) {
        exposedData.sound.loop(true);
        play();
      }
    } else {
      exposedData.stop();
    }

    return () => exposedData.stop();
  }, [playSound]);

  useEffect(() => {
    console.log('open receipts changed', openReceipts);
    setPlaySound(false);
    if (openReceipts) {
      for (const openReceipt of openReceipts) {
        if (
          openReceipt.status === 'PENDING_RECEIVED' ||
          openReceipt.status === 'RECEIVED'
        ) {
          setPlaySound(true);
          break;
        }
      }
    }
  }, [openReceipts]);

  return <></>;
};

export default GastronomyOpenOrdersNotification;
