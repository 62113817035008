import { FormControl, NativeSelect } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';

interface Props {
  gastronomy: GastronomyFragment;
}

const GastronomyStatus: React.FC<Props> = ({ gastronomy }) => {
  const [status, setStatus] = useState<GastronomyState>(
    gastronomy.status || 'DISABLED'
  );
  const isAdmin = useRecoilValue(isAdminSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setGastronomy = useSetRecoilState(gastronomyState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setStatus(gastronomy.status || 'DISABLED');
  }, [gastronomy]);

  const update = async (newStatus: GastronomyState) => {
    try {
      apolloClient.mutate({
        mutation: UpdateGastronomyMutationDocument,
        variables: {
          input: {
            uuid: gastronomy.uuid,
            status: newStatus
          }
        }
      });

      setGastronomy({ ...gastronomy, status: newStatus });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Status erfolgreich aktualisiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const toggleStatus = async (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== status) {
      await update(event.target.value as GastronomyState);
    }
    setStatus(event.target.value as GastronomyState);
  };

  return (
    <FormControl>
      <NativeSelect
        disabled={!isAdmin}
        value={status}
        onChange={toggleStatus}
        inputProps={{
          name: 'status',
          id: 'status'
        }}
      >
        <option key={'DISABLED'} value={'DISABLED'}>
          DISABLED
        </option>
        <option key={'MENUONLY'} value={'MENUONLY'}>
          MENUONLY
        </option>
        <option key={'ENABLED'} value={'ENABLED'}>
          ENABLED
        </option>
      </NativeSelect>
    </FormControl>
  );
};

export default GastronomyStatus;
