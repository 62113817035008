import { useApolloClient } from '@apollo/client';
import { Link, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const ProfileStatusBanner: React.FC = () => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const theme = useTheme();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const status = !!(
    gastronomy &&
    gastronomy.status &&
    gastronomy.unreachable_override
  );

  const updateUnreachableOverride = async () => {
    if (gastronomy) {
      try {
        await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              unreachable_override: false
            }
          }
        });

        setGastronomy({
          ...gastronomy,
          unreachable_override: false
        });

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Sie können jetzt wieder Bestellungen empfangen!`
            }
          ],
          setSnackbarMessages
        );
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <>
      {status && (
        <Alert
          variant="filled"
          severity="error"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <AlertTitle>Achtung!</AlertTitle>
          Ihr Restaurant kann im Moment keine Bestellungen empfangen!
          <br />
          <Link
            style={{
              color: 'white',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={updateUnreachableOverride}
          >
            Bestellungen aktivieren
          </Link>
        </Alert>
      )}
      {gastronomy && !gastronomy.status && (
        <Alert
          variant="filled"
          severity="info"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <AlertTitle>Hinweis</AlertTitle>
          Wenn Sie Ihr Restaurant in der getsby Übersicht listen möchten, wenden
          Sie sich bitte an{' '}
          <a style={{ color: 'white' }} href="mailto:office@gets.by">
            office@gets.by
          </a>
        </Alert>
      )}
    </>
  );
};

export default ProfileStatusBanner;
