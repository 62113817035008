import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CognitoUser } from '@aws-amplify/auth';
import useUrlSearchParams from '../hooks/useUrlSearchParams';
import { useSetRecoilState } from 'recoil';
import { DashboardUser, userAtom } from '../store/auth';
import {
  getCognitoGroups,
  getEmailFromCognitoUser,
  loadInitialGastronomy
} from '../helpers/AuthHelper';
import { useApolloClient } from '@apollo/client';
import { gastronomyState } from '../store/gastronomy';

const AuthComponent: React.FC = () => {
  const setUser = useSetRecoilState(userAtom);
  const setGastronomy = useSetRecoilState(gastronomyState);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  const query = useUrlSearchParams();
  const apolloClient = useApolloClient();

  const r = query.get('r');
  const signup = query.get('signup');

  const mapDashboardUser = async (
    cognitoUser: CognitoUser
  ): Promise<DashboardUser | null> => {
    const session = cognitoUser.getSignInUserSession();

    if (session) {
      const cognitoGroups = getCognitoGroups(session);
      const email = await getEmailFromCognitoUser(cognitoUser);

      return { uuid: cognitoUser.getUsername(), email, groups: cognitoGroups };
    }

    return null;
  };

  // useEffect(() => {
  //   return onAuthUIStateChange(async (nextAuthState, authData) => {
  //     if (authData) {
  //       if (nextAuthState === 'signedin') {
  //         const user = await mapDashboardUser(authData as CognitoUser);
  //         if (user) {
  //           const gastro = await loadInitialGastronomy(apolloClient, user);
  //           if (gastro) {
  //             setGastronomy(gastro);
  //           }
  //         }
  //         setUser(user);
  //       }
  //     }
  //   });
  // }, []);

  const authStateHandler = async (nextAuthState: AuthState, data?: any) => {
    if (data) {
      const user = await mapDashboardUser(data as CognitoUser);
      setUser(user);
      if (user) {
        const gastro = await loadInitialGastronomy(apolloClient, user);
        if (gastro) {
          setGastronomy(gastro);
        }
      }
    }
    if (nextAuthState === 'signedin') {
      if (r) {
        setRedirectPath(decodeURIComponent(r as string));
      } else {
        setRedirectPath('/');
      }
    }
  };

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh'
      }}
    >
      <AmplifyAuthenticator
        initialAuthState={signup !== null ? AuthState.SignUp : AuthState.SignIn}
        usernameAlias="email"
        handleAuthStateChange={authStateHandler}
      >
        <AmplifySignUp
          usernameAlias="email"
          slot="sign-up"
          formFields={[{ type: 'email' }, { type: 'password' }]}
        />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthComponent;
