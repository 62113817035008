import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import EditRounded from '@material-ui/icons/EditRounded';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import Config from '../config';
import { checkAutoAccept } from '../helpers/GastronomyHelper';
import { getPosTypeLabel } from '../helpers/MiscHelper';
import { gastronomyState } from '../store/gastronomy';
import GastronomyConfigUpdateForm from './GastronomyConfigUpdateForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3)
    },
    headerPadding: {
      padding: theme.spacing(0, 2)
    },
    padding: {
      padding: theme.spacing(2)
    },
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const GastronomyConfig: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [update, setUpdate] = useState<boolean>(false);

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {update ? (
        <GastronomyConfigUpdateForm onClose={() => setUpdate(false)} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List>
              <ListItem divider>
                <ListItemText
                  primary="App Version"
                  secondary={gastronomy.pickup_version}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Integration"
                  secondary={
                    gastronomy.posConfig && gastronomy.posConfig.pos_type
                      ? getPosTypeLabel(gastronomy.posConfig.pos_type)
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Speisekarte" />
                <ListItemSecondaryAction>
                  {gastronomy.status === 'MENUONLY' ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Take-Away" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('TAKEAWAY') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Tischservice" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('DINEIN') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Selbstbedienung" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('SELFSERVICE') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Lieferung" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('DELIVERY') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Vorbestellung" />
                <ListItemSecondaryAction>
                  {gastronomy.options.hasPreOrder ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Vorbestellen & Reservieren" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('RESERVATION') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Drive-In" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableTypes &&
                  gastronomy.options.tableTypes.includes('DRIVEIN') ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Bestellungen automatisch annehmen" />
                <ListItemSecondaryAction>
                  {checkAutoAccept(gastronomy)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Trinkgeldvorauswahl"
                  secondary={
                    gastronomy.options.tipPercentage
                      ? gastronomy.options.tipPercentage + '%'
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Abholzeiten"
                  secondary={
                    gastronomy.options.pickupTimes
                      ? gastronomy.options.pickupTimes.toString()
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Serviceinformation"
                  secondary={
                    gastronomy.options.serviceInformation
                      ? gastronomy.options.serviceInformation
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Lieferinformation"
                  secondary={
                    gastronomy.options.deliveryInformation
                      ? gastronomy.options.deliveryInformation
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Bestellinformation"
                  secondary={
                    gastronomy.options.orderInformation
                      ? gastronomy.options.orderInformation
                      : ''
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Kein Restaurant Name in Speisekarte" />
                <ListItemSecondaryAction>
                  {gastronomy.options.hideGastronomyNameInHeader ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Zimmerservice" />
                <ListItemSecondaryAction>
                  {gastronomy.options.tableOrderIsRoomService ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Speisekarte automatisch übersetzen" />
                <ListItemSecondaryAction>
                  {!!gastronomy.options.enableMenuTranslation ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem divider>
                <ListItemText primary="Neukunden Gutscheine deaktivieren" />
                <ListItemSecondaryAction>
                  {!!gastronomy.options.disableNewCustomerCoupon ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="QR Code Umleitung"
                  secondary={
                    <>
                      {gastronomy.options.qrCodeRoute && (
                        <a
                          href={
                            Config.webAppUrl +
                            `/${gastronomy.url_path_id}${gastronomy.options.qrCodeRoute}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Config.webAppUrl}
                          {`/${gastronomy.url_path_id}${gastronomy.options.qrCodeRoute}`}
                        </a>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {gastronomy.options.qrCodeRoute ? (
                    <DoneRounded />
                  ) : (
                    <ClearRounded />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.input}
              onClick={() => setUpdate(true)}
              variant="outlined"
            >
              Bearbeiten
              <EditRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GastronomyConfig;
