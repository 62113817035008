import {
  Badge,
  Card,
  CardActions,
  CardContent,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import EditRounded from '@material-ui/icons/EditRounded';
import LinkRounded from '@material-ui/icons/LinkRounded';
import React, { useState } from 'react';
import PrinterForm from './PrinterForm';
import TextPopper from './TextPopper';
import PrinterProductsDialog from './PrinterProductsDialog';
import SimpleDialog from './SimpleDialog';
import { useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  DeletePrinterMutationDocument,
  PrintBonDocument
} from '../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(1)
    }
  })
);

interface Props {
  printer: PrinterFragment;
}

const PrinterLineItem: React.FC<Props> = ({ printer }) => {
  const classes = useStyles();
  const [update, setUpdate] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showLinkedProducts, setShowLinkedProducts] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [deletePrinterMutation] = useMutation(DeletePrinterMutationDocument);
  const apolloClient = useApolloClient();

  let printerLinksCount = 0;

  if (printer.productGroups) {
    printerLinksCount += printer.productGroups.length;
  }

  if (printer.products) {
    printerLinksCount += printer.products.length;
  }

  const deletePrinter = async (printer: PrinterFragment) => {
    setOpenDeleteDialog(false);

    deletePrinterMutation({
      variables: { uuid: printer.uuid },
      refetchQueries: ['getPrintersQuery']
    });

    addSnackbarMessages(
      [
        {
          type: 'success',
          message: `Drucker ${printer.name} erfolgreich gelöscht`
        }
      ],
      setSnackbarMessages
    );
  };

  const testPrinter = async () => {
    try {
      const text = `
{C}{H1}TEST

Tisch: A3 {<>} 11.01.2021 12:30
{D}
{H2}1x Test Espresso
    - Kein Zucker

{H2}2x Buchstaben äöüß€
    - Mit Sonderzeichen
{D}
`;
      const result = await apolloClient.mutate({
        mutation: PrintBonDocument,
        variables: {
          printerUuid: printer.uuid,
          text
        }
      });

      console.log('print result', result);
      if (result.data && result.data.printBon) {
        if (
          result.data.printBon.sendErrors &&
          result.data.printBon.sendErrors.length > 0
        ) {
          for (const error of result.data.printBon.sendErrors) {
            addSnackbarMessages(
              [
                {
                  type: 'error',
                  message: `Fehler beim Druck: ${error} `
                }
              ],
              setSnackbarMessages
            );
          }
        } else if (result.data.printBon.sendToClients === 0) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: `Konnte Druckauftrag nicht zustellen.`
              }
            ],
            setSnackbarMessages
          );
        } else {
          addSnackbarMessages(
            [
              {
                type: 'info',
                message: `Druckauftrag zugestellt.`
              }
            ],
            setSnackbarMessages
          );
        }
      }
    } catch (error) {
      console.log('Error printing: ', error);
      if (error.errors) {
        for (const err of error.errors) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: `Fehler beim Druck: ${err.message} `
              }
            ],
            setSnackbarMessages
          );
        }
      }
    }
  };

  return (
    <>
      {openDeleteDialog && (
        <SimpleDialog
          open={openDeleteDialog}
          title={`Drucker ${printer.name} löschen`}
          text={'Möchten Sie diesen Drucker wirklich löschen?'}
          onPositiveClose={() => deletePrinter(printer)}
          onNegativeClose={() => {
            setOpenDeleteDialog(false);
          }}
          buttonPositiveLabel={'Löschen'}
          buttonNegativeLabel={'Zurück'}
        />
      )}
      {showLinkedProducts && (
        <PrinterProductsDialog
          open={showLinkedProducts}
          printer={printer}
          onClose={() => {
            setShowLinkedProducts(false);
          }}
        />
      )}
      {!update && (
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom>
              Name: {printer.name}
              {printer.description ? ` (${printer.description})` : ''}
            </Typography>
            <Typography>
              {printer.connection === 'ETHERNET' &&
                `Netzwerk: ${printer.ip}:${printer.port}`}
              {printer.connection === 'BLUETOOTH' &&
                `Bluetooth: ${printer.address}`}
            </Typography>
            {printer.rooms && (
              <List dense>
                {printer.rooms.map((room) => (
                  <ListItem key={room.uuid}>
                    <ListItemText primary={room.title} />
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
          <CardActions>
            <TextPopper
              text={printer.uuid}
              placement={'left'}
              textDescription="UUID"
              copy={true}
            />
            <Tooltip title="Verlinkte Produktgruppen & Produkte">
              <Badge color="primary" badgeContent={printerLinksCount}>
                <IconButton
                  onClick={() => {
                    setShowLinkedProducts(true);
                  }}
                  disabled={printerLinksCount === 0}
                >
                  <LinkRounded />
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title="Bearbeiten">
              <IconButton onClick={() => setUpdate(true)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen">
              <IconButton onClick={() => setOpenDeleteDialog(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Testausdruck">
              <IconButton onClick={testPrinter}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      )}
      {update && (
        <PrinterForm printer={printer} onClose={() => setUpdate(false)} />
      )}
    </>
  );
};

export default PrinterLineItem;
