import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import React from 'react';
import HeaderContainer from '../../components/HeaderContainer';
import AvailabilityTimesForm from '../../components/AvailabilityTimesForm';
import { setErrorSnackbarMessage } from '../../helpers/ErrorHelper';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../../store/app';
import { gastronomyState } from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { UpdateGastronomyMutationDocument } from '../../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    padding: {
      padding: theme.spacing(0, 2)
    },
    margin: {
      margin: theme.spacing(2, 0)
    }
  })
);

const GastronomyOpeningTimes: React.FC = () => {
  const classes = useStyles();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const saveOpeningTimes = async (
    availabilityTimes?: AvailabilityTimes | null
  ) => {
    try {
      if (gastronomy) {
        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              opening_times: {
                ...availabilityTimes,
                cc: gastronomy.country
              } as AvailabilityTimesInput
            }
          }
        });

        if (!data) {
          return;
        }

        const newGastronomy = data.updateGastronomy;

        setGastronomy(newGastronomy);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Öffnungszeiten aktualisiert`
            }
          ],
          setSnackbarMessages
        );
      }
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      <HeaderContainer title={'Öffnungszeiten'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {gastronomy && (
              <AvailabilityTimesForm
                initialAvailabilityTimes={gastronomy.opening_times}
                onSave={saveOpeningTimes}
                showLimit={false}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyOpeningTimes;
