import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme
} from '@material-ui/core';
import { ClearRounded, DoneRounded } from '@material-ui/icons';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import {
  hasPnpFeature,
  hasPpFeature
} from '../helpers/GastronomyFeeModelHelper';
import { gastronomyState } from '../store/gastronomy';

const GastronomyFeeList: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);
  const theme = useTheme();

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {gastronomy.gastronomyFee && (
        <>
          <List>
            <ListItem divider>
              <ListItemText
                primary="Tarif"
                secondary={gastronomy.gastronomyFee.title}
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary="Monatliche Pauschale"
                secondary={formatNumber(
                  gastronomy.gastronomyFee.monthly_fix / 100,
                  '€'
                )}
              />
            </ListItem>
            {hasPpFeature(gastronomy.gastronomyFee) && (
              <ListItem divider>
                <ListItemText
                  primary={'Tischservice'}
                  secondary={`${formatNumber(
                    gastronomy.gastronomyFee.pp_var,
                    '%'
                  )} + ${formatNumber(
                    gastronomy.gastronomyFee.pp_fix / 100,
                    '€'
                  )}`}
                />
              </ListItem>
            )}
            {hasPnpFeature(gastronomy.gastronomyFee) && (
              <ListItem divider>
                <ListItemText
                  primary={'Take-Away oder Lieferung'}
                  secondary={`${formatNumber(
                    gastronomy.gastronomyFee.pnp_var,
                    '%'
                  )} + ${formatNumber(
                    gastronomy.gastronomyFee.pnp_fix / 100,
                    '€'
                  )}
                    `}
                />
              </ListItem>
            )}
            {(hasPnpFeature(gastronomy.gastronomyFee) ||
              hasPpFeature(gastronomy.gastronomyFee) ||
              gastronomy.gastronomyFee.delivery) && (
              <ListItem divider>
                <ListItemText
                  primary={'Gebühr bei Online-Zahlungen'}
                  secondary={'1,4 % + 0,25 €'}
                />
              </ListItem>
            )}
            <ListItem divider>
              <ListItemText primary={'Bonuspunkte Programm'} />
              <ListItemSecondaryAction>
                {gastronomy.gastronomyFee.includes_bonus_program ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary={'Newsletter Tool'} />
              <ListItemSecondaryAction>
                {gastronomy.gastronomyFee.includes_bonus_program ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary={
                  <>
                    Eigene Smartphone App <sup>(1)</sup>
                  </>
                }
              />
              <ListItemSecondaryAction>
                {gastronomy.gastronomyFee.includes_app ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary={'Tischbestellung'} />
              <ListItemSecondaryAction>
                {hasPpFeature(gastronomy.gastronomyFee) ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary={'Take-Away'} />
              <ListItemSecondaryAction>
                {hasPnpFeature(gastronomy.gastronomyFee) ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary={'Selbstbedienung'} />
              <ListItemSecondaryAction>
                {hasPnpFeature(gastronomy.gastronomyFee) ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={'Lieferung'} />
              <ListItemSecondaryAction>
                {gastronomy.gastronomyFee.delivery ? (
                  <DoneRounded />
                ) : (
                  <ClearRounded />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Typography
            variant="body1"
            style={{ marginBottom: theme.spacing(2) }}
          >
            (1) Kosten für optionale App-Store & Playstore App einmalig 600€.
          </Typography>
          <Typography
            variant="body1"
            style={{ marginBottom: theme.spacing(2) }}
          >
            Bei Installationen mit Anbindung zum Kassensystem können Kosten auf
            Seiten ihres Kassensystems anfallen.
          </Typography>
        </>
      )}
    </>
  );
};

export default GastronomyFeeList;
