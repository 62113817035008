import {
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import MaterialTable from 'material-table';
import { GroupedTip } from 'models';
import React, { useState } from 'react';
import { formatNumber } from '../helpers/FormatHelper';
import { getGroupedTips, getOrderTypeLabel } from '../helpers/OrderHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '50%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  })
);

interface Props {
  tips: TipFragment[];
}

const TipTable: React.FC<Props> = ({ tips = [] }) => {
  const classes = useStyles();
  const groupedTips = getGroupedTips(tips);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const exportCsv = (columns: any[], renderData: any[]) => {
    const csvColumns = columns.filter((columnDef) => {
      return !columnDef.hidden && columnDef.field && columnDef.export !== false;
    });

    const data = renderData.map((rowData) =>
      csvColumns.map((columnDef) => {
        if (columnDef.field === 'created_at') {
          return format(new Date(rowData[columnDef.field]), 'HH:mm');
        }
        if (columnDef.field === 'table_type') {
          return getOrderTypeLabel(rowData[columnDef.field]);
        }
        if (columnDef.field === 'amount') {
          return formatNumber(rowData[columnDef.field] / 100);
        }

        return rowData[columnDef.field];
      })
    );

    new CsvBuilder('getsby_export_trinkgeld.csv')
      .setDelimeter(';')
      .setColumns(csvColumns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile();
  };

  return (
    <div className={classes.root}>
      {groupedTips.map((tip: GroupedTip, index: number) => (
        <Accordion
          expanded={expanded === index.toString()}
          onChange={handleChange(index.toString())}
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{tip.date}</Typography>
            <Typography className={classes.secondaryHeading}>
              {formatNumber(tip.amount / 100, '€')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <MaterialTable
                columns={[
                  {
                    title: 'Uhrzeit',
                    field: 'created_at',
                    render: (rowData) =>
                      format(new Date(rowData.created_at), 'HH:mm')
                  },
                  {
                    title: 'Tisch',
                    field: 'table_number'
                  },
                  {
                    title: 'Bestellart',
                    field: 'table_type',
                    render: (rowData) =>
                      rowData.table_type
                        ? getOrderTypeLabel(rowData.table_type)
                        : ''
                  },
                  {
                    title: 'Summe',
                    field: 'amount',
                    type: 'numeric',
                    render: (rowData) => formatNumber(rowData.amount / 100, '€')
                  }
                ]}
                data={tip.tips}
                options={{
                  showTitle: false,
                  showEmptyDataSourceMessage: false,
                  sorting: true,
                  paging: false,
                  draggable: false,
                  exportButton: { csv: true, pdf: false },
                  exportDelimiter: ';',
                  padding: 'dense',
                  emptyRowsWhenPaging: false,
                  exportCsv
                }}
                components={{
                  Container: (props) => <>{props.children}</>
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default TipTable;
