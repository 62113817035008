import { getDate, getMonth } from 'date-fns';
import { AvailabilityTimesKey } from 'models';

/**
 * Returns the day of the week as a label string.
 *
 * @param {!Day} day - Day.
 * @returns {string | null}
 */
export function getDayOfWeekLabel(
  day: Day | AvailabilityTimesKey
): string | null {
  switch (day) {
    case 'mon':
      return 'Montag';
    case 'tue':
      return 'Dienstag';
    case 'wed':
      return 'Mittwoch';
    case 'thu':
      return 'Donnerstag';
    case 'fri':
      return 'Freitag';
    case 'sat':
      return 'Samstag';
    case 'sun':
      return 'Sonntag';
    case 'hol':
      return 'Feiertag';
    case 'e':
      return 'Ausnahmen';
    default:
      return null;
  }
}

export function isWinterHolidaySeason(): boolean {
  const month = getMonth(new Date()) + 1;
  const day = getDate(new Date());

  return true && ((month === 1 && day < 7) || month === 12);
}

export function formatNumber(input: number, appendix = ''): string {
  return input.toFixed(2).replace('.', ',').concat(' ', appendix);
}

export function getDefaultAvailabilityTimes(): AvailabilityTimes {
  const defaultAvailabilityTimes = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
    hol: [],
    e: [],
    cc: 'AT'
  };
  return defaultAvailabilityTimes;
}

export function cleanGraphqlTypeName(
  input: Record<string, unknown>
): Record<string, unknown> {
  return JSON.parse(
    JSON.stringify(input, (k, v) => (k === '__typename' ? undefined : v))
  );
}
