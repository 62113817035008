import { useApolloClient } from '@apollo/client';
import { Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

interface Props {
  gastronomy: GastronomyFragment;
}

const GastronomyUnreachableOverrideStatus: React.FC<Props> = ({
  gastronomy
}) => {
  const [status, setStatus] = useState<boolean>(
    gastronomy.unreachable_override || false
  );
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setGastronomy = useSetRecoilState(gastronomyState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setStatus(gastronomy.unreachable_override || false);
  }, [gastronomy]);

  const update = async (newStatus: boolean) => {
    try {
      await apolloClient.mutate({
        mutation: UpdateGastronomyMutationDocument,
        variables: {
          input: {
            uuid: gastronomy.uuid,
            unreachable_override: newStatus
          }
        }
      });

      setGastronomy({ ...gastronomy, unreachable_override: newStatus });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Status erfolgreich aktualisiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const toggleStatus = async () => {
    const newStatus = !status;

    await update(newStatus);

    setStatus(newStatus);
  };

  return (
    <Switch
      disabled={gastronomy?.status !== 'ENABLED'}
      checked={!status}
      onChange={toggleStatus}
      color="primary"
    />
  );
};

export default GastronomyUnreachableOverrideStatus;
