import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { useState } from 'react';

interface SimpleDialogProps {
  open: boolean;
  title: string;
  text: React.ReactNode;
  onPositiveClose?: (...args: any) => void;
  buttonPositiveLabel?: string;
  onNegativeClose?: (...args: any) => void;
  buttonNegativeLabel?: string;
  positiveDisabled?: boolean;
  negativeDisabled?: boolean;
  isCancelable?: boolean;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  onPositiveClose,
  onNegativeClose,
  title,
  buttonPositiveLabel,
  buttonNegativeLabel,
  text,
  positiveDisabled = false,
  negativeDisabled = false,
  isCancelable = true,
  ...other
}: SimpleDialogProps) => {
  const [disableAction, setDisableAction] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onClick = () => {
    setDisableAction(true);
    if (onPositiveClose) {
      onPositiveClose();
    }
    setDisableAction(false);
  };

  return (
    <Dialog
      onClose={isCancelable ? onNegativeClose : undefined}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      {...other}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" component="div">
          {text}
        </DialogContentText>
      </DialogContent>
      {(buttonNegativeLabel || buttonPositiveLabel) && (
        <DialogActions>
          {buttonNegativeLabel && (
            <Button
              onClick={onNegativeClose}
              color="default"
              disabled={negativeDisabled || !isCancelable}
            >
              {buttonNegativeLabel}
            </Button>
          )}
          {buttonPositiveLabel && (
            <Button
              onClick={onClick}
              color="primary"
              autoFocus
              disabled={positiveDisabled || disableAction}
            >
              {buttonPositiveLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SimpleDialog;
