import {
  AppBar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@material-ui/core';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays, getMonth, getYear, isValid, subMonths } from 'date-fns';
import { endOfMonth, startOfMonth } from 'date-fns/esm';
import React, { Suspense, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import OrderReportTable from '../../components/OrderReportTable';
import ReceiptReportTable from '../../components/ReceiptReportTable';
import ReportTable from '../../components/ReportTable';
import { captureSilentError } from '../../helpers/ErrorHelper';
import { lazyRetry } from '../../helpers/lazyRetry';
import { useRecoilValue } from 'recoil';
import { isAdminSelector } from '../../store/auth';
import { gastronomyState } from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import {
  AdminGetGastronomyReportQueryDocument,
  AdminGetGroupedOrderItemsQueryDocument,
  GetReceiptsQueryDocument
} from '../../services/graphql/typed-operations';
const AdminReportExport = React.lazy(() =>
  lazyRetry(() => import('../../components/AdminReportExport'))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const calculateInitialDate = (year?: string, month?: string): Date => {
  if (year && month) {
    const date = new Date(parseInt(year, 10), parseInt(month, 10));

    if (isValid(date)) {
      return subMonths(date, 1);
    }
  }

  return new Date();
};

interface ReportParams {
  year?: string;
  month?: string;
}

const GastronomyReport: React.FC<RouteComponentProps<ReportParams>> = ({
  match
}) => {
  const { year, month } = match.params;
  const classes = useStyles();
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isFoodLoading, setIsFoodLoading] = useState<boolean>(false);
  const [isDrinksLoading, setIsDrinksLoading] = useState<boolean>(false);
  const [isOtherLoading, setIsOtherLoading] = useState<boolean>(false);
  const [
    isExternalCouponsLoading,
    setIsExternalCouponsLoading
  ] = useState<boolean>(false);
  const [isOverallLoading, setIsOverallLoading] = useState<boolean>(false);
  const [isReceiptsLoading, setIsReceiptsLoading] = useState<boolean>(false);
  const [report, setReport] = useState<GastronomyReportFragment | null>(null);
  const [foodReport, setFoodReport] = useState<GroupedOrderItemFragment[]>([]);
  const [drinkReport, setDrinkReport] = useState<GroupedOrderItemFragment[]>(
    []
  );
  const [otherReport, setOtherReport] = useState<GroupedOrderItemFragment[]>(
    []
  );
  const [externalCouponsReport, setExternalCouponsReport] = useState<
    GroupedOrderItemFragment[]
  >([]);
  const [overallReport, setOverallReport] = useState<
    GroupedOrderItemFragment[]
  >([]);
  const [receipts, setReceipts] = useState<ReceiptFragment[]>([]);
  const [dateRange, setDateRange] = useState<{
    dateFrom: Date;
    dateTo: Date;
  }>({ dateFrom: new Date(), dateTo: new Date() });
  const [value, setValue] = useState<number>(0);
  const gastronomy = useRecoilValue(gastronomyState);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    calculateInitialDate(year, month)
  );
  const history = useHistory();
  const apolloClient = useApolloClient();

  useEffect(() => {
    resetData();
    fetchData();
    setDateRange({
      dateFrom: startOfMonth(selectedDate as Date),
      dateTo: endOfMonth(selectedDate as Date)
    });

    if (selectedDate) {
      const year = getYear(selectedDate);
      const month = getMonth(selectedDate) + 1;

      history.push({ pathname: `/report/${year}/${month}` });
    }

    // eslint-disable-next-line
  }, [gastronomy, selectedDate]);

  const resetData = () => {
    setFoodReport([]);
    setDrinkReport([]);
    setOtherReport([]);
    setOverallReport([]);
    setReceipts([]);
  };

  const fetchData = () => {
    fetchReport();
    fetchTabData(1);
    fetchTabData(2);
    fetchTabData(3);
    fetchTabData(4);
    fetchTabData(5);
    fetchTabData(6);
  };

  const fetchReport = async () => {
    if (gastronomy && gastronomy.uuid) {
      setIsReportLoading(true);
      try {
        const params: GastronomyReportSearchParams = {
          user_uuid: gastronomy.uuid,
          month: getMonth(selectedDate as Date) + 1,
          year: getYear(selectedDate as Date),
          succeededPaymentFilter: false
        };

        const { data } = await apolloClient.query({
          query: AdminGetGastronomyReportQueryDocument,
          variables: { params }
        });

        if (!data) {
          return;
        }

        setReport(data.adminGetGastronomyReport);
      } catch (error) {
        captureSilentError(error);
      }
      setIsReportLoading(false);
    }
  };

  const fetchReceipts = async (): Promise<ReceiptFragment[]> => {
    if (gastronomy) {
      const params: ReceiptSearchParams = {
        user_uuid: gastronomy.uuid,
        status: ['COMPLETED'],
        filterByServiceDate: true
      };

      let dateFrom = startOfMonth(selectedDate as Date);
      dateFrom = addDays(dateFrom, 1);
      dateFrom.setUTCHours(0, 0, 0, 0);

      const dateTo = endOfMonth(selectedDate as Date);
      dateTo.setUTCHours(23, 59, 59, 999);

      params.datetime_from = dateFrom.toISOString();
      params.datetime_to = dateTo.toISOString();

      const { data } = await apolloClient.query({
        query: GetReceiptsQueryDocument,
        variables: { params }
      });

      if (data) {
        return data.getReceipts;
      }
    }

    return [];
  };

  const fetchOrderReport = async (
    detailed: boolean,
    type?: OrderItemTypeFilter
  ) => {
    if (gastronomy) {
      try {
        const params: GroupedOrderItemSearchParams = {
          user_uuid: gastronomy.uuid,
          month: getMonth(selectedDate as Date) + 1,
          year: getYear(selectedDate as Date),
          groupFilter: detailed ? 'DETAILED' : 'TOTAL',
          succeededPaymentFilter: false
        };

        if (type) {
          params.typeFilter = type;
        }

        const { data } = await apolloClient.query({
          query: AdminGetGroupedOrderItemsQueryDocument,
          variables: { params }
        });

        if (!data) {
          throw new Error('API Fehler!');
        }

        return data.adminGetGroupedOrderItems;
      } catch (error) {
        captureSilentError(error);
      }
    }
    return [];
  };

  const fetchTabData = async (index: number) => {
    if (index === 1) {
      setIsFoodLoading(true);
      const food = await fetchOrderReport(false, 'FOOD');
      setFoodReport(food);
      setIsFoodLoading(false);
    } else if (index === 2) {
      setIsDrinksLoading(true);
      const drinks = await fetchOrderReport(false, 'DRINKS');
      setDrinkReport(drinks);
      setIsDrinksLoading(false);
    } else if (index === 3) {
      setIsOtherLoading(true);
      const other = await fetchOrderReport(false, 'OTHER');
      setOtherReport(other);
      setIsOtherLoading(false);
    } else if (index === 4) {
      setIsExternalCouponsLoading(true);
      const externalCoupons = await fetchOrderReport(false, 'EXTERNAL_COUPON');
      setExternalCouponsReport(externalCoupons);
      setIsExternalCouponsLoading(false);
    } else if (index === 5) {
      setIsOverallLoading(true);
      const overall = await fetchOrderReport(true);
      setOverallReport(overall);
      setIsOverallLoading(false);
    } else if (index === 6) {
      setIsReceiptsLoading(true);
      const receiptsResponse = await fetchReceipts();
      setReceipts(receiptsResponse);
      setIsReceiptsLoading(false);
    }
  };

  const handleChange = async (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderContainer title={'Monatsbericht'} />
      <Typography variant={'subtitle1'}>
        {dateRange.dateFrom.toLocaleDateString()} -{' '}
        {dateRange.dateTo.toLocaleDateString()}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.toolbarContainer}>
            <DatePicker
              views={['month', 'year']}
              label="Monat und Jahr"
              value={selectedDate}
              minDate={
                isAdmin ? new Date('1900-01-01') : new Date('2019-11-01')
              }
              minDateMessage="Es gibt keine Aufzeichnung zu diesem Datum"
              onChange={(date: MaterialUiPickersDate) => setSelectedDate(date)}
            />
            {gastronomy && (
              <>
                {report ? (
                  <Suspense fallback={<CircularIndeterminate />}>
                    <AdminReportExport
                      month={getMonth(selectedDate as Date) + 1}
                      year={getYear(selectedDate as Date)}
                      report={report}
                      foodReport={foodReport}
                      drinkReport={drinkReport}
                      otherReport={otherReport}
                      externalCouponsReport={externalCouponsReport}
                      overallReport={overallReport}
                      receipts={receipts}
                      gastronomy={gastronomy}
                      filterPayments={false}
                      disabled={
                        isFoodLoading ||
                        isDrinksLoading ||
                        isOtherLoading ||
                        isExternalCouponsLoading ||
                        isOverallLoading
                      }
                    />
                  </Suspense>
                ) : (
                  <Button variant="contained" color="default" disabled={true}>
                    CSV Herunterladen
                    <SaveAltRounded className={classes.rightIcon} />
                  </Button>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Übersicht" />
              <Tab label="Produkte Speisen" />
              <Tab label="Proukte Getränke" />
              <Tab label="Produkte Sonstige" />
              <Tab label="Fremdgutscheine" />
              <Tab label="Alle Produkte" />
              <Tab label="Bestellungen" />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {gastronomy && (
              <>
                {value === 0 && (
                  <>
                    {isReportLoading ? (
                      <CircularIndeterminate />
                    ) : (
                      <>{report && <ReportTable report={report} />}</>
                    )}
                  </>
                )}
                {value === 1 && (
                  <OrderReportTable
                    orderItems={foodReport}
                    isLoading={isFoodLoading}
                    showDiscounts={true}
                  />
                )}
                {value === 2 && (
                  <OrderReportTable
                    orderItems={drinkReport}
                    isLoading={isDrinksLoading}
                    showDiscounts={true}
                  />
                )}
                {value === 3 && (
                  <OrderReportTable
                    orderItems={otherReport}
                    isLoading={isOtherLoading}
                    showDiscounts={true}
                  />
                )}
                {value === 4 && (
                  <OrderReportTable
                    orderItems={externalCouponsReport}
                    isLoading={isExternalCouponsLoading}
                    showDiscounts={true}
                  />
                )}
                {value === 5 && (
                  <OrderReportTable
                    orderItems={overallReport}
                    isLoading={isOverallLoading}
                    showDiscounts={true}
                  />
                )}
                {value === 6 && (
                  <ReceiptReportTable
                    receipts={receipts}
                    isLoading={isReceiptsLoading}
                  />
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyReport;
