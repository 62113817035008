import { Elements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import BankAccountform from './BankAccountForm';
import * as stripejs from '@stripe/stripe-js';
import { useQuery } from '@apollo/client';
import { GetPaymentProviderTokenQueryDocument } from '../services/graphql/typed-operations';

interface Props {
  onClose: (success: boolean) => void;
  showCloseButton?: boolean;
}

const BankAccount: React.FC<Props> = ({ onClose, showCloseButton = false }) => {
  const [stripePromise, setStripePromise] = useState<
    Promise<stripejs.Stripe | null>
  >();

  useQuery(GetPaymentProviderTokenQueryDocument, {
    onCompleted: (data) => {
      if (data && data.getPaymentProviderToken) {
        const promise = stripejs.loadStripe(data.getPaymentProviderToken.token);
        setStripePromise(promise);
      }
    }
  });

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <BankAccountform
            onClose={onClose}
            showCloseButton={showCloseButton}
          />
        </Elements>
      )}
    </>
  );
};

export default BankAccount;
