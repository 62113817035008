import { useMemo } from 'react';
import { formatNumber } from './FormatHelper';
import getsby from '@getsby-devs/getsby-commons';
import {
  ReceiptData,
  ReceiptDataItem,
  ReceiptDataCredit,
  ReceiptDataModifierItem
} from 'models';

export const getReceiptDataFromReceipt = (
  receipt: FullReceiptFragment
): ReceiptData => {
  const groupedOrderItems = useMemo(() => {
    if (receipt) {
      return getsby.groupOrderItems<OrderItemModifierItem>(receipt.orderItems);
    }
    return [];
  }, [receipt]);
  let discountSum = 0;
  let totalSum = 0;
  let creditSum = 0;
  const tipSum = (receipt && receipt.tip_amount) || 0;
  const items: ReceiptDataItem[] = [];
  const credits: ReceiptDataCredit[] = [];

  groupedOrderItems.forEach((item) => {
    discountSum += item.discount;
    totalSum += !item.is_canceled
      ? item.amount * (item.price + item.discount)
      : 0;

    const title = item.productPrintTitle || item.productTitle;
    const modifiers: ReceiptDataModifierItem[] = [];

    if (item.modifierItems && item.modifierItems.length) {
      item.modifierItems.forEach((modifierItem) => {
        const existingItem = modifiers.find(
          (item) =>
            modifierItem.modifier &&
            item.modifier_uuid === modifierItem.modifier.uuid
        );

        if (existingItem) {
          existingItem.title = existingItem.title.concat(
            ...[', ', modifierItem.print_title || modifierItem.title]
          );
        } else {
          const title = modifierItem.modifier
            ? `${modifierItem.modifier.title}: `
            : '';

          modifiers.push({
            modifier_uuid: modifierItem.modifier
              ? modifierItem.modifier.uuid
              : undefined,
            title: title.concat(modifierItem.print_title || modifierItem.title)
          });
        }
      });
    }

    items.push({
      id: item.id,
      amount: item.amount.toString(),
      title,
      comment: item.comment || undefined,
      orderItemUuids: item.orderItemsUuids,
      is_canceled: item.is_canceled,
      is_negative: item.price < 0,
      price: formatNumber(
        (item.amount * (item.price + item.discount)) / 100,
        '€'
      ),
      productUuid: item.productUuid || undefined,
      productGroupTitle: item.productGroupTitle,
      modifiers
    });
  });

  if (receipt.receiptCredits) {
    receipt.receiptCredits.forEach((credit) => {
      if (credit.value) {
        creditSum += credit.value;

        credits.push({
          title: credit.campaign_coupon_code
            ? `Gutschein ${credit.campaign_coupon_code}`
            : 'Referral',
          amount: `-${formatNumber(credit.value / 100, '€')}`
        });
      }
    });
  }

  // const bonusAmount = receipt.redeemed_bonus_amount || 0;
  // const checkSum = totalSum + tipSum - creditSum - discountSum - bonusAmount;

  // if (receipt.total_price > 0 && receipt.total_price + tipSum !== checkSum) {
  //   throw new Error(
  //     `${Math.abs(
  //       receipt.total_price + tipSum - checkSum
  //     )} difference in receipt ${receipt.uuid}`
  //   );
  // }

  // if (
  //   receipt.total_price === 0 &&
  //   totalSum + tipSum - creditSum !== bonusAmount
  // ) {
  //   throw new Error(
  //     `${Math.abs(
  //       totalSum + tipSum - creditSum - bonusAmount
  //     )} difference in receipt ${receipt.uuid}`
  //   );
  // }

  return {
    items,
    credits,
    totalSum,
    discountSum,
    creditSum,
    tipSum
  };
};

export const calculateReceiptTotal = ({
  receiptSum = 0,
  discountSum = 0,
  creditSum = 0,
  tipSum = 0,
  bonusAmount = 0,
  substractCreditFromSum = false
}: {
  receiptSum?: number;
  discountSum?: number;
  creditSum?: number;
  tipSum?: number;
  bonusAmount?: number;
  substractCreditFromSum?: boolean;
}): number => {
  const total = receiptSum - discountSum + tipSum - bonusAmount;
  const returnValue = substractCreditFromSum ? total - creditSum : total;

  return returnValue < 0 ? 0 : returnValue;
};
