import { ApolloClient } from '@apollo/client';
import {
  Chip,
  colors,
  List,
  ListItem,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import FiberManualRecordRounded from '@material-ui/icons/FiberManualRecordRounded';
import { ProfileProgressStep, ProfileProgressType } from 'models';
import React from 'react';
import { CheckStripeAccountRequirementsQueryDocument } from '../services/graphql/typed-operations';
import { captureSilentError } from './ErrorHelper';
import { formatNumber } from './FormatHelper';
import { getOrderTypeLabel } from './OrderHelper';

export const getProfileProgress = async (
  apolloClient: ApolloClient<object>,
  gastronomy: FullGastronomyFragment | null | undefined
): Promise<ProfileProgressType> => {
  const response: ProfileProgressType = {
    progress: 0,
    due: [
      'MASTER_DATA',
      'GASTRONOMY_FEE',
      'CONTACT_CONFIG',
      'LOGO',
      'HEADER_IMAGE',
      'BANK_ACCOUNT',
      'STRIPE'
    ],
    stripeAccountRequirements: null
  };

  if (!gastronomy) {
    return response;
  }

  const checkStripeAccountRequirements = async (): Promise<StripeAccountRequirementsResponse | null> => {
    try {
      const { data } = await apolloClient.query({
        query: CheckStripeAccountRequirementsQueryDocument,
        variables: { params: { user_uuid: gastronomy.uuid } },
        fetchPolicy: 'network-only'
      });

      if (data) {
        const result = data.checkStripeAccountRequirements;

        return result;
      }
    } catch (error) {
      captureSilentError(error);
    }

    return null;
  };

  const stripeAccountRequirements = await checkStripeAccountRequirements();

  if (!stripeAccountRequirements) {
    return response;
  }

  const AMOUNT_OF_STEPS = 9;
  const due: ProfileProgressStep[] = [];

  const onlyBankAccountMissing = isOnlyBankAccountMissing(
    stripeAccountRequirements
  );

  if (
    !gastronomy.name ||
    !gastronomy.street ||
    !gastronomy.zip ||
    !gastronomy.city
  ) {
    due.push('MASTER_DATA');
  }

  if (!gastronomy.gastronomyFee) {
    due.push('GASTRONOMY_FEE');
  }

  if (!gastronomy.contactConfig || !gastronomy.contactConfig.contact_phone) {
    due.push('CONTACT_CONFIG');
  }

  if (!gastronomy.logo_image) {
    due.push('LOGO');
  }

  if (!gastronomy.header_image) {
    due.push('HEADER_IMAGE');
  }

  if (
    stripeAccountRequirements.status !== 'COMPLETED' &&
    stripeAccountRequirements.status !== 'PENDING_VERIFICATION' &&
    !onlyBankAccountMissing
  ) {
    due.push('STRIPE');
  }

  if (!stripeAccountRequirements.bankAccount) {
    due.push('BANK_ACCOUNT');
  }

  return {
    progress: Math.ceil(
      ((AMOUNT_OF_STEPS - due.length) / AMOUNT_OF_STEPS) * 100
    ),
    due,
    stripeAccountRequirements
  };
};

const getStatusLabel = (gastronomy: GastronomyFragment): string => {
  let status = 'Geschlossen';

  if (gastronomy.status === 'DISABLED') {
    status = 'Disabled';
  } else if (gastronomy.status === 'MENUONLY') {
    status = 'Speisekarte';
  } else if (gastronomy.isOpen && gastronomy.isActive) {
    status = 'Geöffnet';
  } else if (gastronomy.isOpen && !gastronomy.isActive) {
    status = 'Offline';
  }

  return status;
};

export const getTableTypeLabel = (tableType?: TableType): string => {
  switch (tableType) {
    case 'DINEIN':
      return 'Im Lokal';
    case 'TAKEAWAY':
      return 'Take-Away';
    case 'SELFSERVICE':
      return 'Selbstbedienung';
    case 'DELIVERY':
      return 'Lieferung';
    case 'RESERVATION':
      return 'Vorbestellen & Reservieren';
    case 'DRIVEIN':
      return 'Drive-In';
    default:
      return '';
  }
};

const getStatusColor = (gastronomy: GastronomyFragment): string => {
  let color: string = colors.red[500];

  if (gastronomy.isOpen && gastronomy.isActive) {
    color = colors.green[500];
  } else if (gastronomy.isOpen && !gastronomy.isActive) {
    color = colors.amber[500];
  }

  return color;
};

const getStatusIcon = (
  gastronomy: GastronomyFragment,
  styles?: React.CSSProperties
): JSX.Element => {
  const color: string = getStatusColor(gastronomy);

  return (
    <Tooltip title={getStatusLabel(gastronomy)} placement="right">
      <FiberManualRecordRounded style={{ ...styles, color }} />
    </Tooltip>
  );
};

const checkAllowCancellation = (
  gastronomy: FullGastronomyFragment | undefined
): React.ReactNode => {
  if (gastronomy) {
    if (gastronomy.posConfig && gastronomy.posConfig.config) {
      if (gastronomy.posConfig.config.allowCancellation === true) {
        return <DoneRounded />;
      }
    }
  }

  return <ClearRounded />;
};

const checkAutoAccept = (
  gastronomy: FullGastronomyFragment | undefined
): React.ReactNode => {
  if (gastronomy) {
    if (gastronomy.posConfig && gastronomy.posConfig.config) {
      if (gastronomy.posConfig.config.autoAccept === true) {
        return <DoneRounded />;
      }
    }
  }

  return <ClearRounded />;
};

const getPositiveOrNegativeChip = (
  value: boolean,
  labels: { positiveLabel: string; negativeLabel: string }
): React.ReactNode => {
  if (value) {
    return (
      <Chip
        icon={<DoneRounded style={{ color: '#ffffff' }} />}
        label={labels.positiveLabel}
        style={{ backgroundColor: colors.green[500], color: '#ffffff' }}
      />
    );
  }

  return (
    <Chip
      icon={<ClearRounded style={{ color: '#ffffff' }} />}
      label={labels.negativeLabel}
      style={{ backgroundColor: colors.red[500], color: '#ffffff' }}
    />
  );
};

const getTransactionCosts = (
  gastronomyFee: GastronomyFee | undefined
): string => {
  if (!gastronomyFee) {
    return '';
  }

  return ''.concat(
    'Tischbestellungen: ',
    formatNumber(gastronomyFee.pp_var, '%'),
    ' + ',
    formatNumber(gastronomyFee.pp_fix / 100, '€'),
    '\n',
    'Take-Away: ',
    formatNumber(gastronomyFee.pnp_var, '%'),
    ' + ',
    formatNumber(gastronomyFee.pnp_fix / 100, '€')
  );
};

const getGastronomyFeeModel = (
  gastronomyFee: GastronomyFee,
  asText = false
): React.ReactNode => {
  const getFeeText = (): string => {
    let primary = 'getsby Fix: '.concat(
      formatNumber(gastronomyFee.monthly_fix / 100, '€'),
      ', ',
      getTransactionCosts(gastronomyFee)
    );

    if (gastronomyFee.obono === true) {
      primary = primary.concat('; obono Lizenz');
    }

    return primary;
  };

  if (!asText) {
    return (
      <List dense>
        <ListItem>
          <ListItemText primary={getFeeText()} />
        </ListItem>
      </List>
    );
  }

  return (
    <>
      {getFeeText()}
      <br />
    </>
  );
};

const getFeatureChips = (gastronomy: GastronomyFragment): React.ReactNode => {
  if (gastronomy.status === 'MENUONLY') {
    return <Chip size="small" label={'Menu only'} style={{ margin: '8px' }} />;
  }
  return (
    <>
      {gastronomy.options.tableTypes &&
        gastronomy.options.tableTypes.map((t, i) => (
          <Chip
            key={i}
            size="small"
            label={getOrderTypeLabel(t)}
            style={{ margin: '8px' }}
          />
        ))}
      {gastronomy.has_cc_products && (
        <Chip
          size="small"
          label={'Coca Cola Meal Deal'}
          style={{ margin: '8px' }}
        />
      )}
    </>
  );
};

export const isPaymentMethodEnabled = (
  options: GastronomyOptions,
  types: PaymentMethodType[]
): boolean => {
  let hits = 1;

  if (
    options &&
    options.disabledPaymentTypes &&
    options.disabledPaymentTypes.length
  ) {
    const found = options.disabledPaymentTypes.find((item) =>
      types.includes(item.type)
    );

    if (found) {
      hits--;
    }
  }

  return !!hits;
};

export const getStripeAccountRequirementsStatusLabel = (
  status: StripeAccountRequirementsStatus,
  onlyBankAccountMissing?: boolean
): string => {
  if (onlyBankAccountMissing) {
    return 'Bankkonto fehlt';
  }

  switch (status) {
    case 'NO_ACCOUNT':
      return 'Profil nicht verifiziert';
    case 'ACTION_REQUIRED':
      return 'Aktion erforderlich';
    case 'PENDING_VERIFICATION':
      return 'Verfifizierung wird überprüft';
    case 'COMPLETED':
      return 'Profil verifiziert';
    default:
      return 'Profil nicht verifiziert';
  }
};

export const getStripeAccountRequirementsButtonLabel = (
  status: StripeAccountRequirementsStatus,
  onlyBankAccountMissing?: boolean
): string => {
  if (onlyBankAccountMissing) {
    return 'Profil aktualisieren';
  }

  switch (status) {
    case 'NO_ACCOUNT':
      return 'Profil verifizieren';
    case 'ACTION_REQUIRED':
      return 'Profil vervollständigen';
    case 'PENDING_VERIFICATION':
    case 'COMPLETED':
      return 'Profil aktualisieren';
    default:
      return 'Profil verifizieren';
  }
};

export const isOnlyBankAccountMissing = (
  stripeAccountRequirements: StripeAccountRequirementsResponse | null
): boolean => {
  if (!stripeAccountRequirements) {
    return false;
  }

  const requirements = stripeAccountRequirements.requirements;

  if (
    requirements &&
    ((requirements.currently_due &&
      requirements.currently_due.length === 1 &&
      requirements.currently_due.includes('external_account')) ||
      (requirements.eventually_due &&
        requirements.eventually_due.length === 1 &&
        requirements.eventually_due.includes('external_account')))
  ) {
    return true;
  }

  return false;
};

export {
  getStatusLabel,
  getStatusIcon,
  getStatusColor,
  checkAutoAccept,
  checkAllowCancellation,
  getPositiveOrNegativeChip,
  getTransactionCosts,
  getFeatureChips,
  getGastronomyFeeModel
};
