import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: theme.spacing(3),
      width: '100%',
      height: '100%'
    }
  })
);

interface Props {
  color?: 'primary' | 'secondary' | 'inherit';
}

const CircularIndeterminate: React.FC<Props> = ({ color }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color={color} />
    </div>
  );
};

export default CircularIndeterminate;
