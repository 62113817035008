import { useApolloClient } from '@apollo/client';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip
} from '@material-ui/core';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { UpdateProductGroupMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { hasPosSelector } from '../store/gastronomy';

interface Props {
  productGroup: FullProductGroupFragment;
}

const ProductGroupStatus: React.FC<Props> = ({ productGroup }) => {
  const [status, setStatus] = useState(
    productGroup.status === 'ACTIVE' ? true : false
  );
  const [statusOverride, setStatusOverride] = useState(
    productGroup.status === 'INACTIVE'
      ? false
      : productGroup.status_override === 'INACTIVE'
      ? false
      : true
  );
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const update = async ({
    newStatus,
    newStatusOverride
  }: {
    newStatus?: boolean;
    newStatusOverride?: boolean;
  }) => {
    try {
      const input: ProductGroupUpdateInput = {
        user_uuid: productGroup.user_uuid,
        uuid: productGroup.uuid
      };

      if (hasPos) {
        input.status_override = newStatusOverride ? 'ACTIVE' : 'INACTIVE';
      } else {
        input.status = newStatus ? 'ACTIVE' : 'INACTIVE';
      }

      const { data } = await apolloClient.mutate({
        mutation: UpdateProductGroupMutationDocument,
        variables: { input }
      });

      if (!data) {
        return;
      }

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Status erfolgreich aktualisiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const toggleStatus = async () => {
    const newStatus = !status;

    setStatus(newStatus);

    await update({ newStatus });
  };

  const toggleStatusOverride = async () => {
    const newStatusOverride = !statusOverride;

    setStatusOverride(newStatusOverride);

    await update({ newStatusOverride });
  };

  return (
    <Tooltip title="In der App anzeigen">
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={hasPos}
                checked={status}
                onChange={toggleStatus}
                color="primary"
              />
            }
            labelPlacement="start"
            label={hasPos ? 'POS Status' : 'Status'}
          />
          {hasPos && (
            <FormControlLabel
              control={
                <Switch
                  checked={statusOverride}
                  onChange={toggleStatusOverride}
                  color="primary"
                  disabled={!status}
                />
              }
              labelPlacement="start"
              label="Überschreiben"
            />
          )}
        </FormGroup>
      </FormControl>
    </Tooltip>
  );
};

export default ProductGroupStatus;
