import {
  Avatar,
  Badge,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Tooltip
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import StyleRounded from '@material-ui/icons/StyleRounded';
import React, { useState } from 'react';
import { formatNumber } from '../helpers/FormatHelper';
import ModifierDialog from './ModifierDialog';
import Picture from './Picture';
import ProductImageDialog from './ProductImageDialog';
import ProductStatus from './ProductStatus';
import ProductForm from './ProductForm';
import SimpleDialog from './SimpleDialog';
import TextPopper from './TextPopper';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState, hasPosOrWincaratSelector } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { DeleteProductMutationDocument } from '../services/graphql/typed-operations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: 10,
      height: 80,
      width: 80,
      backgroundColor: 'transparent'
    },
    button: {
      margin: theme.spacing(3)
    }
  })
);

interface Props {
  product: FullProductFragment;
}

const ProductLineItem: React.FC<Props> = ({ product }) => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPosOrWincarat = useRecoilValue(hasPosOrWincaratSelector);
  const [update, setUpdate] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [openModifierDialog, setOpenModifierDialog] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const deleteProduct = async (product: FullProductFragment) => {
    setOpenDeleteDialog(false);

    try {
      await apolloClient.mutate({
        mutation: DeleteProductMutationDocument,
        variables: { uuid: product.uuid },
        refetchQueries: ['getProductsQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Produkt ${product.title} erfolgreich gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const getProductTypeLabel = (type: ProductType) => {
    switch (type) {
      case 'VEGETARIAN':
        return <span style={{ color: green[500], fontWeight: 'bold' }}>V</span>;
      case 'VEGAN':
        return (
          <span style={{ color: green[500], fontWeight: 'bold' }}>V+</span>
        );
      default:
    }
  };

  return (
    <>
      {openModifierDialog && (
        <ModifierDialog
          onClose={() => setOpenModifierDialog(false)}
          product={product}
        />
      )}
      <SimpleDialog
        open={openDeleteDialog}
        title={`Produkt ${product.title} löschen`}
        text={'Möchten Sie dieses Produkt wirklich löschen?'}
        onPositiveClose={() => deleteProduct(product)}
        onNegativeClose={() => {
          setOpenDeleteDialog(false);
        }}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      {openImageDialog && (
        <ProductImageDialog
          onClose={() => {
            setOpenImageDialog(false);
          }}
          product={product}
        />
      )}
      {!update && (
        <TableRow key={product.uuid}>
          <TableCell>
            {product.image && (
              <Tooltip title="Produktbild bearbeiten">
                <Picture
                  imageSource={product.image}
                  classes={classes.avatar}
                  width={64}
                  devicePixelRatio={window.devicePixelRatio}
                  onClick={() => setOpenImageDialog(true)}
                />
              </Tooltip>
            )}
            {!product.image && (
              <Tooltip title="Produktbild hochladen">
                <Avatar
                  onClick={() => setOpenImageDialog(true)}
                  className={classes.avatar}
                >
                  <img
                    src={process.env.PUBLIC_URL + '/img/UndrawPhoto.svg'}
                    style={{ maxHeight: 40, width: 'auto' }}
                    alt="Placeholder"
                  />
                </Avatar>
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            {hasPosOrWincarat && product.alias
              ? `${product.alias} (${product.title})`
              : product.title}{' '}
            {getProductTypeLabel(product.type)}
            <br />
            <em>
              {product.description}{' '}
              {product.allergenList && product.allergenList.length > 0 && (
                <span> ({product.allergenList.join(', ')})</span>
              )}
            </em>
          </TableCell>
          <TableCell>
            {product.modifier && (
              <List dense>
                {product.modifier.map((modifier) => (
                  <ListItem key={modifier.uuid}>
                    <ListItemText
                      primary={`${modifier.title} ${
                        modifier.admin_title
                          ? '(' + modifier.admin_title + ')'
                          : ''
                      }`}
                      secondary={`Min: ${modifier.min}, Max: ${modifier.max}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </TableCell>
          <TableCell>{product.tax} %</TableCell>
          <TableCell>
            {!!product.discount && (
              <>
                <del style={{ color: red[500] }}>
                  {formatNumber(
                    (product.gross_price + product.discount) / 100,
                    '€'
                  )}
                </del>
                <br />
              </>
            )}
            {formatNumber(product.gross_price / 100, '€')}
          </TableCell>
          <TableCell padding="checkbox">
            {gastronomy && (
              <ProductStatus product={product} gastronomy={gastronomy} />
            )}
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            <TextPopper
              text={product.uuid}
              textDescription="UUID"
              copy={true}
            />
            <Tooltip title="Optionsgruppen">
              <IconButton
                aria-label="modifier"
                onClick={() => setOpenModifierDialog(true)}
              >
                <Badge
                  color="primary"
                  badgeContent={product.modifier ? product.modifier.length : 0}
                >
                  <StyleRounded />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Bearbeiten">
              <IconButton onClick={() => setUpdate(true)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen">
              <span>
                <IconButton
                  disabled={hasPosOrWincarat}
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
      {update && product.product_group_uuid && (
        <TableRow>
          <TableCell colSpan={7}>
            <ProductForm
              product={product}
              productGroupUuid={product.product_group_uuid}
              onClose={() => setUpdate(false)}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ProductLineItem;
