import { useEffect, useMemo, useState } from 'react';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../helpers/ErrorHelper';
import Config from '../config';
import { getShortUuid } from '../helpers/UUIDShortener';
import usePrevious from './usePrevious';
import { isEqual } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { snackbarMessagesState } from '../store/app';
import { gastronomyState, tablesState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { GetTablesQueryDocument } from '../services/graphql/typed-operations';

interface Hook {
  isLoading: boolean;
  downloadPdf: () => void;
}

const useOrderTypes = (tableTypes: TableType[] = []): Hook => {
  const types = useMemo(() => tableTypes, [tableTypes]);
  const previousTypes = usePrevious(types);
  const [tables, setTables] = useRecoilState(tablesState);
  const gastronomy = useRecoilValue(gastronomyState);
  const previousGastronomy = usePrevious(gastronomy);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchTables = async () => {
      if (gastronomy) {
        setIsLoading(true);
        try {
          const params: TableSearchParams = {
            user_uuid: gastronomy.uuid,
            status: 'ALL',
            table_types: types
          };

          const { data } = await apolloClient.query({
            query: GetTablesQueryDocument,
            variables: { params }
          });

          if (!data) {
            throw new Error('API Fehler!');
          }

          console.debug('Tables', data.getTables);

          setTables(data.getTables);
        } catch (error) {
          captureSilentError(error);
        }
        setIsLoading(false);
      }
    };

    if (
      !isEqual(types, previousTypes) ||
      !isEqual(gastronomy, previousGastronomy)
    ) {
      fetchTables();
    }
  }, [gastronomy, types]);

  const downloadPdf = async (): Promise<void> => {
    if (gastronomy) {
      try {
        const records = [];

        for (const table of tables) {
          const qrCode = await QRCode.toDataURL(
            `${Config.webAppUrl}?t=${getShortUuid(table.uuid)}`
          );

          records.push({ table_number: table.table_number, qrCode });
        }

        const body = { tables: records };
        const res = await fetch(`https://pdfservice.gets.by/genpdf/`, {
          method: 'post',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' }
        });
        if (!res.ok) {
          throw Error('Request failed with server error status');
        }
        const data = await res.blob();
        await saveAs(data, 'QR-Codes.pdf');
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return {
    isLoading,
    downloadPdf
  };
};

export default useOrderTypes;
