import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';
import { SnackbarMessage } from 'models';
import { SetterOrUpdater } from 'recoil';
import { addSnackbarMessages } from '../store/app';

const setErrorSnackbarMessage = (
  error: any,
  setSnackbarMessages: SetterOrUpdater<SnackbarMessage[]>
): void => {
  captureSilentError(error);

  if (error && error.errors) {
    const errors = error.errors as GraphQLError[];

    addSnackbarMessages(
      errors.map((error) => {
        return {
          type: 'error',
          message:
            error.extensions &&
            error.extensions.code !== 'INTERNAL_SERVER_ERROR'
              ? error.message
              : 'Fehler'
        };
      }),
      setSnackbarMessages
    );
  } else if (error) {
    addSnackbarMessages(
      [
        {
          type: 'error',
          message: error.message || 'Fehler'
        }
      ],
      setSnackbarMessages
    );
  } else {
    addSnackbarMessages(
      [
        {
          type: 'error',
          message: 'Fehler'
        }
      ],
      setSnackbarMessages
    );
  }
};

const captureSilentError = (error: any): void => {
  console.log('error', error);

  if (error && error.errors && Array.isArray(error.errors)) {
    const errors = error.errors as GraphQLError[];

    for (const e of errors) {
      if (
        e.extensions &&
        e.extensions.exception &&
        e.extensions.exception.stacktrace
      ) {
        Sentry.captureException(e.extensions.exception.stacktrace);
      }
    }
  } else if (error) {
    Sentry.captureException(error);
  }
};

export { setErrorSnackbarMessage, captureSilentError };
