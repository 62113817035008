import { useApolloClient } from '@apollo/client';
import {
  Grid,
  makeStyles,
  TextField,
  Theme,
  createStyles,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { ClearRounded, DoneRounded } from '@material-ui/icons';
import Joi from 'joi';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { CreateCertificateDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';

interface Props {
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    list: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    divider: {
      width: '100%',
      margin: theme.spacing(2, 0)
    }
  })
);

const schemaValues = Joi.object({
  common_name: Joi.string().domain(),
  alt_names: Joi.array().items(Joi.string().domain()),
  is_getsby_domain: Joi.boolean(),
  generate_vhost_config: Joi.boolean()
});

const CertificateForm: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [commonName, setCommonName] = useState('');
  const [altNames, setAltNames] = useState('');
  const [isGetsbyDomain, setIsGetsbyDomain] = useState(false);
  const [generateVhostConfig, setGenerateVhostConfig] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation errror', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const save = async () => {
    setErrors([]);
    setLoading(true);
    try {
      const alt_names = altNames
        .split(',')
        .map((altName) => altName.trim())
        .filter((altName) => altName.length);
      const input = {
        common_name: commonName,
        alt_names,
        is_getsby_domain: isGetsbyDomain,
        generate_vhost_config: generateVhostConfig
      };
      const result = schemaValues.validate(input);
      if (!processJoiResult(result)) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );
        setLoading(false);
        return;
      }
      const { errors } = await apolloClient.mutate({
        mutation: CreateCertificateDocument,
        variables: {
          input
        },
        refetchQueries: ['getCertificates']
      });
      if (errors) {
        addSnackbarMessages(
          errors.map((error) => ({
            type: 'error',
            message: error.message
          })),
          setSnackbarMessages
        );
      } else {
        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Zertifikat hinzugefügt`
            }
          ],
          setSnackbarMessages
        );
      }

      onClose();
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
    setLoading(false);
  };

  return (
    <>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item sm={3} xs={12}>
            <TextField
              error={errors.includes('common_name')}
              label="Common Name (primary domain)"
              className={classes.input}
              onChange={(evt) => setCommonName(evt.target.value)}
              value={commonName}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              error={errors.includes('alt_names')}
              label="Alternative Names (additional domains)"
              className={classes.input}
              onChange={(evt) => setAltNames(evt.target.value)}
              value={altNames}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGetsbyDomain}
                  onChange={(evt) =>
                    setIsGetsbyDomain(evt.currentTarget.checked)
                  }
                  value={isGetsbyDomain}
                  color="primary"
                />
              }
              className={classes.input}
              label="Ist eine primare Getsby Domain?"
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={generateVhostConfig}
                  onChange={(evt) =>
                    setGenerateVhostConfig(evt.currentTarget.checked)
                  }
                  value={isGetsbyDomain}
                  color="primary"
                />
              }
              className={classes.input}
              label="VHost Config erstellen (standard)"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Abbrechen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              className={classes.input}
              variant="contained"
              color="primary"
              onClick={save}
              disabled={loading}
            >
              Zertifikat erstellen
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CertificateForm;
