import { Paper } from '@material-ui/core';
import { CsvBuilder } from 'filefy';
import { cloneDeep } from 'lodash';
import MaterialTable, { Column, Options } from 'material-table';
import React from 'react';
import { formatNumber } from '../helpers/FormatHelper';

interface Props {
  orderItems: GroupedOrderItemFragment[];
  isLoading: boolean;
  renderPaper?: boolean;
  options?: Options<GroupedOrderItemFragment>;
  showDiscounts?: boolean;
}

const OrderReportTable: React.FC<Props> = ({
  orderItems = [],
  isLoading,
  showDiscounts = false,
  renderPaper = true,
  options
}) => {
  const data = cloneDeep(orderItems);

  const columns: Array<Column<GroupedOrderItemFragment>> = [
    {
      title: 'Datum',
      field: 'date',
      type: 'datetime',
      defaultSort: 'asc',
      hidden: orderItems.length && orderItems[0].date ? false : true,
      render: (rowData) =>
        rowData.date ? new Date(rowData.date).toLocaleString() : ''
    },
    { title: 'Anzahl', field: 'amount', defaultSort: 'desc' },
    {
      title: 'Produkt',
      field: 'product',
      render: (rowData) => {
        const productTitle = rowData.product_print_title || rowData.product;
        const modifierTitle = rowData.modifier_print || rowData.modifier;

        return rowData.modifier
          ? `${productTitle} (${modifierTitle})`
          : productTitle;
      }
    },
    {
      title: 'Produktgruppe',
      field: 'productGroup'
    },
    {
      title: 'Steuersatz',
      field: 'tax',
      render: (rowData) => rowData.tax + ' %'
    },
    {
      title: 'Summe',
      field: 'total',
      type: 'numeric',
      render: (rowData) => formatNumber((rowData.total || 0) / 100, '€')
    },
    {
      title: 'getsby Aktion',
      field: 'discount',
      type: 'numeric',
      hidden: !showDiscounts,
      render: (rowData) =>
        rowData.discount ? formatNumber(rowData.discount / 100, '€') : ''
    }
  ];

  const exportCsv = (columns: any[], renderData: any[]) => {
    const csvColumns = columns.filter((columnDef) => {
      return !columnDef.hidden && columnDef.field && columnDef.export !== false;
    });

    const numberFields = ['total', 'discount'];

    const data = renderData.map((rowData) =>
      csvColumns.map((columnDef) =>
        numberFields.includes(columnDef.field)
          ? formatNumber(rowData[columnDef.field] / 100)
          : rowData[columnDef.field]
      )
    );

    new CsvBuilder('getsby_export_tagesumsatz.csv')
      .setDelimeter(';')
      .setColumns(csvColumns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile();
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        columns={columns}
        data={data}
        localization={{
          toolbar: { searchTooltip: 'Suchen', searchPlaceholder: 'Suchen' },
          pagination: {
            labelRowsSelect: 'Zeilen',
            firstTooltip: 'Erste Seite',
            previousTooltip: 'Vorherige Seite',
            nextTooltip: 'Nächste Seite',
            lastTooltip: 'Letzte Seite'
          }
        }}
        options={{
          showTitle: false,
          showEmptyDataSourceMessage: false,
          sorting: true,
          pageSizeOptions: [10, 20, 50, 100],
          pageSize: 50,
          draggable: false,
          padding: 'dense',
          exportDelimiter: ';',
          emptyRowsWhenPaging: false,
          exportCsv,
          ...options
        }}
        isLoading={isLoading}
        components={{
          Container: (props) =>
            renderPaper ? (
              <Paper>{props.children}</Paper>
            ) : (
              <>{props.children}</>
            )
        }}
      />
    </div>
  );
};

export default OrderReportTable;
