import {
  createStyles,
  Divider,
  Fab,
  Grid,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AvailabilityTimesKeyDays } from 'models';
import React, { useState } from 'react';
import AvailabilityTimeLineItem from './AvailabilityTimeLineItem';
import { getDayOfWeekLabel } from '../helpers/FormatHelper';
import AvailabilityTimeTimesForm from './AvailabilityTimeTimesForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3)
    },
    button: {
      margin: theme.spacing(1)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    padding: {
      padding: theme.spacing(2)
    },
    margin: {
      margin: theme.spacing(2)
    },
    container: {
      width: '100%',
      justifyContent: 'center',
      margin: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  type: AvailabilityTimesKeyDays;
  availabilityTimes?: AvailabilityTimeElement[] | null;
  onChange: (
    type: AvailabilityTimesKeyDays,
    availabilityTimes: AvailabilityTimeElement[]
  ) => void;
  showLimit: boolean;
}

const AvailabilityTimesTable: React.FC<Props> = ({
  type,
  availabilityTimes = [],
  onChange,
  showLimit
}) => {
  const classes = useStyles();
  const [
    showAvailabilityTimeAddForm,
    setShowAvailabilityTimeAddForm
  ] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" className={classes.padding}>
        <Grid item>
          <Typography variant={'h6'}>{getDayOfWeekLabel(type)}</Typography>
        </Grid>
        {!showAvailabilityTimeAddForm && (
          <Grid item>
            <Fab
              color="primary"
              aria-label="add"
              size="medium"
              onClick={() => setShowAvailabilityTimeAddForm(true)}
            >
              <AddIcon />
            </Fab>
          </Grid>
        )}
      </Grid>
      <List>
        {Array.isArray(availabilityTimes) &&
          availabilityTimes.map((availabilityTime, index) => (
            <AvailabilityTimeLineItem
              key={index}
              availabilityTime={availabilityTime}
              onDelete={() => {
                onChange(type, [
                  ...availabilityTimes.slice(0, index),
                  ...availabilityTimes.slice(index + 1)
                ]);
              }}
              onUpdate={(availabilityTime) => {
                onChange(
                  type,
                  availabilityTimes.map((t, i) =>
                    i === index ? availabilityTime : t
                  )
                );
              }}
              showLimit={showLimit}
            />
          ))}
      </List>
      {showAvailabilityTimeAddForm && (
        <div className={classes.container}>
          <AvailabilityTimeTimesForm
            onClose={() => setShowAvailabilityTimeAddForm(false)}
            onSave={(availabilityTime) => {
              const avTimes = availabilityTimes || [];
              onChange(type, [...avTimes, availabilityTime]);
              setShowAvailabilityTimeAddForm(false);
            }}
            showLimit={showLimit}
          />
        </div>
      )}
      <Divider />
    </div>
  );
};

export default AvailabilityTimesTable;
