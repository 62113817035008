import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import React from 'react';
import HeaderContainer from '../../components/HeaderContainer';
import OrderTypeSettingsList from '../../components/OrderTypeSettingsList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    lineItem: {
      padding: theme.spacing(3)
    }
  })
);

const GastronomyOrderTypeSettings: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <HeaderContainer title={'Bestellarten'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <OrderTypeSettingsList />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyOrderTypeSettings;
