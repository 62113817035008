import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import {
  ClearRounded,
  DeleteRounded,
  DoneRounded,
  EditRounded
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import { removeFromS3, uploadImageToS3 } from '../helpers/S3helper';
import {
  AddCategoryDocument,
  GetProductsForAutocompleteQueryDocument,
  UpdateCategoryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import PickImageDialog from './PickImageDialog';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      margin: theme.spacing(1, 0),
      width: '100%'
    },
    radioButton: {
      margin: theme.spacing(1)
    }
  })
);

interface Props {
  category?: Category;
  onClose: () => void;
  user_uuid: string;
}

const CategoryForm: React.FC<Props> = ({ category, onClose, user_uuid }) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const [
    featuredProduct,
    setFeaturedProduct
  ] = useState<ProductForAutocomplete | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);
  const [deleteIcon, setDeleteIcon] = useState<boolean>(false);
  const [iconFile, setIconFile] = useState<File>();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);

  const { data } = useQuery(GetProductsForAutocompleteQueryDocument, {
    variables: { params: { user_uuid, status: 'ALL' } },
    onCompleted: (data) => {
      const featured = data.getProducts.find(
        (prod) => prod?.uuid === category?.featured_product_uuid
      );
      setFeaturedProduct(featured || null);
    }
  });

  const initialValues = {
    name: category?.name || '',
    icon: category?.icon
  };

  const [values, setValues] = useState<{
    name: string;
    icon?: string | null;
  }>(initialValues);

  const handleChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const saveCategory = async () => {
    if (values.name.length < 2) {
      setErrors(['name']);
    }
    let iconPath = null;
    if (iconFile) {
      const imageUrl = await uploadImageToS3(
        iconFile,
        category?.uuid || uuidv4(),
        'icon'
      );
      iconPath = imageUrl;
    }
    if (deleteIcon && category?.icon) {
      const result = await removeFromS3(category.icon);
      if (result) {
        iconPath = null;
      }
    }
    if (category) {
      await apolloClient.mutate({
        mutation: UpdateCategoryDocument,
        variables: {
          input: {
            uuid: category.uuid,
            name: values.name,
            icon: deleteIcon ? null : iconFile ? iconPath : values.icon,
            featured_product_uuid: featuredProduct?.uuid || null
          }
        }
      });
    } else {
      await apolloClient.mutate({
        mutation: AddCategoryDocument,
        variables: {
          input: {
            user_uuid,
            name: values.name,
            icon: iconPath,
            featured_product_uuid: featuredProduct?.uuid
          }
        },
        refetchQueries: ['getCategories']
      });
    }
    addSnackbarMessages(
      [
        {
          type: 'success',
          message: `Kategorie ${values.name} ${
            category ? 'aktualisiert' : 'hinzugefügt'
          }`
        }
      ],
      setSnackbarMessages
    );
    onClose();
  };

  const handleIconSave = (file: File) => {
    setIconFile(file || null);
    setDeleteIcon(false);
    setShowImageDialog(false);
  };

  const handleIconDelete = () => {
    setDeleteIcon(true);
    setShowImageDialog(false);
  };

  return (
    <div className={classes.root}>
      <PickImageDialog
        title="Bonuspunkte Icon"
        isOpen={showImageDialog}
        imageUrl={values.icon || undefined}
        onClose={() => setShowImageDialog(false)}
        onSave={handleIconSave}
        onDelete={handleIconDelete}
      />
      <div className={classes.heading}>
        <Typography variant={'h5'} className={classes.padding}>
          Kategorie {category ? 'bearbeiten' : 'hinzufügen'}
        </Typography>
      </div>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              error={errors.includes('name')}
              id="name"
              label="Name"
              placeholder="Lunchdeals"
              className={classes.input}
              required={true}
              value={values.name}
              onChange={handleChange('name')}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Icon</Typography>
            <Typography color={'textSecondary'}>{values.icon}</Typography>
            <IconButton edge="end" onClick={() => setShowImageDialog(true)}>
              <EditRounded />
            </IconButton>
            <IconButton edge="end" onClick={handleIconDelete}>
              <DeleteRounded />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="category-product"
              options={data ? data.getProducts : []}
              getOptionLabel={(product) =>
                `${product?.title} (${formatNumber(
                  (product?.gross_price || 0) / 100,
                  '€'
                )})${product.status === 'INACTIVE' ? ' inaktiv!' : ''}`
              }
              className={classes.input}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Produkt verlinken"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={featuredProduct}
              onChange={(event, newValue) => {
                setFeaturedProduct(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={saveCategory}
              className={classes.input}
              variant="contained"
              color="primary"
            >
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CategoryForm;
