import React, { useState, useEffect, useRef, RefObject } from 'react';
import Config from '../config';
import { loadScript } from '../helpers/scriptLoader';

let autoComplete: google.maps.places.Autocomplete;

function handleScriptLoad(
  updateQuery: (result: google.maps.places.PlaceResult) => void,
  autoCompleteRef: RefObject<HTMLInputElement>
) {
  if (autoCompleteRef.current) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ['establishment'],
        componentRestrictions: { country: ['at', 'de'] },
        fields: [
          'geometry.location',
          'address_components',
          'geometry',
          'international_phone_number',
          'name',
          'opening_hours',
          'place_id',
          'types',
          'website'
        ]
      }
    );
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }
}

async function handlePlaceSelect(
  updateQuery: (result: google.maps.places.PlaceResult) => void
) {
  const geoObject = autoComplete.getPlace();
  console.log('geoObject', geoObject);
  if (geoObject) {
    updateQuery(geoObject);
  }
}

interface Props {
  placeResult?: google.maps.places.PlaceResult;
  setPlaceResult: (result: google.maps.places.PlaceResult) => void;
}

const LocationAutocomplete: React.FC<Props> = ({
  placeResult,
  setPlaceResult
}: Props) => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${Config.gmaps_key}&libraries=places`,
      () => handleScriptLoad(setPlaceResult, autoCompleteRef)
    );
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  useEffect(() => {
    console.log('location result', placeResult);
    if (placeResult) {
      if (placeResult.formatted_address) {
        setQuery(placeResult.formatted_address);
      }
      if (placeResult.geometry && placeResult.geometry.location) {
        // setLocationState({
        //   lat: placeResult.geometry.location.lat(),
        //   lng: placeResult.geometry.location.lng(),
        //   formatted_address: placeResult.formatted_address
        // });
      }
    }
  }, [placeResult]);

  return (
    <div className="flex items-center justify-between w-full h-10 pl-3 pr-2 my-4 bg-white border rounded md:max-w-lg">
      <input
        className="w-full outline-none appearance-none focus:outline-none active:outline-none"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Wirtshaus zur Post"
        value={query}
        style={{ width: '100%' }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
      {/* not possible to re-request permission 
      <button
        onClick={() => {
          askForPermission();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
        </svg>
      </button>
      */}
    </div>
  );
};

export default LocationAutocomplete;
