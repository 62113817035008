import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { LinkRounded, OpenInNewRounded } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getPositiveOrNegativeChip } from '../helpers/GastronomyHelper';
import {
  AddKassaMutationDocument,
  GetKassaCustomerQueryDocument,
  UpdateGastronomyMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import SimpleDialog from './SimpleDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    },
    link: {
      margin: theme.spacing(2, 0),
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
);

const GetsbyKassa: React.FC = () => {
  const classes = useStyles();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [openAddKassaDialog, setOpenAddKassaDialog] = useState<boolean>(false);
  const [openOptOutDialog, setOpenOptOutDialog] = useState<boolean>(false);
  const apolloClient = useApolloClient();
  const isOptOut = useMemo(() => {
    if (gastronomy && gastronomy.options) {
      return !!gastronomy.options.getsbyKassaOptOut;
    }
    return false;
  }, [gastronomy]);

  const { data: kassaCustomerData } = useQuery(GetKassaCustomerQueryDocument, {
    variables: { user_uuid: gastronomy?.uuid || 'NOT SET' },
    skip: !gastronomy
  });

  const addKassa = async () => {
    if (gastronomy) {
      try {
        await apolloClient.mutate({
          mutation: AddKassaMutationDocument,
          variables: {
            input: {
              user_uuid: gastronomy.uuid,
              wants_signature: false
            }
          },
          refetchQueries: ['getKassaCustomerQuery']
        });

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `getsby Kassa aktiviert!`
            }
          ],
          setSnackbarMessages
        );

        setOpenAddKassaDialog(false);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const optOutFromKassa = async () => {
    if (gastronomy) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              posConfig: { pos_type: 'GETSBY' },
              options: {
                getsbyKassaOptOut: true
              }
            }
          },
          refetchQueries: ['getKassaCustomerQuery']
        });

        if (data) {
          const newGastronomy = data.updateGastronomy;

          setGastronomy(newGastronomy);

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `getsby Kassa deaktiviert!`
              }
            ],
            setSnackbarMessages
          );
        }

        setOpenOptOutDialog(false);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <>
      <SimpleDialog
        open={openAddKassaDialog}
        title={`getsby Kassa`}
        text={'Möchten Sie die getsby Kassa aktivieren?'}
        onPositiveClose={() => addKassa()}
        onNegativeClose={() => setOpenAddKassaDialog(false)}
        buttonPositiveLabel={'Aktivieren'}
        buttonNegativeLabel={'Zurück'}
      />
      <SimpleDialog
        open={openOptOutDialog}
        title={`getsby Kassa`}
        text={
          'Sind Sie sicher, dass Sie die getsby Kasse deaktivieren möchten?'
        }
        onPositiveClose={() => optOutFromKassa()}
        onNegativeClose={() => setOpenOptOutDialog(false)}
        buttonPositiveLabel={'Deaktivieren'}
        buttonNegativeLabel={'Zurück'}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <ListItem divider>
              <ListItemText primary="Status" />
              <ListItemSecondaryAction>
                {getPositiveOrNegativeChip(
                  (!!kassaCustomerData && !isOptOut) || false,
                  {
                    positiveLabel: 'Aktiviert',
                    negativeLabel: 'Nicht aktiviert'
                  }
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
              <ListItemText primary="Beleg-Signatur" />
              <ListItemSecondaryAction>
                {getPositiveOrNegativeChip(
                  kassaCustomerData && !isOptOut
                    ? kassaCustomerData.getKassaCustomer.wants_signature
                    : false,
                  {
                    positiveLabel: 'Aktiviert',
                    negativeLabel: 'Nicht aktiviert'
                  }
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          {!!kassaCustomerData && !isOptOut ? (
            <>
              <Button
                className={classes.input}
                href={kassaCustomerData.getKassaCustomer.login_url}
                target="_blank"
                variant="outlined"
              >
                getsby Kassa
                <OpenInNewRounded className={classes.rightIcon} />
              </Button>
              <Typography
                variant="body2"
                className={classes.link}
                onClick={() => setOpenOptOutDialog(true)}
              >
                getsby Kassa deaktivieren
              </Typography>
            </>
          ) : (
            <Button
              onClick={() => setOpenAddKassaDialog(true)}
              className={classes.input}
              variant="outlined"
            >
              getsby Kassa aktivieren
              <LinkRounded className={classes.rightIcon} />
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GetsbyKassa;
