import { colors } from '@material-ui/core';
import { format } from 'date-fns';
import { GroupedTip } from 'models';

function filterTipsByDate({
  tips = [],
  date
}: {
  tips: TipFragment[];
  date?: string;
}): TipFragment[] {
  if (!date) {
    return tips;
  }
  return tips.filter(
    (tip) => format(new Date(tip.created_at), 'dd.MM.yyyy') === date
  );
}

function findExistingTipItems(
  groupedTips: GroupedTip[],
  tip: TipFragment,
  groupByTable = false
): GroupedTip[] {
  return groupedTips.filter((item) => {
    const tipDate = format(new Date(tip.created_at), 'dd.MM.yyyy');

    if (item.date === tipDate) {
      if (groupByTable && item.table && tip.table) {
        return item.table.uuid === tip.table.uuid ? true : false;
      }

      return true;
    }

    return false;
  });
}

export function getGroupedTips(
  tips: TipFragment[] = [],
  groupByTable = false,
  date?: string
): GroupedTip[] {
  const groupedTips: GroupedTip[] = [];

  tips = filterTipsByDate({ tips, date });

  for (const tip of tips) {
    const existingItems = findExistingTipItems(groupedTips, tip, groupByTable);

    if (existingItems === null || existingItems.length === 0) {
      groupedTips.push({
        date: format(new Date(tip.created_at), 'dd.MM.yyyy'),
        amount: tip.amount,
        table: groupByTable && tip.table ? tip.table : null,
        count: 1,
        tips: [
          {
            created_at: tip.created_at,
            table_number: tip.table?.table_number,
            table_type: tip.table?.table_type_override || tip.table?.table_type,
            amount: tip.amount
          }
        ]
      });
    } else {
      const item = existingItems[0];
      item.amount += tip.amount;
      item.count++;
      item.tips.push({
        created_at: tip.created_at,
        table_number: tip.table?.table_number,
        table_type: tip.table?.table_type_override || tip.table?.table_type,
        amount: tip.amount
      });
    }
  }

  return groupedTips;
}

export function getStatusColor(status?: ReceiptStatus | null): string {
  switch (status) {
    case 'PENDING_RECEIVED':
      return colors.amber[300];
    case 'RECEIVED':
      return colors.amber[500];
    case 'PENDING_ACCEPTED':
      return colors.purple[300];
    case 'ACCEPTED':
      return colors.purple[500];
    case 'PENDING_READY':
      return colors.blue[300];
    case 'READY':
      return colors.blue[500];
    case 'PENDING_COMPLETED':
      return colors.green[300];
    case 'COMPLETED':
      return colors.green[500];
    case 'PENDING_ERROR':
      return colors.red[500];
    case 'ERROR':
      return colors.red[500];
    default:
      return colors.grey[500];
  }
}

export function getReceiptStatusLabel(status?: ReceiptStatus | null): string {
  if (!status) {
    return 'Unbekannt';
  }

  switch (status) {
    case 'PENDING_RECEIVED':
      return 'Wird übermittelt...';
    case 'RECEIVED':
      return 'Warten auf Bestätigung';
    case 'PENDING_ACCEPTED':
      return 'Wird bestätigt...';
    case 'ACCEPTED':
      return 'Bestätigt';
    case 'PENDING_READY':
      return 'Wird bereit...';
    case 'READY':
      return 'Bereit';
    case 'PENDING_COMPLETED':
      return 'Wird abgeschlossen...';
    case 'COMPLETED':
      return 'Abgeschlossen';
    case 'PENDING_ERROR':
      return 'Wird storniert...';
    case 'ERROR':
      return 'Storniert';
    default:
      return status as string;
  }
}

export function getReceiptPaymentLabel(status: PaymentStatus): string {
  switch (status) {
    case 'OPEN':
      return 'Offen';
    case 'FAILED':
      return 'Fehlgeschlagen';
    case 'PENDING':
      return 'In Bearbeitung';
    case 'SUCCEEDED':
      return 'Abgeschlossen';
    case 'REFUNDED':
      return 'Zurückerstattet';
    default:
      return status as string;
  }
}

export function getOrderTypeLabel(
  type?: Maybe<TableType>,
  override?: Maybe<TableType>
): string {
  if (!type) {
    return '';
  }
  switch (override || type) {
    case 'DINEIN':
      return 'Tischservice';
    case 'TAKEAWAY':
      return 'Take-Away';
    case 'SELFSERVICE':
      return 'Selbstbedienung';
    case 'DRIVEIN':
      return 'Drive-In';
    case 'RESERVATION':
      return 'Vorbestellen & Reservieren';
    case 'DELIVERY':
      return 'Lieferung';
    default:
      return type as string;
  }
}

export function translateReceiptError(receipt: FullReceiptFragment): string {
  let message = '';

  if (receipt.error && receipt.error.code) {
    switch (receipt.error.code) {
      case 'OUT_OF_STOCK':
        if (!receipt.orderItems) {
          message = 'Storniert';
          break;
        }

        if (receipt.error.productUuids) {
          const productUuids = receipt.error.productUuids as string[];
          if (productUuids.length === 1) {
            const orderItem = receipt.orderItems.find(
              (value) => value.product && value.product_uuid === productUuids[0]
            );

            if (orderItem && orderItem.product) {
              if (orderItem.product_title) {
                message = orderItem.product_title + ' ist nicht mehr verfügbar';
              }
            } else {
              message = 'Produkt ist nicht mehr verfügbar';
            }
          } else {
            let productNames = '';

            for (let i = 0; i < productUuids.length; i++) {
              const orderItem = receipt.orderItems.find(
                (value) =>
                  value.product && value.product_uuid === productUuids[i]
              );

              let suffix = ', ';

              if (productUuids.length - 1 === i + 1) {
                suffix = ' ' + 'und' + ' ';
              } else if (productUuids.length === i + 1) {
                suffix = '';
              }

              if (orderItem && orderItem.product_title) {
                if (orderItem.product_title) {
                  productNames += orderItem.product_title + suffix;
                }
              } else {
                productNames += 'Produkt' + suffix;
              }
            }

            message = `${
              productNames.length ? productNames : 'Einige Produkte'
            } sind nicht mehr verfügbar`;
          }
        }
        break;
      case 'DECLINED_COMMENT':
        message = `Dein Sonderwunsch ${receipt.error.message} ist nicht möglich`;
        break;
      case 'DECLINED_WITH_MESSAGE':
        message = receipt.error.message;
        break;
      case 'CLOSING_SOON':
        message =
          'Das Restaurant schließt bald und nimmt deine Bestellung nicht mehr an!';
        break;
      case 'EXPIRED':
        message = 'Leider wurde die Bestellung nicht rechtzeitig bestätigt!';
        break;
      case 'RESERVATION_DECLINED':
        message = receipt.error.message;
        break;
      case 'CANCELLED_BY_ADMIN':
        message = receipt.error.message;
        break;
      case 'TARGET_UNREACHABLE':
        message = 'Bestellung konnte nicht übermittelt werden!';
        break;
      case 'PAYMENT_TIMEOUT':
        message = 'Fehler beim Durchführen der Bezahlung: Timeout';
        break;
      default:
        message = receipt.error.message || 'Storniert';
        break;
    }
  }

  return message;
}
