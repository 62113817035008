import React, { Suspense, useState } from 'react';
import CircularIndeterminate from '../components/CircularIndeterminate';
import { lazyRetry } from '../helpers/lazyRetry';
import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useApolloClient } from '@apollo/client';
import { GetBonusProgramRedemptionDocument } from '../services/graphql/typed-operations';
import { format } from 'date-fns';
import HeaderContainer from '../components/HeaderContainer';
import { isFuture } from 'date-fns/esm';

const QrReader = React.lazy(() => lazyRetry(() => import('react-qr-reader')));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0)
    },
    scanner: {
      maxWidth: 400,
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  })
);

const BonusProgramCheck: React.FC = () => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [redemption, setRedemption] = useState<RedemptionFragment>();

  const handleError = (err: Error) => {
    console.log(err.name, err.message);
    setError(err.message);
  };

  const handleScan = async (qrcode_text: string | null) => {
    if (qrcode_text) {
      setLoading(true);
      try {
        const result = await apolloClient.query({
          query: GetBonusProgramRedemptionDocument,
          variables: { params: { qrcode_text } },
          fetchPolicy: 'network-only'
        });
        if (result.data.getBonusProgramRedemption) {
          setRedemption(result.data.getBonusProgramRedemption);
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderContainer title={'Bonuspunkte überprüfen'} />
      <Paper className={classes.paper}>
        <Suspense fallback={<CircularIndeterminate />}>
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
          {loading && <CircularIndeterminate />}
          {redemption && (
            <>
              {isFuture(new Date(redemption.expires_at)) ? (
                <Alert variant="filled" severity="success">
                  Gültig
                </Alert>
              ) : (
                <Alert variant="filled" severity="warning">
                  Abgelaufen
                </Alert>
              )}
              <List dense>
                <ListItem divider>
                  <ListItemText
                    primary="Restaurant"
                    secondary={
                      redemption?.gastronomy?.name ||
                      redemption.bonus_program.title
                    }
                  />
                </ListItem>
                <ListItem divider>
                  <ListItemText
                    primary="Ablaufdatum"
                    secondary={format(
                      new Date(redemption.expires_at),
                      'yyyy.MM.dd - HH:mm'
                    )}
                  />
                </ListItem>
                <ListItem divider>
                  <ListItemText
                    primary="Punkte/Wert"
                    secondary={
                      redemption.redeemed_bonus_points +
                      ' Punkte / ' +
                      (redemption.redeemed_bonus_amount / 100).toFixed(2) +
                      '€'
                    }
                  />
                </ListItem>
              </List>
              <Button
                onClick={() => setRedemption(undefined)}
                variant="contained"
                color="primary"
              >
                Erneut scannen
              </Button>
            </>
          )}
          {!loading && !redemption && (
            <div className={classes.scanner}>
              <p>
                Scannen Sie den QR-Code des Kunden und überprüfen Sie die
                Richtigkeit:
              </p>
              <QrReader
                onError={handleError}
                onScan={handleScan}
                facingMode="environment"
              />
            </div>
          )}
        </Suspense>
      </Paper>
    </>
  );
};

export default BonusProgramCheck;
