import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import AppQrCode from './AppQrCode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  tables: TableFragment[];
  classes?: string;
}

const DownloadQrCodeZip: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { tables } = props;
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const download = async () => {
      const zip = new JSZip();

      for (const table of tables) {
        const canvas = document.getElementById(
          `table_${table.uuid}`
        ) as HTMLCanvasElement;

        if (canvas) {
          zip.file(
            `qrcode_table_${table.table_number}.png`,
            canvas.toDataURL().replace('data:image/png;base64,', ''),
            { base64: true }
          );
        }
      }

      const content = await zip.generateAsync({ type: 'blob' });

      saveAs(content, 'qrcodes.zip');
    };

    if (show) {
      download();
    }
  }, [show, tables]);

  return (
    <>
      {show &&
        tables.map((table) => (
          <div key={table.uuid} hidden>
            <AppQrCode
              value={table.uuid}
              type="t"
              size={2048}
              id={`table_${table.uuid}`}
            />
          </div>
        ))}
      <Button
        variant="contained"
        color="default"
        onClick={() => setShow(true)}
        className={props.classes || ''}
      >
        QR-Codes Herunterladen
        <SaveAltRounded className={classes.rightIcon} />
      </Button>
    </>
  );
};

export default DownloadQrCodeZip;
