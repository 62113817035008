import { AvailabilityTimeException, AvailabilityTimesKey } from 'models';
import React, { useEffect, useState } from 'react';
import { cleanGraphqlTypeName } from '../helpers/FormatHelper';
import AvailabilityTimeExceptions from './AvailabilityTimeExceptions';
import AvailabilityTimesTable from './AvailabilityTimesTable';

const defaultAvailabilityTimes: AvailabilityTimes = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
  hol: [],
  e: [],
  cc: 'AT'
};

interface Props {
  initialAvailabilityTimes?: AvailabilityTimes | null;
  onSave: (availabilityTimes?: AvailabilityTimes | null) => void;
  showLimit: boolean;
}

const AvailabilityTimesForm: React.FC<Props> = ({
  initialAvailabilityTimes,
  onSave,
  showLimit
}) => {
  const [
    availabilityTimes,
    setAvailabilityTimes
  ] = useState<AvailabilityTimes>();

  useEffect(() => {
    const newAvailabilityTimes = { ...defaultAvailabilityTimes };

    for (const key of Object.keys(newAvailabilityTimes)) {
      const mKey = key as AvailabilityTimesKey;
      if (initialAvailabilityTimes && initialAvailabilityTimes[mKey]) {
        if (mKey === 'e') {
          newAvailabilityTimes[mKey] = initialAvailabilityTimes[mKey];
        } else if (mKey !== 'cc') {
          newAvailabilityTimes[mKey] = initialAvailabilityTimes[mKey];
        }
      }
    }

    setAvailabilityTimes(newAvailabilityTimes);
  }, [initialAvailabilityTimes]);

  const handleSave = (
    type: AvailabilityTimesKey,
    times: AvailabilityTimeElement[] | AvailabilityTimeException[]
  ) => {
    if (availabilityTimes) {
      const newTimes: AvailabilityTimes = { ...availabilityTimes };

      let hasEntries = false;
      for (const key of Object.keys(
        availabilityTimes
      ) as AvailabilityTimesKey[]) {
        if (key === 'cc') {
          continue;
        }

        if (key === type) {
          newTimes[key] = times as AvailabilityTimeElement[] &
            AvailabilityTimeException[];
        }
        // @ts-ignore
        if (newTimes && newTimes[key].length > 0) {
          hasEntries = true;
        }
      }

      onSave(
        hasEntries
          ? (cleanGraphqlTypeName(newTimes) as AvailabilityTimes)
          : null
      );
    }
  };

  if (availabilityTimes) {
    return (
      <>
        {Object.keys(availabilityTimes).map((key, index) => {
          const day = key as AvailabilityTimesKey;
          if (day !== 'e' && day !== 'cc') {
            return (
              <div key={index.toString()}>
                <AvailabilityTimesTable
                  type={day}
                  availabilityTimes={availabilityTimes[day]}
                  onChange={handleSave}
                  showLimit={showLimit}
                />
              </div>
            );
          }
        })}
        <AvailabilityTimeExceptions
          exceptions={availabilityTimes.e}
          onChange={handleSave}
        />
      </>
    );
  }
  return null;
};

export default AvailabilityTimesForm;
