import {
  Avatar,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import CustomerServiceNotesDialog from './CustomerServiceNotesDialog';
import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { isAdminSelector } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(2, 0)
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    avatar: {
      margin: 10,
      height: 60,
      width: 60,
      backgroundColor: 'transparent'
    }
  })
);

interface Props {
  title: string;
}

const HeaderContainer: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const [showNotesDialog, setShowNotesDialog] = useState<boolean>(false);
  const gastronomy = useRecoilValue(gastronomyState);
  const isAdmin = useRecoilValue(isAdminSelector);

  return (
    <>
      <Helmet>
        <title>getsby Dashboard - {title}</title>
      </Helmet>
      <div className={classes.headerContainer}>
        <Typography variant="h4" component="h1" className={classes.margin}>
          {title}
        </Typography>
        <div
          onClick={() => setShowNotesDialog(true)}
          style={{ cursor: 'pointer' }}
        >
          {gastronomy && gastronomy.logo_image && (
            <Avatar src={gastronomy.logo_image} className={classes.avatar} />
          )}
          {gastronomy && !gastronomy.logo_image && (
            <Avatar className={classes.avatar}>
              <img
                src={process.env.PUBLIC_URL + '/img/UndrawProfilePic.svg'}
                style={{ maxHeight: 60, width: 'auto' }}
                alt=""
              />
            </Avatar>
          )}
        </div>
        {isAdmin && (
          <CustomerServiceNotesDialog
            open={showNotesDialog}
            onClose={() => {
              setShowNotesDialog(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default HeaderContainer;
