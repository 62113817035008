import {
  Chip,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Tooltip
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import MaterialTable, { Options } from 'material-table';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from '../helpers/FormatHelper';
import { getCurrentBasePath } from '../helpers/MiscHelper';
import {
  getOrderTypeLabel,
  getReceiptStatusLabel,
  getStatusColor
} from '../helpers/OrderHelper';
import { isAdminSelector } from '../store/auth';
import CopyTextToClipboard from './CopyTextToClipboard';
import ReceiptView from './ReceiptView';
import SimpleDialog from './SimpleDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1)
    },
    verticalMargin: {
      margin: theme.spacing(2, 0)
    }
  })
);

interface Props {
  receipts: ReceiptWithGastronomyFragment[];
  isLoading: boolean;
  renderPaper?: boolean;
  options?: Options<ReceiptWithGastronomyFragment>;
}

const ReceiptTable: React.FC<Props> = ({
  receipts = [],
  isLoading,
  renderPaper = true,
  options
}) => {
  const classes = useStyles();
  const isAdmin = useRecoilValue(isAdminSelector);
  const [
    selectedReceipt,
    setSelectedReceipt
  ] = useState<ReceiptFragment | null>(null);
  const data = cloneDeep(receipts);

  return (
    <>
      {selectedReceipt && (
        <SimpleDialog
          open={!!selectedReceipt}
          title={`Bestelldetails - ${selectedReceipt.pickup_code}`}
          text={
            <>
              <CopyTextToClipboard
                text={`${getCurrentBasePath()}/receipt/${selectedReceipt.uuid}`}
                textDescription="Dashboard URL"
                copy={true}
              />
              {isAdmin && selectedReceipt.short_uuid && (
                <CopyTextToClipboard
                  text={`${getCurrentBasePath()}/order/${
                    selectedReceipt.short_uuid
                  }`}
                  textDescription="Öffentliche URL"
                  copy={true}
                />
              )}
              <Divider className={classes.verticalMargin} />
              <ReceiptView uuid={selectedReceipt.uuid} />
            </>
          }
          onNegativeClose={() => {
            setSelectedReceipt(null);
          }}
          buttonNegativeLabel={'Zurück'}
        />
      )}
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          columns={[
            {
              title: 'Datum',
              field: 'created_at',
              type: 'datetime',
              defaultSort: 'desc',
              render: (rowData) =>
                rowData.created_at
                  ? new Date(rowData.created_at).toLocaleString()
                  : ''
            },
            {
              title: 'Restaurant',
              field: 'gastronomy.name',
              hidden: !isAdmin
            },
            {
              title: 'Bestellart',
              field: 'table_type',
              render: (rowData) => (
                <>
                  {rowData.table_type && getOrderTypeLabel(rowData.table_type)}
                </>
              )
            },
            {
              title: 'Bereit um',
              field: 'estimated_pickup_time',
              type: 'datetime',
              render: (rowData) =>
                rowData.estimated_pickup_time
                  ? new Date(rowData.estimated_pickup_time).toLocaleString()
                  : ''
            },
            {
              title: 'Vorbestellt für',
              field: 'requested_pickup_time',
              type: 'datetime',
              render: (rowData) =>
                rowData.requested_pickup_time
                  ? new Date(rowData.requested_pickup_time).toLocaleString()
                  : ''
            },
            {
              title: 'Code',
              field: 'pickup_code',
              render: (rowData) => (
                <Chip
                  size="small"
                  label={rowData.pickup_code}
                  className={classes.chip}
                  color="default"
                />
              )
            },
            {
              title: 'Summe',
              field: 'total_price',
              render: (rowData) =>
                formatNumber(
                  (rowData.status !== 'ERROR'
                    ? rowData.total_price +
                      (rowData.tip_amount || 0) +
                      (!isAdmin ? rowData.discount || 0 : 0)
                    : 0) / 100,
                  '€'
                )
            },
            {
              title: 'Status',
              field: 'status',
              render: (rowData) => {
                return (
                  <>
                    {!rowData.error && (
                      <Chip
                        size="small"
                        label={getReceiptStatusLabel(rowData.status)}
                        className={classes.chip}
                        style={{
                          backgroundColor: getStatusColor(rowData.status),
                          color: 'white'
                        }}
                      />
                    )}
                    {rowData.error && (
                      <Tooltip title={rowData.error.message}>
                        <Chip
                          size="small"
                          label={getReceiptStatusLabel(rowData.status)}
                          className={classes.chip}
                          style={{
                            backgroundColor: getStatusColor(rowData.status),
                            color: 'white'
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                );
              }
            }
          ]}
          data={data}
          localization={{
            toolbar: { searchTooltip: 'Suchen', searchPlaceholder: 'Suchen' },
            pagination: {
              labelRowsSelect: 'Zeilen',
              firstTooltip: 'Erste Seite',
              previousTooltip: 'Vorherige Seite',
              nextTooltip: 'Nächste Seite',
              lastTooltip: 'Letzte Seite'
            }
          }}
          options={{
            showTitle: false,
            showEmptyDataSourceMessage: false,
            sorting: true,
            pageSizeOptions: [10, 20, 50, 100],
            pageSize: 50,
            draggable: false,
            padding: 'dense',
            search: false,
            emptyRowsWhenPaging: false,
            ...options
          }}
          isLoading={isLoading}
          components={{
            Container: (props) =>
              renderPaper ? (
                <Paper>{props.children}</Paper>
              ) : (
                <>{props.children}</>
              )
          }}
          onRowClick={(_event, rowData) => setSelectedReceipt(rowData || null)}
        />
      </div>
    </>
  );
};

export default ReceiptTable;
