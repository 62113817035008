import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useMemo, useState } from 'react';
import {
  captureSilentError,
  setErrorSnackbarMessage
} from '../helpers/ErrorHelper';
import { getPrinterLabel } from '../helpers/MiscHelper';
import { ALLERGENS } from '../constants';
import { getTableTypeLabel } from '../helpers/GastronomyHelper';
import Joi from 'joi';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { isFuture } from 'date-fns';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import {
  gastronomyState,
  hasPosOrWincaratSelector,
  hasPosSelector
} from '../store/gastronomy';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  AddProductMutationDocument,
  AdminUpdateProductMutationDocument,
  GetPrintersQueryDocument,
  GetProductGroupsQueryDocument,
  GetProductPrintersQueryDocument
} from '../services/graphql/typed-operations';
import CircularIndeterminate from './CircularIndeterminate';
import { isAdminSelector } from '../store/auth';
import { getDefaultTaxFromProductGroupType } from '../helpers/ProductHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    radioButton: {
      margin: theme.spacing(1)
    },
    buttons: {
      margin: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

type ProductFormValues = Required<
  Omit<
    ProductFragment,
    | 'gross_price'
    | 'discount'
    | 'uuid'
    | 'allergenList'
    | 'orderTypeExclusions'
    | 'image'
    | 'image_full'
    | 'order_limit'
    | 'status'
    | 'status_override'
    | '__typename'
    | 'out_of_stock_until'
  >
> & {
  gross_price: string;
  discount: string;
};

interface Props {
  product?: Omit<Partial<ProductFragment>, 'image' | 'image_full'>;
  productGroupUuid: string;
  productGroupType?: ProductGroupType;
  onClose: () => void;
  currentOrderNr?: number;
}

const ProductForm: React.FC<Props> = ({
  product,
  productGroupUuid,
  productGroupType,
  onClose,
  currentOrderNr = 0
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<ProductFormValues>({
    title: product && product.title ? product.title : '',
    alias: product && product.alias ? product.alias : '',
    print_title: product && product.print_title ? product.print_title : '',
    gross_price:
      product && product.gross_price ? product.gross_price.toString() : '0',
    discount: product && product.discount ? product.discount.toString() : '0',
    description: product && product.description ? product.description : '',
    tax:
      product && product.tax
        ? product.tax
        : getDefaultTaxFromProductGroupType(productGroupType),
    type: product && product.type ? product.type : 'NONE',
    product_group_uuid: productGroupUuid,
    pos_key: product?.pos_key || ''
  });
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const hasPosOrWincarat = useRecoilValue(hasPosOrWincaratSelector);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [errors, setErrors] = useState<string[]>([]);
  const [productPrinterId, setProductPrinterId] = useState<string>('');
  const [orderTypeExclusions, setOrderTypeExclusions] = useState<TableType[]>(
    product && product.orderTypeExclusions ? product.orderTypeExclusions : []
  );
  const [allergenList, setAllergenList] = useState<Allergen[]>(
    product && product.allergenList ? product.allergenList : []
  );
  const [outOfStockUntil, setOutOfStockUntil] = useState<Date | null>(
    product &&
      product.out_of_stock_until &&
      isFuture(new Date(product.out_of_stock_until))
      ? new Date(product.out_of_stock_until)
      : null
  );
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const schemaValues = useMemo(() => {
    return Joi.object({
      title: Joi.string().min(2),
      alias: Joi.string().allow(''),
      print_title: Joi.string().allow(''),
      gross_price: Joi.string().pattern(isAdmin ? /^-?[0-9]+$/ : /^[0-9]+$/),
      discount: Joi.string().pattern(/^[0-9]+$/),
      description: Joi.string().allow(''),
      tax: Joi.number().integer(),
      type: Joi.string().valid('NONE', 'VEGETARIAN', 'VEGAN'),
      product_group_uuid: Joi.string().uuid(),
      out_of_stock_until: Joi.date().optional(),
      pos_key: Joi.string()
        .allow('')
        .pattern(
          gastronomy?.posConfig?.pos_type === 'WINCARAT'
            ? /^[0-9]+$/
            : /^[a-zA-Z0-9]*$/
        )
    });
  }, [isAdmin]);

  const { data: printersData } = useQuery(GetPrintersQueryDocument, {
    variables: { user_uuid: gastronomy ? gastronomy.uuid : 'not set' },
    skip: !gastronomy
  });

  useQuery(GetProductPrintersQueryDocument, {
    variables: { product_uuid: product ? product.uuid : 'not set' },
    skip: product === null,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      console.debug('ProductPrinters', data.getProductPrinters);

      setProductPrinterId(
        data.getProductPrinters.length ? data.getProductPrinters[0].uuid : ''
      );
    }
  });

  const { data: productGroupsData, loading: productGroupsLoading } = useQuery(
    GetProductGroupsQueryDocument,
    {
      variables: {
        params: {
          user_uuid: gastronomy ? gastronomy.uuid : 'not set',
          status: 'ALL',
          product_status: 'ALL',
          showEmpty: true
        }
      },
      skip: !gastronomy,
      onError: (error) => {
        captureSilentError(error);
      }
    }
  );

  const handleChange = (name: string) => (event: any) => {
    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleOrderTypeChange = (_name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (event.target.checked) {
      setOrderTypeExclusions([...orderTypeExclusions, value as TableType]);
    } else {
      setOrderTypeExclusions(orderTypeExclusions.filter((id) => id !== value));
    }
  };

  const handleAllergenListChange = (_name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (event.target.checked) {
      setAllergenList((prev) => [...prev, value as Allergen]);
    } else {
      setAllergenList((prev) => prev.filter((a) => a !== value));
    }
  };

  const validateInput = (): boolean => {
    const result = schemaValues.validate(values);
    return processJoiResult(result);
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation errror', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const handlePrinterChange = (uuid: string) => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setProductPrinterId(uuid === productPrinterId ? '' : uuid);
  };

  const saveProduct = async () => {
    if (gastronomy && gastronomy.uuid) {
      try {
        if (!validateInput()) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: 'Bitte überprüfen Sie Ihre Eingabe!'
              }
            ],
            setSnackbarMessages
          );

          return;
        }

        if (product && product.uuid) {
          const input: ProductUpdateInput = {
            uuid: product.uuid,
            title: values.title,
            print_title: values.print_title,
            gross_price: parseInt(values.gross_price, 10),
            discount: parseInt(values.discount, 10),
            description: values.description,
            tax: values.tax,
            product_group_uuid: values.product_group_uuid,
            type: values.type,
            printerUuids: productPrinterId ? [productPrinterId] : [],
            orderTypeExclusions,
            allergenList,
            out_of_stock_until: outOfStockUntil
              ? outOfStockUntil.toISOString()
              : '',
            pos_key: values.pos_key
          };

          if (hasPosOrWincarat) {
            input.alias = values.alias;
          }

          const { data } = await apolloClient.mutate({
            mutation: AdminUpdateProductMutationDocument,
            variables: { input },
            refetchQueries: ['getProductsQuery']
          });

          if (!data) {
            return;
          }

          const newProduct = data.adminUpdateProduct;

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Produkt ${newProduct.title} aktualisiert`
              }
            ],
            setSnackbarMessages
          );
        } else {
          const input: ProductAddInput = {
            title: values.title,
            print_title: values.print_title,
            gross_price: parseInt(values.gross_price, 10),
            discount: parseInt(values.discount, 10),
            description: values.description,
            tax: values.tax,
            product_group_uuid: values.product_group_uuid,
            user_uuid: gastronomy.uuid,
            type: values.type,
            printerUuids: productPrinterId ? [productPrinterId] : [],
            orderTypeExclusions,
            allergenList,
            order_nr: currentOrderNr
          };

          const { data } = await apolloClient.mutate({
            mutation: AddProductMutationDocument,
            variables: { input },
            refetchQueries: ['getProductsQuery']
          });

          if (data) {
            const newProduct = data.addProduct;

            addSnackbarMessages(
              [
                {
                  type: 'success',
                  message: `Produkt ${newProduct.title} hinzugefügt`
                }
              ],
              setSnackbarMessages
            );
          }
        }

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <>
      <form className={classes.container} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <TextField
              error={errors.includes('title')}
              label="Titel"
              placeholder="Hamburger"
              className={classes.input}
              onChange={handleChange('title')}
              required={true}
              value={values.title}
              margin="dense"
              disabled={hasPosOrWincarat}
              variant="outlined"
            />
            {hasPosOrWincarat && (
              <>
                <TextField
                  label="Titel Überschreiben"
                  placeholder="Hamburger"
                  className={classes.input}
                  onChange={handleChange('alias')}
                  required={true}
                  value={values.alias}
                  margin="dense"
                  variant="outlined"
                  error={errors.includes('alias')}
                />
                <TextField
                  label="ID in der Registrierkasse"
                  placeholder="123"
                  className={classes.input}
                  onChange={handleChange('pos_key')}
                  required={true}
                  value={values.pos_key}
                  margin="dense"
                  variant="outlined"
                  disabled={gastronomy?.posConfig?.pos_type !== 'WINCARAT'}
                  error={errors.includes('pos_key')}
                />
              </>
            )}
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Drucktitel"
              placeholder="SoZi 0.5l"
              className={classes.input}
              onChange={handleChange('print_title')}
              value={values.print_title}
              margin="dense"
              helperText="Optionaler Titel für interne Bezeichnungen (Wird am Beleg gedruckt)"
              variant="outlined"
              error={errors.includes('print_title')}
              disabled={hasPosOrWincarat}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              error={errors.includes('gross_price')}
              label="Preis in Cent"
              required={true}
              value={values.gross_price}
              type="number"
              onChange={handleChange('gross_price')}
              className={classes.input}
              margin="dense"
              disabled={hasPosOrWincarat}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              error={errors.includes('discount')}
              label="Rabatt in Cent"
              value={values.discount}
              type="number"
              onChange={handleChange('discount')}
              className={classes.input}
              margin="dense"
              variant="outlined"
              helperText="Dient nur zu Darstellungszwecken"
            />
          </Grid>
          <Grid item sm={2}>
            <FormControl
              className={classes.input}
              margin="dense"
              variant="outlined"
            >
              <InputLabel id="p-taxrate-select">Steuersatz</InputLabel>
              <Select
                value={values.tax}
                onChange={handleChange('tax')}
                labelId="p-taxrate-select"
                label="Steuersatz"
                margin="dense"
                error={errors.includes('tax')}
              >
                <MenuItem key={'0'} value={0}>
                  0%
                </MenuItem>
                <MenuItem key={'5'} value={5}>
                  5%
                </MenuItem>
                <MenuItem key={'7'} value={7}>
                  7%
                </MenuItem>
                <MenuItem key={'10'} value={10}>
                  10%
                </MenuItem>
                <MenuItem key={'13'} value={13}>
                  13%
                </MenuItem>
                <MenuItem key={'19'} value={19}>
                  19%
                </MenuItem>
                <MenuItem key={'20'} value={20}>
                  20%
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={
              !hasPos && printersData && printersData.getPrinters.length ? 3 : 6
            }
          >
            <TextField
              label="Beschreibung"
              placeholder="Vom Bio-Weiderind"
              className={classes.input}
              onChange={handleChange('description')}
              value={values.description}
              margin="dense"
              multiline={true}
              rows={3}
              variant="outlined"
              error={errors.includes('description')}
            />
          </Grid>
          {!hasPos && printersData && printersData.getPrinters.length > 0 && (
            <Grid item sm={3}>
              <FormControl component="fieldset" className={classes.radioButton}>
                <FormLabel component="legend">Druckerzuweisung</FormLabel>
                <RadioGroup value={productPrinterId}>
                  {printersData.getPrinters.map((printer) => (
                    <FormControlLabel
                      key={printer.uuid}
                      value={printer.uuid}
                      control={
                        <Radio onClick={handlePrinterChange(printer.uuid)} />
                      }
                      label={getPrinterLabel(printer)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          <Grid item sm={3}>
            <FormControl component="fieldset" className={classes.radioButton}>
              <FormLabel component="legend">Produkt Typ</FormLabel>
              <RadioGroup
                name="type"
                value={values.type}
                onChange={handleChange('type')}
                row
              >
                <FormControlLabel
                  value="NONE"
                  control={<Radio />}
                  color="primary"
                  label="Standard"
                />
                <FormControlLabel
                  value="VEGETARIAN"
                  color="primary"
                  control={<Radio />}
                  label="Vegetarisch"
                />
                <FormControlLabel
                  value="VEGAN"
                  color="primary"
                  control={<Radio />}
                  label="Vegan"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl
              className={classes.input}
              margin="dense"
              variant="outlined"
            >
              <InputLabel id="pg-select">Produktgruppe</InputLabel>
              {productGroupsLoading && <CircularIndeterminate />}
              {productGroupsData && (
                <Select
                  disabled={hasPos}
                  value={values.product_group_uuid}
                  onChange={handleChange('product_group_uuid')}
                  labelId="pg-select"
                  label="Produktgruppe"
                  margin="dense"
                  error={errors.includes('product_group_uuid')}
                >
                  {productGroupsData.getProductGroups.map((pg) => (
                    <MenuItem key={pg.uuid} value={pg.uuid}>
                      {pg.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" className={classes.radioButton}>
              <FormLabel component="legend">Allergene</FormLabel>
              <FormGroup row>
                {ALLERGENS.map((a, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={allergenList.includes(a)}
                        onChange={handleAllergenListChange(a)}
                        value={a}
                        color="primary"
                      />
                    }
                    label={a}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          {product && (
            <Grid item xs={12}>
              <KeyboardDateTimePicker
                label="Ausverkauft bis"
                value={outOfStockUntil}
                onChange={setOutOfStockUntil}
                format={'dd.MM.yyyy HH:mm'}
                ampm={false}
                disablePast={true}
              />
            </Grid>
          )}
          {gastronomy &&
            gastronomy.options &&
            gastronomy.options.tableTypes &&
            gastronomy.options.tableTypes.length && (
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.radioButton}
                >
                  <FormLabel component="legend">
                    Dieses Produkt ist <b>NICHT</b> verfügbar für
                  </FormLabel>
                  <FormGroup row>
                    {gastronomy.options.tableTypes.map((tableType, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={orderTypeExclusions.includes(tableType)}
                            onChange={handleOrderTypeChange(tableType)}
                            value={tableType}
                            color="primary"
                          />
                        }
                        label={getTableTypeLabel(tableType)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
          <Grid item sm={6}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              onClick={saveProduct}
              className={classes.input}
              variant="contained"
              color="primary"
            >
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ProductForm;
