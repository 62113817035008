import ShoppingCartRounded from '@material-ui/icons/ShoppingCartRounded';
import React from 'react';
import { formatNumber } from '../helpers/FormatHelper';
import InfoCard from './InfoCard';

interface Props {
  total: number;
}

const TotalRevenueCard: React.FC<Props> = ({ total }) => {
  return (
    <InfoCard
      icon={<ShoppingCartRounded />}
      title="Umsatz"
      value={formatNumber(total / 100, '€')}
      valueLoading={false}
    />
  );
};

export default TotalRevenueCard;
