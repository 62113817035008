import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays, endOfDay, startOfDay, startOfMonth } from 'date-fns';
import React, { useEffect, useState, Suspense } from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import OrderSumTable from '../../components/OrderSumTable';
import { captureSilentError } from '../../helpers/ErrorHelper';
import { lazyRetry } from '../../helpers/lazyRetry';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { AdminGetOrderSumQueryDocument } from '../../services/graphql/typed-operations';
import { getReceiptStatusLabel } from '../../helpers/OrderHelper';
const OrderSumExport = React.lazy(() =>
  lazyRetry(() => import('../../components/OrderSumExport'))
);
const orderStati: ReceiptStatus[] = [
  'RECEIVED',
  'ACCEPTED',
  'READY',
  'COMPLETED',
  'ERROR'
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    button: {
      margin: theme.spacing(1),
      width: '100%'
    },
    margin: {
      margin: theme.spacing(2, 0)
    },
    paddingRight: {
      paddingRight: theme.spacing(2)
    },
    datePicker: {
      marginRight: theme.spacing(2)
    },
    chartHeader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      flexWrap: 'wrap'
    },
    toolbarColumn: {
      display: 'flex',
      alignItems: 'center'
    },
    formControl: {
      margin: theme.spacing(3)
    }
  })
);

const GastronomyOrderOverview: React.FC = () => {
  const classes = useStyles();
  const [orderSums, setOrderSums] = useState<OrderSumFragment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fromDate, setFromDate] = useState<Date>(startOfMonth(new Date()));
  const [toDate, setToDate] = useState<Date>(endOfDay(new Date()));
  const [statusFilter, setStatusFilter] = useState<ReceiptStatus[]>([
    'COMPLETED'
  ]);
  const gastronomy = useRecoilValue(gastronomyState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const getOrderItems = async () => {
      setIsLoading(true);

      const result = await fetchOrderSums();

      setOrderSums(result);

      setIsLoading(false);
    };

    const fetchOrderSums = async (): Promise<OrderSumFragment[]> => {
      if (gastronomy) {
        try {
          let dateFrom = startOfDay(fromDate as Date);
          dateFrom = addDays(dateFrom, 1);
          dateFrom.setUTCHours(
            fromDate.getHours(),
            fromDate.getMinutes(),
            fromDate.getSeconds(),
            fromDate.getMilliseconds()
          );

          const dateTo = endOfDay(toDate as Date);
          dateTo.setUTCHours(
            toDate.getHours(),
            toDate.getMinutes(),
            toDate.getSeconds(),
            toDate.getMilliseconds()
          );

          const params: OrderSumSearchParams = {
            user_uuid: gastronomy.uuid,
            datetime_from: dateFrom.toISOString(),
            datetime_to: dateTo.toISOString(),
            statusFilter
          };

          const { data } = await apolloClient.query({
            query: AdminGetOrderSumQueryDocument,
            variables: { params }
          });

          if (!data) {
            return [];
          }

          console.debug('Order Sums', data.adminGetOrderSum);

          return data.adminGetOrderSum;
        } catch (error) {
          captureSilentError(error);
        }
      }
      return [];
    };

    getOrderItems();
  }, [gastronomy, fromDate, toDate, statusFilter]);

  return (
    <>
      <HeaderContainer title={'Tagesumsätze'} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.toolbarContainer}>
            <div className={classes.toolbarColumn}>
              <DatePicker
                views={['date', 'month', 'year']}
                label="Von Datum"
                value={fromDate}
                onChange={(date: MaterialUiPickersDate) =>
                  setFromDate(date as Date)
                }
                className={classes.datePicker}
              />
              <DatePicker
                views={['date', 'month', 'year']}
                label="Bis Datum"
                value={toDate}
                onChange={(date: MaterialUiPickersDate) =>
                  setToDate(date as Date)
                }
                className={classes.datePicker}
              />
            </div>
            <div>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Status</FormLabel>
                <FormGroup row>
                  {orderStati.map((status) => (
                    <FormControlLabel
                      key={status}
                      control={
                        <Checkbox
                          checked={statusFilter.includes(status)}
                          onChange={(evt, checked) => {
                            setStatusFilter((prev) => {
                              const index = prev.findIndex(
                                (prevStatus) => prevStatus === status
                              );
                              if (index === -1) {
                                return [...prev, status];
                              } else {
                                return [
                                  ...prev.slice(0, index),
                                  ...prev.slice(index + 1)
                                ];
                              }
                            });
                          }}
                          name={status}
                          color="primary"
                        />
                      }
                      label={getReceiptStatusLabel(status)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
            <Suspense fallback={<CircularIndeterminate />}>
              <OrderSumExport
                orderSums={orderSums}
                fromDate={fromDate}
                toDate={toDate}
                disabled={isLoading || orderSums.length === 0}
              />
            </Suspense>
          </Paper>
          {isLoading && (
            <Paper className={classes.paper}>
              <CircularIndeterminate />
            </Paper>
          )}
          {!isLoading && (
            <OrderSumTable orderSums={orderSums} statusFilter={statusFilter} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyOrderOverview;
