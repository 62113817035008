export function getDefaultTaxFromProductGroupType(
  type?: ProductGroupType
): number {
  switch (type) {
    case 'FOOD':
      return 10;
    default:
      return 20;
  }
}
