import {
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Tooltip
} from '@material-ui/core';
import { SaveAltRounded } from '@material-ui/icons';
import DoneRounded from '@material-ui/icons/DoneRounded';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      display: 'flex',
      flexDirection: 'row'
    },
    input: {
      marginRight: theme.spacing(2),
      width: '100%'
    }
  })
);

interface Props {
  text: string;
  textDescription: string;
  copy?: boolean;
  download?: boolean;
  fileName?: string;
}

const CopyTextToClipboard: React.FC<Props> = ({
  text,
  textDescription,
  copy,
  download,
  fileName
}: Props) => {
  const classes = useStyles();
  const [copied, setCopied] = useState<boolean>(false);
  const input = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    if (input.current) {
      input.current.select();

      document.execCommand('copy');

      setCopied(true);
    }
  };

  const downloadText = () => {
    const element = document.createElement('a');
    const file = new Blob([text], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName || 'download'}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className={classes.wrapper}>
      {copy ? (
        <TextField
          inputRef={input}
          label={textDescription}
          value={text}
          margin="dense"
          className={classes.input}
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
        />
      ) : (
        <div className={classes.input}>{text}</div>
      )}
      <div className={classes.button}>
        {download && (
          <Tooltip title="Herunterladen">
            <IconButton onClick={downloadText}>
              <SaveAltRounded />
            </IconButton>
          </Tooltip>
        )}
        {document.queryCommandSupported('copy') && !copied && (
          <Tooltip title="In Zwischenablage kopieren">
            <IconButton onClick={copyToClipboard}>
              <FileCopyRounded />
            </IconButton>
          </Tooltip>
        )}
        {copied && (
          <Tooltip title="Kopiert">
            <span>
              <IconButton disabled>
                <DoneRounded />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CopyTextToClipboard;
