import React from 'react';
import {
  Dialog,
  makeStyles,
  Theme,
  createStyles,
  useMediaQuery,
  useTheme,
  DialogTitle,
  IconButton,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomerServiceNotes from './CustomerServiceNotes';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    input: {
      width: '100%'
    },
    list: {
      width: '100%',
      flexGrow: 1,
      overflow: 'auto'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    container: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const CustomerServiceNotesDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const gastronomy = useRecoilValue(gastronomyState);

  const handleClose = () => {
    console.log('close');
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        {gastronomy ? `${gastronomy.name} ` : ''}Notizen
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.container}>
        <CustomerServiceNotes />
      </DialogContent>
    </Dialog>
  );
};

export default CustomerServiceNotesDialog;
