import {
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import React, { useState } from 'react';
import AvailabilityTimeTimesForm from './AvailabilityTimeTimesForm';
import SimpleDialog from './SimpleDialog';

interface Props {
  availabilityTime: AvailabilityTimeElement;
  onUpdate: (availabilityTime: AvailabilityTimeElement) => void;
  onDelete: () => void;
  showLimit: boolean;
}

const AvailabilityTimeLineItem: React.FC<Props> = ({
  availabilityTime,
  onUpdate,
  onDelete,
  showLimit
}) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);

  return (
    <>
      <Divider component="li" variant="middle" />
      <SimpleDialog
        open={deleteItem}
        title={`Zeit löschen`}
        text={'Möchten Sie diese Zeit wirklich löschen?'}
        onPositiveClose={() => {
          setDeleteItem(false);
          onDelete();
        }}
        onNegativeClose={() => {
          setDeleteItem(false);
        }}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      {!update && (
        <ListItem>
          <ListItemText
            primary={
              <>
                <b>
                  {availabilityTime.time_from.substring(0, 2)}:
                  {availabilityTime.time_from.substring(2, 4)}
                </b>
                {' bis '}
                <b>
                  {availabilityTime.time_to.substring(0, 2)}:
                  {availabilityTime.time_to.substring(2, 4)}
                </b>
              </>
            }
            secondary={
              showLimit &&
              availabilityTime.limit && (
                <span>
                  Auf {availabilityTime.limit} Bestellungen pro Slot begrenzt
                </span>
              )
            }
          />
          <ListItemSecondaryAction>
            <>
              <Tooltip title="Bearbeiten">
                <IconButton onClick={() => setUpdate(true)}>
                  <EditRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title="Löschen">
                <IconButton onClick={() => setDeleteItem(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {update && (
        <ListItem>
          <AvailabilityTimeTimesForm
            initialAvailabilityTime={availabilityTime}
            onClose={() => setUpdate(false)}
            onSave={(availabilityTime) => {
              onUpdate(availabilityTime);
              setUpdate(false);
            }}
            showLimit={showLimit}
          />
        </ListItem>
      )}
    </>
  );
};

export default AvailabilityTimeLineItem;
