import { useApolloClient } from '@apollo/client';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    list: {
      width: '100%'
    },
    container: {
      width: '100%'
    }
  })
);

const getInitialValues = (
  options?: GastronomyOptionsFragment | null
): string => {
  return options && options.disabledPaymentTypes
    ? JSON.stringify(
        options.disabledPaymentTypes.map((r) => {
          return Object.fromEntries(
            Object.entries(r).filter(([key]) => !key.includes('__typename'))
          );
        })
      )
    : '';
};

const schemaValues = Joi.array()
  .min(1)
  .items(
    Joi.object({
      type: Joi.string()
        .valid(
          'CARD',
          'APPLE_PAY',
          'GOOGLE_PAY',
          'SOFORT',
          'ALIPAY',
          'EPS',
          'CASH'
        )
        .required(),
      origin: Joi.string().valid('USER', 'PAYMENT_PROVIDER').required()
    })
  )
  .allow('');

interface Props {
  onClose: () => void;
}

const DisabledPaymentMethodsForm: React.FC<Props> = ({ onClose }) => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);
  const isAdmin = useRecoilValue(isAdminSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [
    disabledPaymentMethodTypes,
    setDisabledPaymentMethodTypes
  ] = useState<string>(getInitialValues(gastronomy?.options));
  const apolloClient = useApolloClient();

  useEffect(() => {
    setDisabledPaymentMethodTypes(getInitialValues(gastronomy?.options));
  }, [gastronomy]);

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      setErrors((prevErrors) => [...prevErrors, 'disabledPaymentMethodTypes']);
      return false;
    }
    return true;
  };

  const saveGastronomy = async () => {
    if (gastronomy) {
      setErrors([]);

      const joiResult = schemaValues.validate(
        disabledPaymentMethodTypes ? JSON.parse(disabledPaymentMethodTypes) : ''
      );

      if (!processJoiResult(joiResult)) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );
        return;
      }

      try {
        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              disabled_payment_types: disabledPaymentMethodTypes
                ? JSON.parse(disabledPaymentMethodTypes)
                : undefined
            }
          }
        });

        if (!data) {
          return;
        }

        const newGastronomy = data.updateGastronomy;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Bezahlmethoden aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        setGastronomy(newGastronomy);

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={5}
            rowsMax={25}
            label="Deaktivierte Bezahlmethoden"
            error={errors.includes('disabledPaymentMethodTypes')}
            value={disabledPaymentMethodTypes}
            onChange={(evt) => setDisabledPaymentMethodTypes(evt.target.value)}
            className={classes.input}
            margin="dense"
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={onClose}
            variant="outlined"
          >
            Verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={saveGastronomy}
            variant="contained"
            color="primary"
            disabled={!isAdmin}
          >
            Speichern
            <DoneRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DisabledPaymentMethodsForm;
