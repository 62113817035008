import { SnackbarMessage } from 'models';
import { atom, SetterOrUpdater } from 'recoil';

export const snackbarMessagesState = atom<SnackbarMessage[]>({
  key: 'snackbarMessages',
  default: []
});

export const addSnackbarMessages = (
  snackbarMessages: SnackbarMessage[],
  setSnackbarMessages: SetterOrUpdater<SnackbarMessage[]>
): void => {
  if (!snackbarMessages || snackbarMessages.length === 0) {
    return;
  }

  setSnackbarMessages((current) => [...current, ...snackbarMessages]);
};

export const removeSnackbarMessage = (
  snackbarMessage: SnackbarMessage,
  setSnackbarMessages: SetterOrUpdater<SnackbarMessage[]>
): void => {
  if (!snackbarMessage) {
    return;
  }

  setSnackbarMessages((current) =>
    current.filter((n) => n.message !== snackbarMessage.message)
  );
};
