import { I18n } from '@aws-amplify/core';
import { Translations } from '@aws-amplify/ui-components';

export function setAmplifyTranslations(): void {
  I18n.putVocabulariesForLanguage('en', {
    'Custom auth lambda trigger is not configured for the user pool.':
      'Password cannot be empty',
    [Translations.BACK_TO_SIGN_IN]: 'Back to Sign In',
    [Translations.CHANGE_PASSWORD_ACTION]: 'Change',
    [Translations.CHANGE_PASSWORD]: 'Change Password',
    [Translations.CODE_LABEL]: 'Verification code',
    [Translations.CODE_PLACEHOLDER]: 'Enter code',
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Confirmation Code',
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Enter your code',
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirm Sign up',
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Lost your code?',
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Resend Code',
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirm',
    [Translations.CONFIRM_SMS_CODE]: 'Confirm SMS Code',
    [Translations.CONFIRM]: 'Confirm',
    [Translations.CREATE_ACCOUNT_TEXT]: 'Create account',
    [Translations.EMAIL_LABEL]: 'Email Address *',
    [Translations.EMAIL_PLACEHOLDER]: 'Enter your email address',
    [Translations.FORGOT_PASSWORD_TEXT]: 'Forgot your password?',
    [Translations.NEW_PASSWORD_LABEL]: 'New password',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Enter your new password',
    [Translations.NO_ACCOUNT_TEXT]: 'No account?',
    [Translations.PASSWORD_LABEL]: 'Password *',
    [Translations.PASSWORD_PLACEHOLDER]: 'Enter your password',
    [Translations.RESET_PASSWORD_TEXT]: 'Reset password',
    [Translations.RESET_YOUR_PASSWORD]: 'Reset your password',
    [Translations.SEND_CODE]: 'Send Code',
    [Translations.SUBMIT]: 'Submit',
    [Translations.SIGN_IN_ACTION]: 'Sign In',
    [Translations.SIGN_IN_HEADER_TEXT]: 'Sign in to your getsby gastro account',
    [Translations.SIGN_IN_TEXT]: 'Sign in',
    [Translations.SIGN_OUT]: 'Sign Out',
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Email',
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Have an account?',
    [Translations.SIGN_UP_HEADER_TEXT]:
      'Register your restaurant to use getsby',
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Password',
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Create Account',
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Username',
    [Translations.USERNAME_LABEL]: 'Username *',
    [Translations.USERNAME_PLACEHOLDER]: 'Enter your username',
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Email',
    [Translations.VERIFY_CONTACT_HEADER_TEXT]:
      'Account recovery requires verified contact information',
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Phone Number',
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Submit',
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verify',
    [Translations.CONFIRM_SIGN_UP_FAILED]: 'Confirm Sign Up Failed',
    [Translations.SIGN_UP_FAILED]: 'Sign Up Failed'
  });
  I18n.putVocabulariesForLanguage('de', {
    'Custom auth lambda trigger is not configured for the user pool.':
      'Password darf nicht leer sein!',
    'Incorrect username or password.': 'Ungültiger Benutzer oder Passwort!',
    [Translations.BACK_TO_SIGN_IN]: 'Zurück zum Login',
    [Translations.CHANGE_PASSWORD_ACTION]: 'Ändern',
    [Translations.CHANGE_PASSWORD]: 'Passwort ändern',
    [Translations.CODE_LABEL]: 'Bestätigungscode',
    [Translations.CODE_PLACEHOLDER]: 'Code eingeben',
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungscode',
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Code eingeben',
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Registrierung bestätigen',
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: 'Code verloren?',
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Neuen Code senden',
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Bestätigen',
    [Translations.CONFIRM_SMS_CODE]: 'SMS Bestätigungscode',
    [Translations.CONFIRM]: 'Bestätigen',
    [Translations.CREATE_ACCOUNT_TEXT]: 'Restaurant bei getsby anlegen',
    [Translations.EMAIL_LABEL]: 'E-Mail Adresse *',
    [Translations.EMAIL_PLACEHOLDER]: 'Geben Sie Ihre E-Mail an',
    [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
    [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Neues Passwort eingeben',
    [Translations.NO_ACCOUNT_TEXT]: 'Noch nicht registriert?',
    [Translations.PASSWORD_LABEL]: 'Passwort *',
    [Translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
    [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurücksetzen',
    [Translations.RESET_YOUR_PASSWORD]: 'Passwort zurücksetzen',
    [Translations.SEND_CODE]: 'Code senden',
    [Translations.SUBMIT]: 'Abschicken',
    [Translations.SIGN_IN_ACTION]: 'Einloggen',
    [Translations.SIGN_IN_HEADER_TEXT]:
      'Im getsby Dashboard für Restaurants einloggen',
    [Translations.SIGN_IN_TEXT]: 'Einloggen',
    [Translations.SIGN_OUT]: 'Abmelden',
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'E-Mail',
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: 'Bereits registriert?',
    [Translations.SIGN_UP_HEADER_TEXT]:
      'Registrieren Sie Ihr Restaurant jetzt um getsby zu nutzen',
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Passwort',
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Jetzt Registrieren',
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Username',
    [Translations.USERNAME_LABEL]: 'Username *',
    [Translations.USERNAME_PLACEHOLDER]: 'Usernamen angeben',
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'E-Mail',
    [Translations.VERIFY_CONTACT_HEADER_TEXT]:
      'Account recovery requires verified contact information',
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Phone Number',
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Absenden',
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Bestätigen',
    [Translations.CONFIRM_SIGN_UP_FAILED]: 'Konnte Account nicht bestätigen',
    [Translations.SIGN_UP_FAILED]: 'Registrierung fehlgeschlagen',
    [Translations.EMPTY_USERNAME]: 'E-Mail Adresse darf nicht leer sein!',
    [Translations.INVALID_USERNAME]: 'Ungültiger Benutzer!'
  });
}
