import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Box,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Theme
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SimpleDialog from './SimpleDialog';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { endOfDay } from 'date-fns/esm';
import { add } from 'date-fns';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { openReceiptsState, updateState } from '../store/gastronomy';
import { useMutation } from '@apollo/client';
import {
  AdminUpdateReceiptMutationDocument,
  CancelOrderItemsMutationDocument
} from '../services/graphql/typed-operations';
import { getReceiptDataFromReceipt } from '../helpers/ReceiptHelper';
import { ReceiptDataItem } from 'models';
import { isAdminSelector, userAtom } from '../store/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    formControl: {
      width: '100%'
    },
    declineMessageInput: {
      width: '100%',
      margin: theme.spacing(2, 0)
    }
  })
);

interface Props {
  receipt: FullReceiptFragment;
  setReceipt: (receipt: FullReceiptFragment) => void;
  isOpen: boolean;
  onClose: () => void;
}

type DisabledUntil = 'not_disabled' | 'today' | 'tomorrow' | 'disable';

type OrderItemsToCancel = {
  id: string;
  isChecked: boolean;
  orderItemUuids: string[];
  productUuid?: string;
  title: string;
  amount: number;
  until: DisabledUntil;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const getInitialValues = (items: ReceiptDataItem[]): OrderItemsToCancel[] => {
  return items.map((i) => {
    return {
      id: i.id,
      isChecked: false,
      orderItemUuids: i.orderItemUuids,
      productUuid: i.productUuid,
      amount: i.orderItemUuids.length,
      title: i.title,
      until: 'not_disabled'
    };
  });
};

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const CancelOrderDialog: React.FC<Props> = ({
  receipt,
  isOpen,
  onClose,
  setReceipt
}) => {
  const receiptData = getReceiptDataFromReceipt(receipt);
  const cancelableItems = useMemo(() => {
    if (receiptData) {
      return receiptData.items.filter((i) => !i.is_canceled && !i.is_negative);
    }
    return [];
  }, [receiptData]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [orderItemsToCancel, setOrderItemsToCancel] = useState<
    OrderItemsToCancel[]
  >(getInitialValues(cancelableItems));
  const [cancelMessage, setCancelMessage] = useState<string>('');
  const classes = useStyles();
  const isAdmin = useRecoilValue(isAdminSelector);
  const user = useRecoilValue(userAtom);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setOpenReceipts = useSetRecoilState(openReceiptsState);
  const [
    cancelOrderItemsMutation,
    { loading: cancelOrderItemsLoading }
  ] = useMutation(CancelOrderItemsMutationDocument);
  const [
    updateReceiptMutation,
    { loading: updateReceiptLoading }
  ] = useMutation(AdminUpdateReceiptMutationDocument);

  useEffect(() => {
    setOrderItemsToCancel(isOpen ? getInitialValues(cancelableItems) : []);
  }, [isOpen]);

  const onCheckboxChange = (id: string) => (
    _event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderItemsToCancel((current) =>
      current.map((item) => {
        if (item.id === id) {
          return { ...item, isChecked: !item.isChecked };
        }
        return item;
      })
    );
  };

  const onAmountChange = (id: string) => (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setOrderItemsToCancel((current) =>
      current.map((item) => {
        if (item.id === id) {
          return { ...item, amount: +(event.target.value as string) };
        }
        return item;
      })
    );
  };

  const onUntilChange = (id: string) => (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setOrderItemsToCancel((current) =>
      current.map((item) => {
        if (item.id === id) {
          return { ...item, until: event.target.value as DisabledUntil };
        }
        return item;
      })
    );
  };

  const cancelOrderItems = async () => {
    if (receipt) {
      try {
        const input: OrderItemsCancelInput = {
          uuids: []
        };

        for (const itemToCancel of orderItemsToCancel) {
          if (itemToCancel.isChecked) {
            input.uuids = [
              ...input.uuids,
              ...itemToCancel.orderItemUuids.slice(0, itemToCancel.amount)
            ];
            if (
              itemToCancel.productUuid &&
              itemToCancel.until !== 'not_disabled'
            ) {
              const outOfStockInput: OutOfStockInput = {
                productUuid: itemToCancel.productUuid,
                until: calculateUntilValue(itemToCancel.until)
              };
              input.outOfStock = input.outOfStock
                ? [...input.outOfStock, outOfStockInput]
                : [outOfStockInput];
            }
          }
        }

        const { data } = await cancelOrderItemsMutation({
          variables: { input }
        });

        if (!data) {
          return;
        }

        const result = data.cancelOrderItems;

        if (result && result.length) {
          const newReceipt = result[0].receipt;

          setReceipt(newReceipt);

          updateState<ReceiptFragment>(newReceipt, setOpenReceipts);

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Bestellung ${receipt.uuid} geändert`
              }
            ],
            setSnackbarMessages
          );
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      onClose();
    }
  };

  const cancelReceipt = async () => {
    if (receipt) {
      try {
        const input: ReceiptUpdateInput = {
          uuid: receipt.uuid,
          status: 'ERROR'
        };

        if (isAdmin) {
          input.error = {
            code: 'CANCELLED_BY_ADMIN',
            message: `Cancelled by ${
              user ? user.email : 'admin'
            }: ${cancelMessage}`
          };
        } else {
          input.error = {
            code: 'DECLINED_WITH_MESSAGE',
            message: cancelMessage
          };
        }

        const { data } = await updateReceiptMutation({
          variables: { input }
        });

        if (!data) {
          return;
        }

        const result = data.adminUpdateReceipt;

        setReceipt(result);

        updateState<ReceiptFragment>(result, setOpenReceipts);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Bestellung ${receipt.uuid} storniert`
            }
          ],
          setSnackbarMessages
        );
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
      onClose();
    }
  };

  const cancelOrder = async () => {
    if (tabIndex === 0) {
      await cancelOrderItems();
    } else {
      await cancelReceipt();
    }
  };

  const calculateUntilValue = (until: string) => {
    switch (until) {
      case 'today':
        return endOfDay(new Date()).toISOString();
      case 'tomorrow':
        return endOfDay(add(new Date(), { days: 1 })).toISOString();
      case 'disable':
        return undefined;
    }
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const CancelForm = (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          centered
        >
          <Tab label="Teile der Bestellung stornieren" />
          <Tab label="Gesamte Bestellung stornieren" />
        </Tabs>
      </AppBar>

      <Paper square>
        <TabPanel value={tabIndex} index={0}>
          <Alert variant="filled" severity={'info'} className={classes.alert}>
            <AlertTitle>Information</AlertTitle>
            Dem Kunden wird der Betrag automatisch gutgeschrieben.
          </Alert>
          {orderItemsToCancel.map((item, i) => (
            <Grid container spacing={3} key={item.id}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.isChecked}
                      value={item.id}
                      onChange={onCheckboxChange(item.id)}
                      color="primary"
                    />
                  }
                  label={item.title}
                />
              </Grid>
              {item.isChecked && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Anzahl</InputLabel>
                      <Select
                        value={item.amount}
                        onChange={onAmountChange(item.id)}
                        labelId="until"
                        label="Anzahl"
                      >
                        {item.orderItemUuids.map((uuid, i) => (
                          <MenuItem value={i + 1} key={uuid}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Produkt deaktivieren für</InputLabel>
                      <Select
                        value={item.until}
                        onChange={onUntilChange(item.id)}
                        labelId="until"
                        label="Produkt deaktivieren für"
                      >
                        <MenuItem value={'not_disabled'}>
                          Produkt nicht deaktivieren
                        </MenuItem>
                        <MenuItem value={'today'}>Heute</MenuItem>
                        <MenuItem value={'tomorrow'}>Heute und Morgen</MenuItem>
                        <MenuItem value={'disable'}>Dauerhaft</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Alert
            variant="filled"
            severity={'warning'}
            className={classes.alert}
          >
            <AlertTitle>Achtung!</AlertTitle>
            Bitte beachten Sie, dass Bestellungen nur im äußersten Notfall
            storniert werden sollten!
            <br />
            Jedes Mal, wenn Sie eine Bestellung stornieren, gibt es auf der
            anderen Seite des Internets einen traurigen Kunden, der von Ihrem
            Restaurant enttäuscht sein wird und vielleicht nie wieder bei Ihnen
            bestellen wird. Auch kann es sein, dass der Kunde eine negative
            Bewertung über Sie abgibt.
          </Alert>
          <TextField
            label="Grund"
            multiline
            rows={4}
            value={cancelMessage}
            onChange={(event) => setCancelMessage(event.target.value)}
            variant="outlined"
            className={classes.declineMessageInput}
          />
        </TabPanel>
      </Paper>
    </div>
  );

  const canCancelOrder = (): boolean => {
    if (cancelOrderItemsLoading || updateReceiptLoading) {
      return false;
    }

    if (
      tabIndex === 0 &&
      orderItemsToCancel.reduce((prev, item) => {
        return prev + (item.isChecked ? 1 : 0);
      }, 0) === 0
    ) {
      return false;
    }

    return true;
  };

  return (
    <SimpleDialog
      open={isOpen}
      title={`Bestellung ${receipt.pickup_code} stornieren`}
      text={CancelForm}
      onPositiveClose={cancelOrder}
      onNegativeClose={onClose}
      buttonPositiveLabel={'Stornieren'}
      buttonNegativeLabel={'Zurück'}
      positiveDisabled={!canCancelOrder()}
    />
  );
};

export default CancelOrderDialog;
