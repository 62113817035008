import { Chip, colors, Tooltip } from '@material-ui/core';
import {
  CardGiftcardRounded,
  ErrorRounded,
  InfoRounded
} from '@material-ui/icons';
import AlarmOnRounded from '@material-ui/icons/AlarmOnRounded';
import AlarmRounded from '@material-ui/icons/AlarmRounded';
import BluetoothRounded from '@material-ui/icons/BluetoothRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import HelpRounded from '@material-ui/icons/HelpRounded';
import LocalBarRounded from '@material-ui/icons/LocalBarRounded';
import LocalCafeRounded from '@material-ui/icons/LocalCafeRounded';
import RestaurantRounded from '@material-ui/icons/RestaurantRounded';
import SettingsEthernetRounded from '@material-ui/icons/SettingsEthernetRounded';
import WarningIcon from '@material-ui/icons/Warning';
import UsbRounded from '@material-ui/icons/UsbRounded';
import { PaymentProvider } from 'models';
import React from 'react';

const getProductGroupIcon = (type?: ProductGroupType | 'ALL'): JSX.Element => {
  switch (type) {
    case 'FOOD':
      return (
        <Tooltip title="Speisen">
          <RestaurantRounded />
        </Tooltip>
      );
    case 'ALCOHOL':
      return (
        <Tooltip title="Alkoholische Getränke">
          <LocalBarRounded />
        </Tooltip>
      );
    case 'DRINKS':
      return (
        <Tooltip title="Alkoholfreie Getränke">
          <LocalCafeRounded />
        </Tooltip>
      );
    case 'OTHER':
      return (
        <Tooltip title="Sonstige">
          <HelpRounded />
        </Tooltip>
      );
    case 'EXTERNAL_COUPON':
      return (
        <Tooltip title="Fremdgutscheine">
          <CardGiftcardRounded />
        </Tooltip>
      );
    case 'ALL':
      return <DoneRounded />;
    default:
      return (
        <Tooltip title="Nicht zugeordnet">
          <HelpRounded />
        </Tooltip>
      );
  }
};

const getPrinterConnectionIcon = (
  connection: 'ETHERNET' | 'USB' | 'BLUETOOTH'
): JSX.Element => {
  switch (connection) {
    case 'ETHERNET':
      return <SettingsEthernetRounded />;
    case 'USB':
      return <UsbRounded />;
    case 'BLUETOOTH':
      return <BluetoothRounded />;
    default:
      return <HelpRounded />;
  }
};

const getProductGroupLabel = (type: ProductGroupType | 'ALL'): string => {
  switch (type) {
    case 'FOOD':
      return 'Speisen';
    case 'ALCOHOL':
      return 'Alkoholische Getränke';
    case 'DRINKS':
      return 'Getränke';
    case 'OTHER':
      return 'Sonstige';
    case 'ALL':
      return 'Alle';
    default:
      return type as string;
  }
};

const getPrinterConnectionLabel = (
  connection: 'ETHERNET' | 'USB' | 'BLUETOOTH'
): string => {
  switch (connection) {
    case 'ETHERNET':
      return 'Ethernet';
    case 'USB':
      return 'USB';
    case 'BLUETOOTH':
      return 'Bluetooth';
    default:
      return connection as string;
  }
};

export const getPosTypeLabel = (pos?: HasPosType | PosType): string => {
  if (!pos) {
    return 'Unbekannt';
  }

  switch (pos) {
    case 'OTHER':
      return 'Anderes';
    case 'MATRIX':
      return 'Matrix POS';
    case 'GKS':
      return 'GKS';
    case 'OCTOBOX':
      return 'octobox';
    case 'MELZER':
      return 'Melzer X3000';
    case 'CASHIT':
      return 'CashIT';
    case 'WINCARAT':
      return 'WinCarat TBT';
    case 'GETSBY':
      return 'getsby';
    case 'GETSBYKASSA':
      return 'getsby Kassa';
    case 'OBONO':
      return 'obono';
    default:
      return pos as string;
  }
};

const getOrderPreOrderChip = (
  receipt: FullReceiptFragment,
  types: Array<
    'reservation' | 'estimated_pickup_time' | 'requested_pickup_time'
  >
): JSX.Element | null => {
  if (
    receipt.estimated_pickup_time &&
    types.includes('estimated_pickup_time')
  ) {
    const date = new Date(receipt.estimated_pickup_time);

    return (
      <Tooltip title="Bereit">
        <Chip
          icon={<AlarmOnRounded />}
          label={date.toLocaleString()}
          color="default"
          size="small"
        />
      </Tooltip>
    );
  } else if (
    receipt.requested_pickup_time &&
    types.includes('requested_pickup_time')
  ) {
    const date = new Date(receipt.requested_pickup_time);

    return (
      <Tooltip title="Vorbestellt">
        <Chip
          icon={<AlarmRounded />}
          label={date.toLocaleString()}
          color="default"
          size="small"
        />
      </Tooltip>
    );
  }

  return null;
};

const getPaymentStatusChip = (date: string | undefined): React.ReactNode => {
  if (date) {
    return (
      <Chip
        icon={<DoneRounded />}
        label={new Date(date).toLocaleString()}
        style={{ backgroundColor: colors.green[500] }}
        size="small"
      />
    );
  }

  return (
    <Chip
      icon={<AlarmRounded />}
      label="Ausstehend"
      color="default"
      size="small"
    />
  );
};

export const getSeverityChip = (
  severity: 'info' | 'warning' | 'error' | 'success' | string
): React.ReactNode => {
  let label = '';
  let color = '';
  let icon;

  switch (severity) {
    case 'info':
      label = 'Info';
      color = colors.blue[500];
      icon = <InfoRounded />;
      break;
    case 'warning':
      label = 'Warnung';
      icon = <WarningIcon />;
      color = colors.amber[500];
      break;
    case 'error':
      label = 'Fehler';
      icon = <ErrorRounded />;
      color = colors.red[500];
      break;
    case 'success':
      label = 'Erfolg';
      icon = <DoneRounded />;
      color = colors.green[500];
      break;
    default:
      label = 'Info';
      icon = <InfoRounded />;
      color = colors.blue[500];
      break;
  }

  return (
    <Chip
      icon={icon}
      label={label}
      size="small"
      style={{ backgroundColor: color, color: 'white' }}
    />
  );
};

export const getPaymentTypeLabel = (type?: PaymentMethodType): string => {
  switch (type) {
    case 'CASH':
      return 'Barzahlung';
    case 'NONE':
      return 'Keine Bezahlung';
    case 'GOOGLE_PAY':
      return 'Google Pay';
    case 'APPLE_PAY':
      return 'Apple Pay';
    case 'SOFORT':
      return 'Sofortüberweisung';
    case 'CARD':
      return 'Debit- / Kreditkarte';
    case 'ALIPAY':
      return 'Alipay';
    case 'EPS':
      return 'eps-Überweisung';
    default:
      return '';
  }
};
const getPaymentProviderImage = (
  type: PaymentMethodType,
  payment: PaymentProvider | null
): JSX.Element | string => {
  const path = `${process.env.PUBLIC_URL}/img/`;

  if (type === 'CARD' && payment) {
    switch (payment.brand) {
      case 'Visa':
        return (
          <Tooltip title={payment.brand}>
            <img
              src={path + '007-visa.svg'}
              height="40px"
              width="auto"
              alt=""
            />
          </Tooltip>
        );
      case 'MasterCard':
        return (
          <Tooltip title={payment.brand}>
            <img
              src={path + '023-mastercard-1.svg'}
              height="40px"
              width="auto"
              alt=""
            />
          </Tooltip>
        );
      case 'American Express':
        return (
          <Tooltip title={payment.brand}>
            <img
              src={path + '045-american-express.svg'}
              height="50px"
              width="auto"
              alt=""
            />
          </Tooltip>
        );
      default:
        return payment.brand ? payment.brand : '';
    }
  } else if (type === 'APPLE_PAY') {
    return (
      <Tooltip title={'Apple Pay'}>
        <img src={path + 'apple-pay.svg'} height="25px" width="auto" alt="" />
      </Tooltip>
    );
  } else if (type === 'GOOGLE_PAY') {
    return (
      <Tooltip title={'Google Pay'}>
        <img src={path + 'google-pay.svg'} height="25px" width="auto" alt="" />
      </Tooltip>
    );
  } else if (type === 'SOFORT') {
    return (
      <Tooltip title={'Sofortüberweisung'}>
        <img src={path + 'klarna.svg'} height="25px" width="auto" alt="" />
      </Tooltip>
    );
  } else if (type === 'ALIPAY') {
    return (
      <Tooltip title={'Ali Pay'}>
        <img src={path + 'alipay.svg'} height="20px" width="auto" alt="" />
      </Tooltip>
    );
  } else if (type === 'CASH') {
    return (
      <Tooltip title={'Bar'}>
        <img src={path + 'euro.svg'} height="25px" width="auto" alt="" />
      </Tooltip>
    );
  } else if (type === 'EPS') {
    return (
      <Tooltip title={'eps-Überweisung'}>
        <img src={path + 'eps.svg'} height="25px" width="auto" alt="" />
      </Tooltip>
    );
  }

  return '';
};

const getPrinterLabel = (printer?: PrinterFragment): string => {
  if (!printer) {
    return '';
  }

  let label: string = printer.name || '';

  if (Array.isArray(printer.rooms) && printer.rooms.length) {
    label = label.concat(' (');

    printer.rooms.forEach((room, index) => {
      label = label.concat(room.title);

      if (printer.rooms && index < printer.rooms.length - 1) {
        label = label.concat(', ');
      }
    });

    label = label.concat(')');
  }

  return label;
};

const getAuthorizerLabel = (authorizerType?: AuthorizerType): string => {
  switch (authorizerType) {
    case 'COGNITO':
      return 'E-Mail';
    case 'FACEBOOK':
      return 'Facebook';
    case 'GOOGLE':
      return 'Google';
    case 'GUEST':
      return 'Gast';
    default:
      return authorizerType as string;
  }
};

const getPageSize = (items: number): number => {
  if (items <= 10) {
    return 10;
  } else if (items > 10 && items <= 20) {
    return 20;
  } else {
    return 50;
  }
};

export function getPayoutStatusLabel(status: PayoutStatus): string {
  if (!status) {
    return 'Fehler';
  }

  switch (status) {
    case 'PAID':
      return 'Bezahlt';
    case 'PENDING':
      return 'In Bearbeitung';
    case 'IN_TRANSIT':
      return 'Überweisung in Auftrag';
    case 'CANCELED':
      return 'Abgebrochen';
    case 'FAILED':
      return 'Fehlgeschlagen';
    default:
      return status as string;
  }
}

export function getPayoutStatusColor(status: PayoutStatus): string {
  switch (status) {
    case 'CANCELED':
      return colors.amber[500];
    case 'PENDING':
      return colors.purple[500];
    case 'IN_TRANSIT':
      return colors.blue[500];
    case 'PAID':
      return colors.green[500];
    case 'FAILED':
      return colors.red[500];
    default:
      return colors.grey[500];
  }
}

export function getAvailabilityTimesCount(
  availabilityTimes?: AvailabilityTimes | null
): number {
  let count = 0;

  if (!availabilityTimes) {
    return count;
  }

  for (const key of Object.keys(availabilityTimes)) {
    const value = getKeyValue<AvailabilityTimes, keyof AvailabilityTimes>(
      key as keyof AvailabilityTimes
    )(availabilityTimes);

    if (value && Array.isArray(value)) {
      count += value.length;
    }
  }

  return count;
}

export function isPreOrderAllowed(allowedTableTypes: TableType[]): boolean {
  return ([
    'DELIVERY',
    'TAKEAWAY',
    'DRIVEIN',
    'SELFSERVICE'
  ] as TableType[]).some((tt) => allowedTableTypes.includes(tt));
}

export function getCurrentBasePath(): string {
  return `${location.protocol}//${location.hostname}${
    location.port ? ':' + location.port : ''
  }`;
}

export const getKeyValue = <T, U extends keyof T>(key: U) => (obj: T) =>
  obj[key];

export const getBonusProgramStatus = ({
  bonusProgram,
  user_uuid
}: {
  bonusProgram?: BonusProgramFragment;
  user_uuid?: string;
}): boolean => {
  if (!bonusProgram || !user_uuid) {
    return false;
  }

  if (Array.isArray(bonusProgram.gastronomies)) {
    const found = bonusProgram.gastronomies.find((g) => g.uuid === user_uuid);

    if (!found) {
      return false;
    }

    return found.status;
  }

  return false;
};

export {
  getProductGroupIcon,
  getPrinterConnectionIcon,
  getProductGroupLabel,
  getPrinterConnectionLabel,
  getOrderPreOrderChip,
  getPaymentStatusChip,
  getPaymentProviderImage,
  getPrinterLabel,
  getAuthorizerLabel,
  getPageSize
};
