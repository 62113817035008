import { useApolloClient } from '@apollo/client';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip
} from '@material-ui/core';
import { isFuture } from 'date-fns';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { AdminUpdateProductMutationDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { hasPosSelector } from '../store/gastronomy';

interface Props {
  product: FullProductFragment;
  gastronomy: GastronomyFragment;
}

const ProductStatus: React.FC<Props> = ({ product, gastronomy }) => {
  const [status, setStatus] = useState(
    product.status === 'ACTIVE' ? true : false
  );
  const [statusOverride, setStatusOverride] = useState(
    product.status === 'INACTIVE'
      ? false
      : product.status_override === 'INACTIVE'
      ? false
      : true
  );
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const update = async ({
    newStatus,
    newStatusOverride
  }: {
    newStatus?: boolean;
    newStatusOverride?: boolean;
  }) => {
    try {
      const input: ProductUpdateInput = {
        user_uuid: gastronomy.uuid,
        uuid: product.uuid
      };

      if (hasPos) {
        input.status_override = newStatusOverride ? 'ACTIVE' : 'INACTIVE';
      } else {
        input.status = newStatus ? 'ACTIVE' : 'INACTIVE';
      }

      const { data } = await apolloClient.mutate({
        mutation: AdminUpdateProductMutationDocument,
        variables: { input }
        // I think this is not necessary as it updates the cache accordingly
        // refetchQueries: ['getProductsQuery']
      });

      if (!data) {
        return;
      }

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Status erfolgreich aktualisiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const toggleStatus = async () => {
    const newStatus = !status;

    setStatus(newStatus);

    await update({ newStatus });
  };

  const toggleStatusOverride = async () => {
    const newStatusOverride = !statusOverride;

    setStatusOverride(newStatusOverride);

    await update({ newStatusOverride });
  };

  return (
    <Tooltip title="In der App anzeigen">
      <>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={hasPos}
                  checked={status}
                  onChange={toggleStatus}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={hasPos ? 'POS Status' : 'Status'}
            />
            {hasPos && (
              <FormControlLabel
                control={
                  <Switch
                    checked={statusOverride}
                    onChange={toggleStatusOverride}
                    color="primary"
                    disabled={!status}
                  />
                }
                labelPlacement="start"
                label="Überschreiben"
              />
            )}
          </FormGroup>
        </FormControl>
        {product.out_of_stock_until &&
          isFuture(new Date(product.out_of_stock_until)) && (
            <div>Ausverkauft!</div>
          )}
      </>
    </Tooltip>
  );
};

export default ProductStatus;
