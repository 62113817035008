import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import HeaderContainer from '../components/HeaderContainer';
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import { lazyRetry } from '../helpers/lazyRetry';
import CircularIndeterminate from '../components/CircularIndeterminate';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
const GastronomyRegisterForm = React.lazy(() =>
  lazyRetry(() => import('../components/GastronomyRegisterForm'))
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    }
  })
);

const CompleteProfile: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);

  if (gastronomy) {
    return <Redirect to="/profile-wizard" />;
  }

  return (
    <Suspense fallback={<CircularIndeterminate />}>
      <HeaderContainer title={'Registrierung abschließen'} />
      <Paper className={classes.paper}>
        <Typography variant="h4">Stammdaten</Typography>
        <GastronomyRegisterForm />
      </Paper>
    </Suspense>
  );
};

export default CompleteProfile;
