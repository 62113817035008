import { useApolloClient } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Joi from 'joi';
import React, { ChangeEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  AddProductModifierMutationDocument,
  UpdateProductModifierMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    card: {
      margin: theme.spacing(3, 0)
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    input: {
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3)
    }
  })
);

interface ProductModifierFormInput {
  title: string;
  admin_title: string;
  description: string;
  min: number;
  max: number;
  order: number;
}

interface Props {
  modifier?: ModifierFragment;
  product_uuid?: string;
  onClose: () => void;
}

const schemaValues = Joi.object({
  title: Joi.string(),
  admin_title: Joi.string().allow(''),
  description: Joi.string().allow(''),
  min: Joi.number(),
  max: Joi.number().min(Joi.ref('min')),
  order: Joi.number(),
  status: Joi.boolean()
});

const ModifierForm: React.FC<Props> = ({ modifier, product_uuid, onClose }) => {
  const getInitialState = (modifier?: ModifierFragment) => {
    return {
      title: modifier?.title || '',
      admin_title: modifier?.admin_title || '',
      description: modifier?.description || '',
      min: modifier?.min || 0,
      max: modifier?.max || 1,
      order: modifier?.order || 1
    };
  };
  const classes = useStyles();
  const [values, setValues] = useState<ProductModifierFormInput>(
    getInitialState(modifier)
  );
  const [errors, setErrors] = useState<string[]>([]);
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues((prev) => ({
      ...prev,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const handleMinChange = (evt: ChangeEvent<{ name?: string; value: any }>) => {
    setValues((prev) => ({ ...prev, min: evt.target.value }));
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation error', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setValues(getInitialState(modifier));
  };

  const saveProductModifier = async () => {
    if (gastronomy) {
      try {
        const result = schemaValues.validate(values);
        if (!processJoiResult(result)) {
          addSnackbarMessages(
            [
              {
                type: 'error',
                message: 'Bitte überprüfen Sie Ihre Eingabe!'
              }
            ],
            setSnackbarMessages
          );
          return;
        }

        if (modifier) {
          const input: ProductModifierUpdateInput = {
            uuid: modifier.uuid,
            title: values.title,
            admin_title: values.admin_title,
            description: values.description,
            min: +values.min,
            max: +values.max,
            order: +values.order
          };

          const { data } = await apolloClient.mutate({
            mutation: UpdateProductModifierMutationDocument,
            variables: {
              input
            },
            refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
          });

          if (!data) {
            return;
          }

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Optionsgruppe ${values.title} verändert`
              }
            ],
            setSnackbarMessages
          );
        } else {
          const input: ProductModifierAddInput = {
            product_uuid,
            title: values.title,
            admin_title: values.admin_title,
            description: values.description,
            min: +values.min,
            max: +values.max,
            order: +values.order,
            user_uuid: gastronomy.uuid
          };

          const { data } = await apolloClient.mutate({
            mutation: AddProductModifierMutationDocument,
            variables: {
              input
            },
            refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
          });

          if (!data) {
            return;
          }

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `Optionsgruppe ${values.title} hinzugefügt`
              }
            ],
            setSnackbarMessages
          );

          resetForm();
        }
        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5">
          {modifier ? 'Optionsgruppe bearbeiten' : 'Neue Optionsgruppe'}
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <TextField
              error={errors.includes('title')}
              id="modifier-title"
              label="Bezeichnung"
              placeholder="Beilagen zum Mittagsmenü"
              className={classes.input}
              onChange={handleChange('title')}
              required={true}
              value={values.title}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              id="admin-title"
              label="Admin Bezeichnung"
              placeholder="Optionsgruppe für alle Mittagsmenüs"
              className={classes.input}
              onChange={handleChange('admin_title')}
              value={values.admin_title}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="modifier-description"
              label="Beschreibung"
              placeholder="Kurze Beschreibung"
              className={classes.input}
              onChange={handleChange('description')}
              required={false}
              value={values.description}
              margin="dense"
              variant="outlined"
              multiline={true}
              rows={2}
            />
          </Grid>
          <Grid item lg={4}>
            <FormControl
              className={classes.input}
              margin="dense"
              variant="outlined"
            >
              <InputLabel id="modifier-min">Minimalanzahl</InputLabel>
              <Select
                error={errors.includes('min')}
                value={values.min}
                onChange={handleMinChange}
                labelId="default-for-select"
                label="Standard"
                margin="dense"
                required={true}
              >
                <MenuItem key="0" value="0">
                  Optional
                </MenuItem>
                <MenuItem key="1" value="1">
                  Mindestauswahl 1
                </MenuItem>
                <MenuItem key="2" value="2">
                  Mindestauswahl 2
                </MenuItem>
                <MenuItem key="3" value="3">
                  Mindestauswahl 3
                </MenuItem>
                <MenuItem key="4" value="4">
                  Mindestauswahl 4
                </MenuItem>
                <MenuItem key="5" value="5">
                  Mindestauswahl 5
                </MenuItem>
                <MenuItem key="6" value="6">
                  Mindestauswahl 6
                </MenuItem>
                <MenuItem key="7" value="7">
                  Mindestauswahl 7
                </MenuItem>
                <MenuItem key="8" value="8">
                  Mindestauswahl 8
                </MenuItem>
                <MenuItem key="9" value="9">
                  Mindestauswahl 9
                </MenuItem>
                <MenuItem key="10" value="10">
                  Mindestauswahl 10
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4}>
            <TextField
              error={errors.includes('max')}
              id="modifier-max"
              label="Maximalanzahl"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              className={classes.input}
              onChange={handleChange('max')}
              required={true}
              value={values.max}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              error={errors.includes('order')}
              id="modifier-order"
              label="Reihenfolge"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              className={classes.input}
              onChange={handleChange('order')}
              required={true}
              value={values.order}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Verwerfen
              <CloseIcon className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item lg={6}>
            <Button
              onClick={saveProductModifier}
              className={classes.input}
              variant="contained"
              color="primary"
            >
              {modifier ? 'Speichern' : 'Hinzufügen'}
              <AddIcon className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ModifierForm;
