import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getTableTypeLabel } from '../helpers/GastronomyHelper';
import { getPrinterLabel } from '../helpers/MiscHelper';
import {
  AddProductGroupMutationDocument,
  GetCategoriesDocument,
  GetPrintersQueryDocument,
  GetProductGroupPrintersQueryDocument,
  UpdateProductGroupMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState, hasPosSelector } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      margin: theme.spacing(1, 0),
      width: '100%'
    },
    radioButton: {
      margin: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    }
  })
);

interface Props {
  productGroup?: FullProductGroupFragment;
  user_uuid: string;
  onClose: () => void;
  currentOrderNr?: number;
}

const ProductGroupForm: React.FC<Props> = ({
  productGroup,
  user_uuid,
  onClose,
  currentOrderNr = 0
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);
  const hasPos = useRecoilValue(hasPosSelector);
  const gastronomy = useRecoilValue(gastronomyState);
  const apolloClient = useApolloClient();
  const isAdmin = useRecoilValue(isAdminSelector);

  const { data: printerData } = useQuery(GetPrintersQueryDocument, {
    variables: { user_uuid: gastronomy?.uuid || 'NOT SET' },
    skip: !gastronomy
  });

  const { data: categoryData } = useQuery(GetCategoriesDocument, {
    variables: {
      params: { user_uuid: gastronomy?.uuid || 'NOT SET', status: 'ALL' }
    },
    skip: !gastronomy
  });

  const initialValues = {
    product_group_type: productGroup?.product_group_type || 'DRINKS',
    title: productGroup?.title || '',
    alias: productGroup?.alias || '',
    status: productGroup?.status || 'ACTIVE',
    description: productGroup?.description || '',
    category_uuid: productGroup?.category_uuid || '',
    show_in_cart: productGroup?.show_in_cart || false
  };

  const [values, setValues] = useState<{
    product_group_type: ProductGroupType;
    title: string;
    alias: string;
    status: StatusFlag;
    description: string;
    category_uuid?: string;
    show_in_cart?: boolean;
  }>(initialValues);
  const [productGroupPrinterId, setProductGroupPrinterId] = useState<string>(
    ''
  );
  const [orderTypeExclusions, setOrderTypeExclusions] = useState<TableType[]>(
    productGroup?.orderTypeExclusions || []
  );
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);

  useQuery(GetProductGroupPrintersQueryDocument, {
    variables: {
      product_group_uuid: productGroup ? productGroup.uuid : 'not set'
    },
    skip: !productGroup,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      setProductGroupPrinterId(
        data.getProductGroupPrinters.length
          ? data.getProductGroupPrinters[0].uuid
          : ''
      );
    }
  });

  const handleChange = (name: string) => (event: any) => {
    validateInput(name, event.target.value);

    setValues({
      ...values,
      [name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const validateInput = (name: string, value?: string): boolean => {
    if (!value) {
      return false;
    }
    let isValid = true;

    if (name === 'title') {
      if (value.length === 0) {
        isValid = false;
      }
    }

    if (isValid) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors([...errors, name]);
    }

    return isValid;
  };

  const handlePrinterChange = (uuid: string) => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setProductGroupPrinterId(uuid === productGroupPrinterId ? '' : uuid);
  };

  const handleOrderTypeChange = (_name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    if (event.target.checked) {
      setOrderTypeExclusions([...orderTypeExclusions, value as TableType]);
    } else {
      setOrderTypeExclusions(orderTypeExclusions.filter((id) => id !== value));
    }
  };

  const saveProductGroup = async () => {
    try {
      if (!validateInput('title', values.title)) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );

        return;
      }
      if (!values.category_uuid || values.category_uuid === '') {
        setErrors((prev) => [...prev, 'category']);
        return;
      }

      if (productGroup) {
        const { data } = await apolloClient.mutate({
          mutation: UpdateProductGroupMutationDocument,
          variables: {
            input: {
              ...values,
              uuid: productGroup.uuid,
              printerUuids: productGroupPrinterId
                ? [productGroupPrinterId]
                : [],
              orderTypeExclusions
            }
          }
        });

        if (!data) {
          return;
        }

        const newProductGroup = data.updateProductGroup;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Produktgruppe ${newProductGroup.title} aktualisiert`
            }
          ],
          setSnackbarMessages
        );
      } else {
        const input: ProductGroupAddInput = {
          product_group_type: values.product_group_type,
          title: values.title,
          description:
            values.description.length === 0 ? undefined : values.description,
          category_uuid: values.category_uuid,
          user_uuid,
          printerUuids: productGroupPrinterId ? [productGroupPrinterId] : [],
          orderTypeExclusions,
          order_nr: currentOrderNr
        };

        const { data } = await apolloClient.mutate({
          mutation: AddProductGroupMutationDocument,
          variables: { input },
          refetchQueries: ['getProductGroupsQuery']
        });

        if (!data) {
          return;
        }
        const newProductGroup = data.addProductGroup;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Produktgruppe ${newProductGroup.title} hinzugefügt`
            }
          ],
          setSnackbarMessages
        );
      }

      onClose();
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const Form: React.ReactNode = (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant={'h5'} className={classes.padding}>
          Produktgruppe {productGroup ? 'bearbeiten' : 'hinzufügen'}
        </Typography>
      </div>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={errors.includes('title')}
              id="title"
              label="Titel"
              placeholder="Lunchdeals"
              className={classes.input}
              required={true}
              value={values.title}
              onChange={handleChange('title')}
              margin="dense"
              disabled={hasPos}
              variant="outlined"
            />
            {hasPos && (
              <TextField
                id="alias"
                label="Titel Überschreiben"
                placeholder="Lunchdeals"
                className={classes.input}
                required={true}
                value={values.alias}
                onChange={handleChange('alias')}
                margin="dense"
                variant="outlined"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.input} variant="outlined">
              <InputLabel id="pg-type-select">Typ</InputLabel>
              <Select
                value={values.product_group_type}
                onChange={handleChange('product_group_type')}
                labelId="pg-type-select"
                label="Typ"
                margin="dense"
              >
                <MenuItem key={'DRINKS'} value={'DRINKS'}>
                  Alkoholfreie Getränke
                </MenuItem>
                <MenuItem key={'ALCOHOL'} value={'ALCOHOL'}>
                  Alkoholische Getränke
                </MenuItem>
                <MenuItem key={'FOOD'} value={'FOOD'}>
                  Speisen
                </MenuItem>
                <MenuItem key={'OTHER'} value={'OTHER'}>
                  Sonstige
                </MenuItem>
                <MenuItem
                  key={'EXTERNAL_COUPON'}
                  value={'EXTERNAL_COUPON'}
                  disabled={!isAdmin}
                >
                  Fremdgutscheine
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.input} variant="outlined">
              <InputLabel id="category-select">Kategorie</InputLabel>
              <Select
                error={errors.includes('category')}
                value={values.category_uuid}
                onChange={handleChange('category_uuid')}
                labelId="category-select"
                label="Kategorie"
                margin="dense"
                required={true}
              >
                {categoryData &&
                  categoryData.getCategories.map((category, index) => (
                    <MenuItem key={index} value={category.uuid}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label="Beschreibung"
              placeholder="Unsere Spezialitäten!"
              className={classes.input}
              value={values.description}
              onChange={handleChange('description')}
              margin="dense"
              multiline={true}
              rows={2}
              variant="outlined"
            />
          </Grid>
          {!hasPos && printerData && printerData.getPrinters.length > 0 && (
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.radioButton}>
                <FormLabel component="legend">Druckerzuweisung</FormLabel>
                <RadioGroup value={productGroupPrinterId}>
                  {printerData.getPrinters.map((printer) => (
                    <FormControlLabel
                      key={printer.uuid}
                      value={printer.uuid}
                      control={
                        <Radio onClick={handlePrinterChange(printer.uuid)} />
                      }
                      label={getPrinterLabel(printer)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {gastronomy &&
            gastronomy.options &&
            gastronomy.options.tableTypes &&
            gastronomy.options.tableTypes.length > 1 && (
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.radioButton}
                >
                  <FormLabel component="legend">
                    Diese Produktgruppe ist <b>NICHT</b> verfügbar für
                  </FormLabel>
                  <FormGroup row>
                    {gastronomy.options.tableTypes.map((tableType, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={orderTypeExclusions.includes(tableType)}
                            onChange={handleOrderTypeChange(tableType)}
                            value={tableType}
                            color="primary"
                          />
                        }
                        label={getTableTypeLabel(tableType)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    typeof values.show_in_cart === 'boolean'
                      ? values.show_in_cart
                      : false
                  }
                  value={values.show_in_cart}
                  onChange={handleChange('show_in_cart')}
                  color="primary"
                />
              }
              label={'Zeige in Warenkorb (z.B. Gutscheine)'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={onClose}
              className={classes.input}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={saveProductGroup}
              className={classes.input}
              variant="contained"
              color="primary"
            >
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <>
      {productGroup ? (
        <>{Form}</>
      ) : (
        <Paper className={classes.paper}>{Form}</Paper>
      )}
    </>
  );
};

export default ProductGroupForm;
