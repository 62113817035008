import { useApolloClient } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Switch,
  Theme,
  Typography
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { endOfToday, endOfTomorrow, format, isFuture, isPast } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getKeyValue } from '../helpers/MiscHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import { filterTablesByType } from '../helpers/TableHelper';
import usePrevious from '../hooks/usePrevious';
import {
  AdminUpdateTableMutationDocument,
  UpdateGastronomyMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import {
  gastronomyState,
  hasPosSelector,
  tablesState,
  updateState
} from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%'
    },
    dialogContent: {
      padding: theme.spacing(2),
      width: '100%'
    },
    button: {
      width: '100%'
    },
    input: {
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  })
);

interface Props {
  tables: TableFragment[];
  tableType: TableType;
}

const getSwitchValue = (
  tableType: TableType,
  gastronomy: GastronomyFragment | null | undefined,
  filteredTables: TableFragment[]
): boolean => {
  if (!gastronomy) {
    return false;
  }

  const propertyName = `${tableType.toLowerCase()}_disabled_until`;

  const disabled_until = gastronomy.hasOwnProperty(propertyName)
    ? (getKeyValue<GastronomyFragment, keyof GastronomyFragment>(
        propertyName as keyof GastronomyFragment
      )(gastronomy) as string | null | undefined)
    : null;

  if (!disabled_until && filteredTables.length === 1) {
    const table = filteredTables[0];
    const status = table.status_override || table.status;

    return status === 'ACTIVE' ? true : false;
  }

  return disabled_until ? isPast(new Date(disabled_until)) : true;
};

const getDisabledUntilDate = (
  tableType: TableType,
  gastronomy: GastronomyFragment | null | undefined
): Date | null => {
  if (!gastronomy) {
    return null;
  }

  const propertyName = `${tableType.toLowerCase()}_disabled_until`;

  const disabled_until = gastronomy.hasOwnProperty(propertyName)
    ? (getKeyValue<GastronomyFragment, keyof GastronomyFragment>(
        propertyName as keyof GastronomyFragment
      )(gastronomy) as string | null | undefined)
    : null;

  return disabled_until && isFuture(new Date(disabled_until))
    ? new Date(disabled_until)
    : null;
};

const OrderTypeDisabledUntilSwitch: React.FC<Props> = ({
  tables = [],
  tableType
}) => {
  const classes = useStyles();
  const filteredTables = useMemo(() => filterTablesByType(tables, tableType), [
    tables
  ]);
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(
    getSwitchValue(tableType, gastronomy, filteredTables)
  );
  const [disabledUntil, setDisabledUntil] = useState<Date | null>(
    getDisabledUntilDate(tableType, gastronomy)
  );
  const previousDate = usePrevious(disabledUntil);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const setTables = useSetRecoilState(tablesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setDisabledUntil(getDisabledUntilDate(tableType, gastronomy));
    setIsEnabled(getSwitchValue(tableType, gastronomy, filteredTables));
  }, [gastronomy, filteredTables]);

  const handleToggleSwitch = () => {
    const newValue = !isEnabled;

    if (newValue === false) {
      setIsOpen(true);
    } else {
      if (filteredTables.length === 1) {
        const table = filteredTables[0];

        toggleTableStatus(table, 'ACTIVE');
      }

      update(null);
    }
  };

  const update = async (date: Date | null) => {
    if (gastronomy) {
      try {
        const input = {
          uuid: gastronomy.uuid,
          [`${tableType.toLowerCase()}_disabled_until`]: date
            ? date.toISOString()
            : ''
        };

        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: { input }
        });

        if (data) {
          setIsOpen(false);
          setIsEnabled(date === null ? true : false);

          const newGastronomy = data.updateGastronomy;

          setGastronomy(newGastronomy);

          addSnackbarMessages(
            [
              {
                type: 'success',
                message: `${getOrderTypeLabel(tableType)} Status aktualisiert`
              }
            ],
            setSnackbarMessages
          );
        }
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const toggleTableStatus = async (
    table: TableFragment,
    status: StatusFlag
  ) => {
    try {
      const input: any = {
        uuid: table.uuid
      };

      if (hasPos) {
        input.status_override = status;
      } else {
        input.status = status;
      }

      const { data } = await apolloClient.mutate({
        mutation: AdminUpdateTableMutationDocument,
        variables: { input }
      });

      if (data) {
        setIsOpen(false);
        setIsEnabled(status === 'ACTIVE' ? true : false);

        const newTable = data.adminUpdateTable;

        updateState(newTable, setTables);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `${getOrderTypeLabel(tableType)} Status aktualisiert`
            }
          ],
          setSnackbarMessages
        );
      }
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  const handleDateChange = (
    date: Date | null,
    _value: string | null | undefined
  ) => {
    if (date !== previousDate) {
      update(date);
    }

    setDisabledUntil(date);
  };

  const handleButtonClick = (name: 'today' | 'tomorrow') => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    let date: Date | null = null;

    if (name === 'today') {
      date = endOfToday();
    }
    if (name === 'tomorrow') {
      date = endOfTomorrow();
    }

    update(date);
    setDisabledUntil(date);
  };

  const handleDisablePermanently = (table: TableFragment) => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (table) {
      toggleTableStatus(table, 'INACTIVE');
    }
  };

  if (filteredTables.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className={classes.container}>
        <FormControlLabel
          control={
            <Switch
              checked={isEnabled}
              onChange={handleToggleSwitch}
              name={`${tableType}-toggle`}
              color="primary"
            />
          }
          label={`${getOrderTypeLabel(tableType)} aktiv`}
        />
        <Typography variant="body2" component="p">
          {disabledUntil && (
            <>
              Deaktiviert bis{' '}
              {format(new Date(disabledUntil), 'dd.MM.yyyy HH:mm')}
            </>
          )}
        </Typography>
      </div>

      <Dialog
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle>{getOrderTypeLabel(tableType)} deaktivieren</DialogTitle>
        <div className={classes.dialogContent}>
          <ButtonGroup size="large" className={classes.input}>
            <Button
              onClick={handleButtonClick('today')}
              className={classes.button}
            >
              Heute
            </Button>
            <Button
              onClick={handleButtonClick('tomorrow')}
              className={classes.button}
            >
              Heute & Morgen
            </Button>
            {filteredTables.length === 1 && (
              <Button
                onClick={handleDisablePermanently(filteredTables[0])}
                className={classes.button}
              >
                Permanent
              </Button>
            )}
          </ButtonGroup>
          <KeyboardDateTimePicker
            label="Deaktiviert bis"
            value={disabledUntil}
            onChange={handleDateChange}
            format={'dd.MM.yyyy HH:mm'}
            ampm={false}
            disablePast={true}
            clearable={true}
            inputVariant="outlined"
            className={classes.input}
          />
        </div>
      </Dialog>
    </>
  );
};

export default OrderTypeDisabledUntilSwitch;
