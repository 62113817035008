import Storage from '@aws-amplify/storage';
import path from 'path';
import Config from '../config';

export async function uploadImageToS3(
  file: File,
  url_path_id: string,
  type: 'appBarLogo' | 'icon'
): Promise<string | null> {
  const key = `${new Date().getTime()}_${url_path_id}_${type}${path.extname(
    file.name
  )}`;

  console.log('Uploading file', file, key);

  const resultFromS3 = await Storage.put(key, file, {
    contentType: file.type,
    bucket: Config.s3ImageUploadBucket,
    customPrefix: {
      public: ''
    }
  });

  console.log('Uploaded to', resultFromS3);

  return resultFromS3 ? `${Config.s3ImageBaseUrl}/${key}` : null;
}

export async function removeFromS3(imageUrl: string): Promise<boolean> {
  const key = imageUrl.substring(
    imageUrl.lastIndexOf('/') + 1,
    imageUrl.length
  );

  console.log('Removing file', key);

  const resultFromS3 = await Storage.remove(key, {
    bucket: Config.s3ImageUploadBucket,
    customPrefix: {
      public: ''
    }
  });

  return !!resultFromS3;
}
