import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useUrlSearchParams from '../hooks/useUrlSearchParams';
import { userAtom } from '../store/auth';
import { gastronomyState } from '../store/gastronomy';

const ProtectedRoute: React.FC<RouteProps> = ({
  component,
  render,
  path,
  exact,
  location
}) => {
  const user = useRecoilValue(userAtom);
  const gastro = useRecoilValue(gastronomyState);
  const query = useUrlSearchParams();

  const signup = query.get('signup');

  const redirectUrl = location
    ? `?r=${encodeURIComponent(location.pathname + location.search)}`
    : '';

  if (gastro === null) {
    return null;
  }

  if (!user) {
    return (
      <Redirect to={`/auth${signup !== null ? '?signup' : redirectUrl}`} />
    );
  }
  if (
    user &&
    !user.groups.includes('admin') &&
    gastro === undefined &&
    path !== '/complete-signup'
  ) {
    return <Redirect to={`/complete-signup`} />;
  }
  return (
    <Route path={path} exact={exact} component={component} render={render} />
  );
};

export default ProtectedRoute;
