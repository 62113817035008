import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Hidden,
  Drawer,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  Chip
} from '@material-ui/core';
import {
  AccountBalanceWalletRounded,
  AccountCircleRounded,
  AnnouncementRounded,
  AssignmentRounded,
  BeenhereRounded,
  BookRounded,
  Category,
  ContactSupportRounded,
  DateRangeRounded,
  DesktopWindowsRounded,
  DirectionsCarRounded,
  FastfoodRounded,
  FileCopyRounded,
  HelpRounded,
  HomeRounded,
  InsertChartRounded,
  LocalShippingRounded,
  LoyaltyRounded,
  MailRounded,
  MoneyRounded,
  PersonAddRounded,
  ReceiptRounded,
  RedeemRounded,
  RestaurantRounded,
  SchoolRounded,
  ShoppingBasketRounded,
  ShoppingCartRounded,
  SmartphoneRounded,
  StyleRounded,
  SupervisorAccountRounded,
  ViewModuleRounded,
  WebRounded
} from '@material-ui/icons';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import WarningIcon from '@material-ui/icons/Warning';
import Config from '../config';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import GastronomySelect from './GastronomySelect';
import { Link } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { DRAWER_WIDTH } from '../constants';
import { useRecoilValue } from 'recoil';
import { isAdminSelector, userAtom } from '../store/auth';
import { snackbarMessagesState } from '../store/app';
import { useResetRecoilState } from 'recoil';
import {
  gastronomyState,
  hasPosSelector,
  messageLogsState,
  openReceiptsState,
  printersState,
  roomsState,
  tablesState
} from '../store/gastronomy';
import { isStorageAvailable } from '../helpers/StorageHelper';
import GastronomyStatusLabel from './GastronomyStatusLabel';
import { useApolloClient } from '@apollo/client';
import SideMenuSwitches from './SideMenuSwitches';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: DRAWER_WIDTH,
        flexShrink: 0
      }
    },
    grow: {
      flexGrow: 1
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'center'
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    }
  })
);

interface Props {
  isMenuOpen: boolean;
  onChangeMenuOpen: (value: boolean) => void;
}

const SideMenu: React.FC<Props> = ({ isMenuOpen, onChangeMenuOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const isAdmin = useRecoilValue(isAdminSelector);
  const user = useRecoilValue(userAtom);

  const apolloClient = useApolloClient();

  const resetRecoilUser = useResetRecoilState(userAtom);

  const resetRecoilGastronomyState = useResetRecoilState(gastronomyState);
  const resetRecoilTables = useResetRecoilState(tablesState);
  const resetRecoilRooms = useResetRecoilState(roomsState);
  const resetRecoilPrinters = useResetRecoilState(printersState);
  const resetRecoilOpenReceipts = useResetRecoilState(openReceiptsState);
  const resetRecoilSnackbarMessages = useResetRecoilState(
    snackbarMessagesState
  );
  const messageLogs = useRecoilValue(messageLogsState);

  const resetUser = () => {
    resetRecoilUser();
    resetRecoilSnackbarMessages();
    resetRecoilGastronomyState();
    resetRecoilOpenReceipts();
    resetRecoilTables();
    resetRecoilRooms();
    resetRecoilPrinters();

    if (isStorageAvailable()) {
      localStorage.clear();
    }

    Auth.signOut();
    apolloClient.resetStore();
    window.location.href = '/auth';
  };

  const createDrawerLink = (path: string, external?: boolean) => {
    return React.forwardRef<HTMLAnchorElement>((props, ref) => (
      <>
        {external ? (
          <a href={path} rel="noopener noreferrer" target="_blank" {...props}>
            {props.children}
          </a>
        ) : (
          <Link
            innerRef={ref}
            to={path}
            {...props}
            onClick={() => onChangeMenuOpen(false)}
          />
        )}
      </>
    ));
  };

  const DrawerContent: React.ReactNode = (
    <>
      <div className={classes.drawerHeader}>
        {isAdmin ? <GastronomySelect /> : <GastronomyStatusLabel />}
      </div>
      <Divider />
      {gastronomy && gastronomy.status === 'ENABLED' && (
        <>
          <SideMenuSwitches />
          <Divider />
        </>
      )}
      <List>
        {user && !isAdmin && !gastronomy && (
          <ListItem button component={createDrawerLink('/complete-signup')}>
            <ListItemIcon>
              <AccountCircleRounded />
            </ListItemIcon>
            <ListItemText primary={'Profil'} />
          </ListItem>
        )}
        {gastronomy && (
          <>
            <ListItem disabled={true}>
              <ListItemText primary={'Restaurant'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/')}>
              <ListItemIcon>
                <HomeRounded />
              </ListItemIcon>
              <ListItemText primary={'Offene Bestellungen'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/profile')}>
              <ListItemIcon>
                <AccountCircleRounded />
              </ListItemIcon>
              <ListItemText primary={'Profil'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/guest-registration')}
            >
              <ListItemIcon>
                <BookRounded />
              </ListItemIcon>
              <ListItemText primary={'Gästeregistrierung'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/bonus')}>
              <ListItemIcon>
                <RedeemRounded />
              </ListItemIcon>
              <ListItemText primary={'Bonuspunkte Programm'} />
            </ListItem>
            {gastronomy.app && (
              <ListItem button component={createDrawerLink('/apps')}>
                <ListItemIcon>
                  <SmartphoneRounded />
                </ListItemIcon>
                <ListItemText primary={'App'} />
              </ListItem>
            )}
            <ListItem
              button
              component={createDrawerLink(
                `/newsletter${gastronomy.app ? '/app' : ''}`
              )}
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary={'Newsletter'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/pages')}>
              <ListItemIcon>
                <WebRounded />
              </ListItemIcon>
              <ListItemText primary={'Freie Seiten'} />
            </ListItem>

            <ListItem button component={createDrawerLink('/opening-times')}>
              <ListItemIcon>
                <DateRangeRounded />
              </ListItemIcon>
              <ListItemText primary={'Öffnungszeiten'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/messages')}>
              <ListItemIcon>
                <AnnouncementRounded />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    Nachrichten
                    {messageLogs.length > 0 && (
                      <Chip
                        label={messageLogs.length}
                        color="primary"
                        size="small"
                        style={{ marginLeft: theme.spacing(1) }}
                      />
                    )}
                  </span>
                }
              />
            </ListItem>
            <ListItem disabled={true}>
              <ListItemText primary={'Speisekarte'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/categories')}>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary={'Kategorien'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/products')}>
              <ListItemIcon>
                <FastfoodRounded />
              </ListItemIcon>
              <ListItemText primary={'Speisekarte'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/modifier')}>
              <ListItemIcon>
                <StyleRounded />
              </ListItemIcon>
              <ListItemText primary={'Produktoptionen'} />
            </ListItem>
            <ListItem disabled={true}>
              <ListItemText primary={'Bestellarten'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/tables')}
              disabled={
                !gastronomy.gastronomyFee && !gastronomy.options.public_key
              }
            >
              <ListItemIcon>
                <ViewModuleRounded />
              </ListItemIcon>
              <ListItemText primary={'Tische'} />
            </ListItem>
            {!hasPos && (
              <>
                <ListItem
                  button
                  component={createDrawerLink('/takeaway')}
                  disabled={!gastronomy.gastronomyFee}
                >
                  <ListItemIcon>
                    <ShoppingBasketRounded />
                  </ListItemIcon>
                  <ListItemText primary={getOrderTypeLabel('TAKEAWAY')} />
                </ListItem>
                <ListItem
                  button
                  component={createDrawerLink('/selfservice')}
                  disabled={!gastronomy.gastronomyFee}
                >
                  <ListItemIcon>
                    <BeenhereRounded />
                  </ListItemIcon>
                  <ListItemText primary={getOrderTypeLabel('SELFSERVICE')} />
                </ListItem>
                <ListItem
                  button
                  component={createDrawerLink('/drivein')}
                  disabled={!gastronomy.gastronomyFee}
                >
                  <ListItemIcon>
                    <DirectionsCarRounded />
                  </ListItemIcon>
                  <ListItemText primary={getOrderTypeLabel('DRIVEIN')} />
                </ListItem>
                <ListItem
                  button
                  component={createDrawerLink('/delivery')}
                  disabled={!gastronomy.gastronomyFee}
                >
                  <ListItemIcon>
                    <LocalShippingRounded />
                  </ListItemIcon>
                  <ListItemText primary={'Lieferung'} />
                </ListItem>
              </>
            )}
            <ListItem disabled={true}>
              <ListItemText primary={'Berichte'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/orders')}
              disabled={!gastronomy.gastronomyFee}
            >
              <ListItemIcon>
                <ShoppingCartRounded />
              </ListItemIcon>
              <ListItemText primary={'Bestellhistorie'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/order-overview')}
              disabled={!gastronomy.gastronomyFee}
            >
              <ListItemIcon>
                <AccountBalanceWalletRounded />
              </ListItemIcon>
              <ListItemText primary={'Tagesumsätze'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/tips')}
              disabled={!gastronomy.gastronomyFee}
            >
              <ListItemIcon>
                <MoneyRounded />
              </ListItemIcon>
              <ListItemText primary={'Trinkgeld'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/report')}
              disabled={!gastronomy.gastronomyFee}
            >
              <ListItemIcon>
                <AssignmentRounded />
              </ListItemIcon>
              <ListItemText primary={'Monatsbericht'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/invoices')}
              disabled={!gastronomy.gastronomyFee}
            >
              <ListItemIcon>
                <ReceiptRounded />
              </ListItemIcon>
              <ListItemText primary={'Rechnungen'} />
            </ListItem>
          </>
        )}
        <ListItem disabled={true}>
          <ListItemText primary={'Links'} />
        </ListItem>
        {gastronomy && (
          <ListItem
            button
            component={createDrawerLink(Config.orderScreenUrl, true)}
            disabled={!gastronomy.gastronomyFee}
          >
            <ListItemIcon>
              <DesktopWindowsRounded />
            </ListItemIcon>
            <ListItemText primary={'Bestell- & Küchenmonitor'} />
          </ListItem>
        )}
        <ListItem button component={createDrawerLink('/gtc')}>
          <ListItemIcon>
            <BookRounded />
          </ListItemIcon>
          <ListItemText primary={'AGBs'} />
        </ListItem>
        <ListItem button component={createDrawerLink('/privacy-policy')}>
          <ListItemIcon>
            <BookRounded />
          </ListItemIcon>
          <ListItemText primary={'Datenschutz'} />
        </ListItem>
        <ListItem
          button
          component={createDrawerLink(Config.webAppUrl + '/partner', true)}
        >
          <ListItemIcon>
            <WebRounded />
          </ListItemIcon>
          <ListItemText primary={'getsby Website'} />
        </ListItem>
        <ListItem button component={createDrawerLink('/tutorial')}>
          <ListItemIcon>
            <SchoolRounded />
          </ListItemIcon>
          <ListItemText primary={'Tutorial'} />
        </ListItem>
        <ListItem
          button
          component={createDrawerLink('mailto:office@gets.by', true)}
          divider
        >
          <ListItemIcon>
            <HelpRounded />
          </ListItemIcon>
          <ListItemText primary={'Hilfe'} />
        </ListItem>
        {isAdmin && (
          <>
            <ListItem disabled={true}>
              <ListItemText primary={'Administrator'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/gastronomies')}
            >
              <ListItemIcon>
                <RestaurantRounded />
              </ListItemIcon>
              <ListItemText primary={'Restaurants'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/gastronomies/copy')}
            >
              <ListItemIcon>
                <FileCopyRounded />
              </ListItemIcon>
              <ListItemText primary={'Restaurant kopieren'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/gastronomies/register')}
            >
              <ListItemIcon>
                <PersonAddRounded />
              </ListItemIcon>
              <ListItemText primary={'Registrierung'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/coupon-codes')}
            >
              <ListItemIcon>
                <LoyaltyRounded />
              </ListItemIcon>
              <ListItemText primary={'Gutscheine'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/admin/apps')}>
              <ListItemIcon>
                <SmartphoneRounded />
              </ListItemIcon>
              <ListItemText primary={'Apps'} />
            </ListItem>

            <ListItem button component={createDrawerLink('/admin/support')}>
              <ListItemIcon>
                <ContactSupportRounded />
              </ListItemIcon>
              <ListItemText primary={'Supportanfragen'} />
            </ListItem>
            <ListItem disabled={true}>
              <ListItemText primary={'Monitoring'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/admin/monitor')}>
              <ListItemIcon>
                <InsertChartRounded />
              </ListItemIcon>
              <ListItemText primary={'Monitor'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/order-overview')}
            >
              <ListItemIcon>
                <ShoppingCartRounded />
              </ListItemIcon>
              <ListItemText primary={'Bestellungen'} />
            </ListItem>
            <ListItem
              button
              component={createDrawerLink('/admin/faulty-orders')}
            >
              <ListItemIcon>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText primary={'Fehlerhafte Bestellungen'} />
            </ListItem>
            <ListItem button component={createDrawerLink('/admin/users')}>
              <ListItemIcon>
                <SupervisorAccountRounded />
              </ListItemIcon>
              <ListItemText primary={'Benutzer'} />
            </ListItem>
          </>
        )}
        {user && (
          <ListItem button onClick={resetUser}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Logout'}
              secondary={user ? user.email : ''}
            />
          </ListItem>
        )}
        {!user && (
          <ListItem button component={createDrawerLink('/auth')}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={'Login'} />
          </ListItem>
        )}
      </List>
      <div className={classes.grow} />
      <Typography
        variant="body2"
        style={{ alignSelf: 'center' }}
      >{`v${process.env.REACT_APP_VERSION}`}</Typography>
    </>
  );

  return (
    <>
      <nav className={classes.drawer}>
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={isMenuOpen}
            onClose={() => onChangeMenuOpen(false)}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {DrawerContent}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {DrawerContent}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default SideMenu;
