import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import {
  GetCategoriesDocument,
  UpdateCategoryDocument
} from '../../services/graphql/typed-operations';
import { gastronomyState } from '../../store/gastronomy';
import AddIcon from '@material-ui/icons/Add';
import CategoryForm from '../../components/CategoryForm';
import CategoryLineItem from '../../components/CategoryLineItem';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    addButton: {
      width: '100%',
      height: 100
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    container: {
      justifyContent: 'center',
      margin: theme.spacing(2)
    },
    table: {
      width: '100%'
    }
  })
);

const GastronomyCategories: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const apolloClient = useApolloClient();
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const { data, loading } = useQuery(GetCategoriesDocument, {
    variables: {
      params: { user_uuid: gastronomy?.uuid || 'NOT SET', status: 'ALL' }
    },
    skip: !gastronomy
  });

  const dragAction = async (result: DropResult) => {
    console.log('drop result', result);
    if (result.destination) {
      await apolloClient.mutate({
        mutation: UpdateCategoryDocument,
        variables: {
          input: {
            uuid: result.draggableId,
            order_nr: result.destination.index
          }
        },
        refetchQueries: ['getCategories']
      });
    }
  };

  return (
    <>
      <HeaderContainer title={'Kategorien'} />
      <Paper className={classes.paper}>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={dragAction}>
                <Droppable droppableId="droppable">
                  {(provided, _snapshot) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data &&
                        data.getCategories.map((category, index) => (
                          <Draggable
                            key={category.uuid}
                            draggableId={category.uuid}
                            index={index}
                          >
                            {(provided, _snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CategoryLineItem
                                  category={category}
                                  key={index}
                                />
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>

            <div className={classes.container}>
              {showAddForm && gastronomy && (
                <CategoryForm
                  onClose={() => setShowAddForm(false)}
                  user_uuid={gastronomy.uuid}
                />
              )}
              {!showAddForm && (
                <Button
                  variant="outlined"
                  className={classes.addButton}
                  onClick={() => setShowAddForm(true)}
                >
                  Neue Kategorie
                  <AddIcon className={classes.rightIcon} />
                </Button>
              )}
            </div>
          </>
        )}
      </Paper>
    </>
  );
};

export default GastronomyCategories;
