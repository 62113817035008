import {
  createStyles,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { AvailabilityTimeException, AvailabilityTimesKey } from 'models';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { format, isAfter, parse } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import AvailabilityTimeExceptionForm from './AvailablityTimeExceptionForm';
import SimpleDialog from './SimpleDialog';
import { EditRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(8)
    },
    button: {
      margin: theme.spacing(1)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    margin: {
      margin: theme.spacing(2)
    },
    padding: {
      padding: theme.spacing(2)
    },
    container: {
      justifyContent: 'center',
      margin: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    formContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  })
);

interface Props {
  exceptions?: Maybe<AvailabilityTimeException[]>;
  onChange: (
    type: AvailabilityTimesKey,
    ranges: AvailabilityTimeException[]
  ) => void;
}

const AvailabilityTimeExceptions: React.FC<Props> = (props) => {
  const classes = useStyles();

  const exceptions = props.exceptions ? props.exceptions : [];
  const { onChange } = props;

  const [showAddForm, setShowAddForm] = useState<boolean>();
  const [deleteItem, setDeleteItem] = useState<number>();
  const [editItem, setEditItem] = useState<number>();

  const formatTime = (time: string) => {
    return format(parse(time, 'HHmm', new Date()), 'HH:mm');
  };

  const formatDate = (date: string) => {
    return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
  };

  const deleteException = (index: number) => {
    onChange('e', [
      ...exceptions.slice(0, index),
      ...exceptions.slice(index + 1)
    ]);
  };

  const getDeleteText = () => {
    if (deleteItem !== undefined) {
      return `Möchten Sie die Ausnahme ${formatDate(
        exceptions[deleteItem].dateRange.begin_date
      )} - ${formatDate(
        exceptions[deleteItem].dateRange.end_date
      )} wirklich löschen?`;
    }
    return '';
  };

  const exceptionSortFunction = (
    a: AvailabilityTimeException,
    b: AvailabilityTimeException
  ) => {
    const aDate = new Date(a.dateRange.begin_date);
    const bDate = new Date(b.dateRange.begin_date);
    return isAfter(aDate, bDate) ? 1 : -1;
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" className={classes.padding}>
        <Grid item>
          <Typography variant={'h6'}>Ausnahmen</Typography>
        </Grid>
        {!showAddForm && (
          <Grid item>
            <Fab
              color="primary"
              aria-label="add"
              size="medium"
              onClick={() => setShowAddForm(true)}
            >
              <AddIcon />
            </Fab>
          </Grid>
        )}
      </Grid>
      <List>
        {exceptions.map((except, eindex) => {
          return (
            <React.Fragment key={eindex}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <b>{formatDate(except.dateRange.begin_date)}</b> bis{' '}
                      <b>{formatDate(except.dateRange.end_date)}</b>
                    </>
                  }
                  secondary={
                    <span>
                      {' '}
                      {except.timeRanges.length === 0
                        ? 'geschlossen'
                        : 'geöffnet von '}
                      {except.timeRanges
                        .map((timeRange, tindex) => {
                          return (
                            formatTime(timeRange.time_from) +
                            ' - ' +
                            formatTime(timeRange.time_to)
                          );
                        })
                        .join(' & ')}
                    </span>
                  }
                />

                <ListItemSecondaryAction>
                  <>
                    <Tooltip title="Bearbeiten">
                      <IconButton
                        onClick={() =>
                          setEditItem((prev) =>
                            prev === eindex ? undefined : eindex
                          )
                        }
                      >
                        <EditRounded />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen">
                      <IconButton onClick={() => setDeleteItem(eindex)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                </ListItemSecondaryAction>
              </ListItem>
              {editItem === eindex && (
                <li key={eindex + '-form'}>
                  <AvailabilityTimeExceptionForm
                    exception={except}
                    onSave={(eItem) =>
                      onChange(
                        'e',
                        [
                          ...exceptions.slice(0, eindex),
                          eItem,
                          ...exceptions.slice(eindex + 1)
                        ].sort(exceptionSortFunction)
                      )
                    }
                    onCancel={() => setEditItem(undefined)}
                  />
                </li>
              )}
              <Divider component="li" />
            </React.Fragment>
          );
        })}
      </List>
      {showAddForm && (
        <AvailabilityTimeExceptionForm
          onSave={(exception) =>
            onChange(
              'e',
              [...exceptions, exception].sort(exceptionSortFunction)
            )
          }
          onCancel={() => setShowAddForm(false)}
        />
      )}
      <SimpleDialog
        open={deleteItem !== undefined}
        title={`Ausnahme löschen`}
        text={getDeleteText()}
        onPositiveClose={() => {
          if (deleteItem !== undefined) {
            deleteException(deleteItem);
          }
          setDeleteItem(undefined);
        }}
        onNegativeClose={() => {
          setDeleteItem(undefined);
        }}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
    </div>
  );
};

export default AvailabilityTimeExceptions;
