import React, { useEffect } from 'react';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import { captureSilentError } from '../../helpers/ErrorHelper';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { getCurrentBasePath } from '../../helpers/MiscHelper';
import { useApolloClient } from '@apollo/client';
import { TriggerStripeAccountLinksMutationDocument } from '../../services/graphql/typed-operations';

const GastronomyStripeCustomReauth: React.FC = () => {
  const query = useUrlSearchParams();
  const apolloClient = useApolloClient();

  const triggerStripeAccountLinks = async (
    user_uuid: string,
    type: StripeAccountLinksType,
    collect: StripeAccountLinksCollectType,
    returnUrl: string
  ): Promise<void> => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: TriggerStripeAccountLinksMutationDocument,
        variables: {
          input: {
            user_uuid,
            type,
            collect,
            returnUrl,
            refreshUrl: `${getCurrentBasePath()}/stripe-custom-reauth`
          }
        }
      });

      if (data) {
        window.location.href = data.triggerStripeAccountLinks.url;
      }
    } catch (error) {
      captureSilentError(error);
    }
  };

  useEffect(() => {
    const user_uuid = query.get('user_uuid');
    const type = query.get('type');
    const collect = query.get('collect');
    const returnUrl = query.get('returnUrl');

    if (user_uuid && type && collect) {
      triggerStripeAccountLinks(
        user_uuid as string,
        type as StripeAccountLinksType,
        collect as StripeAccountLinksCollectType,
        decodeURIComponent(returnUrl as string)
      );
    }
  }, [query]);

  return <CircularIndeterminate />;
};

export default GastronomyStripeCustomReauth;
