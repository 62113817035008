import { atom, DefaultValue, selector } from 'recoil';
import * as Sentry from '@sentry/react';

export interface DashboardUser {
  uuid: string;
  email: string;
  groups: string[];
}

export const userAtom = atom<DashboardUser | null | undefined>({
  key: 'user',
  default: null,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newUser) => {
        if (newUser instanceof DefaultValue) {
        } else if (newUser) {
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: newUser.uuid,
              email: newUser.email
            });
          });
        }
      });
    }
  ]
});

export const isAdminSelector = selector<boolean>({
  key: 'isAdminSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    let hasPermission = false;

    if (!user || !user.groups) {
      return false;
    }

    if (user.groups.includes('admin')) {
      hasPermission = true;
    }

    return hasPermission;
  }
});

export const isGastroSelector = selector<boolean>({
  key: 'isGastroSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    let hasPermission = false;

    if (!user || !user.groups) {
      return false;
    }

    if (user.groups.includes('gastro')) {
      hasPermission = true;
    }

    return hasPermission;
  }
});
