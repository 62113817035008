import { SnackbarMessage } from 'models';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import SimpleSnackbar from '../components/SimpleSnackbar';
import { removeSnackbarMessage, snackbarMessagesState } from '../store/app';

const SnackbarContainer: React.FC = () => {
  const [snackbarMessages, setSnackbarMessages] = useRecoilState(
    snackbarMessagesState
  );
  const [current, setCurrent] = useState<SnackbarMessage | null>(null);

  const closeSnackbar = () => {
    setCurrent(null);
  };

  useEffect(() => {
    if (!current && snackbarMessages.length) {
      const snackbarMessage = snackbarMessages[0];
      setCurrent(snackbarMessage);
      removeSnackbarMessage(snackbarMessage, setSnackbarMessages);
    }
  }, [snackbarMessages, current]);

  return (
    <>
      {current && (
        <SimpleSnackbar
          variant={current.type}
          message={current.message}
          position={{ horizontal: 'center', vertical: 'top' }}
          onClose={closeSnackbar}
        />
      )}
    </>
  );
};

export default SnackbarContainer;
