import Storage from '@aws-amplify/storage';
import {
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Tooltip
} from '@material-ui/core';
import SaveAltRounded from '@material-ui/icons/SaveAltRounded';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import HeaderContainer from '../../components/HeaderContainer';
import { captureSilentError } from '../../helpers/ErrorHelper';
import { isAdminSelector } from '../../store/auth';
import { gastronomyState } from '../../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      padding: theme.spacing(3)
    },
    button: {
      margin: theme.spacing(1, 0),
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface StorageFile {
  eTag: string;
  key: string;
  lastModified: Date;
  size: number;
}

const GastronomyInvoices: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [files, setFiles] = useState<StorageFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAdmin = useRecoilValue(isAdminSelector);

  useEffect(() => {
    const getInvoices = async () => {
      setFiles([]);

      if (gastronomy) {
        setIsLoading(true);

        try {
          const result: StorageFile[] = await Storage.list(
            'invoices/' + gastronomy.uuid
          );

          console.debug('Files from S3', result);

          setFiles(
            result.sort((a, b) => {
              return (
                new Date(b.lastModified).getTime() -
                new Date(a.lastModified).getTime()
              );
            }) || []
          );
        } catch (error) {
          captureSilentError(error);
        }

        setIsLoading(false);
      }
    };

    getInvoices();
  }, [gastronomy]);

  const downloadFile = async (key: string) => {
    try {
      const fileUrl = await Storage.get(key);

      console.debug('fileUrl', fileUrl);

      const link = document.createElement('a');
      link.download = key.substring(key.lastIndexOf('/') + 1, key.length);
      link.href = fileUrl as string;
      link.click();
    } catch (error) {
      captureSilentError(error);
    }
  };

  return (
    <>
      <HeaderContainer
        title={
          gastronomy && isAdmin
            ? `Rechnungen - ${gastronomy.name}`
            : 'Rechnungen'
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {isLoading && <CircularIndeterminate />}
            {!isLoading && (
              <List>
                {files.map((file) => (
                  <ListItem divider key={file.key}>
                    <ListItemText
                      primary={file.key.substring(
                        file.key.lastIndexOf('/') + 1,
                        file.key.length
                      )}
                      secondary={
                        isAdmin
                          ? `Zuletzt aktualisiert am: ${format(
                              file.lastModified,
                              'dd.MM.yyyy'
                            )}`
                          : ''
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="Herunterladen">
                        <IconButton onClick={() => downloadFile(file.key)}>
                          <SaveAltRounded />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyInvoices;
