import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AddTestOrderDocument } from '../services/graphql/typed-operations';
import { gastronomyState } from '../store/gastronomy';
import { v4 as uuidv4 } from 'uuid';
import { snackbarMessagesState } from '../store/app';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { SendRounded } from '@material-ui/icons';
import SimpleDialog from './SimpleDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    spinner: {
      maxHeight: '20px',
      maxWidth: '20px'
    }
  })
);

const TestOrderButton: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [addTestOrderMutation, { loading }] = useMutation(AddTestOrderDocument);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const triggerTestOrder = async () => {
    if (gastronomy) {
      try {
        const input: OrderItemsAddInput = {
          user_uuid: gastronomy.uuid,
          is_training: true,
          paymentMethod: { type: 'NONE' },
          table_uuid: uuidv4(),
          orderItems: [{ product_uuid: uuidv4() }],
          origin: {
            type: 'WEB',
            version: process.env.REACT_APP_VERSION || 'unknown',
            userAgent: navigator.userAgent,
            debug: process.env.NODE_ENV !== 'production' ? true : false,
            url: window.location.href,
            host: window.location.hostname
          }
        };

        await addTestOrderMutation({
          variables: { input },
          refetchQueries: ['getReceiptsQuery', 'getReceiptsWithGastronomyQuery']
        });
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const isAllowedToOrder = useMemo(() => {
    if (gastronomy) {
      return (
        !!gastronomy.contactConfig &&
        !!gastronomy.gastronomyFee &&
        !!gastronomy.posConfig &&
        (gastronomy.posConfig.pos_type === 'GETSBY' ||
          gastronomy.posConfig.pos_type === 'GETSBYKASSA')
      );
    }
    return false;
  }, [gastronomy]);

  return (
    <>
      <SimpleDialog
        open={showDialog}
        title={`Testbestellung`}
        text={
          <>
            Hier können Sie eine Testbestellung durchführen um die
            Funktionsweise von getsby zu Testen. <br />
            Testbestellungen scheinen nicht in Ihren Umsätzen auf!
          </>
        }
        onPositiveClose={() => {
          triggerTestOrder();
          setShowDialog(false);
        }}
        onNegativeClose={() => setShowDialog(false)}
        buttonPositiveLabel={'Abschicken'}
        buttonNegativeLabel={'Zurück'}
        positiveDisabled={!isAllowedToOrder || loading}
      />
      {isAllowedToOrder && (
        <Button
          className={classes.input}
          onClick={() => setShowDialog(true)}
          variant="contained"
          color="default"
        >
          Testbestellung abschicken
          <SendRounded className={classes.rightIcon} />
        </Button>
      )}
    </>
  );
};

export default TestOrderButton;
