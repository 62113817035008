import { Grid } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HeaderContainer from '../../components/HeaderContainer';
import ReceiptView from '../../components/ReceiptView';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';

interface UrlParams {
  uuid?: string;
}

const GastronomyReceipt: React.FC<RouteComponentProps<UrlParams>> = ({
  match
}) => {
  const { uuid } = match.params;
  const query = useUrlSearchParams();
  const showCoupons = query.get('c');

  return (
    <>
      <HeaderContainer title={'Bestellung'} />
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item sm={12} md={8} xl={6}>
          {uuid && (
            <ReceiptView uuid={uuid} showCoupons={showCoupons === '1'} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GastronomyReceipt;
