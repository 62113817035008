import { useMutation } from '@apollo/client';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { SyncRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { captureSilentError } from '../helpers/ErrorHelper';
import { TriggerPosSyncDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const TriggerPosSyncButton: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const [triggerPosSyncMutation] = useMutation(TriggerPosSyncDocument);

  const triggerPosSync = async (): Promise<void> => {
    if (gastronomy) {
      setIsButtonDisabled(true);
      try {
        const { data } = await triggerPosSyncMutation({
          variables: { user_uuid: gastronomy.uuid }
        });

        if (!data) {
          return;
        }

        if (data.triggerPosSync?.status) {
          addSnackbarMessages(
            [
              {
                type: 'success',
                message: 'POS Sync ausgeführt!'
              }
            ],
            setSnackbarMessages
          );
        }
      } catch (error) {
        captureSilentError(error);
      }
    }
  };

  if (
    gastronomy?.posConfig?.pos_type &&
    ['WINCARAT', 'MERGEPORT', 'MATRIX'].includes(gastronomy.posConfig.pos_type)
  ) {
    return (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        onClick={triggerPosSync}
        disabled={isButtonDisabled}
      >
        Trigger POS Sync
        <SyncRounded className={classes.rightIcon} />
      </Button>
    );
  }
  return null;
};

export default TriggerPosSyncButton;
