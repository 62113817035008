import {
  Button,
  createStyles,
  Grid,
  Input,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearRounded from '@material-ui/icons/ClearRounded';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { format, setHours, setMinutes } from 'date-fns';
import DoneRounded from '@material-ui/icons/DoneRounded';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { useSetRecoilState } from 'recoil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    padding: {
      padding: theme.spacing(2)
    },
    marginRight: {
      marginRight: theme.spacing(2)
    }
  })
);

const initialState = {
  time_from: null,
  time_to: null,
  limit: ''
};

interface Input {
  time_from: string | null;
  time_to: string | null;
  limit?: string | null;
}

interface Props {
  initialAvailabilityTime?: Maybe<AvailabilityTimeElement>;
  onClose: () => void;
  onSave: (availabilityTime: AvailabilityTimeElement) => void;
  showLimit: boolean;
}

const AvailabilityTimeTimesForm: React.FC<Props> = ({
  initialAvailabilityTime,
  onClose,
  onSave,
  showLimit
}) => {
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const initAvailabilityTimes = (): Input => {
    if (initialAvailabilityTime) {
      let timeFrom = setHours(
        new Date(),
        +initialAvailabilityTime.time_from.substring(0, 2)
      );
      timeFrom = setMinutes(
        timeFrom,
        +initialAvailabilityTime.time_from.substring(2, 4)
      );

      let timeTo = setHours(
        new Date(),
        +initialAvailabilityTime.time_to.substring(0, 2)
      );
      timeTo = setMinutes(
        timeTo,
        +initialAvailabilityTime.time_to.substring(2, 4)
      );

      return {
        time_from: timeFrom.toString(),
        time_to: timeTo.toString(),
        limit: initialAvailabilityTime.limit
          ? initialAvailabilityTime.limit.toString()
          : ''
      };
    }

    return initialState;
  };

  const classes = useStyles();
  const [values, setValues] = useState<Input>(() => initAvailabilityTimes());
  const [errors, setErrors] = useState<string[]>([]);

  const handleDateChange = (name: string) => (date: MaterialUiPickersDate) => {
    validateInput(name, date);

    setValues({
      ...values,
      [name]: date
    });
  };

  const setLimit = (value: string) => {
    setValues({
      ...values,
      limit: parseInt(value, 10).toString()
    });
  };

  const resetForm = () => {
    setValues(initialState);
    onClose();
  };

  const validateInput = (name: string, value: any): boolean => {
    let isValid = true;

    if (name === 'time_from') {
      if (!value) {
        isValid = false;
      }
    }
    if (name === 'time_to') {
      if (!value) {
        isValid = false;
      }
    }

    if (isValid) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors([...errors, name]);
    }

    return isValid;
  };

  const handleSave = () => {
    try {
      if (
        !validateInput('time_from', values.time_from) ||
        !validateInput('time_to', values.time_to)
      ) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );

        return;
      }

      const objToSave: AvailabilityTimeElement = {
        time_from: format(new Date(values.time_from!), 'HHmm'),
        time_to: format(new Date(values.time_to!), 'HHmm')
      };
      if (values.limit) {
        objToSave.limit = parseInt(values.limit, 10);
      }

      onSave(objToSave);
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <KeyboardTimePicker
              error={errors.includes('time_from')}
              className={classes.input}
              ampm={false}
              variant="dialog"
              clearable={true}
              label="Zeit von"
              minutesStep={5}
              value={values.time_from}
              onChange={handleDateChange('time_from')}
              inputVariant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item sm={6}>
            <KeyboardTimePicker
              error={errors.includes('time_to')}
              className={classes.input}
              ampm={false}
              variant="dialog"
              clearable={true}
              label="Zeit bis"
              minutesStep={5}
              value={values.time_to}
              onChange={handleDateChange('time_to')}
              inputVariant="outlined"
              margin="dense"
            />
          </Grid>
          {showLimit && (
            <Grid item sm={12}>
              <TextField
                error={errors.includes('limit')}
                id="limit"
                label="Limit"
                className={classes.input}
                onChange={(event) => setLimit(event.currentTarget.value)}
                value={values.limit}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                helperText="Das Limit wird pro Slot berechnet."
                variant="outlined"
                margin="dense"
              />
            </Grid>
          )}
          <Grid item sm={6}>
            <Button
              onClick={resetForm}
              variant="outlined"
              className={classes.input}
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              className={classes.input}
            >
              {initialAvailabilityTime ? (
                <>
                  Speichern
                  <DoneRounded className={classes.rightIcon} />
                </>
              ) : (
                <>
                  Hinzufügen
                  <AddIcon className={classes.rightIcon} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AvailabilityTimeTimesForm;
