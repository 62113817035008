import { useApolloClient, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneRounded from '@material-ui/icons/DoneRounded';
import PictureInPictureRounded from '@material-ui/icons/PictureInPictureRounded';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getsbyPrimary } from '../helpers/Color';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getPrinterLabel } from '../helpers/MiscHelper';
import {
  GetPrintersQueryDocument,
  GetRoomPrintersQueryDocument,
  UpdateRoomMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import {
  gastronomyState,
  hasPosSelector,
  roomsState,
  updateState
} from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    card: {
      minWidth: 275,
      marginTop: theme.spacing(3)
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3)
    },
    margin: {
      marginBottom: theme.spacing(8)
    },
    padding: {
      paddingRight: theme.spacing(2)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      margin: theme.spacing(2, 0),
      width: '100%'
    },
    avatar: {
      margin: theme.spacing(1),
      color: theme.palette.secondary.main,
      backgroundColor: getsbyPrimary[500]
    },
    radioButton: {
      margin: theme.spacing(1)
    }
  })
);

interface Props {
  room: RoomFragment;
  onClose: () => void;
}

const RoomUpdateForm: React.FC<Props> = ({ room, onClose }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);
  const gastronomy = useRecoilValue(gastronomyState);
  const hasPos = useRecoilValue(hasPosSelector);
  const [printers, setPrinters] = useState<PrinterFragment[]>([]);
  const setRooms = useSetRecoilState(roomsState);

  useQuery(GetPrintersQueryDocument, {
    variables: { user_uuid: gastronomy ? gastronomy.uuid : 'not set' },
    skip: !gastronomy,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      console.debug('Printers', data.getPrinters);

      setPrinters(data.getPrinters);
    }
  });

  const initialValues = {
    uuid: room.uuid,
    title: room.title || '',
    description: room.description || '',
    user_uuid: room.user_uuid
  };

  const [values, setValues] = useState<ProductGroupUpdateInput>(initialValues);
  const [roomPrinterId, setRoomPrinterId] = useState<string>('');
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useQuery(GetRoomPrintersQueryDocument, {
    variables: { room_uuid: room ? room.uuid : 'not set' },
    skip: !room,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      console.debug('RoomPrinters', data.getRoomPrinters);

      setRoomPrinterId(
        data.getRoomPrinters.length ? data.getRoomPrinters[0].uuid : ''
      );
    }
  });

  const handleChange = (name: string) => (event: any) => {
    validateInput(name, event.target.value);

    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  const validateInput = (name: string, value?: string | null): boolean => {
    if (!value) {
      return false;
    }

    let isValid = true;

    if (name === 'title') {
      if (value.length === 0) {
        isValid = false;
      }
    }

    if (isValid) {
      setErrors(errors.filter((e) => e !== name));
    } else {
      setErrors([...errors, name]);
    }

    return isValid;
  };

  const handlePrinterChange = (uuid: string) => (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRoomPrinterId(uuid === roomPrinterId ? '' : uuid);
  };

  const saveRoom = async () => {
    try {
      if (!validateInput('title', values.title)) {
        addSnackbarMessages(
          [
            {
              type: 'error',
              message: 'Bitte überprüfen Sie Ihre Eingabe!'
            }
          ],
          setSnackbarMessages
        );

        return;
      }

      const { data } = await apolloClient.mutate({
        mutation: UpdateRoomMutationDocument,
        variables: {
          input: {
            ...values,
            printerUuids: roomPrinterId ? [roomPrinterId] : []
          }
        }
      });

      if (!data) {
        return;
      }

      const newRoom = data.updateRoom;

      updateState(newRoom, setRooms);

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Raum ${newRoom.title} aktualisiert`
          }
        ],
        setSnackbarMessages
      );

      onClose();
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <div className={classes.margin}>
      <div className={classes.heading}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar className={classes.avatar}>
            <PictureInPictureRounded />
          </Avatar>
          <Typography variant={'h5'} className={classes.padding}>
            {room.title} ändern
          </Typography>
        </div>
        <div>
          <Tooltip title="Schließen">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextField
              error={errors.includes('title')}
              id="title"
              label="Titel"
              placeholder="Raum 1"
              className={classes.input}
              required={true}
              value={values.title}
              onChange={handleChange('title')}
              margin="dense"
              disabled={hasPos}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={printers.length ? 4 : 8}>
            <TextField
              id="description"
              label="Beschreibung"
              placeholder="Raum im UG"
              className={classes.input}
              value={values.description}
              onChange={handleChange('description')}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          {printers.length > 0 && (
            <Grid item sm={4}>
              <FormControl component="fieldset" className={classes.radioButton}>
                <FormLabel component="legend">Druckerzuweisung</FormLabel>
                <RadioGroup value={roomPrinterId}>
                  {printers.map((printer) => (
                    <FormControlLabel
                      key={printer.uuid}
                      value={printer.uuid}
                      control={
                        <Radio onClick={handlePrinterChange(printer.uuid)} />
                      }
                      label={getPrinterLabel(printer)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>

      <Button
        onClick={saveRoom}
        className={classes.input}
        variant="contained"
        color="primary"
      >
        Speichern
        <DoneRounded className={classes.rightIcon} />
      </Button>
    </div>
  );
};

export default RoomUpdateForm;
