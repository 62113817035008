import {
  Badge,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core';
import AlarmRounded from '@material-ui/icons/AlarmRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useState } from 'react';
import { getAvailabilityTimesCount } from '../helpers/MiscHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import DownloadAppQrCode from './DownloadAppQrCode';
import TableStatus from './TableStatus';
import TableForm from './TableForm';
import TextPopper from './TextPopper';
import { useRecoilValue } from 'recoil';
import { hasPosSelector, roomsState } from '../store/gastronomy';

interface Props {
  table: TableFragment;
  selectTable: (table: TableFragment) => void;
  selectDeleteTable: (table: TableFragment) => void;
  tableNumberLabel?: string;
  tableNumberPlaceholder?: string;
  allowedTableTypes: TableType[];
  showPreOrderSwitch?: boolean;
}

const TableLineItem: React.FC<Props> = ({
  table,
  selectTable,
  selectDeleteTable,
  tableNumberLabel,
  tableNumberPlaceholder,
  allowedTableTypes,
  showPreOrderSwitch = true
}) => {
  const hasPos = useRecoilValue(hasPosSelector);
  const rooms = useRecoilValue(roomsState);
  const [update, setUpdate] = useState<boolean>(false);

  return (
    <>
      {!update && (
        <TableRow>
          <TableCell>
            {getOrderTypeLabel(table.table_type, table.table_type_override)}
          </TableCell>
          <TableCell>
            {rooms && rooms.length > 0 && !table.room && (
              <Chip
                icon={<WarningIcon />}
                label="Dieser Tisch ist mit keinem Raum verknüpft!"
                color="primary"
                style={{ marginRight: 16 }}
              />
            )}
            {table.table_number}
          </TableCell>
          <TableCell>
            {(['DINEIN', 'DELIVERY'] as TableType[]).some((tt) =>
              allowedTableTypes.includes(tt)
            ) && <TableStatus table={table} />}
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            <TextPopper
              text={table.uuid}
              placement={'left'}
              textDescription="UUID"
              copy={true}
            />
            <DownloadAppQrCode
              title={table.table_number}
              type="t"
              value={table.uuid}
            />
            <Tooltip title="Service Zeiten">
              <IconButton onClick={() => selectTable(table)}>
                <Badge
                  color="primary"
                  badgeContent={getAvailabilityTimesCount(table.service_times)}
                >
                  <AlarmRounded />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Bearbeiten">
              <IconButton onClick={() => setUpdate(true)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen">
              <span>
                <IconButton
                  disabled={hasPos}
                  onClick={() => selectDeleteTable(table)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
      {update && (
        <TableRow>
          <TableCell colSpan={9}>
            <TableForm
              table={table}
              onClose={() => setUpdate(false)}
              allowedTableTypes={allowedTableTypes}
              tableNumberLabel={tableNumberLabel}
              tableNumberPlaceholder={tableNumberPlaceholder}
              showPreOrderSwitch={showPreOrderSwitch}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableLineItem;
