import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useSound from 'use-sound';
import { captureSilentError } from '../helpers/ErrorHelper';
import { GetMessageLogsDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import { gastronomyState, messageLogsState } from '../store/gastronomy';
import Ding from './../assets/ding.mp3';

const GastronomyMessageLogNotification: React.FC = () => {
  const gastronomy = useRecoilValue(gastronomyState);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const isAdmin = useRecoilValue(isAdminSelector);
  const setMessageLogs = useSetRecoilState(messageLogsState);
  const [play, exposedData] = useSound(Ding, { volume: 1, interrupt: false });
  const location = useLocation();
  const isEnabled = useMemo(() => {
    if (!isAdmin) {
      return true;
    }
    if (isAdmin && location.pathname === '/messages') {
      return true;
    }
    return false;
  }, [isAdmin, location]);

  useQuery(GetMessageLogsDocument, {
    variables: {
      params: {
        user_uuid: gastronomy ? gastronomy.uuid : 'not set',
        read: 'UNREAD'
      }
    },
    skip: !gastronomy || !isEnabled,
    fetchPolicy: 'network-only',
    pollInterval: 30000,
    onError: (error) => {
      captureSilentError(error);
    },
    onCompleted: (data) => {
      const result = data.getMessageLogs;

      console.log('MessageLogs', result);

      if (result.length) {
        addSnackbarMessages(
          [
            {
              type: 'info',
              message: `Sie haben neue Nachrichten!`
            }
          ],
          setSnackbarMessages
        );

        if (!exposedData.isPlaying) {
          play();
        }
      }

      setMessageLogs(result);
    }
  });

  return <></>;
};

export default GastronomyMessageLogNotification;
