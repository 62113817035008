import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import { Helmet } from 'react-helmet';
import SimpleDialog from './SimpleDialog';

interface Props {
  hexColor?: string | null;
  isOpen: boolean;
  onClose: (hexColor: string | null) => void;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

const PickColorDialog: React.FC<Props> = ({ isOpen, hexColor, onClose }) => {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [pickedColor, setPickedColor] = useColor('hex', hexColor || '#ffffff');

  return (
    <>
      <Helmet
        style={[
          {
            cssText: `
              .rcp-body {
                  width: 100%;
              }
            `
          }
        ]}
      />
      <SimpleDialog
        open={isOpen}
        title={`Farbe auswählen`}
        text={
          <div className={classes.container}>
            <ColorPicker
              width={456}
              height={228}
              color={pickedColor}
              onChange={setPickedColor}
              hideHSB
              dark={prefersDarkMode}
            />
          </div>
        }
        onPositiveClose={() => onClose(pickedColor.hex)}
        onNegativeClose={() => onClose(null)}
        buttonPositiveLabel={'Auswählen'}
        buttonNegativeLabel={'Zurück'}
      />
    </>
  );
};

export default PickColorDialog;
