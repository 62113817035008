import { AppInitOptions } from 'models';
import React, { useEffect, useState } from 'react';
import App from '../App';
import Config from '../config';
import { STORAGE_KEY_APP_DATA } from '../constants';
import { isStorageAvailable } from '../helpers/StorageHelper';

const AppInit: React.FC = () => {
  const [options, setOptions] = useState<AppInitOptions>();

  useEffect(() => {
    window.initResult = (opt: AppInitOptions) => {
      // console.log('** migrated', migrated, opt);
      if (opt.cognito) {
        for (const cognitoEntry of opt.cognito) {
          // console.log('cognitoEntry', cognitoEntry);
          const [key, value] = cognitoEntry as string[];
          let newKey = '';
          newKey = key
            .substring(key.indexOf(':') + 1)
            .replace(Config.rnUserPoolWebClientId, Config.userPoolWebClientId);
          // console.log('CognitoKey: ', newKey, value);
          if (isStorageAvailable()) {
            localStorage.setItem(newKey, value);
          }
        }
      }

      if (isStorageAvailable()) {
        localStorage.setItem(
          STORAGE_KEY_APP_DATA,
          JSON.stringify({
            platform: opt.platform,
            osVersion: opt.osVersion,
            environment: opt.environment,
            version: opt.version
          })
        );
      }

      // console.log('Options from App: ', opt);
      setOptions(opt);
    };
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'init', includingCognito: true })
    );
  }, []);

  return options ? (
    <App />
  ) : (
    <div className="flex flex-col mt-12 text-center">
      <span className="mb-4 text-6xl text-gray-600 material-icons">
        app_settings_alt
      </span>
      <div>Getting app settings...</div>
    </div>
  );
};

export default AppInit;
