export const isTableTypeAlreadyUsed = (
  tables: TableFragment[],
  tableType: TableType,
  tableUuid?: string
): boolean => {
  if (!tables || tables.length === 0 || !tableType) {
    return false;
  }

  return tables.filter((t) => {
    const isTableType =
      t.table_type_override === tableType ||
      (t.table_type_override === null && t.table_type === tableType);

    const isActive =
      (t.status_override === 'ACTIVE' && t.status === 'ACTIVE') ||
      (t.status_override === null && t.status === 'ACTIVE');

    const isSelf = tableUuid ? tableUuid === t.uuid : false;

    return isTableType && isActive && !isSelf;
  }).length === 1
    ? true
    : false;
};

export const filterTablesByType = (
  tables: TableFragment[],
  tableType: TableType
): TableFragment[] => {
  return tables.filter(
    (t) =>
      (!t.table_type_override && t.table_type === tableType) ||
      t.table_type_override === tableType
  );
};
