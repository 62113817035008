import { useApolloClient, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  GridSize,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Radio,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import React, { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { formatNumber } from '../helpers/FormatHelper';
import {
  hasPnpFeature,
  hasPpFeature
} from '../helpers/GastronomyFeeModelHelper';
import {
  GetCurrentGastronomyFeeModelsQueryDocument,
  GetGastronomyQueryDocument,
  SetGastronomyFeeModelMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    feeModel: {
      marginBottom: theme.spacing(4)
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    },
    cardActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    divider: { width: '100%', margin: theme.spacing(2, 0) }
  })
);

interface Props {
  onClose: (success: boolean) => void;
  showCloseButton?: boolean;
  currentFeeModelUuid?: string;
}

const GastronomyFeeForm: React.FC<Props> = ({
  onClose,
  showCloseButton = false,
  currentFeeModelUuid
}) => {
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const classes = useStyles();
  const theme = useTheme();
  const [gastronomyFeeModels, setGastronomyFeeModels] = useState<
    GastronomyFeeModel[]
  >([]);
  const [selectedFeeModel, setSelectedFeeModel] = useState<string | null>(
    currentFeeModelUuid || null
  );
  const [
    acceptGastronomyFeeModel,
    setAcceptGastronomyFeeModel
  ] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();
  const gridSize: GridSize = useMemo(() => {
    const fullWidth: GridSize = 12;

    if (gastronomyFeeModels.length < 4) {
      const rest = fullWidth % gastronomyFeeModels.length;

      if (rest > 0) {
        return fullWidth;
      }

      const result = fullWidth / gastronomyFeeModels.length;

      return (result >= 1 && result <= 12 ? result : fullWidth) as GridSize;
    }

    if (gastronomyFeeModels.length >= 4) {
      return 6;
    }

    return fullWidth;
  }, [gastronomyFeeModels]);

  useQuery(GetCurrentGastronomyFeeModelsQueryDocument, {
    onCompleted: (data) => {
      if (data && data.getCurrentGastronomyFeeModels) {
        setGastronomyFeeModels(data.getCurrentGastronomyFeeModels);

        if (currentFeeModelUuid) {
          setSelectedFeeModel(currentFeeModelUuid);
        }
      }
    }
  });

  const setGastronomyFeeModel = async (): Promise<void> => {
    if (gastronomy && selectedFeeModel) {
      if (currentFeeModelUuid === selectedFeeModel) {
        onClose(true);
        return;
      }

      try {
        await apolloClient.mutate({
          mutation: SetGastronomyFeeModelMutationDocument,
          variables: {
            input: {
              user_uuid: gastronomy.uuid,
              gastronomy_fee_model_uuid: selectedFeeModel
            }
          }
        });

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Tarif gewählt!`
            }
          ],
          setSnackbarMessages
        );

        const { data } = await apolloClient.query({
          query: GetGastronomyQueryDocument,
          variables: { params: { uuid: gastronomy.uuid } },
          fetchPolicy: 'network-only'
        });

        if (data) {
          setGastronomy(data.getGastronomy);
        }

        onClose(true);
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFeeModel(event.target.value);
  };

  return (
    <Grid container spacing={3}>
      {gastronomyFeeModels.map((gf, index) => (
        <Grid item xs={12} sm={gridSize} key={index}>
          <Card
            className={classes.card}
            style={{
              borderStyle: gf.uuid === selectedFeeModel ? 'solid' : 'none',
              borderColor:
                gf.uuid === selectedFeeModel
                  ? theme.palette.primary.main
                  : 'transparent',
              borderWidth: gf.uuid === selectedFeeModel ? '2px' : '0'
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{ textAlign: 'center' }}
              >
                {gf.title}
              </Typography>
              {gf.description && (
                <Typography
                  variant="body2"
                  style={{ margin: theme.spacing(2, 0) }}
                >
                  {gf.description}
                </Typography>
              )}
              <List dense>
                <ListItem divider>
                  <ListItemText
                    primary={'Monatliche Pauschale'}
                    secondary={`${formatNumber(gf.monthly_fix / 100, '€')}`}
                  />
                </ListItem>
                {hasPpFeature(gf) && (
                  <ListItem divider>
                    <ListItemText
                      primary={'Tischservice'}
                      secondary={`${formatNumber(gf.pp_var, '%')}${
                        gf.pp_fix > 0
                          ? ' + ' + formatNumber(gf.pp_fix / 100, '€')
                          : ''
                      }
                    `}
                    />
                  </ListItem>
                )}
                {(hasPnpFeature(gf) || gf.delivery) && (
                  <ListItem divider>
                    <ListItemText
                      primary={'Take-Away oder Lieferung'}
                      secondary={`${formatNumber(gf.pnp_var, '%')}${
                        gf.pnp_fix > 0
                          ? ' + ' + formatNumber(gf.pnp_fix / 100, '€')
                          : ''
                      }`}
                    />
                  </ListItem>
                )}
                {(hasPnpFeature(gf) || hasPpFeature(gf) || gf.delivery) && (
                  <ListItem divider>
                    <ListItemText
                      primary={'Gebühr bei Online-Zahlungen'}
                      secondary={'1,4 % + 0,25 €'}
                    />
                  </ListItem>
                )}
                <ListItem divider>
                  <ListItemText primary={'Bonuspunkte Programm'} />
                  <ListItemSecondaryAction>
                    {gf.includes_bonus_program ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary={'Newsletter Tool'} />
                  <ListItemSecondaryAction>
                    {gf.includes_bonus_program ? (
                      <DoneRounded />
                    ) : (
                      <ClearRounded />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText
                    primary={
                      <>
                        Eigene Smartphone App <sup>(1)</sup>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    {gf.includes_app ? <DoneRounded /> : <ClearRounded />}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary={'Tischbestellung'} />
                  <ListItemSecondaryAction>
                    {hasPpFeature(gf) ? <DoneRounded /> : <ClearRounded />}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary={'Take-Away'} />
                  <ListItemSecondaryAction>
                    {hasPnpFeature(gf) ? <DoneRounded /> : <ClearRounded />}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                  <ListItemText primary={'Selbstbedienung'} />
                  <ListItemSecondaryAction>
                    {hasPnpFeature(gf) ? <DoneRounded /> : <ClearRounded />}
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Lieferung'} />
                  <ListItemSecondaryAction>
                    {gf.delivery ? <DoneRounded /> : <ClearRounded />}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Radio
                checked={selectedFeeModel === gf.uuid}
                onChange={handleChange}
                value={gf.uuid}
                name="fee-model-radios"
              />
            </CardActions>
          </Card>
        </Grid>
      ))}
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <Typography variant="body1" style={{ marginBottom: theme.spacing(2) }}>
          (1) Kosten für optionale App-Store & Playstore App einmalig 600€.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: theme.spacing(2) }}>
          Bei Installationen mit Anbindung zum Kassensystem können Kosten auf
          Seiten ihres Kassensystems anfallen.
        </Typography>
        <Typography variant="body1">
          Der ausgewählte Tarif ist ab sofort gültig und kommt bei der nächsten
          Abrechnung für den gesamten Abrechnungszeitraum zur Anwendung.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptGastronomyFeeModel}
              onChange={() => setAcceptGastronomyFeeModel((prev) => !prev)}
              color="primary"
            />
          }
          label="Ich akzeptiere den gewählten Tarif"
        />
      </Grid>
      {showCloseButton && (
        <Grid item xs={6}>
          <Button
            className={classes.input}
            onClick={() => onClose(false)}
            variant="outlined"
          >
            Verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      )}
      <Grid item xs={showCloseButton ? 6 : 12}>
        <Button
          className={classes.input}
          onClick={setGastronomyFeeModel}
          disabled={!acceptGastronomyFeeModel || !selectedFeeModel}
          variant="contained"
          color="primary"
        >
          Tarif wählen
          <DoneRounded className={classes.rightIcon} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default GastronomyFeeForm;
