import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { gastronomyState } from '../store/gastronomy';
import GastronomyUpdateForm from './MasterDataForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

const MasterData: React.FC = () => {
  const classes = useStyles();
  const gastronomy = useRecoilValue(gastronomyState);
  const [update, setUpdate] = useState<boolean>(false);

  if (!gastronomy) {
    return <></>;
  }

  return (
    <>
      {update ? (
        <GastronomyUpdateForm
          onClose={() => setUpdate(false)}
          showCloseButton
        />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List>
              <ListItem divider>
                <ListItemText
                  primary="Benutzername"
                  secondary={gastronomy.username}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Restaurant Name"
                  secondary={gastronomy.name}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Kurzbeschreibung"
                  secondary={gastronomy.type}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Adresse"
                  secondary={
                    <>
                      {gastronomy.street}, {gastronomy.zip} {gastronomy.city}
                    </>
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Restaurant Telefon"
                  secondary={gastronomy.phone}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Restaurant Webseite"
                  secondary={gastronomy.website}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary="Inhaber"
                  secondary={
                    <>
                      {gastronomy.owner_firstname} {gastronomy.owner_lastname}
                    </>
                  }
                />
              </ListItem>
              <ListItem divider>
                <ListItemText primary="UID" secondary={gastronomy.uid} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Rechnungs E-Mail"
                  secondary={gastronomy.invoice_email}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.input}
              onClick={() => setUpdate(true)}
              variant="outlined"
            >
              Bearbeiten
              <EditRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MasterData;
