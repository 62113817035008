import { useMutation } from '@apollo/client';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { ClearRounded, DeleteRounded, DoneRounded } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { DeleteCertificateDocument } from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import SimpleDialog from './SimpleDialog';

interface Props {
  certificate: Certificate;
}

const CertificateLineItem: React.FC<Props> = ({ certificate }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);

  const [deleteCertificateMutation] = useMutation(DeleteCertificateDocument, {
    refetchQueries: ['getCertificates']
  });

  const deleteCert = async () => {
    try {
      await deleteCertificateMutation({
        variables: { uuid: certificate.uuid }
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Zertifikat ${certificate.common_name} erfolgreich gelöscht`
          }
        ],
        setSnackbarMessages
      );

      setShowDeleteDialog(true);
    } catch (error) {
      console.log('error');
    }
  };

  console.log('cert', certificate);

  return (
    <>
      <SimpleDialog
        open={showDeleteDialog}
        title={`Zertifikat ${certificate.common_name} löschen`}
        text={'Möchten Sie dieses Zertifikat wirklich löschen?'}
        onPositiveClose={() => deleteCert()}
        onNegativeClose={() => setShowDeleteDialog(false)}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      <TableRow>
        <TableCell>{certificate.common_name}</TableCell>
        <TableCell>{certificate.alt_names}</TableCell>
        <TableCell>{certificate.status}</TableCell>
        <TableCell>
          {certificate.expires_at
            ? new Date(+certificate.expires_at).toLocaleString()
            : '-'}
        </TableCell>
        <TableCell>
          {certificate.is_getsby_domain ? <DoneRounded /> : <ClearRounded />}
        </TableCell>
        <TableCell>
          {certificate.generate_vhost_config ? (
            <DoneRounded />
          ) : (
            <ClearRounded />
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          <Tooltip title="Löschen">
            <span>
              <IconButton onClick={() => setShowDeleteDialog(true)}>
                <DeleteRounded />
              </IconButton>
            </span>
          </Tooltip>{' '}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CertificateLineItem;
