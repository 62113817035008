import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  AdminLinkModifierMutationDocument,
  GetProductGroupsWithProductsQueryDocument,
  GetProductModifierLinksQueryDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState, hasPosSelector } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonContainer: {
      justifyContent: 'center',
      margin: theme.spacing(3, 0)
    },
    addButton: {
      width: '100%',
      height: 50
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    button: {
      marginTop: theme.spacing(4),
      width: '100%'
    },
    listRoot: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300
    },
    listSection: {
      backgroundColor: 'inherit'
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  modifier: ModifierWithModifierItemsFragment;
}

const ModifierLinkDialog: React.FC<Props> = ({
  onClose,
  modifier,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const hasPos = useRecoilValue(hasPosSelector);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const gastronomy = useRecoilValue(gastronomyState);
  const apolloClient = useApolloClient();

  const [
    getProductGroupsWithProductsQuery,
    { data, loading: productGroupsLoading }
  ] = useLazyQuery(GetProductGroupsWithProductsQueryDocument);

  const [
    getProductModifierLinksQuery,
    { loading: modifierLinksLoading }
  ] = useLazyQuery(GetProductModifierLinksQueryDocument, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      console.debug('ProductModifierLinks', data.getProductModifierLinks);

      setSelectedIds(data.getProductModifierLinks.map((p) => p.uuid));
    }
  });

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (gastronomy) {
      getProductGroupsWithProductsQuery({
        variables: {
          params: {
            user_uuid: gastronomy.uuid,
            status: 'ALL',
            product_status: 'ALL'
          }
        }
      });
    }
  }, [gastronomy]);

  useEffect(() => {
    if (modifier) {
      getProductModifierLinksQuery({
        variables: { modifier_uuid: modifier.uuid }
      });
    }
  }, [modifier]);

  const handleChange = (uuid: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setSelectedIds([...selectedIds, uuid]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== uuid));
    }
  };

  const onSave = async () => {
    try {
      await apolloClient.mutate({
        mutation: AdminLinkModifierMutationDocument,
        variables: {
          input: {
            modifierUuid: modifier.uuid,
            productUuids: selectedIds
          }
        },
        refetchQueries: ['getProductsQuery', 'getProductModifierQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Optionsgruppe ${modifier.admin_title} verlinkt`
          }
        ],
        setSnackbarMessages
      );

      onClose();
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          {modifier.admin_title || 'Optionsgruppe'} verlinken
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {productGroupsLoading || modifierLinksLoading ? (
            <CircularIndeterminate />
          ) : (
            <>
              <List className={classes.listRoot} subheader={<li />}>
                {data &&
                  data.getProductGroups.map(
                    (productGroup: FullProductGroupFragment) => (
                      <li
                        key={productGroup.uuid}
                        className={classes.listSection}
                      >
                        <ul className={classes.ul}>
                          <ListSubheader>{productGroup.title}</ListSubheader>
                          {productGroup.products &&
                            productGroup.products.map((product) => (
                              <ListItem key={product.uuid}>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(product.uuid)}
                                    onChange={handleChange(product.uuid)}
                                    value={product.uuid}
                                    tabIndex={-1}
                                    disableRipple
                                    color="primary"
                                    disabled={hasPos}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={product.title} />
                              </ListItem>
                            ))}
                        </ul>
                      </li>
                    )
                  )}
              </List>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onSave}
                disabled={hasPos}
              >
                Speichern
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModifierLinkDialog;
