import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import LinkRounded from '@material-ui/icons/LinkRounded';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import {
  AdminCopyModifierMutationDocument,
  DeleteProductModifierMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { hasPosSelector } from '../store/gastronomy';
import ModifierLinkDialog from './ModifierLinkDialog';
import ModifierPanel from './ModifierPanel';
import SimpleDialog from './SimpleDialog';
import CloseIcon from '@material-ui/icons/Close';
import ModifierStatusSwitch from './ModifierStatusSwitch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    addButtonContainer: {
      justifyContent: 'center',
      marginTop: theme.spacing(3)
    },
    productChip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })
);

interface Props {
  modifier: ModifierWithModifierItemsAndProductsFragment;
}

const ProductModifierTableRow: React.FC<Props> = ({ modifier }) => {
  const apolloClient = useApolloClient();
  const classes = useStyles();
  const hasPos = useRecoilValue(hasPosSelector);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showLinkModifierDialog, setShowLinkModifierDialog] = useState<boolean>(
    false
  );
  const [openModifierDialog, setOpenModifierDialog] = useState(false);

  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const copyModifier = async (
    modifier: ModifierWithModifierItemsAndProductsFragment
  ) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: AdminCopyModifierMutationDocument,
        variables: {
          input: {
            modifierUuid: modifier.uuid
          }
        }
      });

      if (!data) {
        return;
      }

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Optionsgruppe ${modifier.admin_title} kopiert`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }

    setOpenDialog(false);
  };

  const onDeleteModifier = async (
    modifier: ModifierWithModifierItemsFragment
  ) => {
    try {
      await apolloClient.mutate({
        mutation: DeleteProductModifierMutationDocument,
        variables: {
          uuid: modifier.uuid
        },
        refetchQueries: ['getProductModifierQuery']
      });

      addSnackbarMessages(
        [
          {
            type: 'success',
            message: `Optionsgruppe ${modifier.admin_title} gelöscht`
          }
        ],
        setSnackbarMessages
      );
    } catch (error) {
      setErrorSnackbarMessage(error, setSnackbarMessages);
    }

    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Dialog
        open={openModifierDialog}
        onClose={() => setOpenModifierDialog(false)}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle id="simple-dialog-title">
          Optionsgruppen bearbeiten
          <IconButton
            className={classes.closeButton}
            onClick={() => setOpenModifierDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ModifierPanel
            modifier={modifier}
            expanded={`modifier-${modifier.uuid}`}
            handleChange={() => () => null}
          />
        </DialogContent>
      </Dialog>
      <SimpleDialog
        open={openDialog}
        title={`Optionsgruppe ${modifier.admin_title} kopieren`}
        text={'Möchten Sie diese Optionsgruppe wirklich kopieren?'}
        onPositiveClose={() => copyModifier(modifier)}
        onNegativeClose={() => {
          setOpenDialog(false);
        }}
        buttonPositiveLabel={'Kopieren'}
        buttonNegativeLabel={'Zurück'}
      />
      <SimpleDialog
        open={openDeleteDialog}
        title={`Optionsgruppe ${modifier.admin_title} löschen`}
        text={'Möchten Sie diese Optionsgruppe wirklich löschen?'}
        onPositiveClose={() => onDeleteModifier(modifier)}
        onNegativeClose={() => {
          setOpenDeleteDialog(false);
        }}
        buttonPositiveLabel={'Löschen'}
        buttonNegativeLabel={'Zurück'}
      />
      <ModifierLinkDialog
        open={showLinkModifierDialog}
        modifier={modifier}
        onClose={() => {
          setShowLinkModifierDialog(false);
        }}
      />
      <TableRow key={modifier.uuid}>
        <TableCell>{modifier.admin_title}</TableCell>
        <TableCell>{modifier.title}</TableCell>
        <TableCell>{modifier.description}</TableCell>
        <TableCell>{modifier.min}</TableCell>
        <TableCell>{modifier.max}</TableCell>
        <TableCell>
          {modifier.modifierItems ? modifier.modifierItems.length : 0}
        </TableCell>
        <TableCell>
          <ModifierStatusSwitch modifier={modifier} />
        </TableCell>
        <TableCell>
          {modifier.products &&
            modifier.products.map((p) => (
              <Chip
                key={p.uuid}
                size="small"
                label={p.title}
                className={classes.productChip}
              />
            ))}
        </TableCell>
        <TableCell>
          <Tooltip title="Bearbeiten">
            <IconButton
              onClick={() => {
                setOpenModifierDialog(true);
              }}
              disabled={hasPos}
            >
              <EditRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Kopieren">
            <IconButton
              onClick={() => {
                setOpenDialog(true);
              }}
              disabled={hasPos}
            >
              <FileCopyRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Produkte verlinken">
            <IconButton
              onClick={() => {
                setShowLinkModifierDialog(true);
              }}
            >
              <LinkRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
              disabled={hasPos}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProductModifierTableRow;
