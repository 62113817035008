import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import * as Joi from 'joi';
import { ClearRounded, DoneRounded } from '@material-ui/icons';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { gastronomyState } from '../store/gastronomy';
import { useApolloClient } from '@apollo/client';
import { UpdateGastronomyMutationDocument } from '../services/graphql/typed-operations';
import { cleanGraphqlTypeName } from '../helpers/FormatHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    card: {
      marginBottom: theme.spacing(1)
    }
  })
);

const schemaValues = Joi.object({
  universalIp: Joi.string().ip(),
  universalPort: Joi.string().pattern(/^[0-9]+$/),
  deviceId: Joi.string().pattern(/^[0-9]+$/),
  neoServiceUrl: Joi.string().uri(),
  singleoutlet: Joi.string()
    .optional()
    .pattern(/^[0-9]+$/)
});

type FormValues = Omit<
  MatrixConfig,
  'universalPort' | 'deviceId' | 'singleoutlet'
> & {
  universalPort?: string;
  deviceId?: string;
  singleoutlet?: string;
};

const getDefaultValues = (gastronomy?: FullGastronomyFragment | null) => {
  if (
    gastronomy &&
    gastronomy.posConfig &&
    gastronomy.posConfig.config &&
    gastronomy.posConfig.config.matrix
  ) {
    const conf = gastronomy.posConfig.config.matrix;

    return cleanGraphqlTypeName({
      ...conf,
      universalPort: conf.universalPort
        ? conf.universalPort.toString()
        : undefined,
      deviceId: conf.deviceId ? conf.deviceId.toString() : undefined
    });
  }
};

const emptyValues = {
  universalIp: '',
  universalPort: '',
  deviceId: '',
  neoServiceUrl: '',
  singleoutlet: ''
};

const MatrixConfig: React.FC = () => {
  const classes = useStyles();
  const [gastronomy, setGastronomy] = useRecoilState(gastronomyState);
  const [values, setValues] = useState<FormValues>(() => {
    const defValues = getDefaultValues(gastronomy);
    return defValues || emptyValues;
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const defValues = getDefaultValues(gastronomy);
    if (defValues) {
      setValues(defValues);
    } else {
      setValues(emptyValues);
    }
  }, [gastronomy]);

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues((prev) => ({
      ...prev,
      [name]: event.target.value
    }));
  };

  const validateInput = (): boolean => {
    setErrors([]);
    const result = schemaValues.validate(values);
    return processJoiResult(result);
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation errror', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const onClose = () => {
    // redirect
  };

  const saveMatrixConfig = async () => {
    if (validateInput()) {
      setLoading(true);
      await saveGastronomy();
      setLoading(false);
    }
  };

  const saveGastronomy = async () => {
    if (gastronomy) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: UpdateGastronomyMutationDocument,
          variables: {
            input: {
              uuid: gastronomy.uuid,
              posConfig: cleanGraphqlTypeName({
                ...gastronomy.posConfig,
                config: {
                  matrix: {
                    deviceId: (typeof values.deviceId === 'string'
                      ? parseInt(values.deviceId, 10)
                      : values.deviceId) as number,
                    neoServiceUrl: values.neoServiceUrl as string,
                    universalIp: values.universalIp as string,
                    universalPort: (typeof values.universalPort === 'string'
                      ? parseInt(values.universalPort, 10)
                      : values.universalPort) as number,
                    singleoutlet: (typeof values.singleoutlet === 'string'
                      ? parseInt(values.singleoutlet, 10)
                      : values.singleoutlet) as number
                  }
                }
              })
            }
          }
        });

        if (!data) {
          return;
        }

        const newGastronomy: GastronomyFragment = data.updateGastronomy;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Profil aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        setGastronomy(newGastronomy);

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <form noValidate autoComplete="off">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item>
              <TextField
                error={errors.includes('universalIp')}
                id="universalIp"
                label="UniversalPOS IP Address"
                placeholder="192.168.0.1"
                className={classes.input}
                onChange={handleChange('universalIp')}
                required={true}
                value={values.universalIp}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={errors.includes('universalPort')}
                id="universalPort"
                label="UniversalPOS Port"
                placeholder="1313"
                className={classes.input}
                onChange={handleChange('universalPort')}
                required={true}
                value={values.universalPort}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={errors.includes('deviceId')}
                id="deviceId"
                label="Device ID"
                placeholder="100"
                className={classes.input}
                onChange={handleChange('deviceId')}
                required={true}
                value={values.deviceId}
                margin="dense"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item spacing={3}>
            <TextField
              error={errors.includes('neoServiceUrl')}
              id="neoServiceUrl"
              label="Neo Service URL"
              placeholder="http://192.168.0.1:42043/getdata"
              className={classes.input}
              onChange={handleChange('neoServiceUrl')}
              required={true}
              value={values.neoServiceUrl}
              margin="dense"
              variant="outlined"
              size="medium"
            />
          </Grid>
          <Grid item>
            <TextField
              error={errors.includes('singleoutlet')}
              id="singleoutlet"
              label="Outlet ID"
              placeholder="1"
              className={classes.input}
              onChange={handleChange('singleoutlet')}
              required={false}
              value={values.singleoutlet}
              margin="dense"
              variant="outlined"
              size="small"
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Button
                className={classes.input}
                onClick={onClose}
                variant="outlined"
              >
                Verwerfen
                <ClearRounded className={classes.rightIcon} />
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                className={classes.input}
                onClick={saveMatrixConfig}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Speichern
                <DoneRounded className={classes.rightIcon} />
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

export default MatrixConfig;
