import { useQuery } from '@apollo/client';
import {
  Button,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetProductsQueryDocument } from '../services/graphql/typed-operations';
import { gastronomyState, hasPosOrWincaratSelector } from '../store/gastronomy';
import CircularIndeterminate from './CircularIndeterminate';
import ProductForm from './ProductForm';
import ProductLineItem from './ProductLineItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowY: 'auto'
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    addButton: {
      width: '100%',
      height: 100
    },
    addButtonContainer: {
      justifyContent: 'center',
      marginTop: theme.spacing(3)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  productGroup: FullProductGroupFragment;
}

const ProductTable: React.FC<Props> = ({ productGroup }) => {
  const classes = useStyles();
  const [showProductAddForm, setShowProductAddForm] = useState<boolean>(false);
  const hasPosOrWincarat = useRecoilValue(hasPosOrWincaratSelector);
  const gastronomy = useRecoilValue(gastronomyState);

  const { data, loading } = useQuery(GetProductsQueryDocument, {
    variables: {
      params: {
        product_group_uuid: productGroup.uuid,
        status: 'ALL',
        user_uuid: gastronomy ? gastronomy.uuid : 'not set'
      }
    },
    skip: !gastronomy
  });

  return (
    <>
      <div className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Produktbild</TableCell>
              <TableCell>Titel</TableCell>
              <TableCell>Optionsgruppen</TableCell>
              <TableCell>Steuersatz</TableCell>
              <TableCell>Bruttopreis</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularIndeterminate />
            ) : (
              <>
                {data &&
                  data.getProducts.map((product) => (
                    <ProductLineItem key={product.uuid} product={product} />
                  ))}
              </>
            )}
            {productGroup && showProductAddForm && data && (
              <TableRow>
                <TableCell colSpan={7}>
                  <ProductForm
                    productGroupUuid={productGroup.uuid}
                    productGroupType={productGroup.product_group_type}
                    onClose={() => setShowProductAddForm(false)}
                    currentOrderNr={data.getProducts.length}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {productGroup && !showProductAddForm && (
        <div className={classes.addButtonContainer}>
          <Button
            disabled={hasPosOrWincarat}
            variant="outlined"
            className={classes.addButton}
            onClick={() => setShowProductAddForm(true)}
          >
            Produkt hinzufügen
            <AddIcon className={classes.rightIcon} />
          </Button>
        </div>
      )}
    </>
  );
};

export default ProductTable;
