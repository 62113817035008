import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Switch,
  Theme,
  TextField
} from '@material-ui/core';
import { ClearRounded, DeleteRounded, EditRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import PickColorDialog from './PickColorDialog';
import PickImageDialog from './PickImageDialog';
import CustomThemePreview from './CustomThemePreview';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isAdminSelector } from '../store/auth';
import Joi from 'joi';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorPreview: {
      border: '1px solid grey',
      marginRight: '5px',
      display: 'inline-block',
      width: '10px',
      height: '10px'
    },
    switch: {
      margin: theme.spacing(2, 0)
    },
    button: {
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    input: {
      width: '100%',
      margin: theme.spacing(1, 0)
    }
  })
);

type PickedColorType = 'primary' | 'secondary' | 'appBarColors';

type PickedColor = {
  type: PickedColorType;
  objectKey: string;
  hexColor?: string;
};

const defaultColor: ColorPaletteInput = {
  _50: '',
  _100: '',
  _200: '',
  _300: '',
  _400: '',
  _500: '',
  _600: '',
  _700: '',
  _800: '',
  _900: ''
};

const defaultAppBarcolor: AppBarColorsInput = {
  backgroundColor: '',
  primaryColor: '',
  secondaryColor: ''
};

const getInitialAppBarLogoValue = (theme?: CustomTheme | null): string => {
  return theme && theme.colors ? theme.appBarLogo || '' : '';
};

const getInitialAppBarColorValues = (
  theme?: CustomTheme | null
): AppBarColorsInput => {
  if (theme && theme.colors && theme.colors.appBarColors) {
    const colors = { ...(theme.colors.appBarColors || defaultAppBarcolor) };

    if (colors.hasOwnProperty('__typename')) {
      delete colors.__typename;
    }

    return colors;
  }

  return defaultAppBarcolor;
};

const getInitialPrimaryColorValues = (
  theme?: CustomTheme | null
): ColorPaletteInput => {
  if (theme && theme.colors && theme.colors.primary) {
    const colors = { ...(theme.colors.primary || defaultColor) };

    if (colors.hasOwnProperty('__typename')) {
      delete colors.__typename;
    }

    return colors;
  }

  return defaultColor;
};

const getInitialSecondaryColorValues = (
  theme?: CustomTheme | null
): ColorPaletteInput => {
  if (theme && theme.colors && theme.colors.secondary) {
    const colors = { ...(theme.colors.secondary || defaultColor) };

    if (colors.hasOwnProperty('__typename')) {
      delete colors.__typename;
    }

    return colors;
  }

  return defaultColor;
};

const getInitialHeadTags = (theme?: CustomTheme | null): string => {
  return theme?.head
    ? JSON.stringify(
        theme.head.map((h) => {
          return Object.fromEntries(
            Object.entries(h).filter(([key]) => !key.includes('__typename'))
          );
        })
      )
    : '';
};

const getInitialWaitForFonts = (theme?: CustomTheme | null): string => {
  return theme?.waitForFonts ? JSON.stringify(theme.waitForFonts) : '';
};

const headSchemaValues = Joi.array()
  .min(1)
  .items(
    Joi.object({
      tagType: Joi.string().valid('META', 'STYLE', 'LINK').required(),
      attributes: Joi.object().allow(null),
      children: Joi.string().allow(null)
    })
  )
  .allow('');

const waitForFontsSchemaValues = Joi.array()
  .min(1)
  .items(Joi.string())
  .allow('');

export interface CustomThemeFormOutput {
  theme: CustomThemeInput;
  appBarLogoFile: File | null;
  deleteAppBarLogo: boolean;
  hasErrors: boolean;
}

interface Props {
  titleOverride?: string;
  values?: CustomTheme | null;
  onChange: (output: CustomThemeFormOutput) => void;
  disableLink?: boolean;
}

const CustomThemeForm: React.FC<Props> = ({
  values,
  onChange,
  titleOverride,
  disableLink
}) => {
  const classes = useStyles();
  const isAdmin = useRecoilValue(isAdminSelector);
  const [isExpertMode, setIsExpertMode] = useState<boolean>(false);
  const [appBarLogoFile, setAppBarLogoFile] = useState<File | null>(null);
  const [deleteAppBarLogo, setDeleteAppBarLogo] = useState<boolean>(false);
  const [appBarLogo, setAppBarLogo] = useState<string>(
    getInitialAppBarLogoValue(values)
  );
  const [appBarColors, setAppBarColors] = useState<AppBarColorsInput>(
    getInitialAppBarColorValues(values)
  );
  const [primaryColors, setPrimaryColors] = useState<ColorPaletteInput>(
    getInitialPrimaryColorValues(values)
  );
  const [secondaryColors, setSecondaryColors] = useState<ColorPaletteInput>(
    getInitialSecondaryColorValues(values)
  );
  const [pickedColor, setPickedColor] = useState<PickedColor | null>(null);
  const [showImageDialog, setShowImageDialog] = useState<boolean>(false);

  const [customCss, setCustomCss] = useState<string | null>(
    values?.customCss || ''
  );
  const [head, setHead] = useState<string>(getInitialHeadTags(values));
  const [waitForFonts, setWaitForFonts] = useState<string | null>(
    getInitialWaitForFonts(values)
  );
  const [errors, setErrors] = useState<string[]>([]);
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);

  useEffect(() => {
    onChangeTheme();
  }, [
    appBarLogo,
    appBarColors,
    primaryColors,
    secondaryColors,
    appBarLogoFile,
    deleteAppBarLogo,
    customCss,
    head,
    waitForFonts
  ]);

  useEffect(() => {
    resetForm();
  }, [values]);

  const resetForm = () => {
    setAppBarLogo(getInitialAppBarLogoValue(values));
    setAppBarColors(getInitialAppBarColorValues(values));
    setPrimaryColors(getInitialPrimaryColorValues(values));
    setSecondaryColors(getInitialSecondaryColorValues(values));
    setPickedColor(null);
    setAppBarLogoFile(null);
    setDeleteAppBarLogo(false);
    setCustomCss(values?.customCss || '');
    setHead(getInitialHeadTags(values));
  };

  const handleAppBarColors = (
    current: AppBarColorsInput,
    { objectKey, hexColor }: PickedColor
  ): AppBarColorsInput => {
    const newValue: AppBarColorsInput = { ...current };
    newValue[objectKey as keyof AppBarColorsInput] = hexColor;
    return newValue;
  };

  const handleColorPaletteUpdate = (
    current: ColorPaletteInput,
    { objectKey, hexColor }: PickedColor
  ): ColorPaletteInput => {
    const newValue: ColorPaletteInput = { ...current };
    if (isExpertMode) {
      newValue[objectKey as keyof ColorPaletteInput] = hexColor;
    } else {
      for (const [key] of Object.entries(newValue)) {
        if (key !== '__typename') {
          newValue[key as keyof ColorPaletteInput] = hexColor;
        }
      }
    }
    return newValue;
  };

  const onChangeColor = (
    hexColor: string | null,
    optionalPickedColor?: PickedColor
  ) => {
    const currentPickedColor = optionalPickedColor || pickedColor;

    if (currentPickedColor && typeof hexColor === 'string') {
      console.log(
        `Changing color ${currentPickedColor.type}.${currentPickedColor.objectKey} from ${currentPickedColor.hexColor} to ${hexColor}`
      );
      const currentType = currentPickedColor.type;
      const currentKey = currentPickedColor.objectKey;

      if (currentType === 'appBarColors') {
        setAppBarColors((current) =>
          handleAppBarColors(current, {
            type: currentType,
            objectKey: currentKey,
            hexColor
          })
        );
      }
      if (currentType === 'primary') {
        setPrimaryColors((current) =>
          handleColorPaletteUpdate(current, {
            type: currentType,
            objectKey: currentKey,
            hexColor
          })
        );
      }
      if (currentType === 'secondary') {
        setSecondaryColors((current) =>
          handleColorPaletteUpdate(current, {
            type: currentType,
            objectKey: currentKey,
            hexColor
          })
        );
      }
    }

    setPickedColor(null);
  };

  const onPickColor = (color: PickedColor) => () => {
    setPickedColor(color || null);
  };

  const onResetColor = (color: PickedColor) => () => {
    onChangeColor('', color);
  };

  const processHeadJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      setErrors((prevErrors) => [...prevErrors, 'head']);
      return false;
    }
    return true;
  };

  const processWaitForFontsJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      setErrors((prevErrors) => [...prevErrors, 'waitForFonts']);
      return false;
    }
    return true;
  };

  const onChangeTheme = async () => {
    setErrors([]);

    const headResult = headSchemaValues.validate(head ? JSON.parse(head) : '');
    const waitForFontsResults = waitForFontsSchemaValues.validate(
      waitForFonts ? JSON.parse(waitForFonts) : ''
    );

    const hasErrors =
      !processHeadJoiResult(headResult) ||
      !processWaitForFontsJoiResult(waitForFontsResults);

    if (hasErrors) {
      addSnackbarMessages(
        [
          {
            type: 'error',
            message: 'Bitte überprüfen Sie Ihre Eingabe!'
          }
        ],
        setSnackbarMessages
      );
    }

    const output: CustomThemeFormOutput = {
      theme: {
        appBarLogo: appBarLogo || undefined,
        colors: {
          appBarColors: appBarColors || undefined,
          primary: primaryColors || undefined,
          secondary: secondaryColors || undefined
        },
        customCss: customCss || undefined,
        head: head ? JSON.parse(head) : undefined,
        waitForFonts: waitForFonts ? JSON.parse(waitForFonts) : undefined
      },
      appBarLogoFile,
      deleteAppBarLogo,
      hasErrors
    };
    onChange(output);
  };

  const renderColorPreview = (hexColor?: string): React.ReactNode => {
    if (!hexColor) {
      return <>Nicht verwendet</>;
    }

    return (
      <span
        className={classes.colorPreview}
        style={{
          backgroundColor: hexColor || 'transparent'
        }}
      ></span>
    );
  };

  const handleAppBarLogoSave = (file: File) => {
    setAppBarLogoFile(file || null);
    setDeleteAppBarLogo(false);
    setShowImageDialog(false);
  };

  const handleAppBarLogoDelete = () => {
    setDeleteAppBarLogo(true);
    setShowImageDialog(false);
  };

  return (
    <>
      <PickColorDialog
        isOpen={!!pickedColor}
        hexColor={pickedColor ? pickedColor.hexColor : null}
        onClose={onChangeColor}
      />
      <PickImageDialog
        title="App Bar Logo"
        isOpen={showImageDialog}
        imageUrl={appBarLogo}
        appBarColor={appBarColors.backgroundColor as string | undefined}
        onClose={() => setShowImageDialog(false)}
        onSave={handleAppBarLogoSave}
        onDelete={handleAppBarLogoDelete}
      />

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={isExpertMode}
              onChange={() => setIsExpertMode((current) => !current)}
              color="primary"
            />
          }
          className={classes.switch}
          label={'Expertmode'}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid item xs={12}>
          <List dense>
            <ListSubheader>App Bar</ListSubheader>

            <ListItem divider>
              <ListItemText
                primary="App Bar Logo"
                secondary={appBarLogo || 'Nicht verwendet'}
                style={{ wordBreak: 'break-all' }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => setShowImageDialog(true)}>
                  <EditRounded />
                </IconButton>
                <IconButton edge="end" onClick={handleAppBarLogoDelete}>
                  <DeleteRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            {Object.entries(appBarColors).map(([key, value], index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={key}
                  secondary={
                    <>
                      {renderColorPreview(value as string | undefined)}
                      {value}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={onPickColor({
                      type: 'appBarColors',
                      objectKey: key,
                      hexColor: value as string | undefined
                    })}
                  >
                    <EditRounded />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={onResetColor({
                      type: 'appBarColors',
                      objectKey: key
                    })}
                  >
                    <DeleteRounded />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <List dense>
            {isExpertMode ? (
              <>
                <ListSubheader>Primärfarbe</ListSubheader>
                {Object.entries(primaryColors).map(([key, value], index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={key.replace('_', '')}
                      secondary={
                        <>
                          {renderColorPreview(value as string | undefined)}
                          {value}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={onPickColor({
                          type: 'primary',
                          objectKey: key,
                          hexColor: value as string | undefined
                        })}
                      >
                        <EditRounded />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={onResetColor({
                          type: 'appBarColors',
                          objectKey: key
                        })}
                      >
                        <DeleteRounded />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={'Primärfarbe'}
                  secondary={
                    <>
                      {renderColorPreview(
                        primaryColors._500 as string | undefined
                      )}
                      {primaryColors._500}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={onPickColor({
                      type: 'primary',
                      objectKey: '_500',
                      hexColor: primaryColors._500 as string | undefined
                    })}
                  >
                    <EditRounded />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={onResetColor({
                      type: 'primary',
                      objectKey: '_500'
                    })}
                  >
                    <DeleteRounded />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>

        <Grid item xs={12}>
          <List dense>
            {isExpertMode ? (
              <>
                <ListSubheader>Sekundärfarbe</ListSubheader>
                {Object.entries(secondaryColors).map(([key, value], index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={key.replace('_', '')}
                      secondary={
                        <>
                          {renderColorPreview(value as string | undefined)}
                          {value}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={onPickColor({
                          type: 'secondary',
                          objectKey: key,
                          hexColor: value as string | undefined
                        })}
                      >
                        <EditRounded />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={onResetColor({
                          type: 'secondary',
                          objectKey: key
                        })}
                      >
                        <DeleteRounded />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={'Sekundärfarbe'}
                  secondary={
                    <>
                      {renderColorPreview(
                        secondaryColors._500 as string | undefined
                      )}
                      {secondaryColors._500}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={onPickColor({
                      type: 'secondary',
                      objectKey: '_500',
                      hexColor: secondaryColors._500 as string | undefined
                    })}
                  >
                    <EditRounded />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={onResetColor({
                      type: 'secondary',
                      objectKey: '_500'
                    })}
                  >
                    <DeleteRounded />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            onClick={resetForm}
            variant="outlined"
          >
            Aktuelle Änderungen verwerfen
            <ClearRounded className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomThemePreview
          titleOverride={titleOverride}
          disableLink={disableLink}
          appBarColors={appBarColors}
          appBarLogo={deleteAppBarLogo ? undefined : appBarLogo}
          appBarLogoFile={deleteAppBarLogo ? undefined : appBarLogoFile}
          primaryColor={primaryColors}
          secondaryColor={secondaryColors}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={5}
          rowsMax={25}
          label="CSS Overwrite"
          className={classes.input}
          onChange={(evt) => setCustomCss(evt.target.value)}
          value={customCss}
          margin="dense"
          variant="outlined"
        />
      </Grid>
      {isExpertMode && (
        <>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={5}
              rowsMax={25}
              error={errors.includes('head')}
              label="Head Tags"
              className={classes.input}
              onChange={(evt) => setHead(evt.target.value)}
              value={head}
              margin="dense"
              variant="outlined"
              disabled={!isAdmin}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={5}
              rowsMax={25}
              error={errors.includes('waitForFonts')}
              label="Auf Web Fonts warten"
              className={classes.input}
              onChange={(evt) => setWaitForFonts(evt.target.value)}
              value={waitForFonts}
              margin="dense"
              variant="outlined"
              disabled={!isAdmin}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default CustomThemeForm;
