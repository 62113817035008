// dynamically load JavaScript files in our html with callback when finished
export const loadScript = (url: string, callback: () => void): void => {
  console.log('loading script: ', url);
  const script = document.createElement('script'); // create script tag
  script.type = 'text/javascript';

  script.async = true;

  script.onload = () => callback();

  script.src = url; // load by url
  document.getElementsByTagName('head')[0].appendChild(script); // append to head
};
