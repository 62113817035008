import { useApolloClient } from '@apollo/client';
import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import ClearRounded from '@material-ui/icons/ClearRounded';
import DoneRounded from '@material-ui/icons/DoneRounded';
import { Alert, AlertTitle } from '@material-ui/lab';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { setErrorSnackbarMessage } from '../helpers/ErrorHelper';
import { getTableTypeLabel } from '../helpers/GastronomyHelper';
import { getOrderTypeLabel } from '../helpers/OrderHelper';
import { isTableTypeAlreadyUsed } from '../helpers/TableHelper';
import {
  AddTableMutationDocument,
  AdminUpdateTableMutationDocument
} from '../services/graphql/typed-operations';
import { addSnackbarMessages, snackbarMessagesState } from '../store/app';
import { isAdminSelector } from '../store/auth';
import {
  addToState,
  gastronomyState,
  hasPosSelector,
  roomsState,
  tablesState,
  updateState
} from '../store/gastronomy';
import InfoTooltip from './InfoTooltip';
import MapPolygonEdit from './MapPolygonEdit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      width: '100%'
    },
    switch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    margin: {
      margin: theme.spacing(2, 0)
    }
  })
);

const schemaValues = Joi.object({
  table_number: Joi.string().min(1),
  table_type: Joi.string(),
  table_type_override: Joi.string().allow(''),
  allow_preorder: Joi.boolean(),
  room_uuid: Joi.string().guid().allow(''),
  service_information: Joi.string().allow(''),
  pos_key: Joi.string().allow('')
});

const schemaTableConfig = Joi.object({
  leadTime: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  slotLength: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  preorderDays: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  ignoreLeadTimeWhenOpening: Joi.boolean()
});

const schemaDeliveryConfig = Joi.object({
  minOrderAmount: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  deliveryFee: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  freeDeliveryFrom: Joi.string()
    .pattern(/^[0-9]+$/)
    .allow(''),
  area: Joi.string()
});

interface Props {
  table?: DeliveryTableFragment;
  room?: RoomFragment;
  onClose: () => void;
  allowedTableTypes: TableType[];
  tableNumberLabel?: string;
  tableNumberPlaceholder?: string;
  showPreOrderSwitch?: boolean;
}

const TableForm: React.FC<Props> = ({
  table,
  room,
  onClose,
  allowedTableTypes,
  tableNumberLabel = 'Bezeichnung',
  tableNumberPlaceholder = 'Tisch 1',
  showPreOrderSwitch
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<{
    table_number: string;
    table_type: TableType;
    table_type_override: TableType | '';
    allow_preorder: boolean;
    room_uuid: string;
    service_information: string;
    pos_key: string;
  }>({
    table_number: '',
    table_type: allowedTableTypes[0],
    table_type_override: '',
    allow_preorder:
      allowedTableTypes.includes('TAKEAWAY') ||
      allowedTableTypes.includes('DELIVERY'),
    room_uuid: '',
    service_information: '',
    pos_key: ''
  });
  const [tableConfig, setTableConfig] = useState<{
    leadTime: string;
    slotLength: string;
    preorderDays: string;
    ignoreLeadTimeWhenOpening: boolean;
  }>({
    leadTime: '',
    slotLength: '',
    preorderDays: '',
    ignoreLeadTimeWhenOpening: false
  });
  const [deliveryConfig, setDeliveryConfig] = useState<{
    minOrderAmount: string;
    deliveryFee: string;
    freeDeliveryFrom: string;
    area: string;
  }>({
    minOrderAmount: '',
    deliveryFee: '',
    freeDeliveryFrom: '',
    area: ''
  });
  const gastronomy = useRecoilValue(gastronomyState);
  const rooms = useRecoilValue(roomsState);
  const hasPos = useRecoilValue(hasPosSelector);
  const isAdmin = useRecoilValue(isAdminSelector);
  const [tables, setTables] = useRecoilState(tablesState);
  const [errors, setErrors] = useState<string[]>([]);
  const [freeDeliveryFrom, setFreeDeliveryFrom] = useState<boolean>(
    table && table.delivery_config && table.delivery_config.freeDeliveryFrom
      ? true
      : false
  );
  const theme = useTheme();
  const setSnackbarMessages = useSetRecoilState(snackbarMessagesState);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (table) {
      setValues({
        table_number: table.table_number || '',
        table_type: table.table_type || 'DINEIN',
        table_type_override: table.table_type_override || '',
        allow_preorder: table.allow_preorder || false,
        room_uuid: table.room ? table.room.uuid || '' : '',
        service_information: table.service_information || '',
        pos_key: table?.pos_key || ''
      });
      if (table.tableConfig) {
        setTableConfig({
          leadTime: numericToString(table.tableConfig.leadTime),
          slotLength: numericToString(table.tableConfig.slotLength),
          preorderDays: numericToString(table.tableConfig.preorderDays),
          ignoreLeadTimeWhenOpening:
            table.tableConfig.ignoreLeadTimeWhenOpening || false
        });
      }
      if (table.delivery_config) {
        setDeliveryConfig({
          minOrderAmount: numericToString(table.delivery_config.minOrderAmount),
          deliveryFee: numericToString(table.delivery_config.deliveryFee),
          freeDeliveryFrom: numericToString(
            table.delivery_config.freeDeliveryFrom
          ),
          area: table.delivery_config.area
            ? JSON.stringify(table.delivery_config.area)
            : ''
        });
      }
    }
  }, [table]);

  useEffect(() => {
    if (room) {
      setValues((prev) => ({ ...prev, room_uuid: room.uuid }));
    }
  }, [room]);

  const numericToString = (input?: number | null) => {
    return typeof input === 'number' ? input.toString() : '';
  };

  const isInt = (input: string) => {
    return !isNaN(parseInt(input, 10));
  };

  const handleChange = (name: string) => (event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    // validateInput(name, value);

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleDeliveryConfigChange = (name: string) => (event: any) => {
    const value = event.target.value;

    // validateInput(name, value);

    setDeliveryConfig((current) => {
      return {
        ...current,
        [name]: value
      };
    });
  };

  const handleTableConfigChange = (name: string) => (event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    console.log('value', name, value);

    setTableConfig((current) => {
      return {
        ...current,
        [name]: value
      };
    });
  };

  const validateValues = () => {
    const tableType = values.table_type_override || values.table_type;

    if (
      tableType !== 'DINEIN' &&
      tableType !== 'DELIVERY' &&
      isTableTypeAlreadyUsed(tables, tableType, table ? table.uuid : undefined)
    ) {
      addSnackbarMessages(
        [
          {
            type: 'error',
            message: `Sie haben bereits ${getTableTypeLabel(tableType)}!`
          }
        ],
        setSnackbarMessages
      );

      setErrors((prev) => [
        ...prev,
        values.table_type_override ? 'table_type_override' : 'table_type'
      ]);

      return false;
    }

    const result = schemaValues.validate(values);
    return processJoiResult(result);
  };

  const validateTableConfig = () => {
    const result = schemaTableConfig.validate(tableConfig);
    return processJoiResult(result);
  };

  const validateDeliveryConfig = () => {
    const result = schemaDeliveryConfig.validate(deliveryConfig);
    return processJoiResult(result);
  };

  const processJoiResult = (result: Joi.ValidationResult) => {
    if (result.error) {
      console.log('validation errror', result);
      const err: string[] = [];
      if (result.error) {
        for (const error of result.error.details) {
          if (error.context && error.context.key) {
            err.push(error.context.key);
          }
        }
      }
      setErrors((prevErrors) => [...prevErrors, ...err]);
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    setErrors([]);
    const validateValuesResult = validateValues();
    const validateConfigResult = validateTableConfig();
    const validateDeliveryConfigResult =
      (values.table_type_override &&
        values.table_type_override === 'DELIVERY') ||
      (!values.table_type_override && values.table_type === 'DELIVERY')
        ? validateDeliveryConfig()
        : true;
    if (
      validateValuesResult &&
      validateConfigResult &&
      validateDeliveryConfigResult
    ) {
      if (table) {
        await submitUpdateTable();
      } else {
        await submitInsertTable();
      }
    } else {
      addSnackbarMessages(
        [
          {
            type: 'error',
            message: 'Bitte überprüfen Sie Ihre Eingabe!'
          }
        ],
        setSnackbarMessages
      );
    }
  };

  const submitInsertTable = async () => {
    if (gastronomy) {
      try {
        const input: TableAddInput = {
          table_number: values.table_number,
          table_type: values.table_type,
          allow_preorder: values.allow_preorder,
          user_uuid: gastronomy.uuid,
          pos_key: values.pos_key
        };

        if (values.room_uuid) {
          input.room_uuid = values.room_uuid;
        }
        if (values.service_information) {
          input.service_information = values.service_information;
        }

        if (
          values.table_type !== 'DINEIN' &&
          values.table_type !== 'RESERVATION' &&
          tableConfig
        ) {
          input.tableConfig = getTableConfigInput();
        }

        if (values.table_type === 'DELIVERY') {
          input.delivery_config = {
            ...getDeliveryConfigInput(),
            area: (deliveryConfig.area || '') as string
          };
        }

        if (room) {
          input.room_uuid = room.uuid;
        }

        const { data } = await apolloClient.mutate({
          mutation: AddTableMutationDocument,
          variables: { input }
        });

        if (!data) {
          return;
        }

        const newTable = data.addTable;

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Tisch ${newTable.table_number} hinzugefügt`
            }
          ],
          setSnackbarMessages
        );

        resetForm();

        addToState(newTable, setTables);

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  const getTableConfigInput = () => {
    const config: TableConfigInput = {};

    if (isInt(tableConfig.leadTime)) {
      config.leadTime = parseInt(tableConfig.leadTime, 10);
    }

    if (isInt(tableConfig.slotLength)) {
      config.slotLength = parseInt(tableConfig.slotLength, 10);
    }

    if (isInt(tableConfig.preorderDays)) {
      config.preorderDays = parseInt(tableConfig.preorderDays, 10);
    }

    if (tableConfig.ignoreLeadTimeWhenOpening) {
      config.ignoreLeadTimeWhenOpening = tableConfig.ignoreLeadTimeWhenOpening;
    }
    return config;
  };

  const getDeliveryConfigInput = () => {
    const input: Omit<DeliveryConfigAddInput, 'area'> = {};
    if (isInt(deliveryConfig.deliveryFee)) {
      input.deliveryFee = parseInt(deliveryConfig.deliveryFee, 10);
    }

    if (isInt(deliveryConfig.minOrderAmount)) {
      input.minOrderAmount = parseInt(deliveryConfig.minOrderAmount, 10);
    }

    if (freeDeliveryFrom && isInt(deliveryConfig.freeDeliveryFrom)) {
      input.freeDeliveryFrom = parseInt(deliveryConfig.freeDeliveryFrom, 10);
    } else {
      delete input.freeDeliveryFrom;
    }

    return input;
  };

  const resetForm = () => {
    // setValues(initialState);
  };

  const submitUpdateTable = async () => {
    if (gastronomy && table) {
      try {
        const input: TableUpdateInput = {
          ...values,
          table_type_override:
            values.table_type_override !== ''
              ? values.table_type_override
              : undefined,
          uuid: table.uuid,
          clearRoom: values.room_uuid === '',
          clearTableTypeOverride: values.table_type_override === ''
        };

        if (
          ((values.table_type_override &&
            values.table_type_override !== 'DINEIN' &&
            values.table_type_override !== 'RESERVATION') ||
            (!values.table_type_override &&
              values.table_type !== 'DINEIN' &&
              values.table_type !== 'RESERVATION')) &&
          tableConfig
        ) {
          input.tableConfig = getTableConfigInput();
        }

        if (
          values.table_type_override === 'DELIVERY' ||
          values.table_type === 'DELIVERY'
        ) {
          input.delivery_config = {
            ...getDeliveryConfigInput(),
            table_uuid: table.uuid as string,
            area: (deliveryConfig.area || '') as string
          };
        }

        if (values.room_uuid === '') {
          delete input.room_uuid;
        }

        if (values.table_type_override === '') {
          delete input.table_type_override;
        }

        console.debug('input', input);

        const { data } = await apolloClient.mutate({
          mutation: AdminUpdateTableMutationDocument,
          variables: { input }
        });

        if (!data) {
          return;
        }

        const newTable = data.adminUpdateTable;

        console.debug('newTable', newTable);

        addSnackbarMessages(
          [
            {
              type: 'success',
              message: `Tisch ${newTable.table_number} aktualisiert`
            }
          ],
          setSnackbarMessages
        );

        updateState(newTable, setTables);

        onClose();
      } catch (error) {
        setErrorSnackbarMessage(error, setSnackbarMessages);
      }
    }
  };

  return (
    <>
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              error={errors.includes('table_number')}
              id="table-number"
              label={tableNumberLabel}
              placeholder={tableNumberPlaceholder}
              className={classes.input}
              onChange={handleChange('table_number')}
              required={true}
              value={values.table_number}
              margin="dense"
              disabled={hasPos}
              variant="outlined"
            />
          </Grid>
          {gastronomy?.posConfig?.pos_type === 'WINCARAT' && (
            <Grid item>
              <TextField
                error={errors.includes('pos_key')}
                id="pos-key"
                label="ID in der Registrierkasse"
                placeholder="123"
                className={classes.input}
                onChange={handleChange('pos_key')}
                value={values.pos_key}
                margin="dense"
                variant="outlined"
              />
            </Grid>
          )}
          {rooms && rooms.length > 0 && (
            <Grid item>
              <FormControl
                className={classes.input}
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="room-select">Raum</InputLabel>
                <Select
                  disabled={hasPos}
                  value={values.room_uuid}
                  onChange={handleChange('room_uuid')}
                  error={errors.includes('room_uuid')}
                  labelId="room-select"
                  label="Raum"
                  margin="dense"
                >
                  {rooms.map((r) => (
                    <MenuItem key={r.uuid} value={r.uuid}>
                      {r.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormGroup>
              {hasPos ? (
                <FormControl
                  className={classes.input}
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel id="table-type-override-select">
                    Bestellart Überschreiben
                  </InputLabel>
                  <Select
                    value={values.table_type_override}
                    onChange={handleChange('table_type_override')}
                    labelId="table-type-override-select"
                    label="Bestellart Überschreiben"
                    error={errors.includes('table_type_override')}
                    margin="dense"
                  >
                    {allowedTableTypes.map((tt) => (
                      <MenuItem key={tt} value={tt}>
                        {getOrderTypeLabel(tt)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  className={classes.input}
                  margin="dense"
                  variant="outlined"
                >
                  <InputLabel id="table-type-select">Bestellart</InputLabel>
                  <Select
                    value={values.table_type}
                    onChange={handleChange('table_type')}
                    labelId="table-type-select"
                    label="Bestellart"
                    disabled={hasPos}
                    error={errors.includes('table_type')}
                    margin="dense"
                  >
                    {allowedTableTypes.map((tt) => (
                      <MenuItem key={tt} value={tt}>
                        {getOrderTypeLabel(tt)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormGroup>
          </Grid>
          {showPreOrderSwitch && (
            <Grid
              item
              xs={12}
              sm={rooms && rooms.length > 0 ? 3 : 4}
              className={classes.switch}
            >
              <div style={{ marginRight: theme.spacing(2) }}>
                <InfoTooltip text="Ist die Vorbestellung aktiviert können Gäste je nach Einstellung auch außerhalb der Öffnungszeiten, am gleichen Tag oder mehreren Tage im voraus vorbestellen." />
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.allow_preorder}
                    value={values.allow_preorder}
                    onChange={handleChange('allow_preorder')}
                    color="primary"
                    disabled={!showPreOrderSwitch}
                  />
                }
                label={'Vorbestellung'}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Hinweistext"
              value={values.service_information}
              type="text"
              onChange={handleChange('service_information')}
              className={classes.input}
              margin="dense"
              error={errors.includes('service_information')}
              variant="outlined"
            />
          </Grid>
          {((values.table_type_override &&
            values.table_type_override !== 'DINEIN' &&
            values.table_type_override !== 'RESERVATION') ||
            (!values.table_type_override &&
              values.table_type !== 'DINEIN' &&
              values.table_type !== 'RESERVATION')) && (
            <>
              <Grid item xs={12}>
                <Typography>Optionen:</Typography>
              </Grid>
              <Grid item xs={12} sm={values.allow_preorder ? 4 : 12}>
                <TextField
                  label="Vorlaufzeit in Minuten"
                  value={tableConfig.leadTime}
                  placeholder="0"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleTableConfigChange('leadTime')}
                  className={classes.input}
                  margin="dense"
                  error={errors.includes('leadTime')}
                  variant="outlined"
                />
              </Grid>
              {values.allow_preorder && (
                <>
                  <Grid item xs={12} sm={4} className={classes.switch}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={tableConfig.ignoreLeadTimeWhenOpening}
                          value={tableConfig.ignoreLeadTimeWhenOpening}
                          onChange={handleTableConfigChange(
                            'ignoreLeadTimeWhenOpening'
                          )}
                          color="primary"
                        />
                      }
                      label={'Erste Bestellung mit Öffnungszeit ident'}
                    />
                    <div style={{ marginRight: theme.spacing(2) }}>
                      <InfoTooltip text="Wenn aktiv dann entspricht die erste Bestellmöglichkeit der Öffnungszeit. zB. Öffnungszeit 8:00 bedeutet die erste Bestellung die um 8:00 möglich. Wenn deaktiviert wird die Vorlaufzeit (im Beispiel 30 Minuten) hinzugezählt und die erste Bestellung ist erst um 8:30 möglich" />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Vorbestelltage (Standard: 1)"
                      value={tableConfig.preorderDays}
                      type="number"
                      placeholder="1"
                      onChange={handleTableConfigChange('preorderDays')}
                      className={classes.input}
                      margin="dense"
                      InputProps={{ inputProps: { min: 0 } }}
                      helperText="Wie viele Tage im Voraus kann ein Gast vorbestellen (0 = Nur Heute)"
                      error={errors.includes('preorderDays')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Slot in Minuten (Standard: 15)"
                      value={tableConfig.slotLength}
                      type="number"
                      placeholder="15"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={handleTableConfigChange('slotLength')}
                      className={classes.input}
                      margin="dense"
                      error={errors.includes('slotLength')}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {(values.table_type_override === 'DELIVERY' ||
            values.table_type === 'DELIVERY') && (
            <>
              <Grid item xs={12}>
                <Typography>Lieferoptionen:</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Liefergebühr"
                  value={deliveryConfig.deliveryFee}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleDeliveryConfigChange('deliveryFee')}
                  className={classes.input}
                  margin="dense"
                  error={errors.includes('deliveryFee')}
                  variant="outlined"
                  helperText="Betrag in Cent"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Mindestbestellwert"
                  value={deliveryConfig.minOrderAmount}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleDeliveryConfigChange('minOrderAmount')}
                  className={classes.input}
                  margin="dense"
                  error={errors.includes('minOrderAmount')}
                  variant="outlined"
                  helperText="Betrag in Cent"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Kostenlose Lieferung ab"
                  value={deliveryConfig.freeDeliveryFrom}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Checkbox
                          checked={freeDeliveryFrom}
                          onChange={() =>
                            setFreeDeliveryFrom(!freeDeliveryFrom)
                          }
                          value={freeDeliveryFrom}
                          color="primary"
                        />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleDeliveryConfigChange('freeDeliveryFrom')}
                  className={classes.input}
                  margin="dense"
                  disabled={!freeDeliveryFrom}
                  error={errors.includes('freeDeliveryFrom')}
                  variant="outlined"
                  helperText="Betrag in Cent"
                />
              </Grid>
              <Grid item xs={12}>
                <Alert
                  variant="filled"
                  severity="info"
                  className={classes.margin}
                >
                  <AlertTitle>Hinweis</AlertTitle>
                  Kicken sie auf das Polygon Symbol um Ihr Liefergebiet auf der
                  Landkarte einzuzeichnen.
                </Alert>
                <MapPolygonEdit
                  area={deliveryConfig.area as string}
                  onChange={handleDeliveryConfigChange('area')}
                />
              </Grid>
              {isAdmin && (
                <Grid item xs={12}>
                  <TextField
                    label="Liefergebiet"
                    required={true}
                    value={deliveryConfig.area}
                    type="text"
                    onChange={handleDeliveryConfigChange('area')}
                    className={classes.input}
                    margin="dense"
                    multiline={true}
                    rows={4}
                    error={errors.includes('area')}
                    variant="outlined"
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6}>
            <Button
              className={classes.input}
              onClick={onClose}
              variant="outlined"
            >
              Verwerfen
              <ClearRounded className={classes.rightIcon} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              className={classes.input}
              onClick={submitForm}
              variant="contained"
              color="primary"
            >
              Speichern
              <DoneRounded className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default TableForm;
