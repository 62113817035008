import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import queryString from 'query-string';
import AppInit from './containers/AppInit';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import Config from './config';

if (process.env.NODE_ENV === 'production') {
  // Supress console output
  console.log = () => null;
  console.debug = () => null;
  console.info = () => null;
  console.warn = () => null;
}

Sentry.init({
  dsn: Config.sentryDsn,
  environment: Config.stage,
  release: process.env.REACT_APP_VERSION,
  enabled: process.env.NODE_ENV === 'production'
});

const queryParams = queryString.parse(window.location.search);

const app = (
  <Sentry.ErrorBoundary
    fallback={'Ein Fehler ist aufgetreten. Bitte neu laden.'}
    showDialog={true}
  >
    <RecoilRoot>
      <Router>{queryParams.isApp === '1' ? <AppInit /> : <App />}</Router>
    </RecoilRoot>
  </Sentry.ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
