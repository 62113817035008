import React from 'react';

interface Props {
  imageSource?: string;
  classes?: string;
  width?: number;
  height?: number;
  quality?: number;
  devicePixelRatio?: number;
  altTag?: string;
  auto?: boolean;
  onClick?: () => void;
}

const Picture: React.FC<Props> = React.forwardRef(
  (
    {
      imageSource,
      classes,
      height,
      width,
      quality,
      devicePixelRatio,
      auto = true,
      altTag = '',
      onClick
    }: Props,
    ref
  ) => {
    const getParams = () => {
      let params = '';

      if (height) {
        params = params.concat(
          !params.includes('?') ? '?' : '&',
          'h=',
          height.toString()
        );
      }
      if (width) {
        params = params.concat(
          !params.includes('?') ? '?' : '&',
          'w=',
          width.toString()
        );
      }
      if (quality) {
        params = params.concat(
          !params.includes('?') ? '?' : '&',
          'q=',
          quality.toString()
        );
      }
      if (devicePixelRatio) {
        params = params.concat(
          !params.includes('?') ? '?' : '&',
          'dpr=',
          devicePixelRatio.toString()
        );
      }
      if (auto) {
        params = params.concat(
          !params.includes('?') ? '?' : '&',
          'auto=format,compress'
        );
      }

      return params;
    };

    if (!imageSource) {
      return null;
    }

    return (
      <div className={classes} onClick={onClick}>
        <picture>
          <source
            type="image/webp"
            srcSet={`${imageSource}${getParams()}&fm=webp`}
            style={{ width: width, margin: '0 auto' }}
          />
          <img
            alt={altTag}
            src={`${imageSource}${getParams()}`}
            style={{ width: width, margin: '0 auto' }}
          />
        </picture>
      </div>
    );
  }
);

export default Picture;
